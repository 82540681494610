import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataColumnProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { useMemo, useState } from "react";

type MTClientSecurityListRes = {
    serverUno: number;
    serverDn: string;
    login: number;
    changeType: number;
    isDelete: number;
};

type MTClientSecurityListDataColumn = {
    rowKey: string;
    serverUno: number;
    serverDn: string;
    login: number;
    changeType: string;
    isDelete: string;
};
const MTClientSecurityList = () => {
    const [data, setData] = useState<MTClientSecurityListDataColumn[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RC_MT_CLIENT_SECURITY_EDIT);

    const changeTypeArray = [`Schedule change`, `Resign common accounts`, `Resign personal accounts`];
    const deleteTypeArray = [`Not resign yet`, `Resign`, `Disable`];

    const columns: FlexiDataColumnProps[] = useMemo(
        () => [
            DTColProps.Middle({
                title: "Search All",
                dataIndex: "serverUno",
                key: "serverUno",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.text,
                        text: "",
                        callback: (filterValue: string, rowData: MTClientSecurityListDataColumn) => {
                            let findText = filterValue.toLowerCase();
                            return Object.values(rowData).some((value: any) => `${value}`.toLowerCase().indexOf(findText) > -1);
                        },
                    },
                },
            }),
            DTColProps.Middle({
                title: "Server",
                dataIndex: "serverDn",
                key: "serverDn",
                sorter: (a: MTClientSecurityListDataColumn, b: MTClientSecurityListDataColumn) => a.serverDn.localeCompare(b.serverDn),
            }),
            DTColProps.Middle({
                title: "Login",
                dataIndex: "login",
                key: "login",
                sorter: (a: MTClientSecurityListDataColumn, b: MTClientSecurityListDataColumn) => a.login - b.login,
            }),
            {
                title: "Change Type",
                dataIndex: "changeType",
                key: "changeType",
            },
            DTColProps.Middle({
                title: "Is Delete",
                dataIndex: "isDelete",
                key: "isDelete",
            }),
        ],
        []
    );

    const options: FlexiDataTableOptionsProps = {
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, data: MTClientSecurityListDataColumn) => {
        switch (type) {
            case CALLBACK_KEY.DO_DELETE:
                deleteLogin(data.serverUno, data.login);
                break;
            default:
                break;
        }
    };

    function fetchData() {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_MT_CLIENT_SECURITY.GET_ACCOUNTS)
            .then(res => {
                if (res.data) {
                    const data: MTClientSecurityListDataColumn[] = res.data.map((x: MTClientSecurityListRes) => ({
                        ...x,
                        rowKey: `${x.serverUno}-${x.login}-${x.changeType}`,
                        changeType: changeTypeArray[x.changeType - 1],
                        isDelete: deleteTypeArray[x.isDelete],
                    }));
                    setData(data);
                }
            })
            .catch((error: any) => {
                return ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Initial List", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function deleteLogin(serverUno: number, login: number) {
        setIsLoading(true);
        plainAxiosInstance
            .delete(`${APIs.RC_MT_CLIENT_SECURITY.DELETE_ACCOUNT}/${serverUno}/${login}`)
            .then((res: any) => {
                if (res.status === 200) {
                    ErrorMessageHandler(`Login ${login}`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                    fetchData();
                } else {
                    ErrorMessageHandler(`Login ${login}`, SUCCESS_FAILED.FAILED_DELETE_DATA);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Login ${login}`, SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }

    useMemo(() => {
        fetchData();
    }, []);

    return (
        <div className="security-list-container">
            <FlexiDataTable
                rowKeyProperty={"rowKey"}
                title={""}
                columns={columns}
                dataSource={data}
                loading={isLoading}
                options={options}
                callback={componentCallback}
            />
        </div>
    );
};

export default MTClientSecurityList;
