import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";
import { SUCCESS_FAILED, ComponentType, InnerPageActionMode } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CreateEditInlineFormBased } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { Form, Row, Col } from "antd";
import { useState, useEffect, useMemo } from "react";

export interface SymbolAssetTypeCreateEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

const initialValues = {
    symbolAssetTypeId: "",
    symbolAssetType: "",
};

const SymbolAssetTypeCreateEditPage = (props: SymbolAssetTypeCreateEditPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [symbolAssetTypeForm] = Form.useForm();

    const stateInfo = useMemo(() => {
        return {
            isAddAction: props.mode === InnerPageActionMode.CREATE_NEW,
            data: defaultIfEmptyOrNull(props.id, ""),
        };
    }, [props.mode, props.id]);

    const processBackAndRefetch = () => setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);

    const onSubmit = (values: any) => {
        setIsLoading(true);
        if (stateInfo.isAddAction) {
            apiRequest(APIs.CREATE_SYMBOL_ASSET_TYPE, values)
                .then(() => {
                    ErrorMessageHandler("New symbol asset type", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    processBackAndRefetch();
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new symbol asset type", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                )
                .finally(() => setTimeout(() => setIsLoading(false), 500));
        } else {
            apiRequest(APIs.UPDATE_SYMBOL_ASSET_TYPE, values)
                .then(() => {
                    ErrorMessageHandler("Existing symbol asset type", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    processBackAndRefetch();
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("existing symbol asset type", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                    )
                )
                .finally(() => setTimeout(() => setIsLoading(false), 500));
        }
    };

    const getSymbolAssetTypeList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_SYMBOL_ASSET_TYPE_LISTING, { symbolAssetTypeIds: [stateInfo.data] })
            .then((data: any) => {
                if (data) {
                    symbolAssetTypeForm.setFieldsValue(data[0]);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol asset type", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                processBackAndRefetch();
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (!stateInfo.isAddAction) {
            getSymbolAssetTypeList();
        } else {
            symbolAssetTypeForm.setFieldsValue(initialValues);
        }

        return () => {};
    }, [stateInfo, props.resetState]);

    return (
        <SitePageHeaderInline
            enableSubmit={!isLoading}
            title={stateInfo.isAddAction ? "Create New Symbol Asset Type" : "Edit Symbol Asset Type"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        symbolAssetTypeForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={symbolAssetTypeForm}
                    layout="horizontal"
                    initialValues={{}}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"symbolAssetTypeId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Symbol Asset Type"
                                name="symbolAssetType"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default SymbolAssetTypeCreateEditPage;
