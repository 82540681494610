import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState } from "react";
import ToxicClientLabelConfigList from "./LabelConfiguration";
import NotificationConfiguration from "./NotificationConfiguration";
import SourceConfiguration from "./SourceConfiguration";

export interface ToxicClientSettingsPageProps {}

const ToxicClientSettingsPage = (props: ToxicClientSettingsPageProps) => {
    const [segmentVal, setSegmentVal] = useState<string>("Label Configuration");

    return (
        <div className="toxic-client-setting-container">
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Toxic Client Settings</span>
                </div>
            </div>
            <div className="top-panel">
                <Segmented
                    options={["Label Configuration", "Source Configuration", "Notification Configuration"]}
                    value={segmentVal}
                    onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                />
            </div>
            <div className="container">
                {segmentVal === "Label Configuration" && <ToxicClientLabelConfigList />}
                {segmentVal === "Source Configuration" && <SourceConfiguration />}
                {segmentVal === "Notification Configuration" && <NotificationConfiguration />}
            </div>
        </div>
    );
};

export default ToxicClientSettingsPage;
