import { useState } from "react";
import { Segmented } from "antd";
import PriceConnectorPage from "./PriceConnector/newIndex";
import { SegmentedValue } from "antd/lib/segmented";

export interface PriceBackendOptBasedProps {
    pageType: number;
    dataType: number;
    multipleSelection?: boolean;
}

const TabRestartServer = () => {
    const [segmentVal, setSegmentVal] = useState<string>("Price Calculate Tools");

    return (
        <div className="global-setting-container">
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Price Backend Opt</span>
                </div>
            </div>
            <div className="top-panel">
                <Segmented
                    options={["Price Calculate Tools", "Price Connector", "Price Analysis Tools", "Tick Receiver Tools"]}
                    value={segmentVal}
                    onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                />
            </div>
            <div className="container">
                {segmentVal === "Price Calculate Tools" && <PriceConnectorPage pageType={1} dataType={1} multipleSelection />}
                {segmentVal === "Price Connector" && <PriceConnectorPage pageType={2} dataType={1} />}
                {segmentVal === "Price Analysis Tools" && <PriceConnectorPage pageType={3} dataType={2} multipleSelection />}
                {segmentVal === "Tick Receiver Tools" && <PriceConnectorPage pageType={4} dataType={3} multipleSelection />}
            </div>
        </div>
    );
};

export default TabRestartServer;
