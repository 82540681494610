import { DownOutlined, SendOutlined } from "@ant-design/icons";
import { Alert, Dropdown, MenuProps, Space, Spin, Tooltip } from "antd";
import { formatDistanceToNow } from "date-fns";

export interface ReportDropdownMenuProps {
    isSendHourlyReportBtnLoading: boolean;
    isSendEODReportBtnLoading: boolean;
    isSendSocialTradingReportBtnLoading: boolean;
    hourlyLastUpdatedAt: string | null;
    eodlastUpdatedAt: string | null;
    socialTradingLastUpdatedAt: string | null;
    sendHourlyReport: () => void;
    sendEODReport: () => void;
    sendSocialTradingReport: () => void;
}

const ReportDropdownMenu = (props: ReportDropdownMenuProps) => {
    const items: MenuProps["items"] = [
        {
            key: "hourly-report",
            label: (
                <Space className="report-item">
                    <div className="report-info">
                        <div className="sending-text-title">Hourly Report</div>
                        {props.hourlyLastUpdatedAt && (
                            <div className="sending-text-desc">{formatDistanceToNow(new Date(props.hourlyLastUpdatedAt), { addSuffix: true })}</div>
                        )}
                    </div>
                    <SendOutlined style={{ marginLeft: 8 }} />
                </Space>
            ),
            disabled: props.isSendHourlyReportBtnLoading,
            onClick: () => props.sendHourlyReport(),
        },
        {
            key: "eod-report",
            label: (
                <Space className="report-item">
                    <div className="report-info">
                        <div className="sending-text-title">End Of Day Report</div>
                        {props.eodlastUpdatedAt && (
                            <div className="sending-text-desc">{formatDistanceToNow(new Date(props.eodlastUpdatedAt), { addSuffix: true })}</div>
                        )}
                    </div>
                    <SendOutlined style={{ marginLeft: 8 }} />
                </Space>
            ),
            disabled: props.isSendEODReportBtnLoading,
            onClick: () => props.sendEODReport(),
        },
        {
            key: "social-trading-report",
            label: (
                <Space className="report-item">
                    <div className="report-info">
                        <div className="sending-text-title">Social Trading Report</div>
                        {props.socialTradingLastUpdatedAt && (
                            <div className="sending-text-desc">{formatDistanceToNow(new Date(props.socialTradingLastUpdatedAt), { addSuffix: true })}</div>
                        )}
                    </div>
                    <SendOutlined style={{ marginLeft: 8 }} />
                </Space>
            ),
            disabled: props.isSendSocialTradingReportBtnLoading,
            onClick: () => props.sendSocialTradingReport(),
        },
    ];

    // Determine which report was sent more recently
    const latestReport =
        props.hourlyLastUpdatedAt && props.eodlastUpdatedAt && props.socialTradingLastUpdatedAt
            ? new Date(props.hourlyLastUpdatedAt) > new Date(props.eodlastUpdatedAt) && new Date(props.hourlyLastUpdatedAt) > new Date(props.socialTradingLastUpdatedAt)
                ? "Hourly"
                : new Date(props.eodlastUpdatedAt) > new Date(props.socialTradingLastUpdatedAt)
                    ? "End Of Day"
                    : "Social Trading"
            : props.hourlyLastUpdatedAt && props.eodlastUpdatedAt
                ? new Date(props.hourlyLastUpdatedAt) > new Date(props.eodlastUpdatedAt)
                    ? "Hourly"
                    : "End Of Day"
                : props.hourlyLastUpdatedAt && props.socialTradingLastUpdatedAt
                    ? new Date(props.hourlyLastUpdatedAt) > new Date(props.socialTradingLastUpdatedAt)
                        ? "Hourly"
                        : "Social Trading"
                    : props.eodlastUpdatedAt && props.socialTradingLastUpdatedAt
                        ? new Date(props.eodlastUpdatedAt) > new Date(props.socialTradingLastUpdatedAt)
                            ? "End Of Day"
                            : "Social Trading"
                        : props.hourlyLastUpdatedAt
                            ? "Hourly"
                            : props.eodlastUpdatedAt
                                ? "End Of Day"
                                : "Social Trading";

    const latestTime =
        props.hourlyLastUpdatedAt && props.eodlastUpdatedAt && props.socialTradingLastUpdatedAt
            ? new Date(props.hourlyLastUpdatedAt) > new Date(props.eodlastUpdatedAt) && new Date(props.hourlyLastUpdatedAt) > new Date(props.socialTradingLastUpdatedAt)
                ? props.hourlyLastUpdatedAt
                : new Date(props.eodlastUpdatedAt) > new Date(props.socialTradingLastUpdatedAt)
                    ? props.eodlastUpdatedAt
                    : props.socialTradingLastUpdatedAt
            : props.hourlyLastUpdatedAt && props.eodlastUpdatedAt
                ? new Date(props.hourlyLastUpdatedAt) > new Date(props.eodlastUpdatedAt)
                    ? props.hourlyLastUpdatedAt
                    : props.eodlastUpdatedAt
                : props.hourlyLastUpdatedAt && props.socialTradingLastUpdatedAt
                    ? new Date(props.hourlyLastUpdatedAt) > new Date(props.socialTradingLastUpdatedAt)
                        ? props.hourlyLastUpdatedAt
                        : props.socialTradingLastUpdatedAt
                    : props.eodlastUpdatedAt && props.socialTradingLastUpdatedAt
                        ? new Date(props.eodlastUpdatedAt) > new Date(props.socialTradingLastUpdatedAt)
                            ? props.eodlastUpdatedAt
                            : props.socialTradingLastUpdatedAt
                        : props.hourlyLastUpdatedAt
                            ? props.hourlyLastUpdatedAt
                            : props.eodlastUpdatedAt
                                ? props.eodlastUpdatedAt
                                : props.socialTradingLastUpdatedAt

    return (
        <Dropdown menu={{ items }} trigger={["click"]} overlayClassName={"lp-monitor-summary-container-report-menu"}>
            <div className="send-report-dropdown">
                <Tooltip title="Click to select a report to send">
                    {props.isSendHourlyReportBtnLoading || props.isSendEODReportBtnLoading || props.isSendSocialTradingReportBtnLoading ? (
                        <Alert
                            message={
                                <Space size="large">
                                    <Spin size="large" />
                                    <div>
                                        <div className="sending-text-title">
                                            Sending{" "}
                                            {props.isSendEODReportBtnLoading && props.isSendHourlyReportBtnLoading && props.isSendSocialTradingReportBtnLoading
                                                ? "Hourly, EOD and Social Trading"
                                                : props.isSendEODReportBtnLoading
                                                    ? "EOD"
                                                    : props.isSendHourlyReportBtnLoading
                                                        ? "Hourly"
                                                        : props.isSendSocialTradingReportBtnLoading
                                                            ? "Social Trading"
                                                            : ""}{" "}
                                            report...
                                        </div>
                                        <div className="sending-text-desc">This may take a moment.</div>
                                    </div>
                                    <DownOutlined />
                                </Space>
                            }
                            type="warning"
                            style={{ height: "100%" }}
                        />
                    ) : (
                        <div className="send-hourly-report">
                            <Space>
                                {latestTime ? (
                                    <div className="report-info">
                                        <div className="sending-text-desc">Latest report sent: </div>
                                        <div className="sending-text-title">
                                            {latestReport} Report - {formatDistanceToNow(new Date(latestTime), { addSuffix: true })}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="report-info">Select Report</div>
                                )}
                                <DownOutlined />
                            </Space>
                        </div>
                    )}
                </Tooltip>
            </div>
        </Dropdown>
    );
};

export default ReportDropdownMenu;
