import { useState } from "react";
import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import PriceAnalysisGroupSettings from "./components/PriceAnalysisGroup";
import PriceAnalysisSymbolSettingsPage from "./components/SymbolSettingsPage";

export interface PriceAnalysisSettingPageProps {}

export interface ServersProps {
    groupId: number;
    isBaseServer: boolean;
    serverDn: string;
    serverUno: number;
}

const PriceAnalysisSettingPage = (props: PriceAnalysisSettingPageProps) => {
    const [segmentVal, setSegmentVal] = useState<string>("Group Settings");

    return (
        <div className="price-analysis-setting-container">
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Price Analysis Settings</span>
                </div>
            </div>
            <div className="top-panel">
                <Segmented
                    options={["Group Settings", "Symbol Settings"]}
                    value={segmentVal}
                    onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                />
            </div>
            <div className="container">
                {segmentVal === "Group Settings" && <PriceAnalysisGroupSettings />}
                {segmentVal === "Symbol Settings" && <PriceAnalysisSymbolSettingsPage />}
            </div>
        </div>
    );
};

export default PriceAnalysisSettingPage;
