import { useEffect, useState } from "react";
import { ClientAnalysisDailyExposuresbyLogin } from "@/constants/type";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { isEmptyOrNull } from "@/utils/string";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import CustomSkeleton from "@/components/Common/Skeleton";
import EmptyData from "@/components/Common/Empty";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    LegendComponent,
    ToolboxComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
]);

interface DailyExposureChartProps {
    server?: string;
    login?: number | string;
    isInitialLoading: boolean;
};

const DailyExposureChart = (props: DailyExposureChartProps) => {
    const [data, setData] = useState<ClientAnalysisDailyExposuresbyLogin[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(props.isInitialLoading);

    const options: any = {
        // title: {
        //     text: "Daily Exposure",
        // },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                animation: false,
            },
        },
        legend: {
            data: ["True Leverage", "Exposure"],
        },
        toolbox: {
            itemSize: 20,
            feature: {
                show: true,
                dataView: { show: true, readOnly: false },
                saveAsImage: {
                    show: true,
                    title: "Save Image As",
                },
            },
        },
        axisPointer: {
            link: [
                {
                    xAxisIndex: "all",
                },
            ],
        },
        dataZoom: [
            {
                show: true,
                realtime: true,
                start: 0,
                end: 100,
                xAxisIndex: [0, 1]
            },
            {
                type: 'inside',
                realtime: true,
                start: 0,
                end: 100,
                xAxisIndex: [0, 1]
            }
        ],
        grid: [
            {
                left: 60,
                right: 50,
                height: "30%",
            },
            {
                left: 60,
                right: 50,
                top: "60%",
                height: "30%",
            },
        ],
        xAxis: [
            {
                type: "category",
                boundaryGap: false,
                axisLine: { onZero: true },
                data: data.map((x: ClientAnalysisDailyExposuresbyLogin) => x.dateYMD),
            },
            {
                gridIndex: 1,
                type: "category",
                boundaryGap: false,
                axisLine: { onZero: true },
                data: data.map((x: ClientAnalysisDailyExposuresbyLogin) => x.dateYMD),
            },
        ],
        yAxis: [
            {
                name: "True Leverage",
                type: "value",
            },
            {
                gridIndex: 1,
                name: "Exposure",
                type: "value",
            },
        ],
        series: [
            {
                name: "True Leverage",
                type: "line",
                smooth: true,
                showSymbol: false,
                data: data.map((x: ClientAnalysisDailyExposuresbyLogin) => x.trueLeverage),
            },
            {
                name: "Exposure",
                type: "line",
                smooth: true,
                showSymbol: false,
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: data.map((x: ClientAnalysisDailyExposuresbyLogin) => x.exposure),
            },
        ],
    };

    useEffect(() => {
        setIsLoading(true);
        const formData = new FormData();
        if (props && !isEmptyOrNull(props.login) && !isEmptyOrNull(props.server)) {
            formData.append("oldServer", props.server as string);
            formData.append("login", props.login ? props.login.toString() : "");
            plainAxiosInstance
                .post(`${APIs.RC_CLIENT_ANALYSIS.POST_DAILY_EXPOSURES_BY_LOGIN}`, formData)
                .then((res: any) => {
                    if (res.data.length === 0) setData([]);
                    else {
                        let newData = res.data.map((x: ClientAnalysisDailyExposuresbyLogin) => ({
                            ...x,
                            exposure: parseFloat(x.exposure.toFixed(2)),
                            trueLeverage: parseFloat(x.trueLeverage.toFixed(2)),
                        }))
                        setData(newData);
                    };
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("daily exposure chart", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                }))
                .finally(() => setIsLoading(false));
        } else setIsLoading(false);
        return () => {
            setIsLoading(false);
        };
    }, [props.server, props.login]);

    return (
        <div className="client-analysis-chart-panel">
            <div className="title-box">
                <span className={`title-box-text`}>Daily Exposure</span>
            </div>
            {isLoading ? (
                <CustomSkeleton rows={7} />
            ) : data.length > 0
                ? (
                    <>
                        <ReactEChartsCore
                            echarts={echarts}
                            option={options}
                            showLoading={isLoading}
                            notMerge={true}
                            style={{ minHeight: 400 }}
                        />
                    </>
                ) : (
                    <>
                        <EmptyData />
                    </>
                )}
        </div>
    );
};

export default DailyExposureChart;