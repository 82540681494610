import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    PlayCircleOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EditBatchRecordBased, executeStatusLabel } from "./editBatchComponent";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DefaultIfEmpty } from "@/utils/object";
import { Modal, Tooltip, Typography, message } from "antd";
import { ToObjectWithKey } from "@/utils/array";
import { isEmptyOrNull } from "@/utils/string";
import Step3DownloadModal, { Step3DownloadModalCallbackKey } from "./step3DownloadModal";

const { Title } = Typography;

interface Step3SupplementaryProps extends EditBatchRecordBased {
    servers: KeyValuePair[];
}

interface FilterParamsProps {
    serverId: string;
}

interface SupplementaryStatusProps {
    status: number;
    allCount: number;
    successCount: number;
}

export const getSupplementaryList = (batchId: string, serverId: string) =>
    plainAxiosInstance.get(`${APIs.RISK_TOOL.GET_ROLLOVER_SUPPLEMENTARY_LIST}?batchId=${batchId}&serverId=${serverId}`);

const Step3Supplementary = (props: Step3SupplementaryProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isDownLoadModalVisible, setIsDownLoadModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [supplementaryStatus, setSupplementaryStatus] = useState<SupplementaryStatusProps>({ status: 0, allCount: 0, successCount: 0 });
    const [filterParams, setFilterParams] = useState<FilterParamsProps>({ serverId: "" });

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_ROLLOVER_EDIT);

    const executeStatusObj: React.ReactNode = useMemo(() => {
        let sObj = ToObjectWithKey(executeStatusLabel, "value"),
            statusObj = DefaultIfEmpty(sObj, supplementaryStatus.status, { label: "Init", value: 0, btnType: "" });
        return (
            <div className="flex-row" style={{ alignItems: "center" }}>
                <Title level={5} {...(!isEmptyOrNull(statusObj) && { type: statusObj.btnType })} style={{ marginBottom: "0" }}>
                    Execute status: {statusObj.label}
                </Title>
                <Tooltip title={`${supplementaryStatus.successCount} ( Success Count ) / ${supplementaryStatus.allCount} ( Total Count )`}>
                    <span
                        style={{ fontSize: "1rem", lineHeight: "24px", marginLeft: "0.9vw" }}
                    >{` [ ${supplementaryStatus.successCount} / ${supplementaryStatus.allCount} ] `}</span>
                </Tooltip>
            </div>
        );
    }, [supplementaryStatus]);

    const columns = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "server",
                key: "server",
                sorter: (a: any, b: any) => a.server - b.server,
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: props.servers,
                    },
                },
            }),
            DTColProps.XSmall({
                title: "Login",
                dataIndex: "login",
                key: "login",
            }),
            DTColProps.XSmall(
                {
                    title: "Adjustment Product",
                    dataIndex: "adjustmentProduct",
                    key: "adjustmentProduct",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Currency Product",
                    dataIndex: "currencyProduct",
                    key: "currencyProduct",
                },
                ["text-center"]
            ),
            DTColProps.XSmall(
                {
                    title: "Exchange Rate",
                    dataIndex: "exchangeRate",
                    key: "exchangeRate",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Adjustment Client",
                    dataIndex: "adjustmentClient",
                    key: "adjustmentClient",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Currency Client",
                    dataIndex: "currencyClient",
                    key: "currencyClient",
                },
                ["text-center"]
            ),
            DTColProps.XSmall({
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
            }),
            DTColProps.XSmall(
                {
                    title: "Supplemented",
                    dataIndex: "status",
                    key: "status",
                    sorter: (a: any, b: any) => a.status - b.status,
                    render: (text: number) => (
                        <span>
                            {text === 1 ? (
                                <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                            ) : (
                                <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                            )}
                        </span>
                    ),
                },
                ["text-center"]
            ),
            DTColProps.XSmall({
                title: "Error Message",
                dataIndex: "errorMsg",
                key: "errorMsg",
            }),
        ],
        [props.servers]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            serverFiltering: true,
            showHideColumns: false,
            ...(enableUpdate
                ? {
                      extraButtons: [
                          {
                              text: "Download CSV (not supplemented)",
                              icon: <DownloadOutlined />,
                              value: "downloadCSV",
                          },
                          {
                              text: "Refresh supplemented status",
                              icon: <SyncOutlined />,
                              value: "refreshSupplementedStatus",
                          },
                          {
                              text: "Execute supplementary",
                              icon: <PlayCircleOutlined />,
                              value: "executeSupplementary",
                              extras: { disabled: props.currentStep !== 2 || supplementaryStatus.status === 1 || isLoading },
                          },
                          {
                              text: "Finish supplementary step",
                              icon: <CheckCircleOutlined />,
                              value: "finishSupplementaryStep",
                              type: "primary",
                              extras: { danger: true, disabled: props.currentStep !== 2 || isLoading },
                          },
                      ],
                  }
                : {
                      extraButtons: [
                          {
                              text: "Download CSV (not supplemented)",
                              icon: <DownloadOutlined />,
                              value: "downloadCSV",
                          },
                      ],
                  }),
        }),
        [props.currentStep, supplementaryStatus, isLoading, enableUpdate]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setFilterParams(
                    Object.keys(FormData).reduce((acc: any, key: string) => {
                        if (FormData[key] === undefined) return acc;

                        if (key === "server") {
                            acc.serverId = FormData[key];
                        } else {
                            acc[key] = FormData[key];
                        }
                        return acc;
                    }, {})
                );
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "executeSupplementary") {
                    executeSupplementary();
                } else if (FormData === "refreshSupplementedStatus") {
                    refreshSupplementedStatus();
                } else if (FormData === "finishSupplementaryStep") {
                    finishedSupplementaryStep();
                } else if (FormData === "downloadCSV") {
                    setIsDownLoadModalVisible(true);
                }
                break;
            default:
                break;
        }
    };

    const finishedSupplementaryStep = useCallback(() => {
        setIsLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure finish supplementary step?",
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_FINISH_SUPPLEMENTARY_STEP}?batchId=${props.batchId}`)
                    .then(res => {
                        if (res.data === "success") {
                            ErrorMessageHandler("Finish supplementary step successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                            props.refreshBatch();
                        } else {
                            ErrorMessageHandler(`Finish supplementary step failed. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler(`Finish supplementary step failed: (${error.response.data.message}).`, SUCCESS_FAILED.OTHERS_FAILED)
                        )
                    )
                    .finally(() => setIsLoading(false));
            },
            onCancel: () => {
                setIsLoading(false);
            },
        });
    }, [props]);

    const refreshSupplementedStatus = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_REFRESH_SUPPLEMENTARY_STATUS}?batchId=${props.batchId}`)
            .then((res: any) => {
                if (res.data === "success") {
                    ErrorMessageHandler("Refresh supplemented status successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                    setRunRefetchDataList(true);
                } else {
                    ErrorMessageHandler(`Refresh supplemented status failed. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("roll over refresh supplemented status", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    }, [props.batchId]);

    const executeSupplementary = useCallback(() => {
        setIsLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure execute supplementary?",
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_EXECUTE_SUPPLEMENTARY}?batchId=${props.batchId}`)
                    .then(res => {
                        if (res.data.status === 0) {
                            ErrorMessageHandler("Execute Supplementary successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                            setRunRefetchDataList(true);
                        } else {
                            ErrorMessageHandler(`${res.data.msg}.`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler(`Execute Supplementary failed: (${err.response.data.message})`, SUCCESS_FAILED.OTHERS_FAILED)
                        )
                    )
                    .finally(() => setIsLoading(false));
            },
            onCancel: () => {
                setIsLoading(false);
            },
        });
    }, [props.batchId]);

    const getDataList = useCallback(() => {
        setIsLoading(true);
        getSupplementaryList(props.batchId, DefaultIfEmpty(filterParams, "serverId", ""))
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data : []);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("supplementary list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                })
            )
            .finally(() => setIsLoading(false));
    }, [props.batchId, filterParams]);

    const getSupplementaryStatusCount = useCallback(() => {
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_EXECUTE_SUPPLEMENTARY_DEDUCTION_STATUS}?batchId=${props.batchId}`)
            .then((res: any) => {
                if (res.data) {
                    setSupplementaryStatus({
                        status: res.data.supplementary.status,
                        allCount: res.data.supplementary.allCount,
                        successCount: res.data.supplementary.successCount,
                    });
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, () => console.log("Error retrieve supplementary status count")));
    }, [props.batchId]);

    useEffect(() => {
        if (runRefetchDataList) {
            getDataList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList, getDataList]);

    useEffect(() => {
        getSupplementaryStatusCount();
    }, []);

    return (
        <>
            <div className="rollover-tool-step3-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="login"
                    title={executeStatusObj}
                    columns={columns}
                    options={options}
                    dataSource={data}
                    loading={isLoading}
                    callback={componentCallback}
                    filterInitialValue={{ server: "" }}
                />
            </div>
            <Step3DownloadModal
                batchId={props.batchId}
                servers={props.servers}
                isModalVisible={isDownLoadModalVisible}
                callback={(type: number) => {
                    switch (type) {
                        case Step3DownloadModalCallbackKey.Close:
                            setIsDownLoadModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
            />
        </>
    );
};

export default Step3Supplementary;
