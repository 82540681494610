import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

/*
sample response from the API
[
  {
    "type": 1,
    "name": "RC可用DC",
    "order": null
  },
  {
    "type": 2,
    "name": "專線跳板 DC",
    "order": null
  },
  {
    "type": 4,
    "name": "CRM專用",
    "order": null
  },
  {
    "type": 8,
    "name": "APP專用",
    "order": null
  },
  {
    "type": 16,
    "name": "橋提供 DC",
    "order": null
  },
  {
    "type": 32,
    "name": "Backup DC",
    "order": null
  }
]
*/

interface IRCDataCenterTypes {
    type: number;
    name: string;
    order: null;
}

/*
sample response for rcDataCenter
{
    "serverUno": 0,
    "serverId": "VT1",
    "dcName": "DC1 - Sydney",
    "lastUpdate": "2024-05-30 09:15:07",
    "dcUrl": "47.91.47.217:443",
    "typeFlag": 0,
    "loginError": 2,
    "cpuWarning": 0,
    "avgCpuRate": 12,
    "freeMemoryWarning": 0,
    "avgFreeMemory": 2115,
    "freeDiskWarning": 0,
    "avgFreeDisk": 32,
    "nicWarning": 0,
    "avgNic": 2,
    "pingTime": 250,
    "lastUpdateWarning": 0
}
*/

export interface IRCDataCenterData {
    avgCpuRate: number;
    avgFreeDisk: number;
    avgFreeMemory: number;
    avgNic: number;
    cpuWarning: number;
    dcName: string;
    dcUrl: string;
    freeDiskWarning: number;
    freeMemoryWarning: number;
    lastUpdate: string;
    lastUpdateWarning: number;
    loginError: number;
    nicWarning: number;
    pingTime: number;
    serverId: string;
    serverUno: number;
    typeFlag: number;
}
export interface IRCDataCenter {
    serverTime: string;
    data: IRCDataCenterData[];
}

const useRCDataCenter = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcDataCenter"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_DATA_CENTER.GET_DATA_CENTER_MONITOR}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 10 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 10 * 1000, //(60 x 1000ms = 60 seconds)
    });

    const rcDataCenter: IRCDataCenter | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcDataCenterError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcDataCenter,
        rcDataCenterError,
        refetchRcDataCenter: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

export const useRCDataCenterTypes = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcDataCenterTypes"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_DATA_CENTER.GET_DATA_CENTER_TYPES}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcDataCenterTypes: IRCDataCenterTypes[] | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcDataCenterTypesError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcDataCenterTypes,
        rcDataCenterTypesError,
        refetchRcDataCenterTypes: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

/*
sample
{
    "dcId": 111,
    "serverId": "PUG",
    "dcName": "DC1 - NY",
    "enable": 1,
    "dedicated": 0,
    "typeFlag": 16,
    "dcUrl": "192.81.111.241:443",
    "comment": null,
    "serverUno": 0,
    "city": "New York",
    "isp": "Beeks"
}
*/
interface IRCDataCenterServers {
    dcId: number;
    serverId: string;
    dcName: string;
    enable: number;
    dedicated: number;
    typeFlag: number;
    dcUrl: string;
    comment: string;
    serverUno: number;
    city: string;
    isp: string;
}

export const useRCDataCenterServers = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcDataCenterServers"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_DATA_CENTER.GET_DATA_CENTER_MONITOR_SERVERS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcDataCenterServers: IRCDataCenterServers[] | null = data?.data?.status ? null : data?.data || null;

    // @ts-ignore
    const rcDataCenterServersError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcDataCenterServers,
        rcDataCenterServersError,
        refetchRcDataCenterServers: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

/*
sample response
{
    "serverId": "default",
    "dcName": "default",
    "cpuRateMax": 80,
    "cpuMaxToleranceCount": 5,
    "freeMemMin": 500,
    "freeMemMinToleranceCount": 3,
    "freeDiskMin": 2,
    "freeDiskMinToleranceCount": 3,
    "nicMax": 50,
    "nicMaxToleranceCount": 5,
    "maxExpiredNextUpdateTime": 5,
    "systemTaskIntervalMinutes": 1,
    "loginTaskIntervalMinutes": 5
}
*/

export interface IRCDataCenterSettings {
    serverId: string;
    dcName: string;
    cpuRateMax: number;
    cpuMaxToleranceCount: number;
    freeMemMin: number;
    freeMemMinToleranceCount: number;
    freeDiskMin: number;
    freeDiskMinToleranceCount: number;
    nicMax: number;
    nicMaxToleranceCount: number;
    maxExpiredNextUpdateTime: number;
    systemTaskIntervalMinutes: number;
    loginTaskIntervalMinutes: number;
}

export const useRCDataCenterSettings = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcDataCenterSettings"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_DATA_CENTER.GET_DATA_CENTER_MONITOR_SETTINGS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcDataCenterSettings: IRCDataCenterSettings[] | null = data?.data?.status ? null : data?.data || null;

    // @ts-ignore
    const rcDataCenterSettingsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcDataCenterSettings,
        rcDataCenterSettingsError,
        refetchRcDataCenterSettings: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

interface IRCDataCenterDownloadHistoryRequest {
    type: "LOGIN" | "HEALTH" | "NIC_FLOW" | "PING_TIME";
    serverId: string;
    dcName: string;
    startDateTime: string;
    endDateTime: string;
}

export const useRCDataCenterMutate = () => {
    const queryClient = useQueryClient();
    const {
        mutateAsync: downloadHistoryData,
        isLoading: isLoadingDownloadHistoryData,
        error: errorDownloadHistoryData,
    } = useMutation(
        async ({ type, serverId, dcName, startDateTime, endDateTime }: IRCDataCenterDownloadHistoryRequest): Promise<any> => {
            return plainAxiosInstance.post(
                `${APIs.RC_DATA_CENTER.POST_DATA_CENTER_MONITOR_DOWNLOAD_HISTORY}/${type}`,
                {
                    serverId,
                    dcName,
                    startDateTime,
                    endDateTime,
                },
                {
                    headers: {
                        Accept: "application/octet-stream, */*",
                    },
                }
            );
        },
        {
            onSuccess: res => {
                return res;
            },
            onError: error => {
                console.error(`Error on download:`, error);
                // @ts-ignore
                const errorString: string | null = error?.response?.data?.message || error?.message || error || null;
                return errorString;
            },
        }
    );
    const {
        mutateAsync: updateDCSettings,
        isLoading: isLoadingUpdateDCSettings,
        error: errorUpdateDCSettings,
    } = useMutation(
        async (data: IRCDataCenterSettings[]): Promise<any> => {
            return plainAxiosInstance.put(APIs.RC_DATA_CENTER.GET_DATA_CENTER_MONITOR_SETTINGS, [...data], {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcDataCenterSettings"]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcDataCenterSettings"]);
                console.error(`Error while update settings:`, error);
                // @ts-ignore
                const errorString: string | null = error?.response?.data?.message || error?.message || error || null;
                return errorString;
            },
        }
    );
    const {
        mutateAsync: deleteDCSettings,
        isLoading: isLoadingDeleteDCSettings,
        error: errorDeleteDCSettings,
    } = useMutation(
        async (data: Pick<IRCDataCenterSettings, "serverId" | "dcName">[]): Promise<any> => {
            return plainAxiosInstance.delete(APIs.RC_DATA_CENTER.GET_DATA_CENTER_MONITOR_SETTINGS, {
                headers: {
                    Accept: "application/json",
                },
                data: data,
            });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcDataCenterSettings"]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcDataCenterSettings"]);
                console.error(`Error while update settings:`, error);
                // @ts-ignore
                const errorString: string | null = error?.response?.data?.message || error?.message || error || null;
                return errorString;
            },
        }
    );

    return {
        downloadHistoryData,
        isLoadingDownloadHistoryData,
        errorDownloadHistoryData,

        updateDCSettings,
        isLoadingUpdateDCSettings,
        errorUpdateDCSettings,

        deleteDCSettings,
        isLoadingDeleteDCSettings,
        errorDeleteDCSettings,
    };
};
export const useRCDataCenterDownloadHistory = ({ type, serverId, dcName, startDateTime, endDateTime }: IRCDataCenterDownloadHistoryRequest) => {};

export default useRCDataCenter;
