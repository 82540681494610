import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { PlayCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EditBatchRecordBased } from "./editBatchComponent";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { DefaultIfEmpty } from "@/utils/object";

interface Step2OpenPositionProps extends EditBatchRecordBased {
    servers: KeyValuePair[];
}

interface FilterParamsProps {
    serverId: string;
}

const Step2OpenPosition = (props: Step2OpenPositionProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [filterParams, setFilterParams] = useState<FilterParamsProps>({ serverId: "" });

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_ROLLOVER_EDIT);

    const columns = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "server",
                key: "server",
                sorter: (a: any, b: any) => a.server - b.server,
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: props.servers,
                    },
                },
            }),
            DTColProps.Small({
                title: "Ticket/Position",
                dataIndex: "ticket",
                key: "ticket",
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
            }),
            DTColProps.Small({
                title: "Group",
                dataIndex: "accountGroup",
                key: "accountGroup",
            }),
            DTColProps.XSmall(
                {
                    title: "Type",
                    dataIndex: "type",
                    key: "type",
                    render: (type: number) => (type === 0 ? "BUY" : "SELL"),
                },
                ["text-center"]
            ),
            DTColProps.XSmall({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
            }),
            DTColProps.XSmall(
                {
                    title: "Volume",
                    dataIndex: "volume",
                    key: "volume",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Open price",
                    dataIndex: "openPrice",
                    key: "openPrice",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.Small({
                title: "Open time",
                dataIndex: "openTime",
                key: "openTime",
                render: (openTime: string) => (openTime ? moment(openTime).format("YYYY-MM-DD HH:mm:ss") : ""),
            }),
            DTColProps.XSmall(
                {
                    title: "Close price",
                    dataIndex: "closePrice",
                    key: "closePrice",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.Small({
                title: "Close time",
                dataIndex: "closeTime",
                key: "closeTime",
                render: (closeTime: string) => (closeTime ? moment(closeTime).format("YYYY-MM-DD HH:mm:ss") : ""),
            }),
            DTColProps.XSmall(
                {
                    title: "Sl",
                    dataIndex: "sl",
                    key: "sl",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Tp",
                    dataIndex: "tp",
                    key: "tp",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Swaps",
                    dataIndex: "swaps",
                    key: "swaps",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Commission",
                    dataIndex: "commission",
                    key: "commission",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Profit",
                    dataIndex: "profit",
                    key: "profit",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Currency",
                    dataIndex: "accountCurrency",
                    key: "accountCurrency",
                },
                ["text-center"]
            ),
            DTColProps.Small(
                {
                    title: "Contract Size",
                    dataIndex: "contractSize",
                    key: "contractSize",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
            DTColProps.Small(
                {
                    title: "Adj By Order",
                    dataIndex: "adjByOrder",
                    key: "adjByOrder",
                    render: (text: any) => currencyRender(text),
                },
                ["text-right"]
            ),
        ],
        [props.servers]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            serverFiltering: true,
            export: {
                text: "Download Excel",
            },
            ...(enableUpdate && {
                extraButtons: [
                    {
                        text: "Calculate open position order adjustment",
                        icon: <PlayCircleOutlined />,
                        value: "calculate",
                        extras: {
                            disabled: props.currentStep === 0 || props.currentStep === 4,
                        },
                    },
                ],
            }),
        }),
        [enableUpdate]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                exportToExcel();
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setFilterParams(
                    Object.keys(FormData)
                        .filter(x => FormData[x] !== undefined)
                        .reduce((acc: any, key: string) => {
                            if (key === "server") {
                                acc.serverId = FormData[key];
                            } else {
                                acc[key] = FormData[key];
                            }
                            return acc;
                        }, {})
                );
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "calculate") {
                    triggerCalculateOpenPositionOrderAdjustment();
                }
                break;
            default:
                break;
        }
    };

    const triggerCalculateOpenPositionOrderAdjustment = useCallback(() => {
        setIsLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure calculate open position adjustment?",
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_CALC_OPEN_POSITION}?batchId=${props.batchId}`)
                    .then(res => {
                        if (res.data === "success") {
                            ErrorMessageHandler("Calculate successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                            setRunRefetchDataList(true);
                            props.refreshBatch();
                        } else {
                            ErrorMessageHandler(`Calculate failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler(`Calculate failed: (${err.response.data.message})`, SUCCESS_FAILED.OTHERS_FAILED)
                        )
                    )
                    .finally(() => setIsLoading(true));
            },
            onCancel: () => {
                setIsLoading(false);
            },
        });
    }, [props]);

    const exportToExcel = useCallback(() => {
        try {
            plainAxiosInstance
                .get(`${APIs.RISK_TOOL.GET_ROLLOVER_DOWNLOAD_OPEN_POSITION}?batchId=${props.batchId}&serverId=${filterParams.serverId}`, {
                    headers: {
                        Accept: "application/octet-stream, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv;charset=UTF-8" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `OpenPosition_${moment().format("YYYYMMDDHHmmss")}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED));
        } catch (e: any) {
            ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    }, [props.batchId, filterParams]);

    const getOpenPositionList = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(
                `${APIs.RISK_TOOL.GET_ROLLOVER_OPEN_POSITION_LIST}?batchId=${props.batchId}&serverId=${DefaultIfEmpty(filterParams, "serverId", "")}`
            )
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data.map((x: any) => ({ ...x, key: `${x.batchId}_${x.server}_${x.ticket}` })) : []);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("roll over open position data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [props.batchId, filterParams]);

    useEffect(() => {
        if (runRefetchDataList) {
            getOpenPositionList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    return (
        <div className="rollover-tool-step2-container">
            <FlexiDataTable
                bordered
                rowKeyProperty="key"
                title={false}
                columns={columns}
                options={options}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
            />
        </div>
    );
};

export default Step2OpenPosition;
