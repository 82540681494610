import { Col, Form, message, Modal, Row } from "antd";
import { KeyValuePair } from "../../../../../constants/type";
import { useEffect, useState } from "react";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { APIs } from "../../../../../services/apis";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";

export interface ExecuteSymbolSwapUpdateModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
}

export enum ExecuteSymbolSwapUpdateModalCallbackKey {
    Close = 0,
}

const ExecuteSymbolSwapUpdateModal = (props: ExecuteSymbolSwapUpdateModalProps) => {
    const [serverList, setServerList] = useState<KeyValuePair[]>([]);
    const [executeForm] = Form.useForm();

    const confirmExecute = (selectedServerList: string[]) => {
        Modal.confirm({
            title: "Are you sure you want to update SymbolSwap?",
            content: "執行前請再次確認已詳讀FAQ, 並請確實查看Status Monitor(Daily)是否有執行中的UPDATE, 切勿在全數update完成之前重複執行!!!",
            onOk: () => {
                plainAxiosInstance
                    .post(APIs.RISK_TOOL.GET_SYMBOL_SWAP_UPLOAD_EXECUTE_UPDATE, selectedServerList)
                    .then((res: any) => {
                        if (res.status === 200 && res.data === 0) {
                            ErrorMessageHandler(
                                `Execute symbol swap update success. Please wait a moment for the job execution to complete.`,
                                SUCCESS_FAILED.OTHERS_SUCCESS
                            );
                        } else {
                            ErrorMessageHandler(`Failed to execute Symbol Swap Update. Please try again .`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler(`Failed to execute Symbol Swap Update. Error`, SUCCESS_FAILED.OTHERS_FAILED, err)
                        )
                    );
            },
        });
    };

    const getServerList = () => {
        plainAxiosInstance.get(APIs.RISK_TOOL.GET_SYMBOL_SWAP_UPLOAD_SERVER_LIST).then((res: any) => {
            if (res.data && res.data.length > 0) {
                let servers = res.data.map((x: any) => ({ text: x.label, value: x.value }));
                setServerList(servers);
                executeForm.setFieldValue(
                    "selectedServer",
                    servers.map((x: any) => x.value)
                );
            } else {
                setServerList([]);
            }
        });
    };

    useEffect(() => {
        if (props.isModalVisible) {
            getServerList();
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okText="Execute"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        confirmExecute(values.selectedServer);
                        props.callback(ExecuteSymbolSwapUpdateModalCallbackKey.Close, null);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(ExecuteSymbolSwapUpdateModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="vertical">
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Server"
                            name="selectedServer"
                            extra={{
                                type: ComponentType.dropdown,
                                value: serverList,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                inputProps: {
                                    mode: "multiple",
                                },
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ExecuteSymbolSwapUpdateModal;
