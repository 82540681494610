import React, { useRef, useEffect } from "react";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import { Space, Typography } from "antd";
import { colorMap } from "../StatusMonitor/statusUtils";
import Tooltip from "antd/es/tooltip";
import useMeasure from "react-use-measure";

const FloatingTitleBox = ({
    children,
    title,
    titleExtra = null,
    titleBarTabs = undefined,
    inViewCallback = undefined,
    minHeight = "50vh",
}: {
    title: string;
    titleExtra?: React.ReactNode;
    children?: React.ReactNode;
    titleBarTabs?: React.ReactNode;
    inViewCallback?: (isInView: boolean) => void;
    minHeight?: string;
}) => {
    const wrapperRef = useRef(null);
    const isInView = useInView(wrapperRef);
    const { scrollYProgress } = useScroll({
        target: wrapperRef,
        offset: ["start end", "end end"],
    });
    const [unmountHeight, setUnmountHeight] = React.useState<number | null>(null);
    useEffect(() => {
        try {
            if (inViewCallback !== undefined) {
                inViewCallback && inViewCallback(isInView);
            }
        } catch (e) {
            console.log(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInView]);

    const [ref, bounds] = useMeasure();

    useEffect(() => {
        let isMount = true;
        if (isMount) {
            // console.log(title, bounds?.height);
            if (isInView && bounds.height !== 0) {
                setUnmountHeight(bounds?.height);
            }
        }
        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInView, bounds?.height]);

    const height = useTransform(scrollYProgress, [0, 1], ["0px", "50px"]);

    const { Title } = Typography;
    return (
        <div style={{ display: "flex" }}>
            <motion.div
                ref={wrapperRef}
                style={{ flex: 1, minHeight: minHeight, background: "white", borderRadius: "0.25rem", position: "relative" }}
            >
                <section
                    style={{
                        position: "relative",
                        background: "hsl(218,20%,90%)",
                        borderRadius: "0.25rem 0.25rem 0 0",
                        paddingBottom: titleBarTabs ? 0 : "0.5rem",
                    }}
                >
                    <div
                        style={{
                            background: "rgba(243,244,247,0.95)",
                            display: "inline-block",
                            // position: "sticky",
                            top: "6.5rem",
                            marginLeft: "-0.25rem",
                            padding: "0.5rem 1.25rem",
                            backdropFilter: "blur(3px)",
                            boxShadow: `
                        0px 0.1px 0.4px rgba(0, 0, 0, 0.028),
                        0px 0.4px 1.3px rgba(0, 0, 0, 0.042),
                        0px 2px 6px rgba(0, 0, 0, 0.07)
                    `,
                            zIndex: 2,
                            marginTop: "0.5rem",
                        }}
                    >
                        <Space>
                            <Title level={4} style={{ margin: 0 }}>
                                {title}
                            </Title>
                            {titleExtra}
                        </Space>
                        <div
                            style={{
                                position: "absolute",
                                width: 0,
                                height: 0,
                                borderLeft: "5px solid transparent",
                                borderRight: "0px solid transparent",
                                borderTop: "5px solid #999",
                                left: 0,
                                bottom: -5,
                            }}
                        />
                        {/* write title as background with position absolute */}
                    </div>
                    {titleBarTabs /* && isInView  */ && <div>{titleBarTabs}</div>}
                </section>
                <section style={{ height: unmountHeight ? `${unmountHeight}px` : "auto" }}>
                    {
                        /* isInView &&*/ children && (
                            <div ref={ref}>
                                <>{children}</>
                            </div>
                        )
                    }
                </section>
            </motion.div>

            <div style={{ width: "6px" }}>
                <Tooltip title={`${title} - scroll progress`} placement="left">
                    <div
                        style={{
                            height: "50px",
                            position: "sticky",
                            top: "calc(50vh - 25px)",
                            marginLeft: "2px",
                            borderRadius: 4,
                            backgroundColor: "rgba(0,0,0,0.1)",
                            width: 4,
                        }}
                    >
                        <motion.div style={{ width: 4, height, background: colorMap.info, borderRadius: 4 }} />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default FloatingTitleBox;
