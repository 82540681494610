import { SUCCESS_FAILED } from "@/constants";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { message, Modal, Button, Upload } from "antd";
import moment from "moment";

interface BlackListPageProps {}

const BlackListPage = (props: BlackListPageProps) => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.MID_BIAS_PRICE_MONITORING_EDIT);

    const handleSubmit = (file: any, fileList: any) => {
        let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
            isLt5M = file.size / 1024 / 1024 < 5;

        if (fileExtension[0] !== ".xlsx") {
            ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        } else if (!isLt5M) {
            ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        }

        if (file === undefined) {
            ErrorMessageHandler(`Please upload any file.`, SUCCESS_FAILED.OTHERS_FAILED);
            return;
        }

        Modal.confirm({
            title: "Are you sure you want to import mid bias black list?",
            onOk: () => {
                var formData = new FormData();
                formData.append("uploadFile", file);

                plainAxiosInstance
                    .post(APIs.RC_MIDBIAS.POST_UPLOAD_BLACKLIST, formData)
                    .then(res => {
                        if (res.status === 200 && res?.data !== 0) {
                            ErrorMessageHandler(`Failed to upload mid bias black list. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
                        } else {
                            ErrorMessageHandler(`Mid bias black list uploaded successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler(`File upload failed. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED, err)
                        )
                    );
            },
        });
    };

    const handleDownload = () => {
        plainAxiosInstance
            .post(
                APIs.RC_MIDBIAS.POST_DOWNLOAD_BLACKLIST,
                {},
                {
                    headers: {
                        Accept: "application/octet-stream, */*",
                    },
                    responseType: "blob",
                }
            )
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const getCurrentDate = moment().format("YYYY-MM-DD_HH-mm-ss");
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `MidBias_Black_List_${getCurrentDate}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    ErrorMessageHandler(`Time Threshold downloaded  successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                } else {
                    ErrorMessageHandler(`Received non-file response. Error: ${response}.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch(err => {
                ErrorMessageHandler(`Download error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
            });
    };

    return (
        <>
            <div className="fclean-symbol-and-midbias-setting-container">
                <div className="main-container">
                    <div className="panel">
                        <div className="title">Black List</div>
                        <div>
                            <Button type="ghost" icon={<DownloadOutlined />} onClick={() => handleDownload()} style={{ marginRight: "0.9vw" }}>
                                Download
                            </Button>
                            {enableUpdate && (
                                <Upload
                                    {...{
                                        name: "file",
                                        accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                        multiple: false,
                                        showUploadList: false,
                                        onChange: (info: any) => {
                                            if (info.file.status === "error") {
                                                ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                                            }
                                        },
                                        customRequest: ({ file, onSuccess }: any) =>
                                            setTimeout(() => {
                                                onSuccess("ok");
                                            }, 0),
                                        beforeUpload: (file: any, fileList: any) => handleSubmit(file, fileList),
                                    }}
                                >
                                    <Button htmlType="button" icon={<UploadOutlined style={{ fontSize: "0.875rem" }} />} style={{ width: "auto" }}>
                                        Upload
                                    </Button>
                                </Upload>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlackListPage;
