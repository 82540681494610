import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, RollOverAdjustmentList } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { ExclamationCircleOutlined, MailOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EditBatchRecordBased } from "./editBatchComponent";

interface Step1AdjustmentProps extends EditBatchRecordBased {
    emailTo: string;
}

const Step1Adjustment = (props: Step1AdjustmentProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_ROLLOVER_EDIT);

    const columns = [
        {
            title: "Server",
            dataIndex: "server",
            key: "server",
        },
        {
            title: "Clean Symbol",
            dataIndex: "cleanSymbol",
            key: "cleanSymbol",
            sorter: (a: any, b: any) => a.cleanSymbol.localeCompare(b.cleanSymbol),
        },
        DTColProps.XSmall(
            {
                title: "Currency",
                dataIndex: "currency",
                key: "currency",
            },
            ["text-center"]
        ),
        DTColProps.Small(
            {
                title: "Old Bid",
                dataIndex: "oldBid",
                key: "oldBid",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Old Ask",
                dataIndex: "oldAsk",
                key: "oldAsk",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "New Bid",
                dataIndex: "newBid",
                key: "newBid",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "New Ask",
                dataIndex: "newAsk",
                key: "newAsk",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "AU Hub Roll Fee",
                dataIndex: "rollFee",
                key: "rollFee",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Contract Size",
                dataIndex: "contractSize",
                key: "contractSize",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Buy Adjustment",
                dataIndex: "buyAdjustment",
                key: "buyAdjustment",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Sell Adjustment",
                dataIndex: "sellAdjustment",
                key: "sellAdjustment",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            enableFilter: false,
            showHideColumns: false,
            ...(!isLoading && {
                extraButtons: [{ text: "Send Adjustment Value Mail", icon: <MailOutlined />, value: "sendEmail" }],
            }),
            ...(enableUpdate && {
                upload: {
                    uploadText: "Upload Adjustment List",
                    disabled: props.currentStep > 2,
                    onChange: (info: any) => {
                        if (info.file.status === "error") {
                            ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    },
                    customRequest: ({ onSuccess }: any) => setTimeout(() => onSuccess("ok"), 0),
                    beforeUpload: (file: any) => handleSubmit(file),
                },
            }),
        }),
        [enableUpdate, props.currentStep, isLoading]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.OTHERS:
                if (FormData === "sendEmail") {
                    sendEmail();
                }
                break;
            default:
                break;
        }
    };

    const sendEmail = useCallback(() => {
        setIsLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure to send adjustment value mail to these emails below?",
            content: (
                <ol>
                    {props.emailTo.split(",").map((x: string, idx: number) => (
                        <li key={`rl-ov-el-${idx}`}>{x}</li>
                    ))}
                </ol>
            ),
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_SEND_ADJUSTMENT_VALUE_EMAIL}?batchId=${props.batchId}`)
                    .then(res => {
                        if (res.data === "success") {
                            ErrorMessageHandler("Email sent successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                        } else {
                            ErrorMessageHandler(`Send mail failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler(`Send mail failed: (${err.response.data.message})`, SUCCESS_FAILED.OTHERS_FAILED)
                        )
                    )
                    .finally(() => setIsLoading(false));
            },
            onCancel: () => {
                setIsLoading(false);
            },
        });
    }, [props.emailTo, props.batchId]);

    const handleSubmit = (file: any) => {
        setIsLoading(true);
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
                isLt5M = file.size / 1024 / 1024 < 5;

            if (fileExtension[0] !== ".xlsx") {
                ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
                setIsLoading(false);
                return;
            } else if (!isLt5M) {
                ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
                setIsLoading(false);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to import Roll Over Adjustment Data?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("uploadFile", file);
                    formData.append("batchId", `${props.batchId}`);

                    plainAxiosInstance
                        .post(APIs.RISK_TOOL.GET_ROLLOVER_ADD_ADJUSTMENT_BY_UPLOAD, formData)
                        .then((res: any) => {
                            if (res.status === 200 && res.data === "success") {
                                ErrorMessageHandler("File upload successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                            } else {
                                let msgData = "Import failed, no data found.";
                                if (res.data !== 1) {
                                    msgData = res.data;
                                }
                                ErrorMessageHandler(`${msgData}`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        })
                        .catch((error: any) => {
                            ErrorMessageHandler(`File upload failed: (${error.response.data.message}).`, SUCCESS_FAILED.OTHERS_FAILED);
                        })
                        .finally(() => {
                            getAdjustmentList();
                            props.refreshBatch();
                            setIsLoading(false);
                        });
                },
                onCancel: () => {
                    setIsLoading(false);
                },
            });
        } catch (error) {
            ErrorMessageHandler(`Error during uploading file. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
            setIsLoading(false);
        }
    };

    const getAdjustmentList = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ROLLOVER_ADJUSTMENT_LIST}?batchId=${props.batchId}`)
            .then((res: any) => {
                setData(
                    res.data && res.data.length > 0 ? res.data.map((x: RollOverAdjustmentList) => ({ ...x, key: `${x.batchId}_${x.serverId}` })) : []
                );
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("roll over adjustment data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [props.batchId]);

    useEffect(() => {
        getAdjustmentList();
    }, []);

    return (
        <div className="rollover-tool-step1-container">
            <FlexiDataTable
                bordered
                rowKeyProperty="key"
                title={false}
                columns={columns}
                options={options}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
            />
        </div>
    );
};

export default Step1Adjustment;
