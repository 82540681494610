import { useState, useEffect, useMemo } from "react";
import { Tag, Modal, message } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { stepInfo } from "./stepInfo";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, SymbolLeverageToolBatchRecordList } from "../../../../constants/type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { APIs } from "../../../../services/apis";
import CardBox from "../../../../components/Common/CardBox";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { batchRecordListDummyData } from "./dummydata/batchRecordListDummyData";
import { isEmptyOrNull } from "@/utils/string";

const SymbolLeverageTool = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [batchData, setBatchData] = useState<SymbolLeverageToolBatchRecordList[]>([]);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_SYMBOL_LEVERAGE_EDIT);
    let navigate = useNavigate();

    const columns = useMemo(() => {
        return [
            DTColProps.XSmall({
                title: "Batch ID",
                dataIndex: "id",
                key: "id",
            }),
            {
                title: "Current Step",
                dataIndex: "currentStep",
                key: "currentStep",
                render: (currentStep: number) => {
                    return stepTag(currentStep);
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: stepInfo.map(x => ({
                            text: x.label,
                            value: x.value,
                        })),
                        inputProps: {
                            mode: "multiple",
                            allowClear: true,
                        },
                        callback: (filterValue: any, rowData: any) => {
                            if (isEmptyOrNull(filterValue as string[])) return true;

                            return (filterValue as string[]).includes(rowData["currentStep"] || "");
                        },
                    },
                },
            },
            DTColProps.DateTime({
                width: "15vw",
                title: "Create Time",
                dataIndex: "createDate",
                key: "createDate",
                sorter: (a: SymbolLeverageToolBatchRecordList, b: SymbolLeverageToolBatchRecordList) =>
                    moment(a.createDate) > moment(b.createDate) ? -1 : 1,
                render: (createDate: string) => <span>{createDate ? moment(createDate).format("YYYY-MM-DD HH:mm:ss") : ""}</span>,
            }),
            DTColProps.DateTime({
                width: "15vw",
                title: "Last Modify Time",
                dataIndex: "updateDate",
                key: "updateDate",
                sorter: (a: SymbolLeverageToolBatchRecordList, b: SymbolLeverageToolBatchRecordList) =>
                    moment(a.updateDate) > moment(b.updateDate) ? -1 : 1,
                render: (updateDate: string) => <span>{updateDate ? moment(updateDate).format("YYYY-MM-DD HH:mm:ss") : ""}</span>,
            }),
        ];
    }, [batchData, enableUpdate]);

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            enableFilter: true,
            add: enableUpdate,
            edit: enableUpdate,
            delete: enableUpdate,
        }),
        [batchData]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsLoading(true);

                Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    title: "Are you sure you want to add new batch?",
                    width: "30%",
                    onOk() {
                        plainAxiosInstance
                            .get(APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_BATCH_RECORD)
                            .then(res => {
                                if (res.status === 200 && res.data === 0) {
                                    ErrorMessageHandler(`Added batch successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                                } else {
                                    ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                                }
                            })
                            .catch((error: any) => {
                                ErrorMessageHandler(`Added batch failed: (${error.response.data.message}).`, SUCCESS_FAILED.OTHERS_FAILED);
                            })
                            .finally(() => setRunRefetchDataList(true));
                    },
                    onCancel: () => {
                        setIsLoading(false);
                    },
                });
                break;

            case CALLBACK_KEY.DO_EDIT:
                navigate(`/admintools/symbolleverage/edit/${FormData.id}`, { state: { data: FormData, action: "edit" } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                plainAxiosInstance
                    .delete(`${APIs.RISK_TOOL.DELETE_SYMBOL_LEVERAGE_BATCH_RECORD}/${FormData.id}`)
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler(`Batch ID: [ ${FormData.id} ]`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            queryBatchData();
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("symbol leverage batch list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                        )
                    )
                    .finally(() => setIsLoading(false));
                break;
            default:
                break;
        }
    };

    const queryBatchData = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_BATCH_RECORD_LIST}`)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    const realData: SymbolLeverageToolBatchRecordList[] = res.data.filter((item: SymbolLeverageToolBatchRecordList) =>
                        [1, 2, 3, 4, 5, 6].includes(item.currentStep)
                    );
                    // const realData: SymbolLeverageToolBatchRecordList[] = batchRecordListDummyData.filter(item => [1, 2, 3, 4, 5, 6].includes(item.currentStep));
                    setBatchData(realData);
                    setIsLoading(false);
                } else {
                    setBatchData([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol leverage batch list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    const stepTag = (step: number) => {
        return <Tag color={stepInfo[step - 1].color}>{stepInfo[step - 1].label}</Tag>;
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            queryBatchData();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        queryBatchData();
        return () => {};
    }, []);

    return (
        <CardBox title={"Symbol Leverage Tool"}>
            <FlexiDataTable
                rowKeyProperty="id"
                title={false}
                columns={columns}
                options={options}
                dataSource={batchData}
                callback={componentCallback}
                loading={isLoading}
                pagination={{
                    defaultPageSize: 20,
                }}
                scroll={{
                    x: true,
                }}
                filterInitialValue={{ currentStep: [1, 2, 3, 4, 5, 6] }}
            />
        </CardBox>
    );
};

export default SymbolLeverageTool;
