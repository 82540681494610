import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import { ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { Form, Row, Col } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { apiRequest, APIs } from "@/services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { CreateEditInlineFormBased } from "@/constants/type";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";

export interface BrandCreateEditPageV2Props extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

const initialValues = {
    brandId: "",
    brandName: "",
    brandDisplayName: "",
    senderMail: "",
    brandMail: "",
    comment: "",
    enable: true,
};

const BrandCreateEditPageV2 = (props: BrandCreateEditPageV2Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brandForm] = Form.useForm();

    const stateInfo = useMemo(() => {
        return {
            isAddAction: props.mode === InnerPageActionMode.CREATE_NEW,
            data: defaultIfEmptyOrNull(props.id, ""),
        };
    }, [props.mode, props.id]);

    const processBackAndRefetch = () => setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);

    const getBrandList = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRANDS_LIST_V2, { brandId: stateInfo.data })
            .then((data: any) => {
                if (data) {
                    brandForm.setFieldsValue(data);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                processBackAndRefetch();
            })
            .finally(() => setIsLoading(false));
    }, [stateInfo]);

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);
            if (stateInfo.isAddAction) {
                apiRequest(APIs.CREATE_BRAND_V2, values)
                    .then(data => {
                        ErrorMessageHandler("New brand", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new brand", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                        setIsLoading(false);
                    });
            } else {
                apiRequest(APIs.UPDATE_BRAND_V2, values)
                    .then(data => {
                        ErrorMessageHandler("Existing brand", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing brand", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                        setIsLoading(false);
                    });
            }
        },
        [stateInfo]
    );

    useEffect(() => {
        if (!stateInfo.isAddAction) {
            getBrandList();
        } else {
            brandForm.setFieldsValue(initialValues);
        }
    }, [stateInfo, getBrandList, props.resetState]);

    return (
        <SitePageHeaderInline
            enableSubmit={!isLoading}
            title={stateInfo.isAddAction ? "Create New Brand" : "Edit Brand"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        brandForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={brandForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"brandId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Brand Name"
                                name="brandName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    disabled: !stateInfo.isAddAction,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Brand Display Name"
                                name="brandDisplayName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Sender Mail"
                                name="senderMail"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Brand Mail"
                                name="brandMail"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Comment"
                                name="comment"
                                extra={{
                                    type: ComponentType.textarea,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Enable"
                                name="enable"
                                extra={{
                                    type: ComponentType.switch,
                                    value: ["Inactive", "Active"],
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default BrandCreateEditPageV2;
