import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { INTRADAY_FILTER_OPTIONS, INTRADAY_BREACH_SEVERITY, ComponentType, SUCCESS_FAILED, DIMENSION_LEVEL, CALLBACK_KEY } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CustomPaginationProps, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { getProfile } from "@/services/localStorage";
import { ToObjectWithKey, ToOptionTypeList, SortList } from "@/utils/array";
import {
    currencyRender,
    DTColProps,
    DataTableColumnRender,
    ErrorCatchValidator,
    ErrorMessageHandler,
    DimensionLevelObjectConfig,
} from "@/utils/Common";
import { DateTimeUtil } from "@/utils/datetime";
import { DefaultIfEmpty, objectToArray } from "@/utils/object";
import { getStandardMarkupDisplayText, isEmptyOrNull } from "@/utils/string";
import { QuestionCircleOutlined, ClockCircleOutlined, SolutionOutlined } from "@ant-design/icons";
import { Form, Tooltip, Typography, Popover, Timeline, Button, Modal } from "antd";
import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import { FaRegUserCircle, FaRegCommentAlt } from "react-icons/fa";
import { useParams } from "react-router";
import UserPNLListModal, { UserPNLListModalCallbackKey } from "../components/UserPnlListModal";
import WorldMapData from "../../../../assets/data/worldmap.json";

export interface IntradayMonitorAlarmListingProps {}

export interface AlarmItemProps {
    alarmDescription: string;
    alarmEventId: number;
    alarmId: number;
    alarmName: string;
    breachSeverity: number;
    createdDateUtc: string;
    dimensionFilters: string; //JSON
    dimensionSelectors: string; //JSON
    eventDate: string;
    eventDetails: string; //JSON
    eventHashKey: string;
    eventHeader: string; //JSON
    eventStatusId: number;
    eventTime: string;
    eventTimeUtc: string;
    metricFilters: string; //JSON
    modifiedDateUtc: string | null;
    severityProgression: AlarmSeverityProgressionItemProps[];
    eventCommentProgression: EventCommentProgressionProps[];
    accountCount: number;
}

export interface AlarmSeverityProgressionItemProps {
    alarmEventId: number;
    breachSeverity: number;
    createdDateUtc: string;
    eventDetails: string; //JSON
    eventHeader: string; //JSON
    eventTime: string;
    modifiedDateUtc: string | null;
    eventTimeUtc: string;
    alarmCenterEventHashKey: string;
}

interface EventCommentProgressionProps {
    eventComment: string;
    eventCommentBy: number;
    eventCommentDateUtc: string;
}

export interface AlarmComment {
    alarmEventId: number;
    comment: string;
}

export const markupText = (metricObj: any, type: string, value: any, extra: any = undefined) => {
    switch (type) {
        case "metricName":
            return DefaultIfEmpty(metricObj.metricName, value, "").text;
        case "operator":
            return ` ${INTRADAY_FILTER_OPTIONS[value as number]} `.toLowerCase();
        case "filterValue":
            if (extra !== undefined) {
                if ((extra as number) === 6) {
                    return `${value}`.toLowerCase() === "true" ? "True" : "False";
                } else if ([1, 2, 3, 4, 5].includes(extra as number)) {
                    if (typeof value === "string") {
                        if (value.indexOf("|") > -1) {
                            return value
                                .split("|")
                                .map(x => `${currencyRender(x)}`)
                                .join("~");
                        }
                    }
                    return currencyRender(value);
                } else {
                    if (typeof value === "string") {
                        if (value.indexOf("|") > -1) {
                            return value.split("|").join(" ~ ");
                        }
                    }
                    return value;
                }
            } else {
                if (typeof value === "string") {
                    if (value.indexOf("|") > -1) {
                        return value.split("|").join(" ~ ");
                    }
                    return getStandardMarkupDisplayText(value, { decimalPoint: 2 });
                } else if (typeof value === "number") {
                    return currencyRender(value, 2);
                } else if (typeof value === "boolean") {
                    return getStandardMarkupDisplayText(value);
                }
                return value;
            }
        case "dimension":
            let objKey = DefaultIfEmpty(metricObj.dimension, extra, { objKey: "" }).objKey;
            if (!isEmptyOrNull(objKey)) {
                return DefaultIfEmpty(metricObj[objKey], value, { text: "" }).text;
            }
            return value;
    }
};

export const valueTestFilter = (capturedInfo: any, metricFilter: any) => {
    let isKena = false;
    try {
        if (metricFilter.length > 0) {
            let finalMetricArr: string[] = [],
                ruleIdxArr: number[] = [];
            for (let xIdx = 0, xCount = metricFilter.length; xIdx < xCount; xIdx++) {
                const z = metricFilter[xIdx];
                let andIsKena = true,
                    metricArr = [];
                for (let yIdx = 0, yCount = z.metricInnerFilters.length; yIdx < yCount; yIdx++) {
                    const x = z.metricInnerFilters[yIdx];
                    if (capturedInfo?.hasOwnProperty(x["metricName"])) {
                        if ([1, 2, 3, 4, 5].includes(x["metricDataType"] as number)) {
                            if (x["filterOp"] === 7) {
                                let rangeArr = x["filterValue"].split("|").map((y: string) => parseFloat(y));
                                if (rangeArr[0] <= capturedInfo[x["metricName"]] && rangeArr[1] >= capturedInfo[x["metricName"]]) {
                                    metricArr.push(x["metricName"]);
                                } else {
                                    andIsKena = false;
                                    break;
                                }
                            } else {
                                switch (x["filterOp"]) {
                                    case 1:
                                        if (capturedInfo[x["metricName"]] === x["filterValue"]) {
                                            metricArr.push(x["metricName"]);
                                        } else {
                                            andIsKena = false;
                                        }
                                        break;
                                    case 2:
                                        if (capturedInfo[x["metricName"]] !== x["filterValue"]) {
                                            metricArr.push(x["metricName"]);
                                        } else {
                                            andIsKena = false;
                                        }
                                        break;
                                    case 3:
                                        if (capturedInfo[x["metricName"]] > x["filterValue"]) {
                                            metricArr.push(x["metricName"]);
                                        } else {
                                            andIsKena = false;
                                        }
                                        break;
                                    case 4:
                                        if (capturedInfo[x["metricName"]] < x["filterValue"]) {
                                            metricArr.push(x["metricName"]);
                                        } else {
                                            andIsKena = false;
                                        }
                                        break;
                                    case 5:
                                        if (capturedInfo[x["metricName"]] >= x["filterValue"]) {
                                            metricArr.push(x["metricName"]);
                                        } else {
                                            andIsKena = false;
                                        }
                                        break;
                                    case 6:
                                        if (capturedInfo[x["metricName"]] <= x["filterValue"]) {
                                            metricArr.push(x["metricName"]);
                                        } else {
                                            andIsKena = false;
                                        }
                                        break;
                                    default:
                                        andIsKena = false;
                                        break;
                                }
                                if (!andIsKena) {
                                    break;
                                }
                            }
                        } else if (6 === (x["metricDataType"] as number)) {
                            switch (x["filterOp"]) {
                                case 1:
                                    if (capturedInfo[x["metricName"]] === x["filterValue"]) {
                                        metricArr.push(x["metricName"]);
                                    } else {
                                        andIsKena = false;
                                    }
                                    break;
                                case 2:
                                    if (capturedInfo[x["metricName"]] !== x["filterValue"]) {
                                        metricArr.push(x["metricName"]);
                                    } else {
                                        andIsKena = false;
                                    }
                                    break;
                            }
                        } else if ([7, 8].includes(x["metricDataType"] as number)) {
                            switch (x["filterOp"]) {
                                case 8:
                                    if (capturedInfo[x["metricName"]].indexOf(x["filterValue"]) > -1) {
                                        metricArr.push(x["metricName"]);
                                    } else {
                                        andIsKena = false;
                                    }
                                    break;
                                case 9:
                                    if (capturedInfo[x["metricName"]].indexOf(x["filterValue"]) === 0) {
                                        metricArr.push(x["metricName"]);
                                    } else {
                                        andIsKena = false;
                                    }
                                    break;
                                case 10:
                                    let fIdx = capturedInfo[x["metricName"]].indexOf(x["filterValue"]);
                                    if (fIdx > -1) {
                                        if (capturedInfo[x["metricName"]].substring(fIdx) === x["filterValue"]) {
                                            metricArr.push(x["metricName"]);
                                        } else {
                                            andIsKena = false;
                                        }
                                    } else {
                                        andIsKena = false;
                                    }
                                    break;
                                default:
                                    andIsKena = false;
                                    break;
                            }
                            if (!andIsKena) {
                                break;
                            }
                        } else if ([9, 10].includes(x["metricDataType"] as number)) {
                            //date checking
                            andIsKena = false;
                            break;

                            // if (x["filterOp"] === 7) {
                            // } else {
                            // }
                        }
                    } else {
                        andIsKena = false;
                        break;
                    }
                }
                if (andIsKena) {
                    finalMetricArr = finalMetricArr.concat(metricArr);
                    ruleIdxArr = ruleIdxArr.concat(xIdx);
                    isKena = true;
                }
            }
            if (isKena) {
                return { metrics: finalMetricArr, indexes: ruleIdxArr, isKena };
            }
        }
    } catch (error) {}
    return { metrics: [] as string[], indexes: [] as number[], isKena };
};

export const getFilterRulesColumnRender = (key: string, eventDetails: any, metricFilterInfo: any, metricObj: any) => {
    let testResult = valueTestFilter(eventDetails, metricFilterInfo);
    return (
        <div key={`tr-mfl-${key}-mic`} className="metric-info-container">
            {metricFilterInfo
                .filter((x: any, idx: number) => testResult.indexes.includes(idx))
                .map((x: any, idx: number) => (
                    <div key={`tr-mfl-${key}-${idx}`} className={`item-group`}>
                        {x.metricInnerFilters &&
                            x.metricInnerFilters.length > 0 &&
                            x.metricInnerFilters.map((y: any, yIdx: number) => (
                                <div key={`tr-mfl-${key}-${idx}-m-${yIdx}`} className="item">
                                    <div>
                                        <span>{markupText(metricObj, "metricName", y["metricName"])}</span>
                                        <span className="operator">{markupText(metricObj, "operator", y["filterOp"])}</span>
                                        <span>{markupText(metricObj, "filterValue", y["filterValue"], y["metricDataType"])}</span>
                                    </div>
                                </div>
                            ))}
                    </div>
                ))}
        </div>
    );
};

export const getMetricColumnRender = (key: string, eventDetails: any, metricFilterInfo: any, metricObj: any) => {
    let testResult = valueTestFilter(eventDetails, metricFilterInfo);
    return (
        <div className="event-info-container" key={`tr-mfl-${key}-eic`}>
            {Object.keys(eventDetails)
                .filter(x => metricObj.metricName?.hasOwnProperty(x))
                .map((x: string, idx: number) => {
                    return (
                        <div
                            key={`tr-edl-${key}-${idx}`}
                            className={`item ${testResult.metrics.includes(x) && testResult.isKena ? "kena-liao" : ""}`}
                        >
                            <span>{DefaultIfEmpty(metricObj.metricName, x, { text: "" }).text}</span>
                            <span>{markupText(metricObj, "filterValue", eventDetails[x])}</span>
                        </div>
                    );
                })}
        </div>
    );
};

export const getCombineSeverityProgressionAndEventCommentProgression = (
    severityProgression: AlarmSeverityProgressionItemProps[],
    eventCommentProgression: EventCommentProgressionProps[],
    type1: number,
    type2: number
) => {
    const concated = [
        ...severityProgression.map(item => ({
            ...item,
            date: item.eventTimeUtc,
            type: type1,
        })),
        ...eventCommentProgression.map(item => ({
            ...item,
            date: item.eventCommentDateUtc,
            type: type2,
        })),
    ];
    return concated.sort((a, b) => moment.utc(b.date).diff(moment.utc(a.date)));
};

const IntradayMonitorAlarmListing = (props: IntradayMonitorAlarmListingProps) => {
    const uPr: any = getProfile();
    const serverTime_UTCOffset: number | undefined = uPr?.hasOwnProperty("stom") ? uPr.stom : undefined;
    let { severity, brand } = useParams();
    const priorityObj = ToObjectWithKey(ToOptionTypeList(INTRADAY_BREACH_SEVERITY), "value");
    const [commentForm] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [isUserPNLModalVisible, setIsUserPNLModalVisible] = useState<boolean>(false);
    const [selectedUserData, setSelectedUserData] = useState<AlarmItemProps | undefined>(undefined);
    const [comData] = useState<AlarmComment>();

    const [data, setData] = useState<AlarmItemProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorting, setSorting] = useState<string>("eventTime,desc|alarmEventId,desc");
    const [filterParams, setFilterParams] = useState<any>({
        eventDateFrom: DateTimeUtil.getCurrentServerTime().format("YYYY-MM-DD"),
        eventDateTo: DateTimeUtil.getCurrentServerTime().format("YYYY-MM-DD"),
        ...(severity && {
            breachSeverities: [parseInt(severity)],
        }),
        ...(brand && {
            dimensionFilters: [
                {
                    dimensionLevel: 4,
                    filterIn: [parseInt(brand)],
                },
            ],
        }),
    });
    const [mappingData, setMappingData] = useState<any>({});
    const [filterOptions, setFilterOptions] = useState<any>({ alarms: [] });

    const [filterForm] = Form.useForm();

    const columns: any[] = useMemo(
        () => [
            DTColProps.DateTime_ServerTime({
                title: "Captured Time (Server)",
                dataIndex: "eventTime",
                key: "eventTime",
            }),
            DTColProps.XSmall(
                {
                    title: "Severity",
                    dataIndex: "breachSeverity",
                    key: "breachSeverity",
                    render: (value: number) => {
                        return (
                            <span className={`priority-tag priority-color-${value}`}>{DefaultIfEmpty(priorityObj, value, { text: "" }).text}</span>
                        );
                    },
                },
                ["text-center"]
            ),
            DTColProps.Large(
                {
                    width: "13.5vw",
                    title: "Dimension",
                    dataIndex: "eventDetails",
                    key: "eventDetails",
                    render: (value: string, rowData: AlarmItemProps) => {
                        try {
                            let tmpObj = JSON.parse(value);
                            return (
                                <div className="event-info-container" key={`tr-mfl-${rowData["eventHashKey"]}-eic`}>
                                    {Object.keys(tmpObj)
                                        .filter(x => mappingData.dimension?.hasOwnProperty(x))
                                        .map((x: any, idx: number) => {
                                            return (
                                                <div key={`tr-edl-${rowData["eventHashKey"]}-${idx}`} className="item">
                                                    <span>{DefaultIfEmpty(mappingData.dimension, x, { text: "" }).text}</span>
                                                    <span className="dim">{markupText(mappingData, "dimension", tmpObj[x], x)}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                            );
                        } catch (error) {}

                        return <></>;
                    },
                },
                ["vertical-top"]
            ),
            DTColProps.Large(
                {
                    width: "13.5vw",
                    title: "Metrics",
                    dataIndex: "eventDetails",
                    key: "eventDetails",
                    render: (value: string, rowData: AlarmItemProps) => {
                        try {
                            let eventDetails = JSON.parse(value),
                                metricInfo = JSON.parse(rowData["metricFilters"]);
                            return getMetricColumnRender(rowData["eventHashKey"], eventDetails, metricInfo, mappingData);
                        } catch (error) {}

                        return <></>;
                    },
                },
                ["vertical-top"]
            ),
            {
                title: "Alarm Information & Trigger Rule(s)",
                dataIndex: "alarmName",
                key: "alarmName",
                className: "vertical-top",
                render: (value: string, rowData: AlarmItemProps) => {
                    try {
                        let metricInfo = JSON.parse(rowData["metricFilters"]),
                            eventDetails = JSON.parse(rowData["eventDetails"]);
                        return (
                            <>
                                <div className="alarm-info-container">
                                    <div className="title">{value}</div>
                                    {rowData.alarmDescription && <div className="desc">{rowData.alarmDescription}</div>}
                                </div>
                                {metricInfo.length > 0 && (
                                    <div className="trigger-rules">
                                        {getFilterRulesColumnRender(rowData["eventHashKey"], eventDetails, metricInfo, mappingData)}
                                    </div>
                                )}
                            </>
                        );
                    } catch (error) {}

                    return (
                        <div className="alarm-info-container">
                            <div className="title">{value}</div>
                            {rowData.alarmDescription && <div className="desc">{rowData.alarmDescription}</div>}
                        </div>
                    );
                },
            },
            DTColProps.Middle(
                {
                    width: "11vw",
                    title: "Last Comment",
                    dataIndex: "lastComment",
                    key: "lastComment",
                    render: (lastComment: string, rowData: any) => {
                        return (
                            <div className="flex-row-between">
                                {rowData.eventCommentProgression.length > 0 && rowData.eventCommentProgression[0] ? (
                                    <div className="mini-comment-container">
                                        <div className="comments">
                                            <Tooltip title={rowData.eventCommentProgression[0].eventComment}>
                                                <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0" }}>
                                                    {rowData.eventCommentProgression[0].eventComment}
                                                </Typography.Paragraph>
                                            </Tooltip>
                                        </div>
                                        <div className="info">
                                            {`${DataTableColumnRender.DateTime_UTC_TO_ServerTime(
                                                rowData.eventCommentProgression[0].eventCommentDateUtc,
                                                serverTime_UTCOffset
                                            )}`}
                                            <br />
                                            <div className="user">
                                                <FaRegUserCircle />
                                                <span className="user-name">
                                                    {
                                                        DefaultIfEmpty(mappingData.users, rowData.eventCommentProgression[0].eventCommentBy, {
                                                            text: "",
                                                        }).text
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mini-comment-container">-</div>
                                )}
                            </div>
                        );
                    },
                },
                ["vertical-top"]
            ),
            DTColProps.XSmall(
                {
                    width: "6vw",
                    title: (
                        <span key={"urnm-tlps"}>
                            Accounts
                            <Tooltip
                                title={"The final account total for today will only be calculated at the end of the day. (After server time 02:00)"}
                            >
                                <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                            </Tooltip>
                        </span>
                    ),
                    realTitle: "Accounts",
                    dataIndex: "accountCount",
                    key: "accountCount",
                    render: (text: number) => (
                        <span style={{ fontWeight: "bold", fontSize: "1.1rem", color: "#00615d" }}>
                            {`${text}` === "0" ? " - " : currencyRender(text)}
                        </span>
                    ),
                },
                ["text-center"]
            ),
            // DTColProps.Middle(
            //     {
            //         width: "16vw",
            //         title: "Filter Rule(s)",
            //         dataIndex: "metricFilters",
            //         key: "metricFilters",
            //         render: (value: string, rowData: any) => {
            //             try {
            //                 let metricInfo = JSON.parse(value),
            //                     eventDetails = JSON.parse(rowData["eventDetails"]);
            //                 return getFilterRulesColumnRender(rowData["eventHashKey"], eventDetails, metricInfo, mappingData);
            //             } catch (error) {}
            //             return <></>;
            //         },
            //     },
            //     ["vertical-top", "text-right"]
            // ),
            {
                title: "Alarm",
                dataIndex: "alarmId",
                key: "alarmId",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: filterOptions.alarms,
                    },
                },
            },
            {
                title: "Event Date",
                dataIndex: "eventDate",
                key: "eventDate",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                    },
                },
            },
            {
                title: "Breach Severity",
                dataIndex: "breachSeverity",
                key: "breachSeverity",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: ToOptionTypeList(INTRADAY_BREACH_SEVERITY),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            },
            {
                title: "Account ID",
                dataIndex: "accountId",
                key: "accountId",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.rebateAccounts,
                        value: mappingData.server,
                    },
                    form: filterForm,
                },
            },
            {
                title: "Account Group",
                dataIndex: "group",
                key: "group",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: [],
                        inputProps: {
                            mode: "tags",
                            placeholder: "Please insert Account Group",
                            tokenSeparators: [",", "，", " "],
                        },
                    },
                },
            },
            {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: SortList(objectToArray(mappingData.symbol || []), "text"),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            },
            {
                title: "Symbol Asset Type",
                dataIndex: "symbolAssetType",
                key: "symbolAssetType",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: SortList(objectToArray(mappingData.symbolAssetType || []), "text"),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            },
            {
                title: "Server",
                dataIndex: "server",
                key: "server",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: SortList(objectToArray(mappingData.server || []), "text"),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            },
            {
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: SortList(objectToArray(mappingData.brand || []), "text"),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            },
            {
                title: "Country",
                dataIndex: "country",
                key: "country",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: SortList(objectToArray(mappingData.country || []), "text"),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            },
            {
                title: "IB (Rebate Account)",
                dataIndex: "ibAccount",
                key: "ibAccount",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.rebateAccounts,
                        value: mappingData.server,
                    },
                    form: filterForm,
                },
            },
        ],
        [mappingData, filterOptions, filterForm]
    );

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            view: (record: any, button: React.ReactNode) => {
                try {
                    const combinedArray = getCombineSeverityProgressionAndEventCommentProgression(
                        record["severityProgression"],
                        record["eventCommentProgression"],
                        1,
                        2
                    );
                    if (combinedArray.length === 0) return <></>;

                    return (
                        <Popover
                            placement="leftTop"
                            overlayClassName="alarm-historical-popover-content"
                            content={
                                <div className="alarm-historical-popover-timeline-container nice-scrollbar">
                                    <Timeline>
                                        {combinedArray.map((x: any, xIdx: number) => {
                                            let eventDetails = x["eventDetails"] && JSON.parse(x["eventDetails"]);
                                            let metricInfo = x["metricFilters"] && JSON.parse(x["metricFilters"]);
                                            let timelineKey = `${Math.random()}`;
                                            let itemKey = x.alarmEventId && x.createdDateUtc && `${x.alarmEventId}-${x.createdDateUtc}`;

                                            return (
                                                <Timeline.Item key={`tli-${timelineKey}-${xIdx}`} color={x.type === 1 ? "#004b57" : "green"}>
                                                    {x.type === 2 ? (
                                                        <div className="alarm-popover-detail-container shadow-light">
                                                            <div className="title-container">
                                                                <div className="left">
                                                                    <div className="comment-by">
                                                                        <FaRegUserCircle />
                                                                        <span className="user-name">
                                                                            {
                                                                                DefaultIfEmpty(mappingData.users, x.eventCommentBy, {
                                                                                    text: "",
                                                                                }).text
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="right">
                                                                    <ClockCircleOutlined />{" "}
                                                                    {DataTableColumnRender.DateTime_UTC_TO_ServerTime(
                                                                        x.eventCommentDateUtc,
                                                                        serverTime_UTCOffset
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="comment-content">
                                                                <Typography.Paragraph>{x.eventComment}</Typography.Paragraph>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="alarm-popover-detail-container shadow-light">
                                                            <div className="title-container">
                                                                <div className="left">
                                                                    <span className={`priority-div priority-color-${x.breachSeverity}`}>
                                                                        {DefaultIfEmpty(priorityObj, x.breachSeverity, { text: "" }).text}
                                                                    </span>
                                                                </div>
                                                                <div className="right">
                                                                    <ClockCircleOutlined /> {DataTableColumnRender.DateTime_ServerTime(x.eventTime)}
                                                                </div>
                                                            </div>
                                                            <div className="content-container">
                                                                <div className="left">
                                                                    <div className="theader">Metrics</div>
                                                                    <div className="content">
                                                                        {getMetricColumnRender(itemKey, eventDetails, metricInfo, mappingData)}
                                                                    </div>
                                                                </div>
                                                                <div className="right">
                                                                    <div className="theader">Filter Rule(s)</div>
                                                                    <div className="content">
                                                                        {getFilterRulesColumnRender(itemKey, eventDetails, metricInfo, mappingData)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Timeline.Item>
                                            );
                                        })}
                                    </Timeline>
                                </div>
                            }
                            title={<span className="alarm-historical-popover-title">Breach Severity Progression</span>}
                        >
                            {button}
                        </Popover>
                    );
                } catch (error) {}
                return <></>;
            },
            export: { text: "Intraday Report (EOD)" },
        };
    }, [mappingData]);

    const downEODReport = (formData: any) => {
        setIsLoading(true);
        apiRequest(APIs.GET_ALARM_LISTING_EXPORT_EXCEL, formData, "POST", "arraybuffer")
            .then((res: any) => {
                const fileName = res.headers["x-filename"];
                const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); // or any other extension
                // Append to html link element page
                document.body.appendChild(link);
                // start download
                link.click();
                // Clean up and remove the link
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("intraday report (EOD)", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const getAlarmListing = () => {
        apiRequest(APIs.GET_ALARM_LISTING, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(sorting.length > 0 && { order: sorting }),
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
        })
            .then((data: any) => {
                if (data && data.eventGroups && data.eventGroups.length > 0) {
                    setData(data.eventGroups);
                    setPagination(prev => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const convertFilterParamsToObject = (FormData: any) => {
        let filterParams: any = {};
        Object.keys(FormData)
            .filter(x => FormData[x] && FormData[x].toString().length > 0)
            .map(x => {
                if (x === "eventDate") {
                    filterParams["eventDateFrom"] = DateTimeUtil.GetOrigin(FormData[x][0], "YYYY-MM-DD");
                    filterParams["eventDateTo"] = DateTimeUtil.GetOrigin(FormData[x][1], "YYYY-MM-DD");
                } else if (x === "breachSeverity") {
                    filterParams["breachSeverities"] = FormData[x];
                } else if (x === "alarmId") {
                    filterParams["alarmIds"] = [FormData[x]];
                } else if (["symbol", "symbolAssetType", "server", "brand", "country", "ibAccount", "accountId", "group"].includes(x)) {
                    if (!filterParams?.hasOwnProperty("dimensionFilters")) filterParams["dimensionFilters"] = [];
                    let dimensionLevel: number = -1;
                    switch (x) {
                        case "symbol":
                            dimensionLevel = DIMENSION_LEVEL.SYMBOL;
                            break;
                        case "symbolAssetType":
                            dimensionLevel = DIMENSION_LEVEL.SYMBOLASSETTYPE;
                            break;
                        case "server":
                            dimensionLevel = DIMENSION_LEVEL.SERVER;
                            break;
                        case "brand":
                            dimensionLevel = DIMENSION_LEVEL.BRAND;
                            break;
                        case "country":
                            dimensionLevel = DIMENSION_LEVEL.COUNTRY;
                            break;
                        case "ibAccount":
                            dimensionLevel = DIMENSION_LEVEL.IB;
                            break;
                        case "accountId":
                            dimensionLevel = DIMENSION_LEVEL.ACCOUNT;
                            break;
                        case "group":
                            dimensionLevel = DIMENSION_LEVEL.ACCGROUP;
                            break;
                    }
                    if (dimensionLevel > -1) {
                        filterParams["dimensionFilters"].push({
                            dimensionLevel: dimensionLevel,
                            filterIn: FormData[x],
                        });
                    }
                } else {
                    filterParams[x] = FormData[x];
                }
                return x;
            });

        return filterParams;
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("eventTime,desc|alarmEventId,desc")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${
                                    FormData.sorter.order === "ascend" ? "asc|alarmEventId,asc" : "desc|alarmEventId,desc"
                                }`
                              : ""
                      );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setFilterParams(convertFilterParamsToObject(FormData));
                setSorting("eventTime,desc");
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "commenting") {
                    setVisible(true);
                    commentForm.setFieldsValue({
                        alarmCenterCorrelationHashKey: FormData.data.alarmCenterCorrelationHashKey,
                        eventComment: "",
                    });
                } else if (FormData.key === "userpnllist") {
                    setSelectedUserData(FormData.data);
                    setIsUserPNLModalVisible(true);
                }
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                downEODReport(convertFilterParamsToObject(FormData));
                break;
            default:
                break;
        }
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["mimmetric", "mimalarm", "cleansymbol", "symbolassettype", "server", "brand", "user"],
        })
            .then(res => {
                setMappingData({
                    dimension: ToObjectWithKey(DimensionLevelObjectConfig("IntradayDimensionArr"), "value"),
                    country: ToObjectWithKey(
                        WorldMapData.features.map((x: any) => ({ text: x.properties.name, value: x.properties.iso_alpha_2_code })),
                        "value"
                    ),
                    metricName:
                        res["mimMetrics"] && res["mimMetrics"].length > 0
                            ? ToObjectWithKey(
                                  res.mimMetrics.map((x: any) => ({
                                      value: x["metricName"],
                                      text: x["metricDisplayName"],
                                      type: x["metricDataType"],
                                  })),
                                  "value"
                              )
                            : {},
                    alarms:
                        res["mimAlarms"] && res["mimAlarms"].length > 0
                            ? ToObjectWithKey(
                                  res.mimAlarms.map((x: any) => ({ value: x["alarmId"], text: x["alarmName"] })),
                                  "value"
                              )
                            : {},
                    symbol:
                        res["cleanSymbols"] && res["cleanSymbols"].length > 0
                            ? ToObjectWithKey(
                                  res.cleanSymbols.map((x: any) => ({ value: x["name"], text: x["name"] })),
                                  "value"
                              )
                            : {},
                    symbolAssetType:
                        res["symbolAssetTypes"] && res["symbolAssetTypes"].length > 0
                            ? ToObjectWithKey(
                                  res.symbolAssetTypes.map((x: any) => ({ value: x["id"], text: x["name"] })),
                                  "value"
                              )
                            : {},
                    server:
                        res["servers"] && res["servers"].length > 0
                            ? ToObjectWithKey(
                                  res.servers.map((x: any) => ({ value: x["id"], text: x["server"] })),
                                  "value"
                              )
                            : {},
                    brand:
                        res["brands"] && res["brands"].length > 0
                            ? ToObjectWithKey(
                                  res.brands.map((x: any) => ({ value: x["id"], text: x["brand"] })),
                                  "value"
                              )
                            : {},
                    users:
                        res["users"] && res["users"].length > 0
                            ? ToObjectWithKey(
                                  res.users.map((x: any) => ({ value: x["id"], text: x["name"] })),
                                  "value"
                              )
                            : {},
                });
                setFilterOptions((prev: any) => ({
                    ...prev,
                    alarms:
                        res["mimAlarms"] && res["mimAlarms"].length > 0
                            ? SortList(
                                  res.mimAlarms.map((x: any) => ({ value: x["alarmId"], text: x["alarmName"] })),
                                  "text"
                              )
                            : [],
                }));
                setRunRefetchDataList(true);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get alarm config: ", err)));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getAlarmListing();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        filterForm.setFieldsValue({
            eventDate: [moment(), moment()],
            ibAccount: [],
            accountId: [],
            ...(severity && { breachSeverity: [parseInt(severity)] }),
            ...(brand && { brand: [parseInt(brand)] }),
        });
        getConfig();
    }, []);

    return (
        <CardBox title={"Intraday Alarm Listing"}>
            <div className="intraday-alarm-listing-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="eventHashKey"
                    title=""
                    columns={columns}
                    options={{
                        serverFiltering: true,
                        resetFollowDefaultInitFilterValue: ["eventDate"],
                        customExtraActionButton: (record: any, callback: FlexiDataTableCallbackProps) => {
                            if (isEmptyOrNull(record.alarmCenterEventHashKey)) return undefined;

                            return (
                                <>
                                    <Button
                                        type="text"
                                        icon={<FaRegCommentAlt style={{ cursor: "pointer" }} />}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "commenting", data: record });
                                        }}
                                    />
                                    <Button
                                        type="text"
                                        icon={<SolutionOutlined style={{ cursor: "pointer" }} />}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "userpnllist", data: record });
                                        }}
                                    />
                                </>
                            );
                        },
                        ...options,
                    }}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                    filterFormInstance={filterForm}
                    filterInitialValue={{
                        eventDate: [DateTimeUtil.getCurrentServerTime(), DateTimeUtil.getCurrentServerTime()],
                        ...(severity && { breachSeverity: [parseInt(severity)] }),
                        ...(brand && { brand: [parseInt(brand)] }),
                    }}
                />
                <Modal
                    open={visible}
                    title={"Create New Comment"}
                    onCancel={() => {
                        commentForm.resetFields();
                        setVisible(false);
                    }}
                    onOk={() => {
                        commentForm
                            .validateFields()
                            .then((values: any) => {
                                const alarmComment = {
                                    alarmCenterCorrelationHashKey: values.alarmCenterCorrelationHashKey,
                                    eventComment: values.eventComment,
                                };
                                commentForm.resetFields();
                                return apiRequest(APIs.CREATE_ALARM_COMMENT, alarmComment);
                            })
                            .then((data: any) => {
                                ErrorMessageHandler("New comment", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                                setRunRefetchDataList(true);
                                setVisible(false);
                            })
                            .catch((error: any) => {
                                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new comment", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                            });
                    }}
                >
                    <Form form={commentForm} labelCol={{ span: 4.5 }} layout="horizontal" initialValues={comData}>
                        <FormComponent label={""} name={"alarmCenterCorrelationHashKey"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent
                            label="Comment"
                            name={"eventComment"}
                            extra={{
                                type: ComponentType.textarea,
                                value: "",
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                inputProps: {
                                    showCount: true,
                                    rows: 5,
                                },
                            }}
                        />
                    </Form>
                </Modal>
                <UserPNLListModal
                    selectedData={selectedUserData}
                    isModalVisible={isUserPNLModalVisible}
                    modalTitle={"Top 100 Users Daily PNL"}
                    callback={(type: number, data: any) => {
                        if (type === UserPNLListModalCallbackKey.Close) {
                            setSelectedUserData(undefined);
                            setIsUserPNLModalVisible(false);
                        }
                    }}
                />
            </div>
        </CardBox>
    );
};

export default IntradayMonitorAlarmListing;
