import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, CreateEditInlineFormBased } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator, DTColProps } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { Tag } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BrandCreateEditPageV2 from "./CreateEditPage";

export interface BrandListingPageProps {}

export interface BrandV2Props {
    brandDisplayName: string;
    brandId: number;
    brandMail: string | null;
    brandName: string;
    brandUid: string;
    comment: string | null;
    createBy: number;
    createTimeUtc: string;
    enable: boolean;
    senderMail: string | null;
    updateBy: number;
    updateTimeUtc: string;
}

const BrandListingPage = (props: BrandListingPageProps) => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [refetch, setRefetch] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<BrandV2Props[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_BRANDSERVER_BRAND_EDIT);

    const columns: any[] = [
        DTColProps.Middle({
            title: "Brand Name",
            dataIndex: "brandName",
            key: "brandName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "Brand Display Name",
            dataIndex: "brandDisplayName",
            key: "brandDisplayName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Large({
            title: "Brand Mail",
            dataIndex: "brandMail",
            key: "brandMail",
            render: (text: string, rowData: BrandV2Props) =>
                isEmptyOrNull(text)
                    ? ""
                    : text.split(",").map((item: string, index: number) => (
                          <Tag key={`bm-tg-${rowData.brandId}-${index}`} style={{ marginBottom: "5px" }}>
                              {item}
                          </Tag>
                      )),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Large({
            title: "Sender Mail",
            dataIndex: "senderMail",
            key: "senderMail",
            render: (text: string, rowData: BrandV2Props) =>
                isEmptyOrNull(text)
                    ? ""
                    : text.split(",").map((item: string, index: number) => (
                          <Tag key={`sm-tg-${rowData.brandId}-${index}`} style={{ marginBottom: "5px" }}>
                              {item}
                          </Tag>
                      )),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        DTColProps.Status({
            title: "Status",
            dataIndex: "enable",
            key: "enable",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, BrandData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: BrandData.brandId });
                setRefetch(prev => prev + 1);
                break;
            default:
                break;
        }
    };

    const getBrandList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRANDS_LIST_V2, {})
            .then((res: any) => {
                setData(res && res.length > 0 ? res : []);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getBrandList();
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Brand Management</span>
                </div>
            </div>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <div className="v2-brand-listing">
                        <FlexiDataTable
                            bordered
                            rowKeyProperty="brandId"
                            title=""
                            columns={columns}
                            options={options}
                            dataSource={data}
                            callback={componentCallback}
                            loading={isLoading}
                        />
                    </div>
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <BrandCreateEditPageV2
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        getBrandList();
                                    }
                                    break;
                            }
                        }}
                        resetState={refetch}
                    />
                </div>
            </div>
        </>
    );
};

export default BrandListingPage;
