import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { isEmptyOrNull } from "@/utils/string";
import { FormInstance, Form } from "antd";

export interface SymbolRadioComponentProps {
    form: FormInstance;
    servers: any[];
    symbols: any[];
    cleanSymbols: any[];
}

const SymbolRadioComponent = (props: SymbolRadioComponentProps) => {
    const editKey = Form.useWatch("symbolId", props.form);

    return (
        <>
            {isEmptyOrNull(editKey) ? (
                <FormComponent
                    label="Server"
                    name="serverId"
                    extra={{
                        type: ComponentType.dropdown,
                        value: props.servers,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    }}
                />
            ) : (
                <FormComponent
                    label="Server"
                    name="serverId"
                    extra={{
                        type: ComponentType.dropdown,
                        value: props.servers,
                        inputProps: {
                            disabled: true,
                        },
                    }}
                />
            )}
            <FormComponent
                label="Symbol"
                name="symbol"
                extra={{
                    type: ComponentType.text,
                    value: "",
                    itemProps: {
                        validateTrigger: "onBlur",
                    },
                    rules: [
                        { required: true, message: REQUIRED_FIELD },
                        {
                            validator: editKey
                                ? (_: any, value: any) => {
                                      let currentServer = props.form.getFieldValue("serverId"),
                                          currentSymbolId = props.form.getFieldValue("symbolId");
                                      if (value.length === 0 || currentServer.length === 0) return Promise.resolve();

                                      return apiRequest(APIs.GET_SYMBOL_LIST, { selectedServerId: currentServer, symbolListingMode: 3 }).then(res => {
                                          if (res.length > 0) {
                                              let fidx: number = res.findIndex((x: any) => x.symbol.toLowerCase() === value.toLowerCase());
                                              if (fidx > -1 && res[fidx].symbolId !== currentSymbolId) {
                                                  return Promise.reject(new Error("The Symbol already existed. Please try another symbol."));
                                              }
                                          }
                                          return Promise.resolve();
                                      });
                                  }
                                : (_: any, value: any) => {
                                      let currentServer = props.form.getFieldValue("serverId");
                                      if (value.length === 0 || currentServer.length === 0) return Promise.resolve();

                                      return apiRequest(APIs.GET_SYMBOL_LIST, { selectedServerId: currentServer, symbolListingMode: 3 }).then(res => {
                                          if (res.length > 0) {
                                              if (res.some((x: any) => x.symbol.toLowerCase() === value.toLowerCase())) {
                                                  return Promise.reject(new Error("The Symbol already existed. Please try another symbol."));
                                              }
                                          }
                                          return Promise.resolve();
                                      });
                                  },
                        },
                    ],
                }}
            />
        </>
    );
};

export default SymbolRadioComponent;
