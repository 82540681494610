import { Route } from "react-router-dom";
import { routeProps } from "../constants/type";
import WrapperLayout from "../layouts/WrapperLayout";
import NotFoundPage from "../pages/NotFoundPage";
import AuthHelper from "./authHelper";

class RouteHelper {
    private _menus: routeProps[] = [];
    private _authHelper;
    private _authKeys: string[] = [];
    private _byPassKeys: string[] = [];

    constructor(routes: routeProps[]) {
        this._authHelper = new AuthHelper();
        this._authKeys = this._authHelper.getAuthModuleKeys();
        //this._menus = routes && routes.length > 0 ? routes.filter((x) => authKeys.includes(x.key) || x.key.indexOf("/myaccount") > -1) : [];
        this._menus = routes && routes.length > 0 ? routes : [];
        this._byPassKeys = this.getAllGroupKeys(routes);
    }

    private getAllGroupKeys = (menus: any[]) =>
        menus.reduce((arr: string[], x: any) => {
            if ((x.hasOwnProperty("type") && x.type === "group") || (x.hasOwnProperty("shouldShow") && x.shouldShow)) {
                arr.push(x.key);
            }
            if (x.submenu) {
                arr = arr.concat(this.getAllGroupKeys(x.submenu));
            }
            return arr;
        }, []);

    private isAuthorized = (key: string, referKey: string = ""): boolean =>
        this._authKeys.includes(referKey.length > 0 ? referKey : key) || this._byPassKeys.includes(key);

    //#region Get React Route Element
    private getRoute = (props: routeProps): React.ReactElement => {
        return props.submenu ? (
            <Route key={props.key} path={props.path} element={<WrapperLayout />}>
                <Route
                    key={props.key + "idx"}
                    index
                    element={
                        props.component ? this.isAuthorized(props.key, props.referKey || "") ? props.component : <NotFoundPage /> : <WrapperLayout />
                    }
                />
                {props.submenu.map(x => this.getRoute(x))}
            </Route>
        ) : (
            <Route
                key={props.key}
                path={props.path}
                element={this.isAuthorized(props.key, props.referKey || "") ? props.component : <NotFoundPage />}
            />
        );
    };

    getRoutes = (): React.ReactElement => <>{this._menus.map(x => this.getRoute(x))}</>;
    //#endregion

    //#region Get SideBar Navigation Element
    private getSubmenu = (order_text: string, props: routeProps): routeProps => {
        if (props.submenu) {
            props.submenu = props.submenu
                .filter(x => x.showInNavBar && this.isAuthorized(x.key, x.referKey || ""))
                .map(x => {
                    let tmp: any = this._authHelper.getRouteItem(x.key);
                    x.title = tmp?.title || x.title;
                    x.order = tmp?.order || x.order;
                    x.orderString = `${order_text}${x.order}-`;
                    x = this.getSubmenu(x.orderString, x);
                    x.submenu = x.submenu?.filter(y => y.showInNavBar) || undefined;
                    if (x.submenu) {
                        x.submenu.sort((a, b) => (a.order || 0) - (b.order || 0));
                    }
                    return x;
                });
        } else {
            props.submenu = undefined;
        }
        return props;
    };

    getNavigationMenu = () => {
        let navList: routeProps[] = this._menus
            .filter(x => x.showInNavBar && this.isAuthorized(x.key, x.referKey || ""))
            .map(x => {
                let tmp: any = this._authHelper.getRouteItem(x.key);
                x.title = tmp?.title || x.title;
                x.order = tmp?.order || x.order;
                x.orderString = `${x.order}-`;
                x = this.getSubmenu(x.orderString, x);
                x.submenu = x.submenu?.filter(y => y.showInNavBar) || undefined;
                if (x.submenu) {
                    x.submenu.sort((a, b) => (a.order || 0) - (b.order || 0));
                }
                return x;
            });
        navList.sort((a, b) => (a.order || 0) - (b.order || 0));
        return navList;
    };
    //#endregion
}
export default RouteHelper;
