import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import TabRejectOrder from "./TabRejectOrder";
import SystemBreadCrumbs from "../components/SystemBreadCrumbs";

const RejectOrder = () => {
    const [currTab, setCurrTab] = useState<string | null>(null);

    // Ensure user can change tab by URL params
    useEffect(() => {
        const currTabParam = new URLSearchParams(window.location.search).get("tab");
        if (currTabParam) {
            setCurrTab(currTabParam);
        } else {
            setCurrTab("rejectorder");
        }
    }, []);

    // Handle popstate event
    useEffect(() => {
        const handlePopState = () => {
            const currTabParam = new URLSearchParams(window.location.search).get("tab");
            if (currTabParam) {
                setCurrTab(currTabParam);
            } else {
                setCurrTab("rejectorder");
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };
    if (!currTab) return null;
    return (
        <>
            <SystemBreadCrumbs currMenu="Reject Order" />
            <Tabs
                activeKey={currTab}
                onChange={handleChangeTab}
                type="card"
                style={{ marginBottom: 0 }}
                items={[
                    {
                        label: "Reject Order",
                        key: "rejectorder",
                        children: currTab === "rejectorder" && <TabRejectOrder />,
                    },
                ]}
                tabBarStyle={{ marginBottom: 0 }}
            />
        </>
    );
};

export default RejectOrder;
