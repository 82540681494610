import { useCallback, useEffect, useMemo, useState } from "react";
import {
    AlarmHistoricalEventOverdueProps,
    AlarmHistoricalResponseProps,
    AlarmHistoricalSummaryByModulesProps,
    AlarmHistoricaltotalAlarmEventsProps,
    AlarmHistoricaltotalTimeConsumedProps,
    CustomPaginationProps,
    FlexiDataTableCallbackProps,
    KeyValuePair,
    alarmHistoricalAssigneeProps,
    alarmHistoricalEventProps,
    ModalProps,
    callbackParams,
} from "../../../../constants/type";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../../utils/Common";
import { AlarmHistoricalFilterParamsProps } from "../../alarmHistorical";
import { ALARM_BREACH_SEVERITY, ALARM_DASHBOARD_ITEM_CALLBACK_KEY, CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { Button, Form, Modal, Spin } from "antd";
import { getStandardMarkupDisplayText, isEmptyOrNull, isNumeric } from "../../../../utils/string";
import AlarmHistoricalItemSummary from "./alarmHistoricalSummaryItem";
import { DateTimeUtil } from "../../../../utils/datetime";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { CommentOutlined, ExportOutlined, FieldTimeOutlined, ProfileOutlined } from "@ant-design/icons";
import { DefaultIfEmpty, getAvailableObjectElement, objectRemoveProps, objectToArray } from "../../../../utils/object";
import { markupText } from "../../../IntradayMonitor/Alarm/List";
import { ToObjectWithKey, ToOptionTypeList } from "../../../../utils/array";
import { getAlarmItemFeatureModalProps } from "../alarmDashboard/alarmDashboardContentComponent";
import AlarmHistoricalSummaryByModule from "./alarmHistoricalSummaryByModule";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { getProfile } from "../../../../services/localStorage";

export interface AlarmHistoricalContentComponentsProps {
    filterParams: AlarmHistoricalFilterParamsProps;
    statusObject: any;
    isLoading: boolean;
    mappingData: any;
    lpMappingData: any;
    userOptions: KeyValuePair[];
    refreshState: number;
}

interface SummaryDataProps {
    data: alarmHistoricalEventProps[];
    totalAlarmEvent: AlarmHistoricaltotalAlarmEventsProps;
    totalTimeConsumed: AlarmHistoricaltotalTimeConsumedProps;
    eventOverdue: AlarmHistoricalEventOverdueProps;
    summaryByModules: AlarmHistoricalSummaryByModulesProps[];
}

const AlarmHistoricalContentComponents = (props: AlarmHistoricalContentComponentsProps) => {
    const uPr: any = getProfile();
    const serverTime_UTCOffset: number | undefined = uPr?.hasOwnProperty("stom") ? uPr.stom : undefined;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<AlarmHistoricalResponseProps | undefined>(undefined);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorting, setSorting] = useState<string>("");
    const [filterParams, setFilterParams] = useState<any>({});
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [callbackParams, setCallbackParams] = useState<callbackParams | undefined>(undefined);
    const [refreshRate, setRefreshRate] = useState<number>(60);
    const [commentForm] = Form.useForm();
    const authHp = new AuthHelper();
    const allowEditable: boolean = authHp.isAuthorized(AuthKeys.ALARM_CENTER_HISTORICAL_EDIT);

    const modalProps: ModalProps = useMemo(() => {
        let returnParams: ModalProps = getAlarmItemFeatureModalProps(
            serverTime_UTCOffset,
            callbackParams,
            props.statusObject,
            props.mappingData,
            props.lpMappingData,
            props.userOptions,
            commentForm
        );
        if (callbackParams !== undefined) {
            switch (callbackParams.type) {
                case ALARM_DASHBOARD_ITEM_CALLBACK_KEY.LEAVE_COMMENT:
                    returnParams.extraModalProps = {
                        ...returnParams.extraModalProps,
                        onOk: () => {
                            commentForm
                                .validateFields()
                                .then(values => {
                                    if (values.mode === 1) {
                                        updateCommentAndStatus(objectRemoveProps(values, ["clientUsedCommentType", "mode", "correlationHashKey"]));
                                    } else if (values.mode === 2) {
                                        updateCommentAndStatus(objectRemoveProps(values, ["clientUsedCommentType", "mode", "alarmEventId"]));
                                    } else if (values.mode === 3) {
                                        updateCommentAndStatus(objectRemoveProps(values, ["clientUsedCommentType", "mode"]));
                                    }
                                    setIsModalVisible(false);
                                    commentForm.resetFields();
                                })
                                .catch(err => console.log(err));
                        },
                    };
                    break;
            }
        }

        return returnParams;
    }, [props.statusObject, props.mappingData, props.lpMappingData, props.userOptions, callbackParams]);

    const summaryData: SummaryDataProps | undefined = useMemo(() => {
        if (data === undefined) return undefined;

        let tmp = data as AlarmHistoricalResponseProps;
        return {
            data: tmp.result,
            totalAlarmEvent: tmp.totalAlarmEvents,
            totalTimeConsumed: tmp.totalTimeConsumed,
            eventOverdue: tmp.eventOverdue,
            summaryByModules: tmp.summaryByModules,
        };
    }, [data]);

    const updateCommentAndStatus = (data: any) => {
        apiRequest(APIs.UPDATE_ALARM_STATUS, data)
            .then(res => {
                ErrorMessageHandler("alarm comment", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm comment", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)))
            .finally(() => setRunRefetchDataList(true));
    };

    const markupNumberText = (key: string, value: any) => {
        if (["accountId", "account_id"].includes(key)) return value;

        return isNumeric(value) ? currencyRender(value) : value;
    };

    const getDetailsDisplayTemplate = useCallback(
        (alarmEventId: string, moduleCode: string, alertMessage: string) => {
            try {
                switch (moduleCode) {
                    case "MIM": {
                        let dataObj: any = isEmptyOrNull(alertMessage) ? {} : JSON.parse(alertMessage),
                            allKeys = Object.keys(dataObj),
                            dimensionKeys = Object.keys(props.mappingData.dimension),
                            dimensionKeysArr = allKeys.filter(x => dimensionKeys.includes(x)),
                            metricKeysArr = allKeys.filter(x => !dimensionKeys.includes(x));
                        return (
                            <div className={`details-content-${moduleCode}`}>
                                <div className={`template-${moduleCode}`}>
                                    <div className="event-info-container">
                                        {dimensionKeysArr.length > 0 &&
                                            dimensionKeysArr.map(x => (
                                                <div key={`${x}-${dataObj[x]}`} className="item">
                                                    <span>{`${DefaultIfEmpty(props.mappingData.dimension, x, { text: "" }).text}`}</span>
                                                    <span>
                                                        {["account_id", "group"].includes(x)
                                                            ? dataObj[x]
                                                            : DefaultIfEmpty(
                                                                  DefaultIfEmpty(props.mappingData, x, { [x]: {} }),
                                                                  x === "symbol" ? dataObj[x].toLowerCase() : dataObj[x],
                                                                  {
                                                                      text: dataObj[x],
                                                                  }
                                                              ).text}
                                                    </span>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="event-info-container">
                                        {metricKeysArr.length > 0 &&
                                            metricKeysArr.map(x => (
                                                <div key={`${x}-${dataObj[x]}`} className="item">
                                                    <span>{`${DefaultIfEmpty(props.mappingData.metricName, x, { text: "" }).text}`}</span>
                                                    <span>{markupText({}, "filterValue", dataObj[x])}</span>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    case "MR": {
                        let dataObj: any = getAvailableObjectElement(isEmptyOrNull(alertMessage) ? {} : JSON.parse(alertMessage), true),
                            allKeys = Object.keys(dataObj),
                            dimensionKeys = Object.keys(props.lpMappingData.dimension),
                            dimensionKeysArr = allKeys.filter(x => dimensionKeys.includes(x)),
                            metricKeysArr = allKeys.filter(x => !dimensionKeys.includes(x));
                        return (
                            <div className={`details-content-${moduleCode}`}>
                                <div className={`template-${moduleCode}`}>
                                    <div className="event-info-container">
                                        {dimensionKeysArr.length > 0 &&
                                            dimensionKeysArr.map(x => {
                                                let lowercaseKey = x.toLowerCase();
                                                if (lowercaseKey === "company") return <></>;

                                                let propsText = DefaultIfEmpty(props.lpMappingData.dimension, lowercaseKey, { text: "" }).text,
                                                    valueText = Array.isArray(dataObj[x])
                                                        ? dataObj[x].length === 1
                                                            ? dataObj[x]
                                                            : `${dataObj[x].join(", ")}`
                                                        : dataObj[x];

                                                return (
                                                    <div key={`${x}-${dataObj[x]}`} className="item">
                                                        <span>{propsText}</span>
                                                        <span>{valueText}</span>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <div className="event-info-container">
                                        {metricKeysArr.length > 0 &&
                                            metricKeysArr.map(x => {
                                                let lowercaseKey = x.toLowerCase(),
                                                    propsText = DefaultIfEmpty(props.lpMappingData.metricName, lowercaseKey, { text: x }).text,
                                                    valueText = Array.isArray(dataObj[x])
                                                        ? `[ ${dataObj[x].map((x: any) => getStandardMarkupDisplayText(x)).join(", ")} ]`
                                                        : getStandardMarkupDisplayText(dataObj[x], { decimalPoint: 2 });

                                                return (
                                                    <div key={`${x}-${dataObj[x]}`} className="item">
                                                        <span>{propsText}</span>
                                                        <span>{valueText}</span>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    default:
                        {
                            let isObject = JSON.parse(alertMessage);
                            if (Array.isArray(isObject)) {
                                return (
                                    <div className={`details-content-${moduleCode}`}>
                                        {isObject.map((x: any, idx: number) => {
                                            let objectKeys = Object.keys(x);
                                            return (
                                                <div key={`${alarmEventId}-rmk-${idx}`}>
                                                    {objectKeys.reduce((displayText: string, key: string, index: number) => {
                                                        return displayText.concat(
                                                            `${key}: ${markupNumberText(key, x[key])}${index < objectKeys.length - 1 ? ", " : ""}`
                                                        );
                                                    }, "")}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            } else if (typeof isObject === "object") {
                                let objectKeys = Object.keys(isObject);
                                return (
                                    <div className={`details-content-${moduleCode}`}>
                                        {objectKeys.reduce((displayText: string, key: string, index: number) => {
                                            return displayText.concat(
                                                `${key}: ${markupNumberText(key, isObject[key])}${index < objectKeys.length - 1 ? ", " : ""}`
                                            );
                                        }, "")}
                                    </div>
                                );
                            }
                        }
                        break;
                }
            } catch (error) {
                return <span>{alertMessage}</span>;
            }

            return <span>{alertMessage}</span>;
        },
        [props.mappingData, props.lpMappingData]
    );

    const columnConfig = useMemo(() => {
        return [
            DTColProps.DateTime_UTC_To_ServerTime(
                {
                    width: "7vw",
                    title: "Captured Time (Server)",
                    dataIndex: "createdDateUtc",
                    key: "createdDateUtc",
                },
                serverTime_UTCOffset
            ),
            DTColProps.XSmall(
                {
                    width: "4.5vw",
                    title: "Severity",
                    dataIndex: "breachSeverity",
                    key: "breachSeverity",
                    render: (value: number) => {
                        return <span className={`priority-color-${value}-tag`}>{ALARM_BREACH_SEVERITY[value]}</span>;
                    },
                    options: {
                        filter: {
                            type: ComponentType.dropdown,
                            value: ToOptionTypeList(ALARM_BREACH_SEVERITY),
                        },
                    },
                },
                ["text-center"]
            ),
            DTColProps.XLarge({
                width: "22vw",
                className: "vertical-top",
                title: "Alarm Name",
                dataIndex: "alarmName",
                key: "alarmName",
                render: (value: string, rowData: alarmHistoricalEventProps) => {
                    let isDone = !isEmptyOrNull(rowData.resolvedDateUtc),
                        startDate = (isEmptyOrNull(rowData.assignedDateUtc) ? rowData.createdDateUtc : rowData.assignedDateUtc) as string,
                        hasOverdue = !isEmptyOrNull(rowData.targetDueDateUtc),
                        overdueSeconds = DateTimeUtil.getDiffBetween2Date(startDate, rowData.targetDueDateUtc as string, 2) as number;
                    return (
                        <div className="alarm-historical-list-information-container">
                            <div className="top-div">
                                <div className="left">{rowData.alarmName}</div>
                                <div className="right">
                                    <span className={`status status-${rowData.eventStatusCode}`}>{rowData.eventStatusDescription}</span>
                                </div>
                            </div>
                            <div className="content">
                                <div>
                                    <span className="subtitle">{`App: (${rowData.clientApp}) ${rowData.moduleName}`}</span>
                                    {!isEmptyOrNull(rowData.moduleUrl) && (
                                        <Button
                                            size="small"
                                            type="text"
                                            icon={<ExportOutlined />}
                                            className="btn-redirect"
                                            onClick={() => {
                                                const newTab = window.open(rowData.moduleUrl as string, "_blank");
                                                newTab?.focus();
                                            }}
                                        />
                                    )}
                                </div>
                                {!isEmptyOrNull(rowData.assignees) && (rowData.assignees as alarmHistoricalAssigneeProps[]).length > 0 && (
                                    <div>
                                        <span className="subtitle">{`Assignee: ${
                                            (rowData.assignees as alarmHistoricalAssigneeProps[])[0].name
                                        }`}</span>
                                    </div>
                                )}
                                {((hasOverdue && overdueSeconds < 0) || isDone) && (
                                    <div className="date-container">
                                        <FieldTimeOutlined />
                                        <span className={`time-consumed ${hasOverdue && overdueSeconds < 0 ? "error" : ""}`}>
                                            {`${
                                                isDone
                                                    ? DateTimeUtil.convertSecondToReadableTimeDesc(
                                                          DateTimeUtil.getDiffBetween2Date(startDate, rowData.resolvedDateUtc as string, 2) as number,
                                                          true
                                                      )
                                                    : ""
                                            } ${
                                                hasOverdue && overdueSeconds < 0
                                                    ? `(Overdue ${DateTimeUtil.convertSecondToReadableTimeDesc(Math.abs(overdueSeconds))})`
                                                    : ""
                                            }`}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            }),
            {
                className: "vertical-top",
                title: "Details",
                dataIndex: "alertMessage",
                key: "alertMessage",
                render: (value: string, rowData: alarmHistoricalEventProps) => {
                    return (
                        <div className="alarm-historical-list-details-container">
                            {getDetailsDisplayTemplate(rowData.alarmEventId, rowData.moduleCode, value)}
                        </div>
                    );
                },
            },
            {
                title: "Status",
                dataIndex: "eventStatusCode",
                key: "eventStatusCode",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: objectToArray(
                            Object.keys(props.statusObject).reduce((finalObj: any, moduleCode: string) => {
                                finalObj = {
                                    ...finalObj,
                                    ...props.statusObject[moduleCode].reduce((tmpObj: any, i: KeyValuePair) => {
                                        tmpObj[`${i.value}`] = i;
                                        return tmpObj;
                                    }, {}),
                                };
                                return finalObj;
                            }, {})
                        ),
                    },
                },
            },
            {
                title: "Alarm Name",
                dataIndex: "alarmName",
                key: "alarmName",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                    visible: false,
                },
            },
        ];
    }, [getDetailsDisplayTemplate, props.statusObject]);

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("createdDateUtc,desc")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter(x => (FormData[x] === undefined ? false : FormData[x].toString().length > 0))
                    .map(x => {
                        if (x === "breachSeverity") {
                            filterParams["breachSeverities"] = [FormData[x]];
                        } else if (x === "eventStatusCode") {
                            filterParams["eventStatusCodes"] = [FormData[x]];
                        } else {
                            filterParams[x] = FormData[x];
                        }

                        return x;
                    });
                setFilterParams(filterParams);
                setSorting("createdDateUtc,desc");
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "commentHistory") {
                    setCallbackParams(FormData.data);
                    setIsModalVisible(true);
                } else if (FormData.key === "progressHistorical") {
                    setCallbackParams(FormData.data);
                    setIsModalVisible(true);
                } else if (FormData.key === "leaveComment") {
                    commentForm.setFieldsValue({
                        clientUsedCommentType: !isEmptyOrNull(FormData.data.resolvedDateUtc) ? "1" : "",
                        mode: isEmptyOrNull(FormData.data.correlationHashKey) ? 1 : 3,
                        alarmEventId: FormData.data.alarmEventId,
                        correlationHashKey: FormData.data.correlationHashKey,
                        moduleCode: FormData.data.moduleCode,
                        statusCode: isEmptyOrNull(FormData.data.eventStatusCode) ? "" : FormData.data.eventStatusCode,
                        comment: "",
                    });
                    setCallbackParams({ type: ALARM_DASHBOARD_ITEM_CALLBACK_KEY.LEAVE_COMMENT, data: FormData.data });
                    setIsModalVisible(true);
                }
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                exportToCSVCallback();
                break;
            case CALLBACK_KEY.REFRESH:
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.REFRESH_RATE_CHANGED:
                setRefreshRate(FormData.currentRefreshPeriod);
                break;
            default:
                break;
        }
    };

    const exportToCSVCallback = useCallback(() => {
        setIsLoading(true);
        apiRequest(
            APIs.EXPORT_HISTORICAL_ALARM_SUMMARY,
            {
                ...(!isEmptyOrNull(sorting) && { order: sorting }),
                ...(Object.keys(props.filterParams).length > 0 && { ...props.filterParams }),
                ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            },
            "POST",
            "arraybuffer"
        )
            .then((res: any) => {
                const fileName = res.headers["x-filename"];
                const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/zip" })); // Create blob link to download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); // or any other extension
                document.body.appendChild(link); // Append to html link element page
                link.click(); // start download
                document.body.removeChild(link); // Clean up and remove the link
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("export historical alarm summary", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    }, [props.filterParams, filterParams, sorting]);

    const getHistoricalAlarmSummary = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_HISTORICAL_ALARM_SUMMARY, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(!isEmptyOrNull(sorting) && { order: sorting }),
            ...(Object.keys(props.filterParams).length > 0 && { ...props.filterParams }),
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
        })
            .then((res: any) => {
                if (!isEmptyOrNull(res)) {
                    if (res && res.result && res.result.length > 0) {
                        setPagination(prev => ({ ...prev, total: res.total }));
                    } else {
                        setPagination(prev => ({ ...prev, total: 0 }));
                    }
                    setData(res);
                } else {
                    setData(undefined);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("historical alarm summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [props.filterParams, filterParams, pagination, sorting]);

    useEffect(() => {
        !props.isLoading && getHistoricalAlarmSummary();
    }, [props.filterParams, props.isLoading, props.refreshState]);

    useEffect(() => {
        if (runRefetchDataList) {
            !props.isLoading && getHistoricalAlarmSummary();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    return (
        <>
            <Spin spinning={props.isLoading}>
                <div className="main-content-container">
                    <div className="left">
                        <FlexiDataTable
                            bordered
                            rowKeyProperty="alarmEventId"
                            title={<span className="flexi-table-small-title-style">Historical Alarm Listing</span>}
                            columns={columnConfig}
                            options={{
                                serverFiltering: true,
                                refresh: {
                                    timer: true,
                                    refreshSecond: refreshRate,
                                    enablePeriodSelection: true,
                                },
                                export: {
                                    text: "Export as CSV",
                                },
                                ...(allowEditable && {
                                    customExtraActionButton: (record: alarmHistoricalEventProps, callback: FlexiDataTableCallbackProps) => {
                                        return (
                                            <>
                                                <Button
                                                    key={`r-${record.alarmEventId}-btn-progress`}
                                                    type="text"
                                                    icon={<CommentOutlined />}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, {
                                                            key: "progressHistorical",
                                                            data: { type: ALARM_DASHBOARD_ITEM_CALLBACK_KEY.SEVERITY_PROGRESS, data: record },
                                                        });
                                                    }}
                                                />
                                            </>
                                        );
                                    },
                                }),
                            }}
                            dataSource={summaryData?.data || []}
                            callback={componentCallback}
                            loading={isLoading}
                            pagination={pagination}
                            serverSide={true}
                        />
                    </div>
                    <div className="right">
                        <div className="summary-item-container">
                            <AlarmHistoricalItemSummary
                                title={"Total Alarm Events"}
                                SummaryTitle={"Resolved / Abandoned"}
                                SummaryDetails={
                                    isEmptyOrNull(summaryData)
                                        ? "- / -"
                                        : `${summaryData?.totalAlarmEvent.resolved.totalEvents} event${
                                              (summaryData?.totalAlarmEvent.resolved.totalEvents || 0) > 1 ? "s" : ""
                                          } / ${
                                              (summaryData?.totalAlarmEvent.abandoned.totalEvents || 0) > 0
                                                  ? `${summaryData?.totalAlarmEvent.abandoned.totalEvents} event${
                                                        (summaryData?.totalAlarmEvent.abandoned.totalEvents || 0) > 1 ? "s" : ""
                                                    }`
                                                  : "-"
                                          }   `
                                }
                            />
                            <AlarmHistoricalItemSummary
                                title={"Settlement Time"}
                                SummaryTitle={"Highest / Average(Per Event)"}
                                SummaryDetails={
                                    isEmptyOrNull(summaryData)
                                        ? "- / -"
                                        : `${DateTimeUtil.convertSecondToReadableTimeDesc(
                                              summaryData?.totalTimeConsumed.highestInSeconds || 0,
                                              false,
                                              "-"
                                          )} / ${DateTimeUtil.convertSecondToReadableTimeDesc(
                                              summaryData?.totalTimeConsumed.averagePerEventInSeconds || 0,
                                              false,
                                              "-"
                                          )}`
                                }
                            />
                            <AlarmHistoricalItemSummary
                                containerClassName="error"
                                title={"Event Overdue"}
                                SummaryTitle={"Highest / Total Overdue Events"}
                                SummaryDetails={
                                    isEmptyOrNull(summaryData)
                                        ? "- / -"
                                        : `${DateTimeUtil.convertSecondToReadableTimeDesc(
                                              summaryData?.eventOverdue.highestInSeconds || 0,
                                              false,
                                              "-"
                                          )} / ${
                                              (summaryData?.eventOverdue.totalOverdueEvents || 0) > 0
                                                  ? `${summaryData?.eventOverdue.totalOverdueEvents} event${
                                                        (summaryData?.eventOverdue.totalOverdueEvents || 0) > 1 ? "s" : ""
                                                    }`
                                                  : "-"
                                          }`
                                }
                            />
                        </div>
                        <div className="chart-container">
                            <AlarmHistoricalSummaryByModule
                                title={"Alarm Summary by Modules"}
                                SummaryDetails={
                                    isEmptyOrNull(summaryData) ? [] : (summaryData?.summaryByModules as AlarmHistoricalSummaryByModulesProps[])
                                }
                            />
                        </div>
                    </div>
                </div>
            </Spin>
            <Modal
                maskClosable={false}
                open={isModalVisible}
                destroyOnClose={true}
                wrapClassName="alarmdashboard-unassigned-item-modal-popover"
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                {...(modalProps.extraModalProps && modalProps.extraModalProps)}
                style={{ top: 20 }}
            >
                {modalProps.component}
            </Modal>
        </>
    );
};

export default AlarmHistoricalContentComponents;
