import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";
import { InnerPageActionMode, SUCCESS_FAILED, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { GetConstraintKeyList, ARRAY_ACTION_TYPE, GetUniqueKeysList } from "@/utils/array";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { Form, Row, Col } from "antd";
import { useMemo, useState, useCallback, useEffect } from "react";

export interface SecurityGroupCreateEditPageProps {
    mode: InnerPageActionMode;
    groupId?: number;
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

interface SecurityGroupProps {
    id?: number;
    groupType: number;
    groupName: string;
    securityGroups: SecurityProps[];
    isDeleted?: boolean;
}

interface SecurityProps {
    id?: number;
    security: string;
    isDeleted?: boolean;
}

const defaultValue = {
    id: "",
    groupName: "",
    securityGroups: [],
};

export const SecurityGroupCreateEditPage = (props: SecurityGroupCreateEditPageProps) => {
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [securities, setSecurities] = useState<string[]>([]);
    const [oldValue, setOldValue] = useState<SecurityGroupProps | undefined>(undefined);
    const [securityForm] = Form.useForm();

    const onSubmit = useCallback(
        (values: any) => {
            if (isAddAction) {
                apiRequest(APIs.CREATE_HUB_FAILOVER_SERVER_SECURITY_GROUP_CONFIG, {
                    groupType: 1,
                    groupName: values.groupName,
                    securityGroups: values.securityGroups.map((x: string) => ({ security: x })),
                })
                    .then(res => {
                        ErrorMessageHandler("security group", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 800);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("security group", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    );
            } else {
                let submitParams = { ...oldValue, isDeleted: false } as SecurityGroupProps;
                if (values.securityGroups.length > 0) {
                    submitParams.securityGroups = GetConstraintKeyList(
                        values.securityGroups,
                        submitParams.securityGroups.map(x => x.security),
                        ARRAY_ACTION_TYPE.LEFT_OUTER
                    ).reduce(
                        (finalArr: SecurityProps[], x: string) => {
                            finalArr.push({ security: x });
                            return finalArr;
                        },
                        submitParams.securityGroups.map((x: SecurityProps) => ({
                            ...x,
                            ...(!values.securityGroups.includes(x.security) && { isDeleted: true }),
                        }))
                    );
                } else if (submitParams.securityGroups && submitParams.securityGroups.length > 0) {
                    submitParams.securityGroups = submitParams.securityGroups.map((x: SecurityProps) => ({ ...x, isDeleted: true }));
                }
                apiRequest(APIs.UPDATE_HUB_FAILOVER_SERVER_SECURITY_GROUP_CONFIG, submitParams)
                    .then(res => {
                        ErrorMessageHandler("security group", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 800);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("security group", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    );
            }
        },
        [oldValue, isAddAction]
    );

    const getSecurityGroupDetails = (groupId?: number) => {
        if (props.groupId) {
            setIsLoading(true);
            apiRequest(APIs.GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_DETAIL, { groupType: 1, groupId })
                .then(res => {
                    if (res?.hasOwnProperty("groupId")) {
                        securityForm.setFieldsValue({
                            ...defaultValue,
                            id: res.groupId,
                            groupName: res.groupName,
                            securityGroups: res.securityGroupDetails.map((x: any) => x.security),
                        });
                        let cObj = {
                            id: res.groupId,
                            groupType: 1,
                            groupName: res.groupName,
                            securityGroups: res.securityGroupDetails.map((x: any) => ({ ...x, isDeleted: false })),
                        };
                        setOldValue(cObj);
                    } else {
                        setOldValue(undefined);
                    }
                })
                .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("security", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
                .finally(() => setIsLoading(false));
        } else {
            securityForm.setFieldsValue(defaultValue);
            setIsLoading(false);
        }
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["hubFailover"] })
            .then(res => {
                if (res.hubFailover.securities && res.hubFailover.securities.length > 0) {
                    setSecurities(GetUniqueKeysList(res.hubFailover.securities, "name", true));
                }
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => console.log("Error occured during get securities.")));
    };

    useEffect(() => getSecurityGroupDetails(props.groupId), [props.resetState]);
    useEffect(() => getConfig(), []);

    return (
        <SitePageHeaderInline
            enableSubmit
            title={isAddAction ? "Create New Security Group" : "Edit Security Group"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        securityForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={securityForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                    <Row>
                        <Col span={15}>
                            <FormComponent label="" name="id" extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Group Name"
                                name="groupName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Securities"
                                name="securityGroups"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: securities.map(x => ({ text: x, value: x })),
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: {
                                        mode: "multiple",
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default SecurityGroupCreateEditPage;
