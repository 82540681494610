import { PlusOutlined, CloseOutlined, DeleteOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormInstance, Popconfirm, Row, Space, Tag } from "antd";
import EmptyData from "../../../../../../components/Common/Empty";
import { FormComponent } from "../../../../../../components/FormComponent";
import { ComponentType } from "../../../../../../constants";
import { REQUIRED_FIELD } from "../../../../../../constants/errorMessage";
import { KeyValuePair } from "../../../../../../constants/type";
import { useEffect, useState } from "react";

interface MRBrandsPKServerPanelProps {
    form: FormInstance;
    configData: any;
    isAddAction: boolean;
}

interface GroupMRBrands {
    marketBrand: string;
    pkServers: KeyValuePair[];
}

const MRBrandPKServerPanel = (props: MRBrandsPKServerPanelProps) => {
    const [editIndex, setEditIndex] = useState<number | string | null>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [MRBrandsList, setMRBrandsList] = useState<GroupMRBrands[]>(props.form.getFieldValue("mrBrands"));

    const onAddEditClicked = (index: number | null) => {
        setEditIndex(index);
        setIsEdit(true);
    };

    const handleSave = (index: number) => {
        const { marketBrand, pkServers } = props.form.getFieldValue(["mrBrands", index]);
        const updatedPkServers = props.configData.mrPKServers.filter((server: any) => pkServers.includes(server.value));

        let updatedMRBrands = MRBrandsList && MRBrandsList.length > 0 ? [...MRBrandsList] : [];
        if (marketBrand === "" || pkServers.length === 0) {
            updatedMRBrands = updatedMRBrands.filter((_, i) => i !== index);
        } else {
            updatedMRBrands[index] = {
                marketBrand: marketBrand,
                pkServers: updatedPkServers.length > 0 ? updatedPkServers : MRBrandsList[index].pkServers,
            };
        }

        props.form.setFieldsValue({ ["mrBrands"]: updatedMRBrands });
        setMRBrandsList(updatedMRBrands);
        setEditIndex(null);
        setIsEdit(false);
    };

    const handleCancel = (index: number) => {
        if (isEdit) {
            setMRBrandsList([...MRBrandsList]);
            props.form.setFieldsValue({ mrBrands: [...MRBrandsList] });
        } else {
            const newMRBrandsList = MRBrandsList.filter((obj, i) => i !== index);
            setMRBrandsList(newMRBrandsList);
            props.form.setFieldsValue({ mrBrands: newMRBrandsList });
        }

        setEditIndex(null);
        setIsEdit(false);
    };

    const handleRemove = (index: number) => {
        setMRBrandsList(prevMRBrands => prevMRBrands.filter((_, i) => i !== index));
    };

    const handleAddCancel = () => {
        setEditIndex(0);
        const updatedMRBrands = MRBrandsList && MRBrandsList.length > 0 ? [...MRBrandsList] : [];
        updatedMRBrands.unshift({ marketBrand: "", pkServers: [] });
        setMRBrandsList(updatedMRBrands);
        props.form.setFieldsValue({ ["mrBrands"]: updatedMRBrands });
    };

    useEffect(() => {
        if (props.isAddAction) {
            setMRBrandsList([]);
            props.form.setFieldsValue({ ["mrBrands"]: [] });
        }
        return () => {};
    }, [props.isAddAction]);

    return (
        <>
            <div className="mrBrands-container">
                <Row>
                    <Col className="title-div" span={24}>
                        <h1>MR Brand (s)</h1>
                        <div>
                            <Button onClick={handleAddCancel} type="dashed" icon={<PlusOutlined />}>
                                Add Brand
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="list-item-div">
                            <Form.List name={"mrBrands"}>
                                {(fields, { add, remove }) => {
                                    return fields.length > 0 ? (
                                        <>
                                            {fields.map((field, index) => {
                                                return (
                                                    <div key={field.key} className="item-container">
                                                        <div className="content-panel">
                                                            {editIndex === index ? (
                                                                <div className="editable-content" key={`brand-${field.name}`}>
                                                                    <div className="left">
                                                                        <FormComponent
                                                                            label="Brand Name"
                                                                            name={[field.name, "marketBrand"]}
                                                                            extra={{
                                                                                type: ComponentType.text,
                                                                                value: "",
                                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                                                inputProps: {
                                                                                    allowClear: true,
                                                                                },
                                                                            }}
                                                                        />
                                                                        <FormComponent
                                                                            label="PK Servers"
                                                                            name={[field.name, "pkServers"]}
                                                                            extra={{
                                                                                type: ComponentType.dropdown,
                                                                                value: props.configData.mrPKServers,
                                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                                                inputProps: {
                                                                                    allowClear: true,
                                                                                    mode: "multiple",
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="right">
                                                                        <Space>
                                                                            <Button
                                                                                type="default"
                                                                                icon={<CheckOutlined />}
                                                                                onClick={() => handleSave(index)}
                                                                                style={{ marginRight: "5px", color: "#004b57" }}
                                                                            />

                                                                            <Button
                                                                                type="default"
                                                                                icon={<CloseOutlined />}
                                                                                onClick={() => handleCancel(index)}
                                                                                style={{ marginRight: "5px", color: "#f00f00" }}
                                                                            />
                                                                        </Space>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="display-content" key={`brand-${field.name}`}>
                                                                    <div className="left">
                                                                        <div className="title">{MRBrandsList[field.name].marketBrand}</div>
                                                                        <div className="subtitle">
                                                                            {MRBrandsList[field.name].pkServers.map(x => (
                                                                                <Tag className="pkServerColor">{x.text}</Tag>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    <div className="right">
                                                                        <Space>
                                                                            <Button
                                                                                type="link"
                                                                                icon={<EditOutlined />}
                                                                                onClick={() => onAddEditClicked(field.name)}
                                                                            />
                                                                            <Popconfirm
                                                                                key={"mrbrandpk-del"}
                                                                                title="Confirm to delete?"
                                                                                onConfirm={() => {
                                                                                    remove(field.name);
                                                                                    handleRemove(field.name);
                                                                                }}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                            >
                                                                                <Button type="link" danger icon={<DeleteOutlined />} />
                                                                            </Popconfirm>
                                                                        </Space>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <EmptyData />
                                    );
                                }}
                            </Form.List>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default MRBrandPKServerPanel;
