import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { Form, FormInstance } from "antd";
import SymbolRadioComponent from "./components/symbolRadioComponent";

export interface SymbolInfoCreateEditPageProps {
    form: FormInstance;
    servers: any[];
    symbols: any[];
    cleanSymbols: any[];
    //symbolSessionTypes: any[];
}

const SymbolInfoCreateEditPage = (props: SymbolInfoCreateEditPageProps) => {
    /*
    const enum ColSpan {
        LabelSpan = 7,
        InputSpan = 17,
    }

    const [currentSessionMode, setCurrentSessionMode] = useState<string>("0");
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isSymbolSessionModalVisible, setIsSymbolSessionModalVisible] = useState<boolean>(false);
    const [holidayForm] = Form.useForm();
    const [symbolSessionForm] = Form.useForm();

    const getTimeRangePanel = (value: string | undefined, key: string) => {
        if (!isEmptyOrNull(value)) {
            return (
                <div className="time-range-tag-div">
                    {(value as string).split("|").map((x: string, index: number) => (
                        <Tag key={`syss-22-${key}-${index}`} style={{ padding: "0 0.3255vw 0 0.3255vw", borderRadius: "5px" }}>
                            {x}
                        </Tag>
                    ))}
                </div>
            );
        }
        return <></>;
    };

    const getTradingHourItemTemplate = (currSessionKey: string) => {
        return (
            <div className="flex-row-between" style={{ alignItems: "center", border: "1px solid #e5e5e5", borderRadius: "5px", marginBottom: "5px" }}>
                <FormComponent label="" name={currSessionKey} extra={{ type: ComponentType.hidden, value: "" }} />
                <Button
                    icon={<EditOutlined />}
                    type="text"
                    onClick={() => {
                        let currText: string = props.form.getFieldValue(currSessionKey);
                        if (currText && currText.length > 0) {
                            let txtArr = currText.split("|"),
                                timeRangeValue = txtArr.map(x => x.split("-").map(y => moment(moment(new Date()).format(`YYYY-MM-DD ${y}:00`))));
                            symbolSessionForm.setFieldsValue({
                                symbolSessionKey: currSessionKey,
                                timeRange: timeRangeValue,
                            });
                        } else {
                            symbolSessionForm.setFieldsValue({
                                symbolSessionKey: currSessionKey,
                                timeRange: [],
                            });
                        }
                        setIsSymbolSessionModalVisible(true);
                    }}
                />
                {getTimeRangePanel(props.form.getFieldValue(currSessionKey), currSessionKey)}
            </div>
        );
    };
    */

    return (
        <>
            <Form form={props.form} layout="horizontal" labelAlign="left" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
                <div className="sipc-mdl-c-div">
                    <div className="left main-only">
                        <div className="basic-symbol-info-div">
                            <h1 className="title">
                                <span>Basic Info</span>
                            </h1>
                            <div className="label-value-div">
                                <div className="value-div">
                                    <FormComponent label="" name="symbolId" extra={{ type: ComponentType.hidden, value: "" }} />
                                    <SymbolRadioComponent
                                        form={props.form}
                                        servers={props.servers}
                                        cleanSymbols={props.cleanSymbols}
                                        symbols={props.symbols}
                                    />
                                    <FormComponent
                                        label="Clean Symbol"
                                        name="cleanSymbol"
                                        extra={{
                                            type: ComponentType.dropdown,
                                            value: props.cleanSymbols,
                                        }}
                                    />
                                    {/* <FormComponent
                                        label="Pricing Clean Symbol"
                                        name="pricingCleanSymbol"
                                        extra={{ type: ComponentType.text, value: "" }}
                                    /> */}
                                    <FormComponent label="Suffix" name="suffix" extra={{ type: ComponentType.text, value: "" }} />
                                    {/* <FormComponent label="Symbol Group" name="symbolGroup" extra={{ type: ComponentType.text, value: "" }} /> */}
                                    <FormComponent label="Description" name="description" extra={{ type: ComponentType.text, value: "" }} />
                                    {/* <FormComponent label="Base Currency" name="currencyBase" extra={{ type: ComponentType.text, value: "" }} /> */}
                                    <FormComponent label="Profit Currency" name="currencyProfit" extra={{ type: ComponentType.text, value: "" }} />
                                    {/* <FormComponent label="Margin Currency" name="currencyMargin" extra={{ type: ComponentType.text, value: "" }} /> */}
                                    <FormComponent label="Contract Size" name="contractSize" extra={{ type: ComponentType.number, value: "" }} />
                                    <FormComponent
                                        label="Volume Size Adjustment"
                                        name="volumeSizeAdj"
                                        extra={{ type: ComponentType.number, value: "" }}
                                    />
                                    <FormComponent label="Digits" name="digits" extra={{ type: ComponentType.number, value: "" }} />
                                    {/* <FormComponent
                                        label="Is Symbol Removed ?"
                                        name="isRemoved"
                                        extra={{
                                            type: ComponentType.dropdown,
                                            value: [
                                                { text: "Yes", value: "1" },
                                                { text: "No", value: "0" },
                                            ],
                                        }}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="right">
                        <div className="basic-symbol-info-div">
                            <h1 className="title">
                                <span>Trading Hours</span>
                                {props.symbolSessionTypes.length > 0 && (
                                    <Segmented
                                        options={props.symbolSessionTypes.map(x => ({
                                            label: x.text,
                                            value: x.value,
                                        }))}
                                        value={currentSessionMode}
                                        onChange={(sValued: any) => setCurrentSessionMode(sValued)}
                                    />
                                )}
                            </h1>
                            {props.symbolSessionTypes.map(x => (
                                <div
                                    key={`symses-${x.value}`}
                                    className="details-container"
                                    {...(currentSessionMode !== x.value && { style: { display: "none" } })}
                                >
                                    <FormComponent
                                        label=""
                                        name={`symses-${x.value}-symbolSessionId`}
                                        extra={{ type: ComponentType.hidden, value: "" }}
                                    />
                                    <Row>
                                        <Col span={ColSpan.LabelSpan}>Monday</Col>
                                        <Col span={ColSpan.InputSpan}>{getTradingHourItemTemplate(`symses-${x.value}-mondaySession`)}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={ColSpan.LabelSpan}>Tuesday</Col>
                                        <Col span={ColSpan.InputSpan}>{getTradingHourItemTemplate(`symses-${x.value}-tuesdaySession`)}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={ColSpan.LabelSpan}>Wednesday</Col>
                                        <Col span={ColSpan.InputSpan}>{getTradingHourItemTemplate(`symses-${x.value}-wednesdaySession`)}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={ColSpan.LabelSpan}>Thursday</Col>
                                        <Col span={ColSpan.InputSpan}>{getTradingHourItemTemplate(`symses-${x.value}-thursdaySession`)}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={ColSpan.LabelSpan}>Friday</Col>
                                        <Col span={ColSpan.InputSpan}>{getTradingHourItemTemplate(`symses-${x.value}-fridaySession`)}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={ColSpan.LabelSpan}>Saturday</Col>
                                        <Col span={ColSpan.InputSpan}>{getTradingHourItemTemplate(`symses-${x.value}-saturdaySession`)}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={ColSpan.LabelSpan}>Sunday</Col>
                                        <Col span={ColSpan.InputSpan}>{getTradingHourItemTemplate(`symses-${x.value}-sundaySession`)}</Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <Form.List name={"symbolHolidays"}>
                            {(fields, { add, remove }) => (
                                <div className="basic-symbol-info-div">
                                    <h1 className="title">
                                        <span>Upcoming Holidays</span>
                                        <Button
                                            htmlType="button"
                                            style={{ marginLeft: "0.651vw" }}
                                            icon={<PlusOutlined style={{ fontSize: "0.875rem" }} />}
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                setIsModalVisible(true);
                                                holidayForm.setFieldsValue({
                                                    symbolHolidayId: "",
                                                    description: "",
                                                    date: "",
                                                    timeRange: [],
                                                });
                                            }}
                                        >
                                            Create New Holiday
                                        </Button>
                                    </h1>
                                    {fields.length === 0 && <EmptyData />}
                                    {fields.map((field, index) => {
                                        let currSymbolHoliday: any[] = props.form.getFieldValue("symbolHolidays");
                                        return (
                                            <div className="label-value-div create-view" key={`sshhy-${index}`}>
                                                <div className="label-div">{currSymbolHoliday[index].description}</div>
                                                <div className="value-div">
                                                    <div className="symbol-holiday-time-div">
                                                        <div className="date-div">{currSymbolHoliday[index].date}</div>
                                                        <div className="time-range-tag-div">
                                                            {currSymbolHoliday[index].timeRange.split("|").map((x: string, idx: number) => (
                                                                <Tag
                                                                    key={`syh-ii-${index}-${idx}`}
                                                                    style={{ padding: "0 0.3255vw 0 0.3255vw", borderRadius: "5px" }}
                                                                >
                                                                    {x}
                                                                </Tag>
                                                            ))}
                                                            <Button
                                                                icon={<DeleteOutlined style={{ color: "#f00f00" }} />}
                                                                type="text"
                                                                onClick={() => remove(index)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </Form.List>
                    </div> */}
                </div>
            </Form>

            {/* <Modal
                open={isModalVisible}
                maskClosable={false}
                destroyOnClose={true}
                width="25%"
                title="Create New Holiday"
                okText="Save"
                cancelText="Cancel"
                onOk={() => {
                    holidayForm
                        .validateFields()
                        .then((values: any) => {
                            let currHS: any[] = props.form.getFieldValue("symbolHolidays");
                            currHS.push({
                                description: values["description"],
                                date: values["date"].format("YYYY-MM-DD"),
                                timeRange: values["timeRange"].map((x: any[]) => `${x[0].format("HH:mm")}-${x[1].format("HH:mm")}`).join("|"),
                                ...(values["symbolHolidayId"].length > 0 && {
                                    symbolHolidayId: values["symbolHolidayId"],
                                }),
                            });
                            props.form.setFieldsValue({ symbolHolidays: currHS });
                            setIsModalVisible(false);
                        })
                        .catch(err => {});
                }}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
            >
                <SymbolHolidayCreateForm form={holidayForm} />
            </Modal>

            <Modal
                open={isSymbolSessionModalVisible}
                maskClosable={false}
                destroyOnClose={true}
                width="25%"
                title="Edit Symbol Session"
                okText="Save"
                cancelText="Cancel"
                onOk={() => {
                    symbolSessionForm
                        .validateFields()
                        .then((values: any) => {
                            let updateParam: any = {};
                            updateParam[values["symbolSessionKey"]] = values["timeRange"]
                                .map((x: any) => `${x[0].format("HH:mm")}-${x[1].format("HH:mm")}`)
                                .join("|");
                            props.form.setFieldsValue(updateParam);
                            setIsSymbolSessionModalVisible(false);
                        })
                        .catch(err => {});
                }}
                onCancel={() => {
                    setIsSymbolSessionModalVisible(false);
                }}
            >
                <Form form={symbolSessionForm} layout="horizontal" labelAlign="left" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                    <FormComponent label="" name="symbolSessionKey" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent
                        label="Time Range"
                        name="timeRange"
                        extra={{
                            type: ComponentType.multipletimerange,
                            value: "",
                            timeFormat: "HH:mm",
                            multipleElementLabelColSpan: 6,
                            rules: [
                                {
                                    validator: (_: any, value: any) => {
                                        // if (value.length === 0) {
                                        //     return Promise.reject(new Error("This field is required"));
                                        // } else
                                        if (value.filter((x: any) => x === undefined || x === null || (x as any[]).length < 2).length > 0) {
                                            return Promise.reject(new Error("Please fill in all time range"));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ],
                        }}
                    />
                </Form>
            </Modal> */}
        </>
    );
};

export default SymbolInfoCreateEditPage;
