import { Tabs, Space } from "antd";
import { useState } from "react";
import NoMoneySettings from "./NoMoneySettings";
import CommonIssuesLogExcludeAccountSettingsTable from "../CommonIssuesLogExcludeAccountSettingsTable";

export interface IssueLogNoMoneySettingsPanelProps {
    enableUpdate: boolean;
}

const IssueLogNoMoneySettingsPanel = (props: IssueLogNoMoneySettingsPanelProps) => {
    const [currTab, setCurrTab] = useState<string>("hlServer");
    return (
        <>
            <Tabs
                activeKey={currTab}
                onChange={setCurrTab}
                type="card"
                style={{ padding: "0.5rem 0.5rem 0 0.5rem" }}
                tabBarStyle={{ marginBottom: 0 }}
                items={[
                    { label: "High-load Server", key: "hlServer" },
                    { label: "Whitelist", key: "whitelist" },
                ]}
            />
            <Space style={{ width: "100%", padding: "1rem" }} direction="vertical">
                {currTab === "hlServer" && <NoMoneySettings enableUpdate={props.enableUpdate} />}
                {currTab === "whitelist" && (
                    <CommonIssuesLogExcludeAccountSettingsTable type="NO_MONEY_WHITELIST" title="" enableUpdate={props.enableUpdate} />
                )}
            </Space>
        </>
    );
};

export default IssueLogNoMoneySettingsPanel;
