import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeader from "@/components/PageHeader";
import { SUCCESS_FAILED, ComponentType, InnerPageActionMode } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { HomeOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col } from "antd";
import { useState, useMemo, useCallback, useEffect } from "react";
import { CreateEditInlineFormBased, KeyValuePair } from "@/constants/type";
import { SortList } from "@/utils/array";
import { ExternalSystemV2Props } from ".";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";

interface ExternalSystemCreateEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

const initialValues = {
    extSysId: "",
    extSysName: "",
    extSysTypeId: "",
    serverIds: [],
    enable: true,
};

const ExternalSystemCreateEditPage = (props: ExternalSystemCreateEditPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [extSysType, setExtSysType] = useState<KeyValuePair[]>([]);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [externalSystemForm] = Form.useForm();

    const stateInfo = useMemo(() => {
        return {
            isAddAction: props.mode === InnerPageActionMode.CREATE_NEW,
            data: defaultIfEmptyOrNull(props.id, ""),
        };
    }, [props.mode, props.id]);

    const processBackAndRefetch = () => setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);

    const getExternalSystemInfo = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_EXTERNAL_SYSTEM_LIST_V2, { extSysIds: [stateInfo.data] })
            .then((res: any) => {
                if (res.result.length > 0) {
                    let tmp = res.result[0] as ExternalSystemV2Props;
                    externalSystemForm.setFieldsValue({ ...tmp, serverIds: tmp.servers.map((x: any) => x.serverId) });
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("external system", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                processBackAndRefetch();
            })
            .finally(() => setIsLoading(false));
    }, [stateInfo]);

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);
            if (stateInfo.isAddAction) {
                apiRequest(APIs.CREATE_EXTERNAL_SYSTEM_V2, values)
                    .then(data => {
                        ErrorMessageHandler("New external system", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new external system", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                        setIsLoading(false);
                    });
            } else {
                apiRequest(APIs.UPDATE_EXTERNAL_SYSTEM_V2, values)
                    .then(data => {
                        ErrorMessageHandler("Existing external system", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("existing external system", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        );
                        setIsLoading(false);
                    });
            }
        },
        [stateInfo]
    );

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["riskinsightexternalsystemtype", "riskinsightserver"] })
            .then((res: any) => {
                setExtSysType(
                    SortList(
                        res.riskInsightExternalSystemTypes.map((x: any) => ({ text: x.extSysTypeLabel, value: x.extSysTypeId })),
                        "text"
                    )
                );
                setServers(
                    SortList(
                        res.riskInsightServers.map((x: any) => ({ text: x.serverName, value: x.serverId })),
                        "text"
                    )
                );
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("external system config", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    useEffect(() => {
        if (!stateInfo.isAddAction) {
            getExternalSystemInfo();
        } else {
            externalSystemForm.setFieldsValue(initialValues);
        }
    }, [stateInfo, getExternalSystemInfo, props.resetState]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <SitePageHeaderInline
            enableSubmit={!isLoading}
            title={stateInfo.isAddAction ? "Create New External System" : "Edit External System"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        externalSystemForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={externalSystemForm}
                    layout="horizontal"
                    initialValues={{}}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="System ID"
                                name="extSysId"
                                extra={{
                                    type: ComponentType.number,
                                    value: "",
                                    ...(stateInfo.isAddAction
                                        ? {
                                              rules: [{ required: true, message: REQUIRED_FIELD }],
                                              inputProps: { min: 1 },
                                          }
                                        : {
                                              inputProps: {
                                                  min: 1,
                                                  disabled: true,
                                              },
                                          }),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Name"
                                name="extSysName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Type"
                                name="extSysTypeId"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: extSysType,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Servers"
                                name="serverIds"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: servers,
                                    inputProps: { mode: "multiple" },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Status"
                                name="enable"
                                extra={{
                                    type: ComponentType.switch,
                                    value: ["Inactive", "Active"],
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default ExternalSystemCreateEditPage;
