import BasicFilter from "@/components/BasicFilter";
import CardBox from "@/components/Common/CardBox";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ContainerOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Form, Row, Col, Divider, Segmented, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import CADividendSeparateProcessTable from "./components/CADividendSeparateProcessTable";
import CADividendSeparateProcessGroupData from "./components/CADividendSeparateProcessGroupData";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "@/pages/ReportingModule/ReportBatch";
import { ErrorMessageHandler } from "@/utils/Common";

export interface CADividendSeparateProcessProps {}

const CADividendSeparateProcess = (props: CADividendSeparateProcessProps) => {
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<string>("Group Data");
    const [runRefetchDataList, setRunRefetchDataList] = useState<number>(0);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [filterParam, setFilterParam] = useState<any>({
        startSeparateDate: moment().format("YYYYMMDD"),
        endSeparateDate: moment().format("YYYYMMDD"),
    });
    const [filterForm] = Form.useForm();

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_CA_DIVIDEND_SEPARATE_PROCESS_EDIT);

    const exportCSV = useCallback(() => {
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_CA_DIVIDEND_SEPARATE_PROCESS_DOWNLOAD_REPORT, filterParam)
            .then((res: any) => {
                if (res.data === "success") {
                    ErrorMessageHandler("Add to batch successed. Please download from【Report Listing】.", SUCCESS_FAILED.OTHERS_SUCCESS);
                } else {
                    ErrorMessageHandler(`Add to batch failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) => ErrorMessageHandler(`Add to batch failed: "${error.message}".`, SUCCESS_FAILED.OTHERS_FAILED));
    }, [filterParam]);

    const onFinished = (FormData: any) => {
        let tmpFilterParams: any = {};
        Object.keys(FormData)
            .filter(x => (FormData[x] === undefined ? false : FormData[x].toString().length > 0))
            .forEach(x => {
                if (x === "separateDate") {
                    tmpFilterParams["startSeparateDate"] = FormData[x][0].format("YYYYMMDD");
                    tmpFilterParams["endSeparateDate"] = FormData[x][1].format("YYYYMMDD");
                } else {
                    tmpFilterParams[x] = FormData[x];
                }
            });
        setFilterParam(tmpFilterParams);
        setRunRefetchDataList(prev => prev + 1);
    };

    const getConfig = () => {
        plainAxiosInstance.get(APIs.RISK_TOOL.GET_CA_DIVIDEND_SEPARATE_PROCESS_SERVERS).then(response => {
            setServers(response.data.map((x: any) => ({ text: x.name, value: x.id })));
        });
    };

    useEffect(() => {
        getConfig();
        filterForm.setFieldsValue({ separateDate: [moment(), moment()] });
    }, []);

    const colsProps = {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        style: { marginBottom: "1.414vh" },
    };

    return (
        <>
            <div className="cadividend-separate-process-container">
                <CardBox title={"CA Dividend Separate Process"}>
                    <BasicFilter
                        titleBarChildren={
                            <>
                                <Button
                                    htmlType="button"
                                    style={{ marginLeft: "0.651vw", width: "auto" }}
                                    icon={<ContainerOutlined style={{ fontSize: "0.875rem" }} />}
                                    onClick={event => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setIsReportModalVisible(true);
                                    }}
                                    disabled={!enableUpdate}
                                >
                                    Report Listing
                                </Button>
                                <Button
                                    htmlType="button"
                                    style={{ marginLeft: "0.651vw", width: "auto" }}
                                    icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}
                                    onClick={event => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        exportCSV();
                                    }}
                                    disabled={!enableUpdate}
                                >
                                    Download Report
                                </Button>
                            </>
                        }
                    >
                        <Form form={filterForm} layout="vertical" onFinish={onFinished}>
                            <Row gutter={24} style={{ width: "100%" }}>
                                <Col {...colsProps}>
                                    <FormComponent label="Server" name="serverId" extra={{ type: ComponentType.dropdown, value: servers }} />
                                </Col>
                                <Col {...colsProps}>
                                    <FormComponent
                                        label="Separate Date"
                                        name="separateDate"
                                        extra={{
                                            type: ComponentType.daterange,
                                            value: [],
                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                            dateFormat: "YYYY-MM-DD",
                                            inputProps: {
                                                dateOnly: true,
                                                allowClear: false,
                                            },
                                        }}
                                    />
                                </Col>
                                <Col {...colsProps}>
                                    <FormComponent label="Symbol" name="symbol" extra={{ type: ComponentType.text, value: "" }} />
                                </Col>
                            </Row>
                            <Divider orientation="left" className="divider-nogap"></Divider>
                            <Row style={{ width: "100%" }}>
                                <Col span={24} key="flt-f-c-btns" className="flt-f-c-btns">
                                    <Button
                                        type="default"
                                        onClick={e => {
                                            e.preventDefault();
                                            filterForm.resetFields();
                                            filterForm.setFieldsValue({ separateDate: [moment(), moment()] });
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </BasicFilter>

                    <div className="mid-segment">
                        <Segmented options={["Group Data", "Listing"]} value={currentTab} onChange={(activeKey: any) => setCurrentTab(activeKey)} />
                    </div>
                    <CADividendSeparateProcessGroupData filterParam={filterParam} runRefetchDataList={runRefetchDataList} currentTab={currentTab} />
                    <CADividendSeparateProcessTable filterParam={filterParam} runRefetchDataList={runRefetchDataList} currentTab={currentTab} />
                </CardBox>
            </div>
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "CaDividendSeparateProcess"}
            />
        </>
    );
};

export default CADividendSeparateProcess;
