import { useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { useRCBalanceAdjustmentMutate, useRCBalanceAdjustmentSMSSettings } from "@/hooks/useRCBalanceAdjustment";
import { ErrorMessageHandler } from "@/utils/Common";
import { Col, Form, message, Row, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";

interface BalanceAdjustmentSMSProps {
    enableUpdate: boolean;
}

interface ISMSModalProps {
    level: number;
    threshold: number;
    mobile: string;
}

const BalanceAdjustmentSMS = (props: BalanceAdjustmentSMSProps) => {
    const { rcBalanceAdjustmentSMSSettings, isLoading } = useRCBalanceAdjustmentSMSSettings();
    const { mutateUpdateSMSSetting, isLoadingUpdateSMSSetting } = useRCBalanceAdjustmentMutate();
    const [openSMSModal, setOpenSMSModal] = useState<ISMSModalProps | null>(null);

    const [form] = Form.useForm();
    const { Text } = Typography;

    const initFormValue = {
        level: 0,
        threshold: 0,
        mobile: "",
    };

    const handleSubmit = async () => {
        // console.log("------FORM VALUE", form.getFieldsValue());
        try {
            const resp = await mutateUpdateSMSSetting(form.getFieldsValue());
            // console.log("-----HELLLLO Resp", resp);
            if (resp?.data > 1) {
                ErrorMessageHandler("SMS settings", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                form.resetFields();
                setOpenSMSModal(null);
            }
        } catch (error: any) {
            ErrorMessageHandler(`Failed to update SMS settings. Error: ${error?.response?.data?.message}`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const keyboardStyle = {
        // fontSize: "0.75rem",
        fontWeight: "normal",
        height: "fit-content",
        display: "inline-block",
        alignSelf: "center",
        background: "#ccc",
        borderRadius: "4px",
        color: "#333",
        padding: "2px 5px",
    };

    const columns = [
        {
            title: "Level",
            dataIndex: "level",
            key: "level",
            sorter: (a: any, b: any) => a.level - b.level,
        },
        {
            title: "Threshold",
            dataIndex: "threshold",
            key: "threshold",
            sorter: (a: any, b: any) => a.threshold - b.threshold,
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        separateActionButton: true,
        edit: props.enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.DO_EDIT:
                setOpenSMSModal(FormData);
                form.setFieldsValue(FormData);
                break;
            default:
                break;
        }
    };

    const massagedData = useMemo(() => {
        return rcBalanceAdjustmentSMSSettings && rcBalanceAdjustmentSMSSettings?.length >= 1
            ? rcBalanceAdjustmentSMSSettings?.map((item: any) => {
                  return {
                      ...item,
                      key: `${item?.level ?? ""}-${item?.threshold ?? ""}`,
                  };
              })
            : [];
    }, [rcBalanceAdjustmentSMSSettings]);

    return (
        <>
            <div className="single-page">
                <div className={`active`}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="key"
                        title={false}
                        columns={columns}
                        dataSource={massagedData ?? []}
                        loading={isLoading || isLoadingUpdateSMSSetting}
                        options={options}
                        callback={componentCallback}
                        pagination={{ defaultPageSize: 50, pageSizeOptions: [10, 20, 50, 100, 500, 1000] }}
                    />
                    <Form
                        form={form}
                        layout="horizontal"
                        initialValues={initFormValue}
                        // /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                        onFinish={handleSubmit}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                    >
                        <Modal
                            title="Balance Adjustment SMS Settings"
                            width={"30%"}
                            open={openSMSModal !== null}
                            onCancel={() => setOpenSMSModal(null)}
                            onOk={() => form.submit()}
                            okButtonProps={{ loading: isLoadingUpdateSMSSetting }}
                            okText="Submit"
                        >
                            <div>
                                <Row>
                                    <Col span={22}>
                                        <FormComponent
                                            label="Level"
                                            name={"level"}
                                            extra={{
                                                type: ComponentType.labelOnly,
                                                value: "",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={22}>
                                        <FormComponent
                                            label="Threshold"
                                            name={"threshold"}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={22}>
                                        <FormComponent
                                            label="Mobile"
                                            name={"mobile"}
                                            extra={{
                                                type: ComponentType.text,
                                                value: "",
                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                itemProps: {
                                                    extra: (
                                                        <Text style={{ color: "#666", marginBottom: 4, display: "block" }}>
                                                            <small>
                                                                Add <span style={keyboardStyle}>+</span> infront of number and Seperate numbers with{" "}
                                                                <span style={keyboardStyle}>,</span>
                                                            </small>
                                                        </Text>
                                                    ),
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Modal>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default BalanceAdjustmentSMS;
