import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { DefaultIfEmpty } from "@/utils/object";
import { isEmptyOrNull } from "@/utils/string";
import { message } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

export interface HoriDifferentRecordsPageProps {}

interface ConfigDataProps {
    brands: KeyValuePair[];
    tables: KeyValuePair[];
    brandObject: { [key: string]: string[] };
}

const HoriDifferentRecordsPage = (props: HoriDifferentRecordsPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [configData, setConfigData] = useState<ConfigDataProps>({ brands: [], tables: [], brandObject: {} });
    const [filterParams, setFilterParams] = useState<any>({});

    const columnProps = useMemo(() => {
        if (!isEmptyOrNull(DefaultIfEmpty(filterParams, "brandId", ""))) {
            return [
                { title: "Column", dataIndex: "column", key: "column" },
                ...DefaultIfEmpty(configData.brandObject, filterParams["brandId"], []).map((x: any) => ({
                    title: `${x}`.toUpperCase(),
                    dataIndex: x,
                    key: x,
                })),
            ];
        }

        return [];
    }, [configData, filterParams]);

    const markupData = useMemo(() => {
        if (Object.keys(columnProps).length > 0) {
            return data.map((x: string[]) =>
                columnProps.reduce((obj: any, y: any, idx: number) => {
                    obj[y.key] = x[idx] || "";
                    return obj;
                }, {})
            );
        }

        return [];
    }, [data, columnProps]);

    const columns: any[] = useMemo(
        () => [
            ...[
                {
                    title: "Table",
                    dataIndex: "configType",
                    key: "configType",
                    options: {
                        visible: false,
                        filter: {
                            type: ComponentType.dropdown,
                            value: configData.tables,
                            itemProps: {
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            },
                        },
                    },
                },
                {
                    title: "Brand",
                    dataIndex: "brandId",
                    key: "brandId",
                    options: {
                        visible: false,
                        filter: {
                            type: ComponentType.dropdown,
                            value: configData.brands.map(x => ({ text: x.text, value: x.value })),
                            itemProps: {
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            },
                        },
                    },
                },
                {
                    title: "Date",
                    dataIndex: "dataDate",
                    key: "dataDate",
                    options: {
                        visible: false,
                        filter: {
                            type: ComponentType.date,
                            value: "",
                            dateFormat: "YYYY-MM-DD",
                            itemProps: {
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            },
                        },
                    },
                },
                {
                    title: "Name",
                    dataIndex: "group",
                    key: "group",
                    options: {
                        visible: false,
                        filter: {
                            type: ComponentType.text,
                            value: "",
                            itemProps: {
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            },
                        },
                    },
                },
            ],
            ...columnProps,
        ],
        [configData, columnProps]
    );

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        export: { text: "Download" },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, formData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                getHoriRecords(formData);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                downloadReport(formData);
                break;
            default:
                break;
        }
    };

    const downloadReport = (formData: any) => {
        if (!isEmptyOrNull(DefaultIfEmpty(formData, "dataDate", ""))) {
            try {
                formData["dataDate"] = formData["dataDate"].format("YYYY-MM-DD");
            } catch (error) {}
        }

        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_HORI_DIFF_RECORD_GET_LIST, formData)
            .then((res: any) => {
                if (res.data !== "") {
                    ErrorMessageHandler(`Query failed: ${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                    setIsLoading(false);
                } else {
                    plainAxiosInstance
                        .get(`${APIs.RISK_TOOL.GET_HORI_DIFF_RECORD_DOWNLOAD}?${new URLSearchParams(formData).toString()}`, {
                            headers: {
                                Accept: "application/octet-stream,text/csv, */*",
                            },
                            responseType: "blob",
                        })
                        .then(response => {
                            const contentType = response.headers["content-type"];
                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "text/csv" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", `HoriDiffData_${DefaultIfEmpty(formData, "dataDate", "").split("-").join("")}.csv`);
                                document.body.appendChild(link);
                                link.click();
                                // Clean up
                                window.URL.revokeObjectURL(url);
                            } else {
                                ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        })
                        .catch((error: any) =>
                            ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED)
                        );
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Query failed`, SUCCESS_FAILED.OTHERS_FAILED, err)));
    };

    const getHoriRecords = (formData: any) => {
        setIsLoading(true);
        setFilterParams(formData);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_HORI_DIFF_RECORD_GET_LIST, formData)
            .then((res: any) => {
                if (res.data !== "") {
                    ErrorMessageHandler(`Query failed: ${res.data}`, SUCCESS_FAILED.OTHERS_FAILED);
                    setIsLoading(false);
                } else {
                    plainAxiosInstance
                        .post(APIs.RISK_TOOL.GET_HORI_DIFF_RECORD_GET_LIST_RESULT, formData)
                        .then((res: any) => {
                            if (res.data && Object.keys(res.data).length > 0) {
                                setData(
                                    Object.keys(res.data).reduce((arr: any[], key: string) => {
                                        return arr.concat(res.data[key]);
                                    }, [])
                                );
                            } else {
                                setData([]);
                                ErrorMessageHandler(`Compare all same.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                            }
                        })
                        .catch((error: any) => {
                            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hori records", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                            setData([]);
                        })
                        .finally(() => setIsLoading(false));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Query failed`, SUCCESS_FAILED.OTHERS_FAILED, err));
                setIsLoading(false);
            });
    };

    const getConfig = () => {
        Promise.all([
            plainAxiosInstance.get(APIs.RISK_TOOL.GET_HORI_DIFF_RECORD_GET_BRANDs),
            plainAxiosInstance.get(APIs.RISK_TOOL.GET_HORI_DIFF_RECORD_GET_TABLES),
        ]).then((res: any) => {
            setConfigData({
                brands: res[0].data.map((x: any) => ({
                    text: isEmptyOrNull(x.brandName) ? x.brandId : x.brandName,
                    value: x.brandId,
                })),
                tables: res[1].data.map((x: any) => ({ text: x, value: x })),
                brandObject: res[0].data.reduce((obj: any, x: any) => {
                    obj[x.brandId] = (x.servers || "").toUpperCase().split(",");
                    return obj;
                }, {}),
            });
        });
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="hori-diff-records-container">
            <CardBox title={"Hori Diff Records"}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="column"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={markupData}
                    callback={componentCallback}
                    loading={isLoading}
                    filterInitialValue={{
                        dataDate: moment(),
                    }}
                />
            </CardBox>
        </div>
    );
};

export default HoriDifferentRecordsPage;
