import { useState, useEffect, useMemo } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY } from "../../../../constants";
import { CustomPaginationProps, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "../../../../constants/type";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { DTColProps, DataTableColumnRender, currencyRender, scientificToDecimal } from "../../../../utils/Common";
import { Link } from "react-router-dom";
import { isEmptyOrNull } from "../../../../utils/string";
import { Tag, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export interface LPListingProps {
    isLoading: boolean;
    selectedMRGroup: number[];
    submitMRGroup: boolean;
}

interface LPProps {
    accountId: string;
    balance: number;
    equity: number;
    exposure: number;
    lp: string;
    marginLevel: number;
    createdTimeUtc: string;
}

const LPListing = (props: LPListingProps) => {
    const [data, setData] = useState<LPProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("");
    const [lpAccounts, setLpAccounts] = useState<string[]>([]);

    const over30mins = (createdTimeUtc: string) => {
        const createdTime = new Date(createdTimeUtc).getTime();
        const currentUTCTime = new Date().getTime();
        const timeDifferenceMs = currentUTCTime - createdTime;
        const timeDifferenceMinutes = timeDifferenceMs / (1000 * 60);
        return timeDifferenceMinutes > 30;
    };

    const tableColConfig = useMemo(
        () => [
            {
                title: "Account",
                dataIndex: "accountId",
                key: "accountId",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
                render: (name: string, rowData: any) => {
                    let id = btoa(
                        JSON.stringify({
                            lp: rowData.lp,
                            accountId: rowData.accountId,
                            marketGroupId: rowData.marketGroupId,
                        })
                    );
                    return (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Link to={`/lpmonitor/summary/lp360/${id}`} target="_blank" style={{ color: "#0e65cc" }}>
                                {`${rowData["lp"]} - ${name} ${rowData["marketGroup"] ? `(${rowData["marketGroup"]})` : ""}`}
                            </Link>
                            {!isEmptyOrNull(rowData["createdTimeUtc"]) && (
                                <span className="last-refresh-time">
                                    {DataTableColumnRender.DateTime(rowData["createdTimeUtc"])}
                                    {over30mins(rowData["createdTimeUtc"]) && <Tag color="orange">Not been synced for over 30 minutes</Tag>}
                                </span>
                            )}
                        </div>
                    );
                },
            },
            {
                title: "Liquidity Provider",
                dataIndex: "lp",
                key: "lp",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: lpAccounts.map((x: any) => ({ text: x, value: x })),
                    },
                    visible: false,
                },
            },
            DTColProps.Currency({
                width: "7vw",
                realTitle: "Exposure",
                title: (
                    <>
                        Exposure
                        <Tooltip
                            title="Sum of absolute value of position's exposure"
                            mouseEnterDelay={0}
                            zIndex={1071}
                            overlayInnerStyle={{ background: "#000" }}
                        >
                            <ExclamationCircleOutlined style={{ marginLeft: "0.32vw", color: "#fc5252", cursor: "pointer" }} />
                        </Tooltip>
                    </>
                ),
                dataIndex: "exposure",
                key: "exposure",
                sorter: true,
            }),
            DTColProps.Currency({
                width: "8vw",
                title: "Equity",
                dataIndex: "equity",
                key: "equity",
                sorter: true,
            }),
            DTColProps.Currency({
                width: "8vw",
                title: "Balance",
                dataIndex: "balance",
                key: "balance",
                sorter: true,
            }),
            DTColProps.Currency({
                width: "7vw",
                title: "Margin Level (%)",
                dataIndex: "marginLevel",
                key: "marginLevel",
                sorter: true,
                render: (value: number, rowData: any) => {
                    let lvlColor: string = "";
                    if (value > 30 && 50 >= value) {
                        lvlColor = "lvl-30-50";
                    } else if (value > 50 && 70 >= value) {
                        lvlColor = "lvl-51-70";
                    } else if (value > 70) {
                        lvlColor = "lvl-71";
                    }

                    return <div className={`margin-level-container ${lvlColor}`}>{currencyRender(scientificToDecimal(value * 100), 2)}</div>;
                },
            }),
        ],
        [lpAccounts]
    );

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        defaultCollapseFilterPanel: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "accountId") {
                            filterParams["accountIds"] = [FormData[x]];
                        } else if (x === "lp") {
                            filterParams["lPs"] = [FormData[x]];
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
        }
    };

    useEffect(() => {
        if (props.submitMRGroup) {
            setFilterParams(filterParams);
            setPagination(prev => ({ ...prev, current: 1 }));
        }
        return () => {};
    }, [props.submitMRGroup]);

    const getLPListing = () => {
        apiRequest(APIs.GET_METRICS_ACCOUNT, {
            limit: pagination.pageSize,
            current: pagination.current,
            marketGroupIds: props.selectedMRGroup,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((res: any) => {
                if (res && res.result && res.result.length > 0) {
                    setData(res.result.map((x: any) => ({ ...x, propertyId: `${x.lp}${x.accountId}` })));
                    setPagination(prev => ({ ...prev, total: res.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .finally(() => setIsLoading(false));
    };

    const getConfigData = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["marketrisklpaccount"] }).then((res: any) => {
            if (res?.hasOwnProperty("mrLPAccount") && res.mrLPAccount.length > 0) {
                setLpAccounts(
                    Object.keys(
                        res.mrLPAccount.reduce((finalObj: any, i: any) => {
                            finalObj[i.lp] = 1;
                            return finalObj;
                        }, {})
                    )
                );
            }
        });
    };

    useEffect(() => {
        getConfigData();
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getLPListing();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        if (props.isLoading && !runRefetchDataList) {
            setRunRefetchDataList(true);
        }
        return () => {};
    }, [props.isLoading]);

    return (
        <div className="half-view half-view-2">
            <FlexiDataTable
                rowKeyProperty="propertyId"
                title={<span className="flexi-table-small-title-style">Liquidity Provider Listing</span>}
                columns={tableColConfig}
                options={options}
                dataSource={data ?? []}
                callback={componentCallback}
                loading={isLoading}
                pagination={pagination}
                serverSide={true}
                scroll={{
                    x: true,
                }}
            />
        </div>
    );
};

export default LPListing;
