import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED, InnerPageActionMode } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair, CreateEditInlineFormBased } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { SortList } from "@/utils/array";
import { ErrorMessageHandler, ErrorCatchValidator, DTColProps } from "@/utils/Common";
import { Tag } from "antd";
import { useState, useEffect, useMemo } from "react";
import ExternalSystemCreateEditPage from "./CreateEditPage";

export interface ExternalSystemPageProps {}

export interface ExternalSystemV2Props {
    enable: boolean;
    extSysId: number;
    extSysName: string;
    extSysTypeId: number;
    extSysTypeLabel: string;
    servers: ExternalSystemV2ServersProps[];
    updateTimeUtc: string;
}

interface ExternalSystemV2ServersProps {
    serverId: number;
    serverDn: string;
    serverName: string;
}

const ExternalSystemPage = (props: ExternalSystemPageProps) => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [refetch, setRefetch] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<ExternalSystemV2Props[]>([]);
    const [extSysType, setExtSysType] = useState<KeyValuePair[]>([]);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_EXTERNAL_SYSTEM_V2_EDIT);

    const columns: any[] = useMemo(
        () => [
            DTColProps.Middle({
                title: "External System Name",
                dataIndex: "extSysName",
                key: "extSysName",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            {
                title: "External System Type",
                dataIndex: "extSysTypeId",
                key: "extSysTypeId",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: extSysType,
                    },
                },
            },
            DTColProps.Small({
                title: "Type",
                dataIndex: "extSysTypeLabel",
                key: "extSysTypeLabel",
            }),
            {
                title: "Servers",
                dataIndex: "servers",
                key: "servers",
                render: (text: ExternalSystemV2ServersProps[]) => text.map(x => <Tag key={`extSys-tbl-${x.serverId}`}>{x.serverName}</Tag>),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers,
                        inputProps: { mode: "multiple" },
                        callback: (filterValue: any, rowData: any) => rowData.servers.some((x: any) => filterValue.includes(x.serverId)),
                    },
                },
            },
            DTColProps.Status({
                title: "Status",
                dataIndex: "enable",
                key: "enable",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            { text: "Active", value: true },
                            { text: "Inactive", value: false },
                        ],
                    },
                },
            }),
        ],
        [servers, extSysType]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, ExternalSystemData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: ExternalSystemData.extSysId });
                setRefetch(prev => prev + 1);
                break;
            default:
                break;
        }
    };

    const getExternalSystemList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_EXTERNAL_SYSTEM_LIST_V2, { current: 1, limit: 999999 })
            .then((res: any) => {
                setData(res.result && res.result.length > 0 ? res.result : []);
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("external system list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["riskinsightexternalsystemtype", "riskinsightserver"] })
            .then((res: any) => {
                setExtSysType(
                    SortList(
                        res.riskInsightExternalSystemTypes.map((x: any) => ({ text: x.extSysTypeLabel, value: x.extSysTypeId })),
                        "text"
                    )
                );
                setServers(
                    SortList(
                        res.riskInsightServers.map((x: any) => ({ text: x.serverName, value: x.serverId })),
                        "text"
                    )
                );
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("external system config", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => getExternalSystemList());
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>External System Management</span>
                </div>
            </div>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <div className="v2-external-system-listing">
                        <FlexiDataTable
                            bordered
                            rowKeyProperty="extSysId"
                            title=""
                            columns={columns}
                            options={options}
                            dataSource={data}
                            callback={componentCallback}
                            loading={isLoading}
                        />
                    </div>
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <ExternalSystemCreateEditPage
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        getExternalSystemList();
                                    }
                                    break;
                            }
                        }}
                        resetState={refetch}
                    />
                </div>
            </div>
        </>
    );
};

export default ExternalSystemPage;
