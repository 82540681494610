import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleTwoTone, InfoCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { message } from "antd";
import { useEffect, useState } from "react";
import CardBox from "../../components/Common/CardBox";
import FlexiDataTable from "../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, NOTIFICATION_LEVEL, SUCCESS_FAILED } from "../../constants";
import { CustomPaginationProps, FlexiDataTableOptionsProps, NotifResult } from "../../constants/type";
import { apiRequest } from "../../services/apiConfig";
import { APIs } from "../../services/apis";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import { SOMETHING_WENT_WRONG } from "../../constants/errorMessage";
import { ToOptionTypeList } from "../../utils/array";
import { DateTimeUtil } from "../../utils/datetime";
import { useAppSelector } from "../../store/hook";

const NotificationPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [data, setData] = useState<NotifResult[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("createdDateUtc,desc");
    const systemState = useAppSelector((state: any) => state.system);

    const columns = [
        DTColProps.Large({
            title: "Subject",
            dataIndex: "subject",
            key: "subject",
            // options: {
            //     filter: {
            //         type: ComponentType.text,
            //     },
            // },
        }),

        {
            title: "Content",
            dataIndex: "content",
            key: "content",
            render: (content: string) => {
                // console.log("content", content);
                // const result = <div dangerouslySetInnerHTML={{ __html: JSON.parse(content) }} />;
                // console.log(result);
                // return content;
                if (!content) return null;
                try {
                    return <div dangerouslySetInnerHTML={{ __html: content }} />;
                } catch (error) {
                    return <div>{content}</div>;
                }
            },
            // options: {
            //     filter: {
            //         type: ComponentType.text,
            //     },
            // },
        },

        DTColProps.Small({
            title: "Level",
            dataIndex: "levelId",
            key: "levelId",
            align: "center",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: ToOptionTypeList(NOTIFICATION_LEVEL),
                    inputProps: {
                        style: { width: "100%" },
                        mode: "multiple",
                        allowClear: true,
                    },
                },
            },
            render: (levelId: number) => {
                switch (levelId) {
                    case 1:
                        return (
                            <div style={{ textAlign: "center" }}>
                                <>
                                    <InfoCircleTwoTone style={{ fontSize: "0.9375rem", marginRight: 3 }} /> Low
                                </>
                            </div>
                        );
                    case 2:
                        return (
                            <div style={{ textAlign: "center" }}>
                                <>
                                    <InfoCircleTwoTone style={{ fontSize: "0.9375rem", marginRight: 3 }} /> Normal
                                </>
                            </div>
                        );
                    case 3:
                        return (
                            <div style={{ textAlign: "center" }}>
                                <>
                                    <ExclamationCircleTwoTone twoToneColor="#FFC000" style={{ fontSize: "0.9375rem", marginRight: 3 }} /> High
                                </>
                            </div>
                        );
                    case 4:
                        return (
                            <div style={{ textAlign: "center" }}>
                                <>
                                    <WarningTwoTone twoToneColor="#F44336" style={{ fontSize: "0.9375rem", marginRight: 3 }} /> Critical
                                </>
                            </div>
                        );
                }
            },
        }),

        DTColProps.Small({
            title: "Created By",
            dataIndex: "createdByName",
            key: "createdByName",
        }),

        DTColProps.DateTime({
            title: "Created At (Local)",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: true,
                    },
                },
            },
            sorter: true,
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: false,
        export: {
            text: "Export as CSV",
        },
        serverFiltering: true,
    };

    const componentCallback = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("createdDateUtc,DESC")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "createdDateUtc") {
                            fParams.createdDateTimeFrom = DateTimeUtil.GetUTC(FormData[x][0]);
                            fParams.createdDateTimeTo = DateTimeUtil.GetUTC(FormData[x][1]);
                        } else if (x === "levelId") {
                            fParams.levels = [FormData[x]];
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });

                setFilterParams(fParams);
                setSorting("createdDateUtc,desc");
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                setIsBtnLoading(true);
                apiRequest(
                    APIs.DOWNLOAD_NOTIFICATION,
                    {
                        fileType: 0,
                        type: 1,
                        ...(Object.keys(filterParams)?.length > 0 && { ...filterParams }),
                        ...(sorting.length > 0 && { order: sorting }),
                    },
                    "POST",
                    "arraybuffer"
                )
                    .then((res: any) => {
                        const fileName = res.headers["x-filename"];
                        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/zip" })); // Create blob link to download
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName); // or any other extension
                        document.body.appendChild(link); // Append to html link element page
                        link.click(); // start download
                        document.body.removeChild(link); // Clean up and remove the link
                    })
                    .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(SOMETHING_WENT_WRONG, SUCCESS_FAILED.OTHERS_FAILED)))
                    .finally(() => setIsBtnLoading(false));
                break;
            default:
                break;
        }
    };

    const getDataList = () => {
        apiRequest(APIs.GET_NOTIFICATION_CONTENTS_LIST, {
            limit: pagination.pageSize,
            current: pagination.current,
            type: 1,
            levels: [[1, 2, 3, 4]],
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data[0].result && data[0].result.length > 0) {
                    let newData = data[0].result.map((x: NotifResult) => {
                        let newContent = JSON.parse(x.content);
                        return {
                            ...x,
                            subject: newContent.subject,
                            content: newContent.content,
                        };
                    });
                    setData(newData);
                    setPagination(prev => ({ ...prev, total: data[0].total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (res: any) => ErrorMessageHandler("all notification", SUCCESS_FAILED.FAILED_LOAD_DATA, res));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getDataList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        getDataList();
        return () => {};
    }, [systemState.notifCount]);

    return (
        <div className="all-notification-container">
            <CardBox title={"Notification"}>
                <FlexiDataTable
                    rowKeyProperty="contentId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data || []}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                    exporting={isBtnLoading}
                />
            </CardBox>
        </div>
    );
};

export default NotificationPage;
