import { Button, Descriptions, Form, message } from "antd";
import { EditBatchRecordBased } from "./editBatchComponent";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isEmptyOrNull } from "@/utils/string";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import moment from "moment";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import FlexiDataTable from "@/components/FlexiDataTable";
import { getSupplementaryList } from "./step3Supplementary";
import { getDeductionList } from "./Step4Deduction";

interface Step5CompletionProps extends EditBatchRecordBased {
    servers: KeyValuePair[];
    updatedData: string;
}

interface TotalSupplementaryDeductionProps {
    supplementary: number;
    deduction: number;
}

interface TotalCalculationProps {
    TotalPositiveProfit: string;
    TotalNegativeProfit: string;
    TotalProfit: string;
    TotalSupplementary: string;
    TotalDeduction: string;
    TotalSupplementaryDeduction: string;
}

const Step5Completion = (props: Step5CompletionProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalSupplementaryDeduction, setTotalSupplementaryDeduction] = useState<TotalSupplementaryDeductionProps>({
        supplementary: 0,
        deduction: 0,
    });
    const [verifiedData, setVerifiedData] = useState<any[]>([]);
    const [stopOutData, setStopOutData] = useState<any[]>([]);
    const [selectedServerId, setSelectedServerId] = useState<string>("");
    const [executeForm] = Form.useForm();

    const totalCalculation: TotalCalculationProps = useMemo(() => {
        let positiveProfit = 0,
            negativeProfit = 0,
            sumProfit = 0;

        if (verifiedData.length > 0) {
            verifiedData.forEach(({ profit }) => {
                if (profit > 0) {
                    positiveProfit += profit;
                } else if (profit < 0) {
                    negativeProfit += profit;
                }

                sumProfit += profit;
            });
        }

        return {
            TotalPositiveProfit: currencyRender(positiveProfit.toFixed(2)),
            TotalNegativeProfit: currencyRender(negativeProfit.toFixed(2)),
            TotalProfit: currencyRender(sumProfit.toFixed(2)),
            TotalSupplementary: currencyRender(totalSupplementaryDeduction.supplementary.toFixed(2)),
            TotalDeduction: currencyRender(totalSupplementaryDeduction.deduction.toFixed(2)),
            TotalSupplementaryDeduction: currencyRender(
                (totalSupplementaryDeduction.supplementary + totalSupplementaryDeduction.deduction).toFixed(2)
            ),
        };
    }, [verifiedData, totalSupplementaryDeduction]);

    const columnVerifiedData: any = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => a.server - b.server,
        }),
        DTColProps.XSmall({
            title: "Ticket/Deal",
            dataIndex: "ticket",
            key: "ticket",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.XSmall({
            title: "Open Time",
            dataIndex: "openTime",
            key: "openTime",
            render: (openTime: string) => (openTime ? moment(openTime).format("YYYY-MM-DD HH:mm:ss") : ""),
        }),
        DTColProps.XSmall(
            {
                title: "Profit",
                dataIndex: "profit",
                key: "profit",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
        DTColProps.XSmall({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        }),
    ];

    const columnStopOutData: any = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => a.server - b.server,
        }),
        DTColProps.XSmall({
            title: "Ticket/Deal",
            dataIndex: "ticket",
            key: "ticket",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.XSmall(
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                render: (type: number) => (type === 0 ? "Buy" : "Sell"),
            },
            ["text-center"]
        ),
        DTColProps.XSmall({
            title: "Open Time",
            dataIndex: "openTime",
            key: "openTime",
            render: (openTime: string) => (openTime ? moment(openTime).format("YYYY-MM-DD HH:mm:ss") : ""),
        }),
        DTColProps.XSmall(
            {
                title: "Close Price",
                dataIndex: "closePrice",
                key: "closePrice",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
        DTColProps.XSmall({
            title: "Close Time",
            dataIndex: "closeTime",
            key: "closeTime",
            render: (closeTime: string) => (closeTime ? moment(closeTime).format("YYYY-MM-DD HH:mm:ss") : ""),
        }),
        DTColProps.XSmall(
            {
                title: "Profit",
                dataIndex: "profit",
                key: "profit",
                render: (text: any) => currencyRender(text),
            },
            ["text-right"]
        ),
        DTColProps.XSmall({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        showHideColumns: false,
    };

    const downloadExcel = useCallback(() => {
        try {
            plainAxiosInstance
                .get(`${APIs.RISK_TOOL.GET_ROLLOVER_DOWNLOAD_VERIFICATION_AND_STOP_OUT}?batchId=${props.batchId}&serverId=${selectedServerId}`, {
                    headers: {
                        Accept: "application/octet-stream,text/csv, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const contentDisposition = response.headers["content-disposition"] || response.headers["Content-Disposition"];

                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        // Extract filename from content-disposition if available
                        let fileName = `DownloadRollOverVerificationAndStopOut_${moment().format("YYYYMMDDHHmmss")}.csv`;
                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                            if (fileNameMatch && fileNameMatch[1]) {
                                fileName = fileNameMatch[1];
                            }
                        }

                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED));
        } catch (e: any) {
            ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    }, [props.batchId, selectedServerId]);

    const getSupplementaryDeduction = (batchId: string) => {
        return new Promise(resolve => {
            Promise.all([getSupplementaryList(batchId, ""), getDeductionList(batchId, "")])
                .then((res: any) => {
                    let supplementary = res[0].data.reduce((acc: number, x: any) => acc + x.adjustmentClient, 0);
                    let deduction = res[1].data.reduce((acc: number, x: any) => acc + x.adjustmentClient, 0);
                    setTotalSupplementaryDeduction({ supplementary, deduction });
                })
                .finally(() => resolve(true));
        });
    };

    const getStopOutData = (batchId: string, serverId: string[]) => {
        return new Promise(resolve => {
            Promise.all(
                serverId.map(serverId =>
                    plainAxiosInstance.get(`${APIs.RISK_TOOL.GET_ROLLOVER_STOP_OUT_LIST}?batchId=${batchId}&serverId=${serverId}`)
                )
            )
                .then(res => {
                    setStopOutData(res.reduce((acc: any[], cur: any) => [...acc, ...(cur.data && cur.data.length > 0 ? cur.data : [])], []));
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("query stop out data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
                )
                .finally(() => resolve(true));
        });
    };

    const getVerifiedData = (batchId: string, serverId: string[]) => {
        return new Promise(resolve => {
            Promise.all(
                serverId.map(serverId =>
                    plainAxiosInstance.get(`${APIs.RISK_TOOL.GET_ROLLOVER_VERIFICATION_LIST}?batchId=${batchId}&serverId=${serverId}`)
                )
            )
                .then(res => {
                    setVerifiedData(res.reduce((acc: any[], cur: any) => [...acc, ...(cur.data && cur.data.length > 0 ? cur.data : [])], []));
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("query verification data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
                )
                .finally(() => resolve(true));
        });
    };

    const getCompletionData = useCallback(() => {
        setIsLoading(true);
        let queryServers = isEmptyOrNull(selectedServerId)
            ? props.servers.filter(x => !isEmptyOrNull(x.value)).map(x => `${x.value}`)
            : [selectedServerId];
        Promise.all([
            getVerifiedData(props.batchId, queryServers),
            getStopOutData(props.batchId, queryServers),
            getSupplementaryDeduction(props.batchId),
        ]).then(() => setIsLoading(false));
    }, [props.servers, props.batchId, selectedServerId]);

    useEffect(() => {
        if (runRefetchDataList) {
            getCompletionData();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList, getCompletionData]);

    useEffect(() => {
        if (props.servers && props.servers.length > 0) {
            executeForm.setFieldsValue({
                server: props.servers[0].value,
            });
        }
    }, [props.servers]);

    return (
        <div className="rollover-tool-step5-container">
            <Form
                form={executeForm}
                layout="inline"
                onValuesChange={(changedValues: any) => {
                    Object.keys(changedValues).forEach((key: string) => {
                        if (key === "server") {
                            setSelectedServerId(isEmptyOrNull(changedValues[key]) ? "" : changedValues[key]);
                        }
                    });
                }}
            >
                <div className="step-5-search-panel">
                    <FormComponent
                        label=""
                        name="server"
                        extra={{
                            type: ComponentType.dropdown,
                            value: props.servers,
                            inputProps: {
                                allowClear: false,
                                style: {
                                    width: "12vw",
                                },
                            },
                            withSyncBtn: {
                                icon: <SearchOutlined />,
                                enable: true,
                                callback: () => {
                                    setRunRefetchDataList(true);
                                },
                            },
                        }}
                    />
                    <Button icon={<DownloadOutlined />} onClick={() => downloadExcel()}>
                        Download Excel
                    </Button>
                </div>
            </Form>
            <div className="table-panel">
                <FlexiDataTable
                    bordered
                    title={<span className="">{`${props.updatedData} MT4/5 Roll Over Order`}</span>}
                    columns={columnVerifiedData}
                    options={options}
                    dataSource={verifiedData}
                    loading={isLoading}
                />
            </div>
            <div className="total-panel">
                <Descriptions bordered labelStyle={{ width: "30%" }} column={4}>
                    <Descriptions.Item
                        label={<b>Total (Supplementary + Deduction)</b>}
                    >{`(${totalCalculation.TotalSupplementary}) + (${totalCalculation.TotalDeduction}) = ${totalCalculation.TotalSupplementaryDeduction}`}</Descriptions.Item>
                    <Descriptions.Item
                        label={<b>Total (Positive Profit + Negative Profit)</b>}
                    >{`(${totalCalculation.TotalPositiveProfit}) + (${totalCalculation.TotalNegativeProfit}) = ${totalCalculation.TotalProfit}`}</Descriptions.Item>
                </Descriptions>
            </div>
            <div className="table-panel">
                <FlexiDataTable
                    bordered
                    title={<span className="">{`MT4/5 Stop Out Order`}</span>}
                    columns={columnStopOutData}
                    options={options}
                    dataSource={stopOutData}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};

export default Step5Completion;
