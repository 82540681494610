import { useEffect, useState } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../utils/Common";
import { CALLBACK_KEY, ComponentType, PROGRESS_TYPES, PROGRESS_TYPES_ENUM, SUCCESS_FAILED } from "../../../constants";
import { CheckCircleOutlined, CloseCircleOutlined, FieldTimeOutlined, FileSearchOutlined, WarningOutlined } from "@ant-design/icons";
import { Badge, Modal, Progress, Table } from "antd";
import {
    CustomPaginationProps,
    FlexiDataTableCallbackProps,
    HistoricalRestoreTrade,
    HistoricalTicketRestorationSummary,
    ProgressDataProps,
    ServersList,
    TixRestorationSumProgressItem,
} from "../../../constants/type";
import { DateTimeUtil } from "../../../utils/datetime";
import { APIs, apiRequest } from "../../../services/apiConfig";
import moment from "moment";
import SignalRHelper from "../../../helpers/signalRHelper";
import loading_gif from "../../../assets/images/loading1.gif";
import LoadingComponent from "../../../components/Loading";

interface HistoricalListData {
    result: HistoricalRestoreTrade[];
    total: number;
}

export const initialProgressStatusData: ProgressDataProps = {
    status: PROGRESS_TYPES[PROGRESS_TYPES_ENUM.TO_BE_PROCESSED],
    error: "",
    percentage: 0,
    lastExecutedDateTime: "",
    stopExecutedDateTime: "",
};

const HistoricalList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<HistoricalRestoreTrade[]>([]);
    const [modalData, setModalData] = useState<TixRestorationSumProgressItem[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("");
    const [servers, setServers] = useState<ServersList[]>([]);
    const [isExpandAll, setIsExpandAll] = useState<boolean>(false);
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
    const [sRConnection] = useState<SignalRHelper>(new SignalRHelper("tp-ticketRestoration", `/mtRestoreTradesHub`));
    const [progressStatusData, setProgressStatusData] = useState<any>({});
    const [incomingProgressStatsData, setIncomingProgressStatsData] = useState<any>({});
    const [isMt5, setIsMt5] = useState<boolean>(false);

    const columns = [
        DTColProps.DateTime({
            title: "Created At (Local)",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            sorter: (a: any, b: any) => (moment(b.createdDateUtc) > moment(a.createdDateUtc) ? -1 : 1),
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        ranges: {
                            Today: [moment().startOf("day"), moment().endOf("day")],
                            "This Week": [moment().startOf("week"), moment().endOf("day")],
                            "This Month": [moment().startOf("month"), moment().endOf("day")],
                        },
                        dateOnly: true,
                    },
                },
            },
        }),
        DTColProps.Small({
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
        }),
        DTColProps.XLarge({
            title: "Server & Account",
            dataIndex: "servers",
            key: "servers",
            render: (value: string, rowData: HistoricalRestoreTrade) => {
                let accountsList = rowData.logins.replace(",", ", ");
                return (
                    <div className="affected-server-account-column">
                        <div className="left">
                            <p className="title">Affected Server:</p>
                            <span>{rowData.serverName}</span>
                        </div>
                        <div className="right">
                            <p className="title">Affected Accounts:</p>
                            <span>{accountsList}</span>
                        </div>
                        <div className="action">
                            <FileSearchOutlined
                                style={{ fontSize: "1.25rem" }}
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setIsExpandAll(false);
                                    setIsModalLoading(true);
                                    apiRequest(APIs.GET_HISTORICAL_RETORE_TRADES_PROGRESS, { batchId: rowData.batchId })
                                        .then((data: HistoricalTicketRestorationSummary) => {
                                            if (data.ticketRestorationSummary.length > 0) {
                                                let statusBySummary: any = {};
                                                let statusByDetail: any = {};
                                                data.ticketRestorationSummary.forEach((currSummary: any) => {
                                                    statusBySummary[`${currSummary.batchId}|${currSummary.progressSummaryId}`] = {
                                                        ...initialProgressStatusData,
                                                        status:
                                                            currSummary.progressSummaryToStatus === null
                                                                ? PROGRESS_TYPES[currSummary.progressSummaryFromStatus]
                                                                : PROGRESS_TYPES[currSummary.progressSummaryToStatus],
                                                        percentage: currSummary.progressByPercentage,
                                                        error:
                                                            currSummary.progressSummaryErrorMessage === null
                                                                ? initialProgressStatusData.error
                                                                : currSummary.progressSummaryErrorMessage,
                                                    };
                                                    currSummary.ticketRestorationDetails.forEach((currDetail: any) => {
                                                        statusByDetail[
                                                            `${currSummary.batchId}|${currSummary.progressSummaryId}|${currDetail.progressDetailId}`
                                                        ] = {
                                                            ...initialProgressStatusData,
                                                            status:
                                                                currDetail.progressDetailToStatus === null
                                                                    ? PROGRESS_TYPES[currDetail.progressDetailFromStatus]
                                                                    : PROGRESS_TYPES[currDetail.progressDetailToStatus],
                                                            error:
                                                                currDetail.progressDetailErrorMessage === null
                                                                    ? initialProgressStatusData.error
                                                                    : currDetail.progressDetailErrorMessage,
                                                        };
                                                    });
                                                });
                                                let newProgressStatusData = {
                                                    ...progressStatusData,
                                                    ...statusBySummary,
                                                    ...statusByDetail,
                                                };
                                                setProgressStatusData(newProgressStatusData);

                                                let newData = data.ticketRestorationSummary.map((currSum: any) => {
                                                    const currServer = servers.filter((serverObj: ServersList) => serverObj.id === currSum.serverId);
                                                    const serverName = currServer[0].server;

                                                    let newTicketRestorationDetails = currSum.ticketRestorationDetails.map((currDeet: any) => ({
                                                        ...currDeet,
                                                        batchId: currSum.batchId,
                                                        progressSummaryId: currSum.progressSummaryId,
                                                    }));

                                                    return {
                                                        ...currSum,
                                                        serverName,
                                                        ticketRestorationDetails: [...newTicketRestorationDetails],
                                                    };
                                                });
                                                setModalData(newData);

                                                if (newData[0].serverName.includes("MT5")) setIsMt5(true);
                                                else setIsMt5(false);
                                            } else {
                                                setModalData([]);
                                            }
                                        })
                                        .catch((error: any) => {
                                            ErrorCatchValidator(error, (err: any) => {
                                                ErrorMessageHandler("historical restore trades progress", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                                            });
                                        })
                                        .finally(() => {
                                            setIsModalLoading(false);
                                        });
                                }}
                            />
                        </div>
                    </div>
                );
            },
        }),
        DTColProps.Small({
            title: "Status",
            dataIndex: "toStatus",
            key: "toStatus",
            render: (value: string, rowData: HistoricalRestoreTrade) => {
                let thisKey: string = `${rowData.batchId}|`;
                if (progressStatusData?.hasOwnProperty(thisKey)) {
                    let thisProStatData: any = progressStatusData[thisKey];
                    if (thisProStatData.stopExecutedDateTime.length > 0) {
                        let timeDiff = moment(thisProStatData.stopExecutedDateTime).diff(moment(thisProStatData.lastExecutedDateTime));
                        let duration = moment.duration(timeDiff);
                        let finalDurationValue = Math.floor(duration.asHours()) + moment.utc(timeDiff).format(":mm:ss");
                        switch (thisProStatData.status) {
                            case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.COMPLETED]:
                                return (
                                    <div>
                                        <div style={{ color: "#24663B", margin: "3px 0" }}>
                                            <span style={{ background: "#C3E6CD", borderRadius: "33px", padding: "3px 8px" }}>
                                                <CheckCircleOutlined /> {thisProStatData.status}
                                            </span>
                                        </div>
                                        <div style={{ color: "#6D6D6D" }}>
                                            <span style={{ padding: "3px 8px" }}>
                                                <FieldTimeOutlined /> {finalDurationValue}
                                            </span>
                                        </div>
                                        {rowData.totalFailed > 0 && (
                                            <div style={{ color: "#D30808" }}>
                                                <span style={{ padding: "3px 8px" }}>
                                                    <WarningOutlined /> {rowData.totalFailed} failed
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                );
                            case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.FAILED]:
                                return (
                                    <div>
                                        <div style={{ color: "#ff3333", margin: "3px 0" }}>
                                            <span style={{ background: "#ffcac2", borderRadius: "33px", padding: "3px 8px" }}>
                                                <CloseCircleOutlined /> {thisProStatData.status}
                                            </span>
                                        </div>
                                        <div style={{ color: "#6D6D6D" }}>
                                            <span style={{ padding: "3px 8px" }}>
                                                <FieldTimeOutlined /> {finalDurationValue}
                                            </span>
                                        </div>
                                    </div>
                                );
                        }
                    } else {
                        switch (thisProStatData.status) {
                            case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.PROCESSING]:
                                return (
                                    <div>
                                        <span style={{ padding: "3px 8px" }}>
                                            <Badge status="processing" text={thisProStatData.status} />
                                        </span>
                                    </div>
                                );
                            case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.TO_BE_PROCESSED]:
                                return (
                                    <div>
                                        <span style={{ padding: "3px 8px" }}>{thisProStatData.status}</span>
                                    </div>
                                );
                            default:
                                return (
                                    <div>
                                        <span style={{ padding: "3px 8px" }}>{thisProStatData.status}</span>
                                    </div>
                                );
                        }
                    }
                } else
                    return (
                        <div>
                            <span style={{ padding: "3px 8px" }}>{PROGRESS_TYPES[PROGRESS_TYPES_ENUM.TO_BE_PROCESSED]}</span>
                        </div>
                    );
            },
        }),
    ];

    const modalMainColumns = [
        DTColProps.Small({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
        }),
        DTColProps.Small({
            title: "Account ID",
            dataIndex: "login",
            key: "login",
            sorter: (a: any, b: any) => a.login - b.login,
        }),
        DTColProps.XSmall({
            title: "Estimated New Equity (USD)",
            dataIndex: "calculatedNewEquityInUSD",
            key: "calculatedNewEquityInUSD",
            sorter: (a: any, b: any) => a.calculatedNewEquityInUSD - b.calculatedNewEquityInUSD,
            render: (value: number) => (value === 0 ? 0 : currencyRender(value, 2)),
        }),
        DTColProps.XSmall({
            title: "Estimated New Balance (USD)",
            dataIndex: "calculatedNewBalanceInUSD",
            key: "calculatedNewBalanceInUSD",
            sorter: (a: any, b: any) => a.calculatedNewBalanceInUSD - b.calculatedNewBalanceInUSD,
            render: (value: number) => (value === 0 ? 0 : currencyRender(value, 2)),
        }),
        DTColProps.XSmall({
            title: "Estimated New Margin (USD)",
            dataIndex: "calculatedNewMarginInUSD",
            key: "calculatedNewMarginInUSD",
            sorter: (a: any, b: any) => a.calculatedNewMarginInUSD - b.calculatedNewMarginInUSD,
            render: (value: number) => (value === 0 ? 0 : currencyRender(value, 2)),
        }),
        DTColProps.XSmall(
            {
                title: "Progress",
                dataIndex: "progressByPercentage",
                key: "progressByPercentage",
                render: (value: number, rowData: TixRestorationSumProgressItem) => {
                    let thisKey: string = `${rowData.batchId}|${rowData.progressSummaryId}`;
                    if (progressStatusData?.hasOwnProperty(thisKey)) {
                        let thisProStatData: any = progressStatusData[thisKey];

                        if (thisProStatData.percentage < 100 && thisProStatData.status === PROGRESS_TYPES[PROGRESS_TYPES_ENUM.FAILED]) {
                            return <Progress type="circle" status="exception" width={40} percent={thisProStatData.percentage} />;
                        } else {
                            if (thisProStatData.percentage === 100) {
                                return <Progress type="circle" width={40} percent={100} />;
                            } else {
                                return <Progress type="circle" status="active" width={40} percent={thisProStatData.percentage} />;
                            }
                        }
                    } else
                        return (
                            <div>
                                <span style={{ padding: "3px 8px" }}>{PROGRESS_TYPES[PROGRESS_TYPES_ENUM.TO_BE_PROCESSED]}</span>
                            </div>
                        );
                },
            },
            ["text-center"]
        ),
        DTColProps.Middle({
            title: "Progress Message",
            dataIndex: "progressSummaryErrorMessage",
            key: "progressSummaryErrorMessage",
            render: (value: string, rowData: any) => {
                let thisKey: string = `${rowData.batchId}|${rowData.progressSummaryId}`;
                if (progressStatusData?.hasOwnProperty(thisKey)) {
                    let thisProStatData: any = progressStatusData[thisKey];
                    if (thisProStatData.error === null || thisProStatData.error === "") return "-";
                    else return thisProStatData.error;
                } else return "-";
            },
        }),
    ];

    const modalExpandedColumns = [
        DTColProps.XSmall({
            title: `${isMt5 ? "Deal" : "Ticket"}`,
            dataIndex: "ticket",
            key: "ticket",
            sorter: (a: any, b: any) => a.ticket - b.ticket,
        }),
        DTColProps.XSmall({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            sorter: (a: any, b: any) => (a.symbol > b.symbol ? 1 : -1),
        }),
        DTColProps.XSmall({
            title: "Profit",
            dataIndex: "profit",
            key: "profit",
            sorter: (a: any, b: any) => a.profit - b.profit,
        }),
        DTColProps.XSmall({
            title: "Profit (USD)",
            dataIndex: "profitInUSD",
            key: "profitInUSD",
            sorter: (a: any, b: any) => a.profitInUSD - b.profitInUSD,
        }),
        DTColProps.XSmall({
            title: "Swap",
            dataIndex: "swap",
            key: "swap",
            sorter: (a: any, b: any) => a.swap - b.swap,
        }),
        DTColProps.XSmall({
            title: "Swap (USD)",
            dataIndex: "swapInUSD",
            key: "swapInUSD",
            sorter: (a: any, b: any) => a.swapInUSD - b.swapInUSD,
        }),
        DTColProps.XSmall({
            title: "Commission",
            dataIndex: "commission",
            key: "commission",
            sorter: (a: any, b: any) => a.commission - b.commission,
        }),
        DTColProps.XSmall({
            title: "Commission (USD)",
            dataIndex: "commissionInUSD",
            key: "commissionInUSD",
            sorter: (a: any, b: any) => a.commissionInUSD - b.commissionInUSD,
        }),
        DTColProps.DateTime_ServerTime({
            title: "Open Time (Server)",
            dataIndex: "openTime",
            key: "openTime",
            sorter: (a: any, b: any) => (moment(b.openTime) > moment(a.openTime) ? -1 : 1),
            render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss.SSS") : ""),
        }),
        DTColProps.DateTime_ServerTime({
            title: "Close Time (Server)",
            dataIndex: "closeTime",
            key: "closeTime",
            sorter: (a: any, b: any) => (moment(b.closeTime) > moment(a.closeTime) ? -1 : 1),
            render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss.SSS") : ""),
        }),
        DTColProps.Middle({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            render: (value: string) => (value === "" ? "-" : value),
        }),
        DTColProps.XSmall(
            {
                title: "Status",
                dataIndex: "progressDetailToStatus",
                key: "progressDetailToStatus",
                render: (value: string, rowData: any) => {
                    let thisKey: string = `${rowData.batchId}|${rowData.progressSummaryId}|${rowData.progressDetailId}`;
                    if (progressStatusData?.hasOwnProperty(thisKey)) {
                        let thisProStatData: any = progressStatusData[thisKey];

                        switch (thisProStatData.status) {
                            case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.COMPLETED]:
                                return <CheckCircleOutlined style={{ color: "#52C41A", fontSize: "1rem" }} />;
                            case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.FAILED]:
                                return <CloseCircleOutlined style={{ color: "#ff4d4f", fontSize: "1rem" }} />;
                            case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.TO_BE_PROCESSED]:
                            case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.PROCESSING]:
                            default:
                                return <img src={loading_gif} alt="animated loading gif" style={{ height: 30 }} />;
                        }
                    } else return `${PROGRESS_TYPES[PROGRESS_TYPES_ENUM.TO_BE_PROCESSED]}`;
                },
            },
            ["text-center"]
        ),
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let tempFilterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && (Array.isArray(FormData[x]) ? FormData[x].length > 0 : FormData[x].toString().length > 0))
                    .map(x => {
                        if (x === "createdDateUtc") {
                            tempFilterParams["createdDateUtcFrom"] = DateTimeUtil.GetOrigin(moment(FormData[x][0]).startOf("day"));
                            tempFilterParams["createdDateUtcTo"] = DateTimeUtil.GetOrigin(moment(FormData[x][1]).endOf("day"));
                        } else {
                            tempFilterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(tempFilterParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getExpandedRowRender = (rowData: any) => {
        return (
            <div>
                <Table
                    bordered
                    className="modal-table-expanded-row"
                    size="small"
                    rowKey={"progressDetailId"}
                    columns={modalExpandedColumns}
                    dataSource={rowData.ticketRestorationDetails ?? []}
                    pagination={{
                        showTotal: (total: number, range: number[]) =>
                            `${currencyRender(range[0])} - ${currencyRender(range[1])} of ${currencyRender(total)}`,
                        defaultPageSize: 100,
                    }}
                    scroll={{ y: 150 }}
                />
            </div>
        );
    };

    const getHistoricalRestoreTrades = () => {
        apiRequest(APIs.GET_HISTORICAL_RESTORE_TRADES, {
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: HistoricalListData) => {
                let statusByBatch: any = {};
                if (data === null || data.result.length === 0) {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                    setProgressStatusData({});
                } else {
                    data.result.forEach((currBatch: HistoricalRestoreTrade) => {
                        statusByBatch[`${currBatch.batchId}|`] = {
                            ...initialProgressStatusData,
                            status: currBatch.toStatus === null ? PROGRESS_TYPES[currBatch.fromStatus] : PROGRESS_TYPES[currBatch.toStatus],
                            lastExecutedDateTime: currBatch.lastExecutedDate === null ? "" : currBatch.lastExecutedDate,
                            stopExecutedDateTime: currBatch.stopExecutedDateTime === null ? "" : currBatch.stopExecutedDateTime,
                        };
                    });
                    setProgressStatusData(statusByBatch);
                    setData(data.result ?? []);
                    setPagination(prev => ({ ...prev, total: data.total }));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("historical restore trades", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getHistoricalRestoreTrades();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server"] })
            .then((data: any) => {
                setServers(data.servers);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to load servers list: ", err));
            });
        return () => {};
    }, []);

    useEffect(() => {
        sRConnection.setHandler((hubConnection: any) => {
            hubConnection.on("MtRestoreTradesStream", (message: any) => {
                if (message && message.events && message.events.length > 0) {
                    let formattedIncomingObj: any = {};
                    message.events.forEach((currBatch: any) => {
                        formattedIncomingObj[`${currBatch.batchId}|`] = {
                            ...initialProgressStatusData,
                            status: PROGRESS_TYPES[currBatch.toStatus],
                            lastExecutedDateTime: currBatch.lastExecutedDateTime === null ? "" : currBatch.lastExecutedDateTime,
                            stopExecutedDateTime: currBatch.stopExecutedDateTime === null ? "" : currBatch.stopExecutedDateTime,
                        };
                        currBatch.restoreSummary.forEach((currSummary: any) => {
                            formattedIncomingObj[`${currBatch.batchId}|${currSummary.summaryId}`] = {
                                ...initialProgressStatusData,
                                status: PROGRESS_TYPES[currSummary.toStatus],
                                percentage: currSummary.progressPercentage,
                            };
                            if (currSummary.details.length > 0) {
                                currSummary.details.forEach((currDetail: any) => {
                                    formattedIncomingObj[`${currBatch.batchId}|${currSummary.summaryId}|${currDetail.detailId}`] = {
                                        ...initialProgressStatusData,
                                        status: PROGRESS_TYPES[currDetail.toStatus],
                                    };
                                });
                            }
                        });
                        setIncomingProgressStatsData(formattedIncomingObj);
                    });
                }
            });
        });
        sRConnection.startConnection();
        return () => {
            sRConnection.dispose();
        };
    }, []);

    useEffect(() => {
        if (Object.keys(incomingProgressStatsData).length > 0) {
            let clonedCopy: any = Object.assign({}, progressStatusData);
            Object.keys(incomingProgressStatsData).forEach((currKey: string) => {
                clonedCopy[currKey] = {
                    ...clonedCopy[currKey],
                    ...incomingProgressStatsData[currKey],
                };
            });
            setProgressStatusData(clonedCopy);
        }
    }, [incomingProgressStatsData]);

    if (isMt5) {
        modalExpandedColumns.splice(
            0,
            0,
            DTColProps.XSmall({
                title: "Order",
                dataIndex: "order",
                key: "order",
                sorter: (a: any, b: any) => a.order - b.order,
            })
        );
        modalExpandedColumns.splice(9, 1);
    }

    return (
        <div className="ticket-restoration-historical-tab-div">
            <FlexiDataTable
                title=""
                columns={columns}
                dataSource={data ?? []}
                callback={componentCallback}
                rowKeyProperty={"batchId"}
                loading={isLoading}
                pagination={pagination}
                options={{
                    serverFiltering: true,
                }}
                serverSide={true}
            />
            <Modal
                title="Preview"
                width={1500}
                style={{ top: 20 }}
                centered
                open={isModalOpen}
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={() => {
                    setIsModalOpen(false);
                }}
                onCancel={() => setIsModalOpen(false)}
            >
                {isModalLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <FlexiDataTable
                        title={false}
                        columns={modalMainColumns}
                        dataSource={modalData ?? []}
                        rowKeyProperty={"progressSummaryId"}
                        loading={isModalLoading}
                        options={{
                            showHideColumns: false,
                            enableFilter: false,
                            expandable: {
                                expandedRowRender: getExpandedRowRender,
                            },
                        }}
                        scroll={{ y: 350 }}
                    />
                )}
            </Modal>
        </div>
    );
};

export default HistoricalList;
