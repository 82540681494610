import LoadingComponent from "@/components/Loading";
import { HFTThreshold, HFTThresholdRes } from "@/pages/RealTime/HFTReport";
import { useEffect, useState } from "react";
import SettingsTab from "./SettingsTab";
import { Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { SUCCESS_FAILED } from "@/constants";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";

export interface HFTReportSettingsPanelProps {}

const initThreshold: HFTThreshold = {
    newprofit10minLevel1: 0,
    newprofit10minLevel2: 0,
    newprofit2minLevel1: 0,
    newprofit2minLevel2: 0,
    newprofit30minLevel1: 0,
    newprofit30minLevel2: 0,
    newprofitpnl10min: 0,
    newprofitpnl2min: 0,
    newprofitpnl30min: 0,
    profit10min: 0,
    profit2min: 0,
    profit30min: 0,
    threshold1: 30,
    threshold2: 50,
    threshold3: 100,
};

const HFTReportSettingsPanel = (props: HFTReportSettingsPanelProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [threshold, setThreshold] = useState<HFTThreshold>(initThreshold);

    const filterHFTThreshold = (data: HFTThresholdRes[]): HFTThreshold =>
        data.reduce(
            (acc: any, x: HFTThresholdRes) => {
                switch (x.type) {
                    case "newprofit10min":
                        if (x.level === 1 || x.level === 2) {
                            acc[`newprofit10minLevel${x.level}`] = x.value;
                        }
                        break;
                    case "newprofit2min":
                        if (x.level === 1 || x.level === 2) {
                            acc[`newprofit2minLevel${x.level}`] = x.value;
                        }
                        break;
                    case "newprofit30min":
                        if (x.level === 1 || x.level === 2) {
                            acc[`newprofit30minLevel${x.level}`] = x.value;
                        }
                        break;
                    case "newprofitpnl10min":
                        if (x.level === 1) {
                            acc.newprofitpnl10min = x.value;
                        }
                        break;
                    case "newprofitpnl2min":
                        if (x.level === 1) {
                            acc.newprofitpnl2min = x.value;
                        }
                        break;
                    case "newprofitpnl30min":
                        if (x.level === 1) {
                            acc.newprofitpnl30min = x.value;
                        }
                        break;
                    case "profit":
                        if (x.level === 1 || x.level === 2 || x.level === 3) {
                            acc[`threshold${x.level}`] = x.value;
                            acc.threshold1 = x.value;
                        }
                        break;
                    case "profit10min":
                        if (x.level === 1) {
                            acc.profit10min = x.value;
                        }
                        break;
                    case "profit2min":
                        if (x.level === 1) {
                            acc.profit2min = x.value;
                        }
                        break;
                    case "profit30min":
                        if (x.level === 1) {
                            acc.profit30min = x.value;
                        }
                        break;
                    case "symbol":
                        if (x.level === 1) {
                            acc.threshold4 = x.value;
                        }
                        if (x.level === 2) {
                            acc.threshold5 = x.value;
                        }
                        break;
                    default:
                        acc[`unknown-${x.type}-${x.level}`] = x.value;
                        break;
                }
                return acc;
            },
            { ...initThreshold }
        );

    const getThresholdSettings = () => {
        setIsLoading(true);
        plainAxiosInstance
            .post(`${APIs.RC_HFT_REPORT.GET_HFT_THRESHOLD}`)
            .then(res => {
                setThreshold(filterHFTThreshold(res.data as HFTThresholdRes[]));
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("HFT Threshold", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getThresholdSettings();
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>HFT Report Settings</span>
                    <div className="extra-panel">
                        <Button icon={<ReloadOutlined />} onClick={() => getThresholdSettings()}>
                            Resync
                        </Button>
                    </div>
                </div>
            </div>
            {isLoading ? <LoadingComponent /> : <SettingsTab hftThreshold={threshold} refreshData={getThresholdSettings} />}
        </>
    );
};

export default HFTReportSettingsPanel;
