import { useRCIssuesLogNoMoneyMail } from "@/hooks/useRCIssuesLog";
import { Tag, Space, Form } from "antd";
import { toFixed, thousandSeparator, colorMap } from "../../StatusMonitor/statusUtils";
import { useAppDispatch, useAppSelector } from "@/store/hook";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableOptionsProps } from "@/constants/type";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { IssueLogGState, setDateRange } from "@/store/reducers/issuelog";
import moment from "moment";
import { useEffect, useMemo } from "react";

const NoMoneyMailTable = () => {
    const typeKey = "NO_MONEY";
    const issueLogState: IssueLogGState = useAppSelector((state: any) => state.issuelog);
    const dispatch = useAppDispatch();

    const { rcIssuesLogNoMoneyMail, isLoading } = useRCIssuesLogNoMoneyMail({
        startTime: issueLogState[typeKey].dateRange[0],
        endTime: issueLogState[typeKey].dateRange[1],
        page: 1,
        rows: 10,
    });
    const [dateForm] = Form.useForm();

    const massagedData = useMemo(() => {
        return rcIssuesLogNoMoneyMail?.resultList?.length >= 1
            ? rcIssuesLogNoMoneyMail?.resultList?.map((item: any) => ({
                  ...item,
                  key: `${item.server}_${item.login}_${item.createTime}_${item.uniqueKey}`,
              }))
            : [];
    }, [rcIssuesLogNoMoneyMail]);

    const columns: any[] = [
        {
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.server?.toLowerCase() < b.server?.toLowerCase()) {
                    return -1;
                }
                if (a.server?.toLowerCase() > b.server?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Login",
            dataIndex: "login",
            key: "login",
            sorter: (a: any, b: any) => a.login - b.login,
            render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{text}</div>,
        },
        {
            title: "Balance",
            dataIndex: "balance",
            key: "balance",
            sorter: (a: any, b: any) => a.balance - b.balance,
            render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{toFixed(thousandSeparator(text), 2)}</div>,
        },
        {
            title: "Count",
            dataIndex: "alarmCount",
            key: "alarmCount",
            sorter: (a: any, b: any) => a.alarmCount - b.alarmCount,
            render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{thousandSeparator(text)}</div>,
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
            sorter: (a: any, b: any) => a.state - b.state,
            render: (text: any, data: any) => (
                <Tag color={text === 1 ? colorMap.info : text === 2 ? colorMap.warning : colorMap.error}>
                    {text === 1 ? "Minor" : text === 2 ? "Warning" : "Serious"}
                </Tag>
            ),
        },
        {
            title: "Count Down",
            dataIndex: "timeExpired",
            key: "timeExpired",
            sorter: (a: any, b: any) => a.timeExpired - b.timeExpired,
            render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{text}</div>,
        },
        {
            title: "Mail Type",
            dataIndex: "sentMailType",
            key: "sentMailType",
            render: (text: any, data: any) => (text === 0 ? <Tag color="lime">Alarm</Tag> : <Tag color="red">Changed PWD</Tag>),
            sorter: (a: any, b: any) => a.sentMailType - b.sentMailType,
        },
        {
            title: "Create Time",
            dataIndex: "createTime",
            key: "createTime",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.createTime?.toLowerCase() < b.createTime?.toLowerCase()) {
                    return -1;
                }
                if (a.createTime?.toLowerCase() > b.createTime?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Date Range",
            dataIndex: "dateRange",
            key: "dateRange",
            options: {
                visible: false,
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    rules: [{ required: true, message: REQUIRED_FIELD }],
                    dateFormat: "YYYY-MM-DD",
                    inputProps: {
                        showTime: false,
                        dateOnly: true,
                        disabledDate: (current: any) =>
                            current && (current > moment().endOf("day") || current < moment().subtract(20, "days").startOf("day")),
                        onChange: (data: any) => {
                            const startDate = data?.[0] !== undefined ? moment(data[0]).format("YYYY-MM-DD") : "";
                            const endDate = data?.[1] !== undefined ? moment(data[1]).format("YYYY-MM-DD") : "";
                            dispatch(setDateRange({ key: "NO_MONEY", value: [startDate, endDate] }));
                        },
                    },
                },
            },
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        hideFilterSubmitResetButtons: true,
        serverFiltering: true,
    };

    useEffect(() => {
        dateForm.setFieldsValue({ dateRange: issueLogState[typeKey].dateRange.map((x: string) => moment(`${x} 00:00:00`)) });
    }, [issueLogState[typeKey]]);

    return (
        <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
            <FlexiDataTable
                bordered
                title={false}
                rowKeyProperty="key"
                columns={columns}
                dataSource={massagedData}
                options={options}
                loading={isLoading}
                filterFormInstance={dateForm}
            />
        </Space>
    );
};

export default NoMoneyMailTable;
