import CardBox from "@/components/Common/CardBox";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import {
    BackwardOutlined,
    ClearOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    DownloadOutlined,
    ForwardOutlined,
    SaveOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Alert, Badge, Button, Card, Col, Divider, Empty, Form, Row, Typography, message } from "antd";
import moment from "moment";
import { useMemo, useState } from "react";
import LPPriceCompareLineChart, { CompareData } from "./components/LPPriceCompareLineChart";
import SaveCompareSetModal from "./components/SaveCompareSetModal";
import SymbolFilters from "./components/SymbolFilters";

export type CompareItem = {
    compareServerName: string;
    compareServer: number;
    compareSymbol: string;
};
export type LPPriceCompareProps = {};
const LPPriceCompare = ({}: LPPriceCompareProps) => {
    const [data, setData] = useState<CompareData | undefined>(undefined);
    const [filterForm] = Form.useForm();
    const [compareItems, setCompareItems] = useState<CompareItem[]>([]);
    const [loadingServerTime, setLoadingServerTime] = useState<boolean>(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [filterDate, setFilterDate] = useState<{ start?: string; end?: string }>({});
    const queryClient = useQueryClient();

    function onAddCompareItem(items: CompareItem[]) {
        const prev = [...compareItems];
        items.forEach(item => {
            if (!prev.find(p => p.compareServer === item.compareServer && p.compareSymbol === item.compareSymbol)) {
                prev.push(item);
            }
        });
        //max 20 items

        setCompareItems(prev.slice(0, 20));
        // setCompareItems(prev => Array.from(new Set([...prev, ...items])));
    }

    function deleteCompareItem(index: number) {
        const prev = [...compareItems];
        prev.splice(index, 1);
        setCompareItems(prev);
    }

    function getT10Time() {
        setLoadingServerTime(true);
        plainAxiosInstance
            .get<string>(`${APIs.RC_LP_PRICE_COMPARE.GET_T10_TIME}`)
            .then(res => {
                if (res.status !== 200) return;
                const t10Time = res.data;
                filterForm.setFieldsValue({ startDate: moment(t10Time, "YYYY-MM-DD HH:mm") });
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Server Time", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setLoadingServerTime(false));
    }

    function fetchLPPriceCompare(values: any) {
        const { startDate, minutes } = values;
        const param = {
            start: moment(startDate).format("YYYY-MM-DD HH:mm"),
            end: moment(startDate).add(minutes, "minutes").format("YYYY-MM-DD HH:mm"),
            serverUno: compareItems.map(item => item.compareServer),
            symbol: compareItems.map(item => item.compareSymbol),
        };
        setFilterDate({ start: param.start, end: param.end });
        plainAxiosInstance
            .post(`${APIs.RC_LP_PRICE_COMPARE.GET_COMPARE_DATA}`, param)
            .then(res => {
                if (res.status !== 200) return;
                setData(res.data);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("LP Price Compare", SUCCESS_FAILED.FAILED_LOAD_DATA, error))
            );
    }

    function downloadLPPriceCompare() {
        const startDate = filterForm.getFieldValue("startDate");
        const minutes = filterForm.getFieldValue("minutes");
        const param = {
            start: moment(startDate).format("YYYY-MM-DD HH:mm"),
            end: moment(startDate).add(minutes, "minutes").format("YYYY-MM-DD HH:mm"),
            serverUno: compareItems.map(item => item.compareServer),
            symbol: compareItems.map(item => item.compareSymbol),
        };
        plainAxiosInstance
            .post(`${plainAxiosInstance.defaults.baseURL}${APIs.RC_LP_PRICE_COMPARE.GET_REPORT_DOWNLOAD}`, param)
            .then(response => {
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `lp_price_compare_${param.start}_to_${param.end}.csv`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    ErrorMessageHandler(`LP Price Compare Report downloaded successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                } else {
                    ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("LP Price Compare Report", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            });
    }

    const getCurrentDateTimeRangText = useMemo(() => {
        return isEmptyOrNull(filterDate.start) || isEmptyOrNull(filterDate.end) ? (
            <></>
        ) : (
            <div className="btns-fwbw">
                <Button
                    type="primary"
                    className="shadow-light"
                    icon={<BackwardOutlined />}
                    disabled={moment(`${filterDate.start}:00`).isBefore(moment().subtract(3, "days").startOf("day"))}
                    onClick={() => {
                        let startTime = filterForm.getFieldValue("startDate"),
                            minutes = filterForm.getFieldValue("minutes");
                        const newTime = moment(startTime).subtract(minutes, "minutes");
                        filterForm.setFieldsValue({ startDate: newTime });
                        filterForm.submit();
                    }}
                />
                <span>{`${filterDate.start} ~ ${filterDate.end}`}</span>
                <Button
                    type="primary"
                    className="shadow-light"
                    icon={<ForwardOutlined />}
                    disabled={moment(`${filterDate.end}:00`).isAfter(moment())}
                    onClick={() => {
                        let startTime = filterForm.getFieldValue("startDate"),
                            minutes = filterForm.getFieldValue("minutes");
                        const newTime = moment(startTime).add(minutes, "minutes");
                        filterForm.setFieldsValue({ startDate: newTime });
                        filterForm.submit();
                    }}
                />
            </div>
        );
    }, [filterForm, filterDate]);

    return (
        <div className="pricemonitor-lp-price-compare-container">
            <CardBox title={"LP Price Compare"}>
                <div className="main-content">
                    <Form form={filterForm} layout="vertical" onFinish={fetchLPPriceCompare}>
                        <Row>
                            <Col span={12}>
                                <Row gutter={8}>
                                    <Col span={8}>
                                        <FormComponent
                                            label="Start Date Time"
                                            name="startDate"
                                            extra={{
                                                type: ComponentType.date,
                                                value: "",
                                                dateFormat: "YYYY-MM-DD HH:mm",
                                                inputProps: {
                                                    showTime: { format: "HH:mm" },
                                                    disabledDate: (current: any) => {
                                                        return (
                                                            (current && current > moment().endOf("day")) ||
                                                            current < moment().subtract(4, "days").endOf("day")
                                                        );
                                                    },
                                                },
                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                            }}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <FormComponent
                                            label="Minutes"
                                            name="minutes"
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                                rules: [
                                                    { required: true, message: REQUIRED_FIELD },
                                                    {
                                                        validator(_, value) {
                                                            if (isEmptyOrNull(value)) return Promise.reject("");
                                                            const minutes = Number(value);
                                                            if (isNaN(minutes)) {
                                                                return Promise.reject("");
                                                            }
                                                            if (minutes < 1) {
                                                                return Promise.reject("At least 1 minute");
                                                            }
                                                            if (minutes > 20) {
                                                                return Promise.reject("At most 20 minutes");
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ],
                                            }}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <div className="form-btns">
                                            <Button
                                                htmlType="button"
                                                style={{ marginLeft: "0.651vw", width: "auto" }}
                                                icon={<ClockCircleOutlined style={{ fontSize: "0.875rem" }} />}
                                                onClick={getT10Time}
                                                loading={loadingServerTime}
                                            >
                                                T-10
                                            </Button>
                                            <Button
                                                htmlType="button"
                                                style={{ marginLeft: "0.651vw", width: "auto" }}
                                                icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}
                                                disabled={compareItems.length === 0}
                                                onClick={downloadLPPriceCompare}
                                            >
                                                Download
                                            </Button>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                style={{ marginLeft: "0.651vw", width: "auto" }}
                                                icon={<SearchOutlined style={{ fontSize: "0.875rem" }} />}
                                                disabled={compareItems.length === 0}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <Divider />
                    <Row className="compare-items-row">
                        <Col className="right" span={12}>
                            <CompareItemsOverview
                                items={compareItems}
                                clearItems={() => setCompareItems([])}
                                deleteItem={deleteCompareItem}
                                onSaveSet={() => setIsCreateModalOpen(true)}
                            />
                            <SaveCompareSetModal
                                open={isCreateModalOpen}
                                onModalCancel={() => {
                                    setIsCreateModalOpen(false);
                                }}
                                onModalOk={() => {
                                    setIsCreateModalOpen(false);
                                    queryClient.invalidateQueries([APIs.RC_LP_PRICE_COMPARE.GET_COMPARE_SET]);
                                }}
                                compareItems={compareItems}
                            />
                        </Col>
                        <Col className="left" span={12}>
                            <SymbolFilters onAddCompareItem={onAddCompareItem} />
                        </Col>
                    </Row>
                </div>

                <Divider />
                {getCurrentDateTimeRangText}
                <LPPriceCompareLineChart compareData={data} />
            </CardBox>
        </div>
    );
};

export default LPPriceCompare;

const CompareItemsOverview = ({
    items,
    clearItems,
    deleteItem,
    onSaveSet,
}: {
    items: CompareItem[];
    clearItems: () => void;
    deleteItem: (index: number) => void;
    onSaveSet: () => void;
}) => {
    return (
        <div className="compare-items-overview">
            <Card
                title={
                    <div className="title">
                        <span>Compare Items Overview </span>
                        <Badge count={items.length} />
                    </div>
                }
                size="small"
                actions={[
                    <ClearOutlined key={"clear"} title="Clear" onClick={clearItems} />,
                    <SaveOutlined
                        key={"save"}
                        title="Save"
                        disabled={items.length === 0}
                        onClick={() => {
                            if (items.length === 0) {
                                message.warning("No items to save");
                                return;
                            }
                            onSaveSet();
                        }}
                    />,
                ]}
            >
                <div className="wrapper">
                    {items.length === 0 && <Empty style={{ flex: "1", paddingBlock: "1rem" }} description={"Add items from the left panel"} />}
                    {
                        //fill new array with 10 items then render each with a tag
                        items.map((item, index) => (
                            <div className="item" key={`${item.compareServer}_${item.compareServerName}_${index}`}>
                                <Typography.Text>
                                    {item.compareServerName}-{item.compareSymbol}
                                </Typography.Text>
                                <CloseOutlined onClick={() => deleteItem(index)} />
                            </div>
                        ))
                    }
                </div>
            </Card>
            {items.length >= 20 && (
                <div>
                    <Alert message="Max 20 items reached" type="warning" showIcon />
                </div>
            )}
        </div>
    );
};
