import { useGetTemplatesQuery } from "../../../store/apis/template";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { Link, useNavigate } from "react-router-dom";
import { Tags, Template, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, FlexiDataColumnProps } from "../../../constants/type";
import { Form, message, Modal, Tag, Tooltip } from "antd";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { useEffect, useState } from "react";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { FormComponent } from "../../../components/FormComponent";
import CardBox from "../../../components/Common/CardBox";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import moment from "moment";
import { ExportOutlined, FieldTimeOutlined } from "@ant-design/icons";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";

interface adhocModalProps {
    isShow: boolean;
    data: any;
}

const defaultAdhocModal = { reportId: 0, fireAndForget: true, snapshotDate: "" };

const TemplateListing = (pros: any) => {
    const { isLoading, data, refetch } = useGetTemplatesQuery({ reportName: "" });
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const [adhocModal, setAdhocModal] = useState<adhocModalProps>({ isShow: false, data: defaultAdhocModal });
    const [adhocForm] = Form.useForm();
    const authHp = new AuthHelper();
    const authEditEnabled = authHp.isAuthorized(AuthKeys.TEMPLATE_EDIT);

    let navigate = useNavigate();

    useEffect(() => {
        refetch();
        return () => {};
    }, []);

    const columns: FlexiDataColumnProps[] = [
        DTColProps.XLarge({
            title: "Template Name",
            dataIndex: "name",
            key: "name",
            fixed: "left",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        DTColProps.Middle({
            title: "Tags",
            dataIndex: "tags",
            key: "tags",
            render: (tags: Tags[]) => {
                if (tags?.length > 0) {
                    tags.sort((a: Tags, b: Tags) => b.name.length - a.name.length);
                    return tags.map((tag: Tags) => (
                        <Tag key={tag.id} style={{ marginBottom: "0.9898vh", padding: "0 0.651vw 0 0.651vw" }}>
                            {tag.name}
                        </Tag>
                    ));
                } else return "-";
            },
        }),

        DTColProps.Small({
            title: "Settings",
            key: "settings",
            render: (record: any) => {
                const onTrueStyles = { color: "#0ab76e", fontSize: "1.4375rem", marginRight: "1.302vw" };
                const onFalseStyles = { color: "#d9d9d9", fontSize: "1.4375rem", marginRight: "1.302vw" };

                if (record.sharingRules === 1) {
                    if (record.status === 1 && record.schedulerStatus === 1) {
                        return (
                            <div>
                                <Tooltip title="Scheduler: Yes">
                                    <FieldTimeOutlined style={{ ...onTrueStyles }} />
                                </Tooltip>
                                <Tooltip title="Sharing: Private">
                                    <ExportOutlined style={{ ...onFalseStyles }} />
                                </Tooltip>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <Tooltip title="Scheduler: No">
                                    <FieldTimeOutlined style={{ ...onFalseStyles }} />
                                </Tooltip>
                                <Tooltip title="Sharing: Private">
                                    <ExportOutlined style={{ ...onFalseStyles }} />
                                </Tooltip>
                            </div>
                        );
                    }
                }

                if (record.sharingRules === 2) {
                    if (record.status === 1 && record.schedulerStatus === 1) {
                        return (
                            <div>
                                <Tooltip title="Scheduler: Yes">
                                    <FieldTimeOutlined style={{ ...onTrueStyles }} />
                                </Tooltip>
                                <Tooltip title="Sharing: Public">
                                    <ExportOutlined style={{ ...onTrueStyles }} />
                                </Tooltip>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <Tooltip title="Scheduler: No">
                                    <FieldTimeOutlined style={{ ...onFalseStyles }} />
                                </Tooltip>
                                <Tooltip title="Sharing: Public">
                                    <ExportOutlined style={{ ...onTrueStyles }} />
                                </Tooltip>
                            </div>
                        );
                    }
                }
            },
        }),

        DTColProps.Small({
            title: "Created By",
            dataIndex: "ownerName",
            key: "ownerName",
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),

        DTColProps.DateTime({
            title: "Created At (Local)",
            dataIndex: "createdDateUtc",
            key: "createdDateUtc",
            defaultSortOrder: "descend",
            options: {
                filter: {
                    type: ComponentType.daterange,
                    value: "",
                    inputProps: {
                        showTime: true,
                    },
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        view: (record: any, option: any) => {
            let newProps = { ...option };
            let viewState = `${record.reportId}|${record.name}|template|`;
            newProps = <Link to={`/report/template/view/${btoa(viewState)}`}>{newProps}</Link>;
            return newProps;
        },
        add: authEditEnabled,
        edit: authEditEnabled ? (record: Template, option: any) => (record.editable ? option : undefined) : false,
        delete: authEditEnabled ? (record: Template, option: any) => (record.editable ? option : undefined) : false,
        ...(authEditEnabled && {
            rowExtra: (record: any) =>
                record.adHocEnable
                    ? [
                          { text: "Run Ad-hoc", value: 1 },
                          { text: "Run Ad-hoc By Date", value: 2 },
                      ]
                    : [],
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                let viewState = `${data.reportId}|${data.name}|template|`;
                navigate(`/report/template/view/${btoa(viewState)}`);
                break;
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/report/template/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                let state = `${data.reportId}|${data.name}|edit`;
                navigate(`/report/template/edit/${btoa(state)}`);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(APIs.DELETE_TEMPLATE, { reportId: data.reportId })
                    .then(data => {
                        ErrorMessageHandler("The template", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setManualLoading(false);
                        refetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (res: any) => ErrorMessageHandler("template", SUCCESS_FAILED.FAILED_DELETE_DATA, error));
                        setManualLoading(false);
                    });
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (data.key === 1) {
                    apiRequest(APIs.CREATE_ADHOC, { reportId: data.data.reportId, fireAndForget: true, snapshotDate: "" })
                        .then(data => {
                            ErrorMessageHandler(`Your report is generating. Please check it later in report result.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                        })
                        .catch(error =>
                            ErrorCatchValidator(error, (res: any) =>
                                ErrorMessageHandler("an ad-hoc report", SUCCESS_FAILED.FAILED_CREATE_DATA, error)
                            )
                        );
                } else if (data.key === 2) {
                    adhocForm.resetFields();
                    setAdhocModal({ ...adhocModal, isShow: true, data: { ...adhocModal.data, reportId: data.data.reportId } });
                }
                break;
        }
    };

    return (
        <>
            <CardBox title={"Report Template"}>
                <FlexiDataTable
                    rowKeyProperty="reportId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data?.data || []}
                    // dataSource={dummyData || []}
                    callback={componentCallback}
                    loading={isLoading || manualLoading}
                />
            </CardBox>
            <Modal
                open={adhocModal.isShow}
                title="Ad-hoc Date"
                onCancel={() => {
                    setAdhocModal({ ...adhocModal, isShow: false, data: defaultAdhocModal });
                }}
                onOk={() => {
                    adhocForm
                        .validateFields()
                        .then(values => {
                            let adhocDateObj = values.adhocdate as moment.Moment;
                            apiRequest(APIs.CREATE_ADHOC, {
                                ...adhocModal.data,
                                snapshotDate: adhocDateObj.format("YYYY-MM-DD"),
                            })
                                .then(data => {
                                    ErrorMessageHandler(
                                        `Your report is generating. Please check it later in report result.`,
                                        SUCCESS_FAILED.OTHERS_SUCCESS
                                    );
                                })
                                .catch(error =>
                                    ErrorCatchValidator(error, (res: any) =>
                                        ErrorMessageHandler("an ad-hoc report", SUCCESS_FAILED.FAILED_CREATE_DATA, error)
                                    )
                                );
                            setAdhocModal({ ...adhocModal, isShow: false, data: defaultAdhocModal });
                        })
                        .catch(info => {
                            console.log("Validate Failed:", info);
                        });
                }}
            >
                <Form form={adhocForm} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} layout="horizontal">
                    <FormComponent
                        label={"Date"}
                        name={"adhocdate"}
                        extra={{
                            type: ComponentType.date,
                            value: "",
                            inputProps: {
                                format: (value: any) => value.format("YYYY-MM-DD"),
                                disabledDate: (current: any) => {
                                    return current && current > moment().add(-1, "days").endOf("day");
                                },
                            },
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                        }}
                    />
                </Form>
            </Modal>
        </>
    );
};

export default TemplateListing;
