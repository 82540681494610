import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";
import { ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CreateEditInlineFormBased, KeyValuePair } from "@/constants/type";
import { apiRequest, APIs } from "@/services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { Col, Form, Row } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface BridgeCreateEditPageV2Props extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

export type BridgeState = {
    action: string;
    data: any;
};

const initialValues = {
    bridgeId: "",
    bridgeName: "",
    bridgeDisplayName: "",
    bridgeTypeId: "",
    version: "",
    host: "",
    login: "",
    password: "",
    port: "",
    brokerVhost: "",
    brokerLogin: "",
    brokerPassword: "",
    enable: true,
};

const BridgeCreateEditPageV2 = (props: BridgeCreateEditPageV2Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bridgeTypes, setBridgeTypes] = useState<KeyValuePair[]>([]);
    const [bridgeForm] = Form.useForm();

    const stateInfo = useMemo(() => {
        return {
            isAddAction: props.mode === InnerPageActionMode.CREATE_NEW,
            data: defaultIfEmptyOrNull(props.id, ""),
        };
    }, [props.mode, props.id]);

    const processBackAndRefetch = () => setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);
            if (stateInfo.isAddAction) {
                apiRequest(APIs.CREATE_BRIDGE, values)
                    .then(data => {
                        ErrorMessageHandler("New bridge", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new bridge", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                        setIsLoading(false);
                    });
            } else {
                apiRequest(APIs.UPDATE_BRIDGE, values)
                    .then(data => {
                        ErrorMessageHandler("Existing bridge", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing bridge", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                        setIsLoading(false);
                    });
            }
        },
        [stateInfo]
    );

    const getBridgeById = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRIDGE_LIST, { bridgeId: stateInfo.data })
            .then((data: any) => {
                if (data) {
                    bridgeForm.setFieldsValue(data);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                processBackAndRefetch();
            })
            .finally(() => setIsLoading(false));
    }, [stateInfo]);

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["riskinsightbridgetype"] }).then((res: any) => {
            if (res.riskInsightBridgeTypes && res.riskInsightBridgeTypes.length > 0) {
                setBridgeTypes(res.riskInsightBridgeTypes.map((x: any) => ({ text: x.bridgeType, value: x.bridgeTypeId })));
            }
        });
    };

    useEffect(() => {
        getConfig();

        if (!stateInfo.isAddAction) {
            getBridgeById();
        } else {
            bridgeForm.setFieldsValue(initialValues);
        }
        return () => {};
    }, [stateInfo, getBridgeById, props.resetState]);

    return (
        <SitePageHeaderInline
            enableSubmit={!isLoading}
            title={stateInfo.isAddAction ? "Create New Bridge" : "Edit Bridge"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        bridgeForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={bridgeForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"bridgeId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Bridge Name"
                                name="bridgeName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    disabled: !stateInfo.isAddAction,
                                }}
                            />
                            <FormComponent
                                label="Bridge Display Name"
                                name="bridgeDisplayName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label="Bridge Type"
                                name="bridgeTypeId"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: bridgeTypes,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label="Version"
                                name="version"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label="Host"
                                name="host"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Port"
                                name="port"
                                extra={{
                                    type: ComponentType.number,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Login"
                                name="login"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Password"
                                name="password"
                                extra={{
                                    type: ComponentType.password,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Broker VHost"
                                name="brokerVhost"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Broker Login"
                                name="brokerLogin"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Broker Password"
                                name="brokerPassword"
                                extra={{
                                    type: ComponentType.password,
                                    value: "",
                                }}
                            />
                            <FormComponent
                                label="Enable"
                                name="enable"
                                extra={{
                                    type: ComponentType.switch,
                                    value: ["Inactive", "Active"],
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default BridgeCreateEditPageV2;
