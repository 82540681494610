import { useEffect, useMemo, useState } from "react";
import SitePageHeader from "../../../components/PageHeader";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, DownloadOutlined, HomeOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Progress, Table, message } from "antd";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../utils/Common";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { selectedTicketData } from "./TicketList";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { PROGRESS_TYPES, PROGRESS_TYPES_ENUM, SUCCESS_FAILED } from "../../../constants";
import moment from "moment";
import { HistoricalTicketRestorationSummary, PreviewMt4RestoreTradeStopOut, TixRestorationSumProgressItem } from "../../../constants/type";
import SignalRHelper from "../../../helpers/signalRHelper";
import { initialProgressStatusData } from "./HistoricalList";
import loading_gif from "../../../assets/images/loading1.gif";
import { CSVLink } from "react-csv";

interface markedupUploadedMt4Mt5Tickets {
    serverName: string;
    login: number;
    ticket?: number;
    order?: number;
    deal?: number;
    symbol: string;
    profit: number;
    openTime?: string;
    closeTime: string;
    comment: string;
}

interface CalculateStopOutProps {
    selectedTicketData: selectedTicketData[];
    serverId: number;
    uploadedData?: markedupUploadedMt4Mt5Tickets[];
    previewData?: PreviewMt4RestoreTradeStopOut[];
    method: "byUpload" | "byManual";
}

const CalculateStopOut = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let comState = location.state as CalculateStopOutProps;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dataSource, setDataSource] = useState<any>([]);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [sRConnection] = useState<SignalRHelper>(new SignalRHelper("tp-ticketRestoration", `/mtRestoreTradesHub`));

    const [isRestore, setIsRestore] = useState<boolean>(false);
    const [progressStatusData, setProgressStatusData] = useState<any>({});
    const [progressData, setProgressData] = useState<any>([]);
    const [incomingProgressStatsData, setIncomingProgressStatsData] = useState<any>({});
    const [isAllCompleted, setIsAllCompleted] = useState<boolean>(false);
    const [isMt5, setIsMt5] = useState<boolean>(false);
    const [isStopOut, setIsStopOut] = useState<boolean>(false);

    const exportColumns = useMemo(
        () => [
            ...(isMt5
                ? [
                      { label: "Order", key: "order" },
                      { label: "Deal", key: "deal" },
                  ]
                : [{ label: "Ticket", key: "ticket" }]),
            { label: "Server", key: "serverName" },
            { label: "Symbol", key: "symbol" },
            { label: "Account ID", key: "login" },
            { label: "Profit", key: "profit" },
            { label: "Profit (USD)", key: "profitInUSD" },
            { label: "Swap", key: "swap" },
            { label: "Swap (USD)", key: "swapInUSD" },
            { label: "Commission", key: "commission" },
            { label: "Commission (USD)", key: "commissionInUSD" },
            ...(!isMt5 ? [{ label: "Open Time (Server)", key: "openTime" }] : []),
            { label: "Close Time (Server)", key: "closeTime" },
            { label: "Comment", key: "comment" },
            { label: "Account Currency", key: "loginAccountCurrency" },
            { label: "Stop Out", key: "isStopOut" },
            ...(isRestore ? [{ label: "Status", key: "status" }] : []),
        ],
        [isMt5, isRestore]
    );

    const columns = [
        DTColProps.Small({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
        }),
        DTColProps.Small({
            title: "Account ID",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.XSmall({
            title: "Account Currency",
            dataIndex: "loginAccountCurrency",
            key: "loginAccountCurrency",
        }),
        DTColProps.XSmall({
            title: "Estimated New Equity (USD)",
            dataIndex: "calculatedNewEquityInUSD",
            key: "calculatedNewEquityInUSD",
            sorter: (a: any, b: any) => a.calculatedNewEquityInUSD - b.calculatedNewEquityInUSD,
            render: (value: number) => (value === 0 ? 0 : currencyRender(value, 2)),
        }),
        DTColProps.XSmall({
            title: "Estimated New Balance (USD)",
            dataIndex: "calculatedNewBalanceInUSD",
            key: "calculatedNewBalanceInUSD",
            sorter: (a: any, b: any) => a.calculatedNewBalanceInUSD - b.calculatedNewBalanceInUSD,
            render: (value: number) => (value === 0 ? 0 : currencyRender(value, 2)),
        }),
        DTColProps.XSmall({
            title: "Estimated New Margin (USD)",
            dataIndex: "calculatedNewMarginInUSD",
            key: "calculatedNewMarginInUSD",
            sorter: (a: any, b: any) => a.calculatedNewMarginInUSD - b.calculatedNewMarginInUSD,
            render: (value: number) => (value === 0 ? 0 : currencyRender(value, 2)),
        }),
        DTColProps.Small({
            title: "Stop Out",
            dataIndex: "isStopOut",
            key: "isStopOut",
            render: (isStopOut: boolean) => (isStopOut ? "True" : "False"),
        }),
        DTColProps.XSmall({
            title: "",
            dataIndex: "",
            key: "delete",
            render: (rowData: any) => (
                <DeleteOutlined
                    style={{ color: "#f00f00", fontSize: "1.125rem" }}
                    onClick={() => {
                        let newData = dataSource.filter((x: any) => x.login !== rowData.login);
                        setDataSource(newData);
                    }}
                />
            ),
        }),
    ];

    const expandedRowColumns = useMemo(
        () => [
            ...(!isMt5
                ? [
                      DTColProps.XSmall({
                          title: "Ticket",
                          dataIndex: "ticket",
                          key: "ticket",
                          sorter: (a: any, b: any) => a.ticket - b.ticket,
                      }),
                  ]
                : [
                      DTColProps.XSmall({
                          title: "Order",
                          dataIndex: "order",
                          key: "order",
                          sorter: (a: any, b: any) => a.order - b.order,
                      }),
                      ...(!isRestore
                          ? [
                                DTColProps.XSmall({
                                    title: "Deal",
                                    dataIndex: "deal",
                                    key: "deal",
                                    sorter: (a: any, b: any) => a.deal - b.deal,
                                }),
                            ]
                          : [
                                DTColProps.XSmall({
                                    title: "Deal",
                                    dataIndex: "ticket",
                                    key: "ticket",
                                    sorter: (a: any, b: any) => a.ticket - b.ticket,
                                }),
                            ]),
                  ]),
            DTColProps.XSmall({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                sorter: (a: any, b: any) => (a.symbol > b.symbol ? 1 : -1),
            }),
            DTColProps.XSmall({
                title: "Profit",
                dataIndex: "profit",
                key: "profit",
                sorter: (a: any, b: any) => a.profit - b.profit,
            }),
            ...(comState.method === "byManual"
                ? [
                      DTColProps.XSmall({
                          title: "Profit (USD)",
                          dataIndex: "profitInUSD",
                          key: "profitInUSD",
                          sorter: (a: any, b: any) => a.profitInUSD - b.profitInUSD,
                      }),
                      DTColProps.XSmall({
                          title: "Swap",
                          dataIndex: "swap",
                          key: "swap",
                          sorter: (a: any, b: any) => a.swap - b.swap,
                      }),
                      DTColProps.XSmall({
                          title: "Swap (USD)",
                          dataIndex: "swapInUSD",
                          key: "swapInUSD",
                          sorter: (a: any, b: any) => a.swapInUSD - b.swapInUSD,
                      }),
                      DTColProps.XSmall({
                          title: "Commission",
                          dataIndex: "commission",
                          key: "commission",
                          sorter: (a: any, b: any) => a.commission - b.commission,
                      }),
                      DTColProps.XSmall({
                          title: "Commission (USD)",
                          dataIndex: "commissionInUSD",
                          key: "commissionInUSD",
                          sorter: (a: any, b: any) => a.commissionInUSD - b.commissionInUSD,
                      }),
                  ]
                : []),
            ...(!isMt5
                ? [
                      DTColProps.DateTime_ServerTime({
                          title: "Open Time (Server)",
                          dataIndex: "openTime",
                          key: "openTime",
                          // defaultSortOrder: "descend",
                          sorter: (a: any, b: any) => (moment(b.openTime) > moment(a.openTime) ? -1 : 1),
                          render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss.SSS") : ""),
                      }),
                  ]
                : []),
            DTColProps.DateTime_ServerTime({
                title: "Close Time (Server)",
                dataIndex: "closeTime",
                key: "closeTime",
                // defaultSortOrder: "descend",
                sorter: (a: any, b: any) => (moment(b.closeTime) > moment(a.closeTime) ? -1 : 1),
                render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss.SSS") : ""),
            }),
            DTColProps.Middle({
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
                render: (value: string) => (value === "" ? "-" : value),
            }),
            ...(isRestore
                ? [
                      DTColProps.XSmall(
                          {
                              title: "Status",
                              dataIndex: "progressDetailToStatus",
                              key: "progressDetailToStatus",
                              render: (value: string, rowData: any) => {
                                  let thisKey: string = `${rowData.batchId}|${rowData.progressSummaryId}|${rowData.progressDetailId}`;
                                  if (progressStatusData?.hasOwnProperty(thisKey)) {
                                      let thisProStatData: any = progressStatusData[thisKey];

                                      switch (thisProStatData.status) {
                                          case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.COMPLETED]:
                                              return <CheckCircleOutlined style={{ color: "#52C41A", fontSize: "1.25rem" }} />;
                                          case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.FAILED]:
                                              return <CloseCircleOutlined style={{ color: "#ff4d4f", fontSize: "1.25rem" }} />;
                                          case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.TO_BE_PROCESSED]:
                                          case PROGRESS_TYPES[PROGRESS_TYPES_ENUM.PROCESSING]:
                                          default:
                                              return <img src={loading_gif} alt="animated_loading_gif" style={{ height: 30 }} />;
                                      }
                                  } else return `${PROGRESS_TYPES[PROGRESS_TYPES_ENUM.TO_BE_PROCESSED]}`;
                              },
                          },
                          ["text-center"]
                      ),
                  ]
                : []),
        ],
        [comState.method, isMt5, progressStatusData, isRestore]
    );

    const dataMarkup = (data: any, comData: any, method: "byUpload" | "byManual"): any => {
        let temp_data: any = [];
        let temp_comData: any = [];
        let final_data: any = [];

        data.forEach((currData: any) => {
            temp_data[`${currData.login}`] = currData;
        });
        if (method === "byManual") {
            comData.forEach((currTicketData: selectedTicketData) => {
                temp_comData[`${currTicketData.login}`] = temp_comData[`${currTicketData.login}`] ?? [];
                temp_comData[`${currTicketData.login}`].push({
                    symbol: currTicketData.symbol,
                    serverName: currTicketData.serverName,
                    closeTime: currTicketData.closeTime,
                    profitInUSD: currTicketData.profitInUSD,
                    comment: currTicketData.comment,
                    ticket: currTicketData.ticket,
                    order: currTicketData.order,
                    deal: currTicketData.deal,
                    openTime: currTicketData.openTime,
                    profit: currTicketData.profit,
                    swap: currTicketData.swap,
                    swapInUSD: currTicketData.swapInUSD,
                    commission: currTicketData.commission,
                    commissionInUSD: currTicketData.commissionInUSD,
                });
            });
        } else if (method === "byUpload") {
            comData.forEach((currTicketData: markedupUploadedMt4Mt5Tickets) => {
                temp_comData[`${currTicketData.login}`] = temp_comData[`${currTicketData.login}`] ?? [];
                temp_comData[`${currTicketData.login}`].push(currTicketData);
            });
        }
        Object.keys(temp_data).forEach((loginKey: any) => {
            final_data.push({
                key: temp_data[loginKey].login,
                ...temp_data[loginKey],
                tickets: temp_comData[loginKey],
                serverName: temp_comData[loginKey][0].serverName,
            });
        });
        setDataSource(final_data);
    };

    const getExpandedRowRender = (rowData: any) => {
        let newData = rowData.tickets.map((x: any) => ({
            key: x.ticket,
            ...x,
        }));

        return (
            <FlexiDataTable
                rowKeyProperty={`${isMt5 ? "deal" : "ticket"}`}
                title={`${isMt5 ? "Selected Order Deals" : "Selected Tickets"}`}
                columns={expandedRowColumns}
                options={{
                    enableFilter: false,
                }}
                dataSource={newData}
                loading={false}
                bordered
                scroll={{ x: 900, y: 280 }}
                pagination={{ defaultPageSize: 100 }}
            />
        );
    };

    const getExpandedProgressRowRender = (rowData: any) => {
        let newData = rowData.ticketRestorationDetails.map((x: any) => ({
            key: x.ticket,
            ...x,
        }));

        return (
            <FlexiDataTable
                rowKeyProperty="progressDetailId"
                title={`${isMt5 ? "Selected Order Deals" : "Selected Tickets"}`}
                columns={expandedRowColumns}
                options={{
                    enableFilter: false,
                }}
                dataSource={newData}
                loading={false}
                bordered
                scroll={{ x: 900, y: 280 }}
                pagination={{ defaultPageSize: 100 }}
            />
        );
    };

    useEffect(() => {
        let tempDataByAcc: any = [];
        let ticketsToRestore: any = [];
        let dealsToRestore: any = [];

        if (comState?.hasOwnProperty("selectedTicketData")) {
            comState.selectedTicketData.forEach((x: any) => {
                tempDataByAcc[`${x.login}`] = tempDataByAcc[`${x.login}`] ?? [];
                if (x.order) {
                    tempDataByAcc[`${x.login}`].push({ deal: x.deal, symbol: x.symbol, order: x.order });
                } else tempDataByAcc[`${x.login}`].push({ ticket: x.ticket, symbol: x.symbol });
                return x;
            });

            Object.keys(tempDataByAcc).forEach((currAcc: string, i: number) => {
                ticketsToRestore.push({
                    login: parseInt(currAcc),
                    credit: 1000000,
                    tickets: [...tempDataByAcc[currAcc]],
                });
                dealsToRestore.push({
                    login: parseInt(currAcc),
                    credit: 1000000,
                    orderDeals: [...tempDataByAcc[currAcc]],
                });
            });

            if (comState.selectedTicketData[0].serverName.includes("MT5")) {
                setIsMt5(true);
                apiRequest(APIs.GET_MT5_PREVIEW_CLOSED_TRADES_STOP_OUT, {
                    serverId: comState.serverId,
                    dealsToRestore,
                })
                    .then((data: PreviewMt4RestoreTradeStopOut[]) => {
                        dataMarkup(data, comState.selectedTicketData, comState.method);
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("MT5 closed trades stop out", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                        );
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                setIsMt5(false);
                apiRequest(APIs.GET_MT4_PREVIEW_CLOSED_TRADES_STOP_OUT, {
                    serverId: comState.serverId,
                    ticketsToRestore,
                })
                    .then((data: PreviewMt4RestoreTradeStopOut[]) => {
                        dataMarkup(data, comState.selectedTicketData, comState.method);
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("MT4 closed trades stop out", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                        );
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        } else {
            if (comState && comState.uploadedData && comState.uploadedData[0].serverName.toUpperCase().includes("MT5")) setIsMt5(true);
            else setIsMt5(false);
            dataMarkup(comState.previewData, comState.uploadedData, comState.method);
            setIsLoading(false);
        }
        return () => {};
    }, []);

    useEffect(() => {
        if (dataSource.length > 0) {
            let isStopOutIdx = dataSource.findIndex((d: any) => d.isStopOut);
            if (isStopOutIdx > -1) setIsStopOut(true);
            else setIsStopOut(false);
        }
        return () => {};
    }, [dataSource]);

    if (isRestore) {
        columns.splice(
            7,
            1,
            DTColProps.Small({
                title: "Progresss",
                key: "progressByPercentage",
                dataIndex: "progressByPercentage",
                render: (value: number, rowData: TixRestorationSumProgressItem) => {
                    let thisKey: string = `${rowData.batchId}|${rowData.progressSummaryId}`;
                    if (progressStatusData?.hasOwnProperty(thisKey)) {
                        let thisProStatData: any = progressStatusData[thisKey];

                        if (thisProStatData.status === PROGRESS_TYPES[PROGRESS_TYPES_ENUM.FAILED]) {
                            return <Progress type="circle" status="exception" width={40} percent={thisProStatData.percentage} />;
                        } else {
                            if (thisProStatData.percentage === 100) {
                                return <Progress type="circle" width={40} percent={100} />;
                            } else {
                                return <Progress type="circle" status="active" width={40} percent={thisProStatData.percentage} />;
                            }
                        }
                    } else
                        return (
                            <div>
                                <span style={{ padding: "3px 8px" }}>{PROGRESS_TYPES[PROGRESS_TYPES_ENUM.TO_BE_PROCESSED]}</span>
                            </div>
                        );
                },
            }),
            DTColProps.Middle({
                title: "Progress Message",
                dataIndex: "progressSummaryErrorMessage",
                key: "progressSummaryErrorMessage",
                render: (value: string, rowData: any) => {
                    let thisKey: string = `${rowData.batchId}|${rowData.progressSummaryId}`;
                    if (progressStatusData?.hasOwnProperty(thisKey)) {
                        let thisProStatData: any = progressStatusData[thisKey];
                        if (thisProStatData.error === null || thisProStatData.error === "") return "-";
                        else return thisProStatData.error;
                    } else return "-";
                },
            })
        );
    }

    const getRestoreTradesProgress = (batchId: number) => {
        setIsLoading(true);
        apiRequest(APIs.GET_HISTORICAL_RETORE_TRADES_PROGRESS, { batchId: batchId })
            .then((data: HistoricalTicketRestorationSummary) => {
                if (data.ticketRestorationSummary.length > 0) {
                    let statusBySummary: any = {};
                    let statusByDetail: any = {};
                    data.ticketRestorationSummary.forEach((currSummary: any) => {
                        statusBySummary[`${currSummary.batchId}|${currSummary.progressSummaryId}`] = {
                            ...initialProgressStatusData,
                            status:
                                currSummary.progressSummaryToStatus === null
                                    ? PROGRESS_TYPES[currSummary.progressSummaryFromStatus]
                                    : PROGRESS_TYPES[currSummary.progressSummaryToStatus],
                            percentage: currSummary.progressByPercentage,
                            error:
                                currSummary.progressSummaryErrorMessage === null
                                    ? initialProgressStatusData.error
                                    : currSummary.progressSummaryErrorMessage,
                        };
                        currSummary.ticketRestorationDetails.forEach((currDetail: any) => {
                            statusByDetail[`${currSummary.batchId}|${currSummary.progressSummaryId}|${currDetail.progressDetailId}`] = {
                                ...initialProgressStatusData,
                                status:
                                    currDetail.progressDetailToStatus === null
                                        ? PROGRESS_TYPES[currDetail.progressDetailFromStatus]
                                        : PROGRESS_TYPES[currDetail.progressDetailToStatus],
                                error:
                                    currDetail.progressDetailErrorMessage === null
                                        ? initialProgressStatusData.error
                                        : currDetail.progressDetailErrorMessage,
                            };
                        });
                    });
                    let newProgressStatusData = {
                        ...progressStatusData,
                        ...statusBySummary,
                        ...statusByDetail,
                    };
                    setProgressStatusData(newProgressStatusData);

                    let newData = data.ticketRestorationSummary.map((currSum: any) => {
                        let newTicketRestorationDetails = currSum.ticketRestorationDetails.map((currDeet: any) => ({
                            ...currDeet,
                            batchId: currSum.batchId,
                            progressSummaryId: currSum.progressSummaryId,
                        }));

                        return {
                            ...currSum,
                            serverName:
                                comState.method === "byManual"
                                    ? comState.selectedTicketData[0].serverName
                                    : comState.uploadedData && comState.uploadedData[0].serverName,
                            ticketRestorationDetails: [...newTicketRestorationDetails],
                        };
                    });
                    setProgressData(newData);
                } else {
                    setProgressData([]);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("historical restore trades progress", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                });
            })
            .finally(() => {
                sRConnection.setHandler((hubConnection: any) => {
                    hubConnection.on("MtRestoreTradesStream", (message: any) => {
                        if (message && message.events && message.events.length > 0) {
                            message.events.forEach((currBatch: any) => {
                                let formattedIncomingObj: any = {};
                                formattedIncomingObj[`${currBatch.batchId}|`] = {
                                    ...formattedIncomingObj[`${currBatch.batchId}|`],
                                    status: PROGRESS_TYPES[currBatch.toStatus],
                                };
                                currBatch.restoreSummary.forEach((currSummary: any) => {
                                    formattedIncomingObj[`${currBatch.batchId}|${currSummary.summaryId}`] = {
                                        ...formattedIncomingObj[`${currBatch.batchId}|${currSummary.summaryId}`],
                                        status: PROGRESS_TYPES[currSummary.toStatus],
                                        percentage: currSummary.progressPercentage,
                                    };
                                    if (currSummary.details.length > 0) {
                                        currSummary.details.forEach((currDetail: any) => {
                                            formattedIncomingObj[`${currBatch.batchId}|${currSummary.summaryId}|${currDetail.detailId}`] = {
                                                ...formattedIncomingObj[`${currBatch.batchId}|${currSummary.summaryId}|${currDetail.detailId}`],
                                                status: PROGRESS_TYPES[currDetail.toStatus],
                                            };
                                        });
                                    }
                                });
                                setIncomingProgressStatsData(formattedIncomingObj);
                            });
                        }
                    });
                });
                sRConnection.startConnection();
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (Object.keys(incomingProgressStatsData).length > 0) {
            let clonedCopy: any = Object.assign({}, progressStatusData);
            Object.keys(incomingProgressStatsData).forEach((currKey: string) => {
                clonedCopy[currKey] = {
                    ...clonedCopy[currKey],
                    ...incomingProgressStatsData[currKey],
                };
            });
            setProgressStatusData(clonedCopy);

            // if all status in clonedCopy is completed, set btn text to 'Restored'
            const isAllCompletedResult = Object.values(clonedCopy).every((currObj: any) => {
                if (currObj.status === PROGRESS_TYPES[PROGRESS_TYPES_ENUM.COMPLETED]) {
                    return true;
                }
            });
            if (isAllCompletedResult) {
                setIsAllCompleted(true);
            }
        }
        return () => {};
    }, [incomingProgressStatsData]);

    const wholeData: any = useMemo(() => {
        let newData: any = [];
        if (dataSource.length > 0) {
            if (!isRestore) {
                dataSource.forEach((y: any) => {
                    y.tickets.forEach((x: any) => {
                        newData.push({
                            ...x,
                            login: y.login,
                            loginAccountCurrency: y.loginAccountCurrency,
                            isStopOut: y.isStopOut,
                        });
                    });
                });
                return newData;
            }
        } else {
            return [];
        }
    }, [dataSource]);

    return (
        <div className="calculate-stop-out-div">
            <SitePageHeader
                title={"Calculating Stop Out"}
                routes={[
                    {
                        path: "/admintools/ticket",
                        breadcrumbName: "Ticket Restoration",
                        icon: <HomeOutlined />,
                    },
                    {
                        path: "",
                        breadcrumbName: "Calculating Stop Out",
                    },
                ]}
                onBack={() => navigate("/admintools/ticket")}
                extraProps={{
                    extra: [
                        <Button
                            type="primary"
                            key={"cso-start"}
                            disabled={dataSource.length === 0 || isBtnLoading || isStopOut}
                            onClick={() => {
                                let restoreTrades = dataSource.map((currData: any) => {
                                    let trades = currData.tickets.map((currTicket: any) => {
                                        if (isMt5) return { ticket: currTicket.deal, symbol: currTicket.symbol, order: currTicket.order };
                                        else return { ticket: currTicket.ticket, symbol: currTicket.symbol };
                                    });
                                    return {
                                        login: currData.login,
                                        creditInOut: 1000000,
                                        loginAccountCurrency: currData.loginAccountCurrency,
                                        creditConversionRate: currData.creditConversionRate,
                                        convertedCreditInOut: currData.convertedCreditInOut,
                                        isStopOut: currData.isStopOut,
                                        trades,
                                    };
                                });
                                setIsBtnLoading(true);
                                apiRequest(APIs.CREATE_RESTORE_TRADE_TICKETS_JOB, {
                                    serverId: comState.serverId,
                                    restoreTrades,
                                })
                                    .then((data: any) => {
                                        if (data?.hasOwnProperty("batchId")) {
                                            getRestoreTradesProgress(data.batchId);
                                            setIsRestore(true);
                                        }
                                    })
                                    .catch((error: any) => {
                                        ErrorCatchValidator(error, (err: any) => {
                                            ErrorMessageHandler(
                                                `Failed to restore closed trades tickets: ${err.message}. ${err.data}.`,
                                                SUCCESS_FAILED.OTHERS_FAILED
                                            );
                                            setIsBtnLoading(false);
                                        });
                                    });
                            }}
                        >
                            {isBtnLoading && isAllCompleted ? "Restored" : isBtnLoading ? "Restoring..." : "Start Restoration"}
                        </Button>,
                    ],
                }}
            >
                {isRestore ? (
                    <Table
                        className="calculate-stop-out-div-table"
                        rowKey={"progressSummaryId"}
                        columns={columns}
                        dataSource={progressData || []}
                        loading={isLoading}
                        expandable={{ expandedRowRender: getExpandedProgressRowRender, defaultExpandAllRows: true }}
                        pagination={{
                            showTotal: (total: number, range: number[]) =>
                                `${currencyRender(range[0])} - ${currencyRender(range[1])} of ${currencyRender(total)}`,
                            defaultPageSize: 100,
                            showSizeChanger: true,
                        }}
                        scroll={{ x: 900 }}
                    />
                ) : (
                    <>
                        {!isRestore && wholeData.length > 0 && (
                            <div className="export-preview-button-div">
                                <CSVLink
                                    key={`btn-export-${Math.random()}`}
                                    data={wholeData.map((x: any) => {
                                        return {
                                            ...x,
                                            openTime: DataTableColumnRender.DateTime_ServerTime(x.openTime),
                                            closeTime: DataTableColumnRender.DateTime_ServerTime(x.closeTime),
                                        };
                                    })}
                                    headers={exportColumns}
                                    filename={`Ticket_Restoration_Preview-${new Date().getTime()}.csv`}
                                >
                                    <Button htmlType="button" icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />} disabled={isLoading}>
                                        Export as CSV
                                    </Button>
                                </CSVLink>
                            </div>
                        )}
                        <Table
                            className="calculate-stop-out-div-table"
                            columns={columns}
                            dataSource={dataSource || []}
                            loading={isLoading}
                            expandable={{ expandedRowRender: getExpandedRowRender }}
                            pagination={{
                                showTotal: (total: number, range: number[]) =>
                                    `${currencyRender(range[0])} - ${currencyRender(range[1])} of ${currencyRender(total)}`,
                                defaultPageSize: 100,
                                showSizeChanger: true,
                            }}
                            scroll={{ x: 900 }}
                        />
                    </>
                )}
            </SitePageHeader>
        </div>
    );
};

export default CalculateStopOut;
