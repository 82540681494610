import { message, Modal } from "antd";
import CodeBlock from "./CodeBlock";
import { useCallback, useEffect, useState } from "react";
import LoadingComponent from "@/components/Loading";
import EmptyData from "@/components/Common/Empty";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { IRCPriceBackendOpt } from "@/hooks/useRCPriceBackendOpt";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";

interface ShowDetailsModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
    data: IRCPriceBackendOpt | undefined;
    settingType: string;
    pageType: number;
}

export enum ShowDetailsModalCallbackKey {
    Close = 0,
}

const ShowDetailsModal = (props: ShowDetailsModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [settingInfo, setSettingInfo] = useState<string | undefined>(undefined);

    const getDetailInfo = useCallback(() => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("keyStr", `${props.data !== undefined ? props.data.serverDn : ""},${props.settingType}`);
        plainAxiosInstance
            .post(
                props.pageType === 4
                    ? APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_DETAILS_TICK_RECEIVER
                    : APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_DETAILS,
                formData
            )
            .then((res: any) => {
                setSettingInfo(res.data ? res.data : undefined);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server settings", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [props.data, props.settingType, props.pageType]);

    useEffect(() => {
        if (props.isModalVisible) getDetailInfo();
    }, [props.isModalVisible, getDetailInfo]);

    return (
        <Modal
            title={props.modalTitle}
            open={props.isModalVisible}
            width="80vw"
            footer={null}
            onCancel={() => props.callback(ShowDetailsModalCallbackKey.Close, null)}
            confirmLoading={false}
            style={{ top: 10 }}
            bodyStyle={{ padding: "5px" }}
        >
            {isLoading ? <LoadingComponent /> : settingInfo !== undefined ? <CodeBlock code={settingInfo} /> : <EmptyData />}
        </Modal>
    );
};

export default ShowDetailsModal;
