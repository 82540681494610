import { Button, Form, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 14 },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: { span: 14, offset: 7 },
};

const AttrOptionsCreateEdit = () => {
    return (
        <div>
            <Form.List
                name="options"
                rules={[
                    {
                        validator: async (_, options) => {
                            if (!options || options.length < 2) {
                                return Promise.reject(new Error("At least 2 options"));
                            }

                            const duplicates = options.filter((name: string, index: number) => options.indexOf(name) !== index);
                            if (duplicates.length > 0) {
                                return Promise.reject(new Error(`Please resolve duplicates before submitting:  ${duplicates.join(", ")}`));
                            }
                        },
                    },
                ]}
            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                label={index === 0 ? "Options" : ""}
                                required={false}
                                key={field.key}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={["onChange", "onBlur"]}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please input an option",
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input placeholder="" style={{ width: "95%" }} autoComplete="off" />
                                </Form.Item>
                                {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                        style={{ marginLeft: 10 }}
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item {...formItemLayoutWithOutLabel}>
                            <Form.ErrorList errors={errors} />
                            <Button type="dashed" onClick={() => add()} style={{ width: "100%" }} icon={<PlusOutlined />}>
                                Add option
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </div>
    );
};

export default AttrOptionsCreateEdit;
