import DataCenterSettingsTable from "./DataCenterSettingsTable";

const DataCenterSettings = () => {
    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Data Center Settings</span>
                </div>
            </div>
            <DataCenterSettingsTable />
        </>
    );
};

export default DataCenterSettings;
