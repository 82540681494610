import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
const useRCRejectOrders = () => {
    // const token = getToken();
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcRejectOrders"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_REJECT_ORDER.GET_REJECT_ORDERS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        refetchInterval: 10 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcRejectOrders = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcRejectOrdersError = data?.data?.message || error?.message || error || null;

    return {
        rcRejectOrders,
        rcRejectOrdersError,
        refetchRcRejectOrders: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

export const useRCRejectOrderSolved = () => {
    // const token = getToken();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, error } = useMutation(
        async ({ server }: { server: string }): Promise<any> => {
            // Add return type annotation
            if (!server) {
                return Promise.reject("server name shouldn't be empty");
            }
            return plainAxiosInstance.put(
                `${APIs.RC_REJECT_ORDER.PUT_REJECT_ORDER_SOLVED}/${server}`,
                {},
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcRejectOrders"]);
                // console.log(`Solving reject orders response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcRejectOrders"]);
                console.error(`Error on solving reject orders:`, error);
                return error;
            },
        }
    );

    return {
        rejectOrderSolved: mutateAsync,
        isLoading,
        error,
    };
};

export default useRCRejectOrders;
