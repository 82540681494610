import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "../../../constants";
import { ServersList, FlexiDataColumnProps, CustomPaginationProps, FlexiDataTableCallbackProps, HightlightExpression } from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import {
    DTColProps,
    DataTableColumnRender,
    ErrorCatchValidator,
    ErrorMessageHandler,
    currencyRender,
    scientificToDecimal,
} from "../../../utils/Common";
import { getNavigationUrl, ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileId } from "../../Analysis/AccountProfileHelper";
import { Col, Form, Modal, Row, message } from "antd";
import { REQUIRED_FIELD, SOMETHING_WENT_WRONG } from "../../../constants/errorMessage";
import { FormComponent } from "../../../components/FormComponent";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import TinyAreaChart from "../../../components/Charts/SubComponents/TinyArea";
import { findObjectKeys } from "../../../utils/object";

interface AccountViewProps {
    servers: ServersList[];
    currentViewType: number;
    onModalCallback: (data: any) => void;
    onViewTypeChanged: (data: number, selectAccountInfo: any) => void;
}

export const exportInitialValues = {
    exportType: "",
    modalAccountId: undefined,
    modalIsGoldSymbolOnly: true,
    modalServerId: undefined,
    date: [],
};

const initialFilterValues = {
    maxDpmFrom: 200,
    maxProfitFrom: 100,
    dpmSlopeFrom: 0.4,
    dpmRSquaredFrom: 0.85,
    dpmRSquaredTo: 1,
    decayScoreFrom: 0,
    decayScoreTo: 100,
};

const flexiTableInitialFilterValues = {
    maxDpmIn20Min: [200, 1000],
    maxProfitIn20Min: [100, 1000],
    dpmSlopeIn20Min: [0.4, 2],
    dpmRSquaredIn20Min: [0.85, 1],
    decayScore: [0, 100],
};

const AccountView = (props: AccountViewProps) => {
    const getColumnsConfig = (servers: ServersList[] = []) => {
        const hightExpression: HightlightExpression = (value: number) => value > 0;
        const highlightFunc: any = (value: number, decimalPoint: number, hExpression: Function) => {
            return hExpression !== undefined ? (
                <span className={hExpression(value) ? "highlight" : ""}>
                    {value && value !== 0 ? currencyRender(scientificToDecimal(value), decimalPoint) : value}
                </span>
            ) : value && value !== 0 ? (
                currencyRender(scientificToDecimal(value), decimalPoint)
            ) : (
                value
            );
        };
        const getDPMColumnConfig: any = (columnText: string) => ({
            className: "decay-column-style",
            render: (text: number, rowData: any) => {
                return (
                    <div className="decay-column-container">
                        {highlightFunc(rowData[`dpm${columnText}`], -1, hightExpression)}
                        {highlightFunc(rowData[`profit${columnText}`], -1, hightExpression)}
                    </div>
                );
            },
        });
        return [
            DTColProps.Small({
                title: "Account ID",
                dataIndex: "accountId",
                key: "accountId",
                fixed: "left",
                width: "4.5vw",
                render: (accountId: number, rowData: any) => {
                    return (
                        <Link
                            to={getNavigationUrl(
                                ACCOUNT_PROFILE_FROM_MODULE.DECAY_ANALYSIS,
                                getAccountProfileId(rowData["serverId"], accountId, ACCOUNT_PROFILE_FROM_MODULE.DECAY_ANALYSIS, "", "", "", "", "")
                            )}
                            target="_blank"
                            style={{ color: "#0e65cc" }}
                        >
                            {accountId}
                        </Link>
                    );
                },
                options: {
                    filter: {
                        type: ComponentType.number,
                    },
                },
            }),
            DTColProps.Small({
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
                fixed: "left",
                width: "4.5vw",
                render: (serverId: number) => {
                    let fidx: number = servers.findIndex(x => x.id === serverId);
                    if (fidx > -1) return servers[fidx].server;
                    return serverId;
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers.map(x => ({ text: x.server, value: x.id })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            }),
            {
                title: "Show Gold Symbol Only",
                dataIndex: "isGoldSymbolOnly",
                key: "isGoldSymbolOnly",
                width: "4.8vw",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.switch,
                        value: ["No", "Yes"],
                    },
                },
            },
            DTColProps.Small(
                {
                    title: "Today Opened Trade Count",
                    dataIndex: "todayOpenedTradeCount",
                    key: "todayOpenedTradeCount",
                    sorter: true,
                    width: "5vw",
                },
                ["text-right"]
            ),
            DTColProps.Small(
                {
                    title: "Today Closed P&L",
                    dataIndex: "todayClosedPnl",
                    key: "todayClosedPnl",
                    sorter: true,
                    width: "5vw",
                },
                ["text-right"]
            ),
            DTColProps.Small(
                {
                    title: "Max DPM",
                    dataIndex: "maxDpmIn20Min",
                    key: "maxDpmIn20Min",
                    sorter: true,
                    width: "5vw",
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                min: -1000,
                                max: 1000,
                                tooltip: {
                                    formatter: (value: number) => {
                                        if (value === 1000) {
                                            return `${value} and above`;
                                        } else if (value === -1000) {
                                            return `${value} and lower`;
                                        } else return `${value}`;
                                    },
                                },
                            },
                        },
                    },
                },
                ["text-right"]
            ),
            DTColProps.Small(
                {
                    title: "Max Profit",
                    dataIndex: "maxProfitIn20Min",
                    key: "maxProfitIn20Min",
                    sorter: true,
                    width: "5vw",
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                min: -1000,
                                max: 1000,
                                tooltip: {
                                    formatter: (value: number) => {
                                        if (value === 1000) {
                                            return `${value} and above`;
                                        } else if (value === -1000) {
                                            return `${value} and lower`;
                                        } else return `${value}`;
                                    },
                                },
                            },
                        },
                    },
                },
                ["text-right"]
            ),
            DTColProps.SCurrency(
                {
                    title: "DPM Slope",
                    dataIndex: "dpmSlopeIn20Min",
                    key: "dpmSlopeIn20Min",
                    sorter: true,
                    width: "5vw",
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                min: -2,
                                max: 2,
                                step: 0.01,
                                tooltip: {
                                    formatter: (value: number) => {
                                        if (value === 2) {
                                            return `${value} and above`;
                                        } else if (value === -2) {
                                            return `${value} and lower`;
                                        } else return `${value}`;
                                    },
                                },
                            },
                        },
                    },
                },
                ["text-right"],
                4
            ),
            DTColProps.SCurrency(
                {
                    title: "DPM R-Squared",
                    dataIndex: "dpmRSquaredIn20Min",
                    key: "dpmRSquaredIn20Min",
                    sorter: true,
                    width: "5vw",
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                min: 0,
                                max: 1,
                                step: 0.01,
                            },
                        },
                    },
                },
                ["text-right"],
                4
            ),
            DTColProps.Small(
                {
                    title: "Decay Score",
                    dataIndex: "decayScore",
                    key: "decayScore",
                    sorter: true,
                    width: "5vw",
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                min: 0,
                                max: 100,
                            },
                        },
                    },
                },
                ["text-right"]
            ),
            // {
            //     title: "HFT Count",
            //     children: [
            //         DTColProps.SCurrency(
            //             {
            //                 title: "< 2min",
            //                 dataIndex: "todayHftCountLt2Min",
            //                 key: "todayHftCountLt2Min",
            //                 sorter: true,
            //                 width: "4.5vw",
            //             },
            //             [],
            //             -1
            //         ),
            //         DTColProps.SCurrency(
            //             {
            //                 title: "2 - 10min",
            //                 dataIndex: "todayHftCountBtw2And10Min",
            //                 key: "todayHftCountBtw2And10Min",
            //                 sorter: true,
            //                 width: "5.5vw",
            //             },
            //             [],
            //             -1
            //         ),
            //     ],
            // },
            // {
            //     title: "HFT P&L",
            //     children: [
            //         DTColProps.SCurrency(
            //             {
            //                 title: "< 2min",
            //                 dataIndex: "todayHftPnlLt2Min",
            //                 key: "todayHftPnlLt2Min",
            //                 sorter: true,
            //                 width: "4.5vw",
            //             },
            //             [],
            //             -1,
            //             hightExpression
            //         ),
            //         DTColProps.SCurrency(
            //             {
            //                 title: "2 - 10min",
            //                 dataIndex: "todayHftPnlBtw2And10Min",
            //                 key: "todayHftPnlBtw2And10Min",
            //                 sorter: true,
            //                 width: "5.5vw",
            //             },
            //             [],
            //             -1,
            //             hightExpression
            //         ),
            //     ],
            // },
            {
                title: "DPM",
                children: [
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-30s",
                    //         dataIndex: "dpmN30",
                    //         key: "dpmN30",
                    //         sorter: true,
                    //         width: "3.5vw",
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-10s",
                    //         dataIndex: "dpmN10",
                    //         key: "dpmN10",
                    //         sorter: true,
                    //         width: "3.5vw",
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-5s",
                    //         dataIndex: "dpmN5",
                    //         key: "dpmN5",
                    //         sorter: true,
                    //         width: "3.5vw",
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-1s",
                    //         dataIndex: "dpmN1",
                    //         key: "dpmN1",
                    //         sorter: true,
                    //         width: "3.5vw",
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "0s",
                    //         dataIndex: "dpm0",
                    //         key: "dpm0",
                    //         sorter: true,
                    //         width: "3.5vw",
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "1s",
                    //         dataIndex: "dpm1",
                    //         key: "dpm1",
                    //         sorter: true,
                    //         width: "4vw",
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "5s",
                    //         dataIndex: "dpm5",
                    //         key: "dpm5",
                    //         sorter: true,
                    //         width: "4vw",
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "10s",
                    //         dataIndex: "dpm10",
                    //         key: "dpm10",
                    //         sorter: true,
                    //         width: "4vw",
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "30s",
                    //         dataIndex: "dpm30",
                    //         key: "dpm30",
                    //         sorter: true,
                    //         width: "4vw",
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    DTColProps.SCurrency(
                        {
                            title: "1min",
                            dataIndex: "dpm60",
                            key: "dpm60",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "2min",
                            dataIndex: "dpm120",
                            key: "dpm120",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "3min",
                            dataIndex: "dpm180",
                            key: "dpm180",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "4min",
                            dataIndex: "dpm240",
                            key: "dpm240",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "5min",
                            dataIndex: "dpm300",
                            key: "dpm300",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "6min",
                            dataIndex: "dpm360",
                            key: "dpm360",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "7min",
                            dataIndex: "dpm420",
                            key: "dpm420",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "8min",
                            dataIndex: "dpm480",
                            key: "dpm480",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "9min",
                            dataIndex: "dpm540",
                            key: "dpm540",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "10min",
                            dataIndex: "dpm600",
                            key: "dpm600",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "11min",
                            dataIndex: "dpm660",
                            key: "dpm660",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "12min",
                            dataIndex: "dpm720",
                            key: "dpm720",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "13min",
                            dataIndex: "dpm780",
                            key: "dpm780",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "14min",
                            dataIndex: "dpm840",
                            key: "dpm840",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "15min",
                            dataIndex: "dpm900",
                            key: "dpm900",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "16min",
                            dataIndex: "dpm960",
                            key: "dpm960",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "17min",
                            dataIndex: "dpm1020",
                            key: "dpm1020",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "18min",
                            dataIndex: "dpm1080",
                            key: "dpm1080",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "19min",
                            dataIndex: "dpm1140",
                            key: "dpm1140",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "20min",
                            dataIndex: "dpm1200",
                            key: "dpm1200",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                ],
            },
            {
                title: "Profit",
                children: [
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-30s",
                    //         dataIndex: "profitN30",
                    //         key: "profitN30",
                    //         width: "5.5vw",
                    //         sorter: true,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-10s",
                    //         dataIndex: "profitN10",
                    //         key: "profitN10",
                    //         width: "5.5vw",
                    //         sorter: true,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-5s",
                    //         dataIndex: "profitN5",
                    //         key: "profitN5",
                    //         width: "5.5vw",
                    //         sorter: true,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "-1s",
                    //         dataIndex: "profitN1",
                    //         key: "profitN1",
                    //         width: "5.5vw",
                    //         sorter: true,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "0s",
                    //         dataIndex: "profit0",
                    //         key: "profit0",
                    //         width: "5.5vw",
                    //         sorter: true,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "1s",
                    //         dataIndex: "profit1",
                    //         key: "profit1",
                    //         width: "5.5vw",
                    //         sorter: true,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "5s",
                    //         dataIndex: "profit5",
                    //         key: "profit5",
                    //         width: "5.5vw",
                    //         sorter: true,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "10s",
                    //         dataIndex: "profit10",
                    //         key: "profit10",
                    //         width: "5.5vw",
                    //         sorter: true,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    // DTColProps.SCurrency(
                    //     {
                    //         title: "30s",
                    //         dataIndex: "profit30",
                    //         key: "profit30",
                    //         width: "5.5vw",
                    //         sorter: true,
                    //     },
                    //     [],
                    //     -1,
                    //     hightExpression
                    // ),
                    DTColProps.SCurrency(
                        {
                            title: "1min",
                            dataIndex: "profit60",
                            key: "profit60",
                            width: "4vw",
                            sorter: true,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "2min",
                            dataIndex: "profit120",
                            key: "profit120",
                            width: "4vw",
                            sorter: true,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "3min",
                            dataIndex: "profit180",
                            key: "profit180",
                            width: "4vw",
                            sorter: true,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "4min",
                            dataIndex: "profit240",
                            key: "profit240",
                            width: "4vw",
                            sorter: true,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "5min",
                            dataIndex: "profit300",
                            key: "profit300",
                            width: "4vw",
                            sorter: true,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "6min",
                            dataIndex: "profit360",
                            key: "profit360",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "7min",
                            dataIndex: "profit420",
                            key: "profit420",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "8min",
                            dataIndex: "profit480",
                            key: "profit480",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "9min",
                            dataIndex: "profit540",
                            key: "profit540",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "10min",
                            dataIndex: "profit600",
                            key: "profit600",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "11min",
                            dataIndex: "profit660",
                            key: "profit660",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "12min",
                            dataIndex: "profit720",
                            key: "profit720",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "13min",
                            dataIndex: "profit780",
                            key: "profit780",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "14min",
                            dataIndex: "profit840",
                            key: "profit840",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "15min",
                            dataIndex: "profit900",
                            key: "profit900",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "16min",
                            dataIndex: "profit960",
                            key: "profit960",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "17min",
                            dataIndex: "profit1020",
                            key: "profit1020",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "18min",
                            dataIndex: "profit1080",
                            key: "profit1080",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "19min",
                            dataIndex: "profit1140",
                            key: "profit1140",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "20min",
                            dataIndex: "profit1200",
                            key: "profit1200",
                            sorter: true,
                            width: "4vw",
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                ],
            },
            DTColProps.DateTime_ServerTime({
                title: "Last Trade Opened Time (Server)",
                dataIndex: "lastTradeOpenedTime",
                key: "lastTradeOpenedTime",
                sorter: true,
                render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss.SSS") : ""),
            }),
            DTColProps.Middle({
                title: "Trend",
                dataIndex: "timeSeriesStats",
                key: "timeSeriesStats",
                fixed: "right",
                render: (timeSeries: number[]) => {
                    if (timeSeries.length < 1) return <span></span>;
                    let checkColorIdx = timeSeries.length - 1;
                    for (; checkColorIdx > 0; checkColorIdx--) {
                        if (timeSeries[checkColorIdx] !== null && timeSeries[checkColorIdx] !== undefined) break;
                    }
                    return (
                        <TinyAreaChart
                            data={timeSeries}
                            config={
                                timeSeries[checkColorIdx] >= 0
                                    ? {
                                          width: 220,
                                          color: "#9bdab5",
                                          line: {
                                              color: "#009F42",
                                          },
                                      }
                                    : {
                                          width: 220,
                                          color: "#ec9892",
                                          line: {
                                              color: "#f00f00",
                                          },
                                      }
                            }
                        />
                    );
                },
            }),
        ];
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>([...getColumnsConfig(props.servers)]);
    const [data, setData] = useState<any[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 50,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({ isGoldSymbolOnly: true, ...initialFilterValues });
    const [sorting, setSorting] = useState<string>("");
    const [refreshRate, setRefreshRate] = useState<number>(30);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
    const [exportForm] = Form.useForm();
    const eType = Form.useWatch("exportType", exportForm);
    const [archiveDate, setArchiveDate] = useState<string>("");
    const [isGold, setIsGold] = useState<boolean>(true);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                props.onViewTypeChanged(2, {
                    accountId: FormData.accountId,
                    serverId: FormData.serverId,
                    isGoldSymbolOnly: filterParams.isGoldSymbolOnly,
                });
                break;
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let tmpFilterParams: any = {};
                Object.keys(FormData)
                    .filter(x =>
                        typeof FormData[x] !== "boolean"
                            ? FormData[x] && (Array.isArray(FormData[x]) ? FormData[x].length > 0 : FormData[x].toString().length > 0)
                            : true
                    )
                    .map(x => {
                        if (x === "serverId") {
                            tmpFilterParams["serverIds"] = FormData[x];
                        } else if (x === "accountId") {
                            tmpFilterParams["accountIds"] = [parseInt(FormData[x])];
                        } else if (x === "maxDpmIn20Min") {
                            try {
                                if (FormData[x][0] > -1000) {
                                    tmpFilterParams.maxDpmFrom = FormData[x][0];
                                } else {
                                    delete tmpFilterParams.maxDpmFrom;
                                }
                                if (FormData[x][1] < 1000) {
                                    tmpFilterParams.maxDpmTo = FormData[x][1];
                                } else {
                                    delete tmpFilterParams.maxDpmTo;
                                }
                            } catch (error) {
                                delete tmpFilterParams.maxDpmFrom;
                                delete tmpFilterParams.maxDpmTo;
                            }
                        } else if (x === "maxProfitIn20Min") {
                            try {
                                if (FormData[x][0] > -1000) {
                                    tmpFilterParams.maxProfitFrom = FormData[x][0];
                                } else {
                                    delete tmpFilterParams.maxProfitFrom;
                                }
                                if (FormData[x][1] < 1000) {
                                    tmpFilterParams.maxProfitTo = FormData[x][1];
                                } else {
                                    delete tmpFilterParams.maxProfitTo;
                                }
                            } catch (error) {
                                delete tmpFilterParams.maxProfitFrom;
                                delete tmpFilterParams.maxProfitTo;
                            }
                        } else if (x === "dpmSlopeIn20Min") {
                            try {
                                if (FormData[x][0] > -2) {
                                    tmpFilterParams.dpmSlopeFrom = FormData[x][0];
                                } else {
                                    delete tmpFilterParams.dpmSlopeFrom;
                                }
                                if (FormData[x][1] < 2) {
                                    tmpFilterParams.dpmSlopeTo = FormData[x][1];
                                } else {
                                    delete tmpFilterParams.dpmSlopeTo;
                                }
                            } catch (error) {
                                delete tmpFilterParams.dpmSlopeFrom;
                                delete tmpFilterParams.dpmSlopeTo;
                            }
                        } else if (x === "dpmRSquaredIn20Min") {
                            tmpFilterParams.dpmRSquaredFrom = FormData[x][0];
                            tmpFilterParams.dpmRSquaredTo = FormData[x][1];
                        } else if (x === "decayScore") {
                            tmpFilterParams.decayScoreFrom = FormData[x][0];
                            tmpFilterParams.decayScoreTo = FormData[x][1];
                        } else if (x === "isGoldSymbolOnly") {
                            if (FormData[x]) {
                                setIsGold(true);
                                tmpFilterParams.isGoldSymbolOnly = FormData[x];
                            } else {
                                setIsGold(false);
                                tmpFilterParams.isGoldSymbolOnly = false;
                            }
                        } else {
                            tmpFilterParams[x] = FormData[x];
                        }
                        return x;
                    });

                if (findObjectKeys(FormData, ["isGoldSymbolOnly"])) {
                    if (FormData["isGoldSymbolOnly"] === false) {
                        delete tmpFilterParams.maxDpmFrom;
                        delete tmpFilterParams.maxDpmTo;
                        delete tmpFilterParams.maxProfitFrom;
                        delete tmpFilterParams.maxProfitTo;
                        delete tmpFilterParams.dpmSlopeFrom;
                        delete tmpFilterParams.dpmSlopeTo;
                        delete tmpFilterParams.dpmRSquaredFrom;
                        delete tmpFilterParams.dpmRSquaredTo;
                        delete tmpFilterParams.decayScoreFrom;
                        delete tmpFilterParams.decayScoreTo;

                        delete FormData["maxDpmIn20Min"];
                        delete FormData["maxProfitIn20Min"];
                        delete FormData["dpmSlopeIn20Min"];
                        delete FormData["dpmRSquaredIn20Min"];
                        delete FormData["decayScore"];
                    }
                }

                setFilterParams(tmpFilterParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.REFRESH_RATE_CHANGED:
                setRefreshRate(FormData.currentRefreshPeriod);
                break;
            case CALLBACK_KEY.REFRESH:
                getAccountList();
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                setIsExportModalOpen(true);
                break;
        }
    };

    const getAccountList = () => {
        apiRequest(APIs.GET_DECAY_CHART_ACCOUNT_LIST, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    let markupData = data.result.map((x: any) => ({
                        ...x,
                        Id: `${x.accountId}-${x.serverId}`,
                        timeSeriesStats: [
                            x.dpm60,
                            x.dpm120,
                            x.dpm180,
                            x.dpm240,
                            x.dpm300,
                            x.dpm360,
                            x.dpm420,
                            x.dpm480,
                            x.dpm540,
                            x.dpm600,
                            x.dpm660,
                            x.dpm720,
                            x.dpm780,
                            x.dpm840,
                            x.dpm900,
                            x.dpm960,
                            x.dpm1020,
                            x.dpm1080,
                            x.dpm1140,
                            x.dpm1200,
                        ],
                    }));
                    setData(markupData);
                    setPagination(prev => ({ ...prev, total: data.total }));
                    setArchiveDate(data.firstArchiveDate);
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("decay analysis", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                setData(prev => [...prev]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getAccountList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        setTableCols(getColumnsConfig(props.servers));
        return () => {};
    }, [props.servers]);

    const disabledDate: RangePickerProps["disabledDate"] = current => {
        return current > moment().subtract(1, "day").endOf("day") || current < moment(archiveDate);
    };

    const exportResponseHandler = (res: any) => {
        const fileName = res.headers["x-filename"];
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/zip" })); // Create blob link to download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // or any other extension
        document.body.appendChild(link); // Append to html link element page
        link.click(); // start download
        document.body.removeChild(link); // Clean up and remove the link
    };

    useEffect(() => {
        if (!isGold) {
            let tempTableCols = [...getColumnsConfig(props.servers)];
            tempTableCols.splice(
                5,
                5,
                DTColProps.Small({
                    title: "Max DPM",
                    dataIndex: "maxDpmIn20Min",
                    key: "maxDpmIn20Min",
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                min: -1000,
                                max: 1000,
                            },
                        },
                        visible: false,
                    },
                }),
                DTColProps.Small({
                    title: "Max Profit",
                    dataIndex: "maxProfitIn20Min",
                    key: "maxProfitIn20Min",
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                min: -1000,
                                max: 1000,
                            },
                        },
                        visible: false,
                    },
                }),
                DTColProps.SCurrency({
                    title: "DPM Slope",
                    dataIndex: "dpmSlopeIn20Min",
                    key: "dpmSlopeIn20Min",
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                min: -2,
                                max: 2,
                                step: 0.01,
                            },
                        },
                        visible: false,
                    },
                }),
                DTColProps.SCurrency({
                    title: "DPM R-Squared",
                    dataIndex: "dpmRSquaredIn20Min",
                    key: "dpmRSquaredIn20Min",
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                min: 0,
                                max: 1,
                                step: 0.01,
                            },
                        },
                        visible: false,
                    },
                }),
                DTColProps.Small({
                    title: "Decay Score",
                    dataIndex: "decayScore",
                    key: "decayScore",
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                min: 0,
                                max: 100,
                            },
                        },
                        visible: false,
                    },
                })
            );
            setTableCols(tempTableCols);
        } else {
            setTableCols(getColumnsConfig(props.servers));
        }
        return () => {};
    }, [isGold]);

    return (
        <>
            <div style={props.currentViewType === 2 ? { display: "none" } : {}} className="decay-chart-account-view">
                <FlexiDataTable
                    size="small"
                    rowKeyProperty="Id"
                    title=""
                    columns={tableCols}
                    options={{
                        rowClassName: ["tiny-row"],
                        view: true,
                        serverFiltering: true,
                        refresh: {
                            timer: true,
                            refreshSecond: refreshRate,
                            enablePeriodSelection: true,
                        },
                        export: { text: "Export as CSV" },
                    }}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                    tableProps={{
                        bordered: true,
                    }}
                    filterInitialValue={{ isGoldSymbolOnly: true, ...flexiTableInitialFilterValues }}
                />
                <Modal
                    title="Export As CSV"
                    width={600}
                    open={isExportModalOpen}
                    destroyOnClose
                    okButtonProps={{ loading: isBtnLoading }}
                    onCancel={() => {
                        setIsExportModalOpen(false);
                        exportForm.resetFields();
                    }}
                    onOk={() => {
                        exportForm.validateFields().then((values: any) => {
                            setIsBtnLoading(true);
                            if (eType === "today") {
                                apiRequest(
                                    APIs.DOWNLOAD_DECAY_CHART_ACCOUNT_LIST,
                                    {
                                        fileType: 0,
                                        serverIds: values.modalServerId,
                                        isGoldSymbolOnly: values.modalIsGoldSymbolOnly,
                                        ...(values.modalAccountId && { accountIds: [parseInt(values.modalAccountId)] }),
                                        ...(sorting.length > 0 && { order: sorting }),
                                    },
                                    "POST",
                                    "arraybuffer"
                                )
                                    .then((res: any) => exportResponseHandler(res))
                                    .catch((error: any) =>
                                        ErrorCatchValidator(error, (err: any) =>
                                            ErrorMessageHandler(SOMETHING_WENT_WRONG, SUCCESS_FAILED.OTHERS_FAILED)
                                        )
                                    )
                                    .finally(() => {
                                        setIsBtnLoading(false);
                                        setIsExportModalOpen(false);
                                        exportForm.resetFields();
                                    });
                            }
                            if (eType === "historical") {
                                apiRequest(
                                    APIs.DOWNLOAD_DECAY_CHART_ACCOUNT_LIST,
                                    {
                                        fileType: 0,
                                        serverIds: values.modalServerId,
                                        isGoldSymbolOnly: values.modalIsGoldSymbolOnly,
                                        dateFrom: moment(values.date[0]).format("YYYY-MM-DD"),
                                        dateTo: moment(values.date[1]).format("YYYY-MM-DD"),
                                        ...(values.modalAccountId && { accountIds: [parseInt(values.modalAccountId)] }),
                                        ...(sorting.length > 0 && { order: sorting }),
                                    },
                                    "POST",
                                    "arraybuffer"
                                )
                                    .then((res: any) => exportResponseHandler(res))
                                    .catch((error: any) =>
                                        ErrorCatchValidator(error, (err: any) => {
                                            if (err.message.includes("404")) message.warning("No records found within selected date(s).", 3);
                                            else ErrorMessageHandler(SOMETHING_WENT_WRONG, SUCCESS_FAILED.OTHERS_FAILED);
                                        })
                                    )
                                    .finally(() => {
                                        setIsBtnLoading(false);
                                        setIsExportModalOpen(false);
                                        exportForm.resetFields();
                                    });
                            }
                        });
                    }}
                >
                    <Form form={exportForm} labelCol={{ span: 9 }} wrapperCol={{ span: 13 }} layout="horizontal" initialValues={exportInitialValues}>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Account ID"
                                    name={"modalAccountId"}
                                    extra={{
                                        type: ComponentType.number,
                                        value: "",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Server"
                                    name={"modalServerId"}
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: props.servers.map(x => ({ text: x.server, value: x.id })),
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Show Gold Symbol Only"
                                    name={"modalIsGoldSymbolOnly"}
                                    extra={{
                                        type: ComponentType.switch,
                                        value: ["No", "Yes"],
                                        rules: [
                                            {
                                                required: true,
                                                message: REQUIRED_FIELD,
                                            },
                                        ],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Export Type"
                                    name={"exportType"}
                                    extra={{
                                        type: ComponentType.radio,
                                        value: [
                                            { text: "Today", value: "today" },
                                            { text: "Historical", value: "historical" },
                                        ],
                                        rules: [
                                            {
                                                required: true,
                                                message: REQUIRED_FIELD,
                                            },
                                        ],
                                    }}
                                />
                            </Col>
                            {eType === "historical" && (
                                <>
                                    <Row>
                                        <Col span={24} offset={9}>
                                            <FormComponent
                                                label=""
                                                name={"date"}
                                                extra={{
                                                    type: ComponentType.daterange,
                                                    value: "",
                                                    dateFormat: "YYYY-MM-DD",
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: REQUIRED_FIELD,
                                                        },
                                                    ],
                                                    inputProps: {
                                                        style: { width: "100%" },
                                                        disabledDate: disabledDate,
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Row>
                    </Form>
                </Modal>
            </div>
        </>
    );
};

export default AccountView;
