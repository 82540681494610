import BasicFilter from "@/components/BasicFilter";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CustomPaginationProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Row } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { SyndicateTradingTableFilterParamsProps } from "../type";
import syndicateTradingAudio from "@/assets/audios/rc/syndicate-trading-audio.mp3";
import SoundButton from "@/pages/SystemMonitor/components/SoundButton";

const currentDateTime: SyndicateTradingTableFilterParamsProps = {
    startDate: moment().format("YYYY-MM-DD HH:mm"),
    endDate: moment().format("YYYY-MM-DD HH:mm"),
};

const SyndicateTradingTable = () => {
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [filterForm] = Form.useForm();
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [defaultDates, setDefaultDates] = useState<SyndicateTradingTableFilterParamsProps>(currentDateTime);

    const colsProps = {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        style: { marginBottom: "1.414vh" },
    };

    const columns: any[] = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            fixed: "left",
            sorter: (a: any, b: any) => a.server.localeCompare(b.server),
        }),
        DTColProps.XSmall({
            title: "Batch Number",
            dataIndex: "batchNum",
            key: "batchNum",
            sorter: (a: any, b: any) => a.batchNum - b.batchNum,
        }),
        DTColProps.XSmall({
            title: "Ticket",
            dataIndex: "ticket",
            key: "ticket",
            sorter: (a: any, b: any) => a.ticket - b.ticket,
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
            sorter: (a: any, b: any) => a.login - b.login,
        }),
        DTColProps.Small({
            title: "Group",
            dataIndex: "group",
            key: "group",
            sorter: (a: any, b: any) => a.group.localeCompare(b.group),
        }),
        DTColProps.Small({
            title: "Open Time",
            dataIndex: "openTimeStr",
            key: "openTimeStr",
            sorter: (a: any, b: any) => a.openTimeStr.localeCompare(b.openTimeStr),
        }),
        DTColProps.XXSmall({
            title: "Side",
            dataIndex: "cmd",
            key: "cmd",
            sorter: (a: any, b: any) => a.cmd - b.cmd,
            render: (cmd: number) => (cmd === 0 ? "Buy" : cmd === 1 ? "Sell" : cmd),
        }),
        DTColProps.XSmall({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
        }),
        DTColProps.XSmall({
            title: "Lots",
            dataIndex: "volume",
            key: "volume",
            sorter: (a: any, b: any) => a.volume - b.volume,
        }),
        DTColProps.XSmall({
            title: "Batch Lots",
            dataIndex: "sumVolume",
            key: "sumVolume",
            sorter: (a: any, b: any) => a.sumVolume - b.sumVolume,
        }),
        DTColProps.XSmall({
            title: "Open Price",
            dataIndex: "openPrice",
            key: "openPrice",
            sorter: (a: any, b: any) => a.openPrice - b.openPrice,
            render: (openPrice: string) => parseFloat(openPrice).toFixed(2),
        }),
        DTColProps.XSmall({
            title: "Exposure",
            dataIndex: "exposure",
            key: "exposure",
            sorter: (a: any, b: any) => a.exposure - b.exposure,
            render: (exposure: string) => parseFloat(exposure).toFixed(2),
        }),
        DTColProps.Small({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            sorter: (a: any, b: any) => a.comment.localeCompare(b.comment),
        }),
        DTColProps.Small({
            title: "Change Reason",
            dataIndex: "changeReason",
            key: "changeReason",
            sorter: (a: any, b: any) => {
                if (!a.changeReason && !b.changeReason) return 0;
                if (!a.changeReason) return 1;
                if (!b.changeReason) return -1;
                return a.changeReason.localeCompare(b.changeReason);
            },
        }),
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                if (FormData.pagination.current !== pagination.current || FormData.pagination.pageSize !== pagination.pageSize) {
                    setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                    setRunRefetchDataList(true);
                }
                break;
            default:
                break;
        }
    };

    const onFinished = (values: any) => {
        let tmpFP = {
            startDate: moment(values.updateTimeStr[0]).format("YYYY-MM-DD HH:mm"),
            endDate: moment(values.updateTimeStr[1]).format("YYYY-MM-DD HH:mm"),
        };

        filterForm
            .validateFields()
            .then(res => {
                setIsExporting(true);
                plainAxiosInstance
                    .get(`${APIs.RC_SYNDICATE_TRADING.GET_SYNDICATE_TRADING_REPORT_DOWNLOAD}?startTime=${tmpFP.startDate}&endTime=${tmpFP.endDate}`, {
                        headers: {
                            Accept: "application/octet-stream, */*",
                        },
                        responseType: "blob",
                    })
                    .then(res => {
                        const fileName = `trading_together-${moment().format("YYYYMMDDHHmmss")}.xlsx`;
                        const contentType = res.headers["content-type"];
                        if (
                            contentType === "application/octet-stream" ||
                            contentType === "text/csv" ||
                            contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ) {
                            // Handle the file download response
                            const url = window.URL.createObjectURL(new Blob([res.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `${fileName}`); // or any other extension
                            link.setAttribute("type", "hidden");
                            document.body.appendChild(link);
                            link.click();
                            if (link.parentNode) {
                                link.parentNode.removeChild(link); // Clean up and remove the link
                            } else document.body.removeChild(link);
                            // Clean up
                            window.URL.revokeObjectURL(url);
                        } else {
                            ErrorMessageHandler(`Received non-file response. Error: ${res}`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    })
                    .catch(err => {
                        ErrorMessageHandler(`Download error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
                    })
                    .finally(() => setIsExporting(false));
            })
            .catch(err => {
                ErrorMessageHandler(`Failed to download record. Please check the filter for errors.`, SUCCESS_FAILED.OTHERS_FAILED);
            });
    };

    const getData = () => {
        let current = pagination.current === undefined ? 1 : pagination.current;
        let pageSize = pagination.pageSize === undefined ? 10 : pagination.pageSize;

        setIsLoading(true);
        plainAxiosInstance
            .post(`${APIs.RC_SYNDICATE_TRADING.POST_SYNDICATE_TRADING_DATA}`, {
                limit: pageSize,
                offset: (current - 1) * pageSize,
            })
            .then(res => {
                const data: any = res.data;
                if (res.status === 200) {
                    setData(data.rows);
                    setPagination(prev => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler(`Failed to get syndicate trading data`, SUCCESS_FAILED.OTHERS_FAILED, err)
                );
                setData([]);
                setPagination(prev => ({ ...prev, total: 0 }));
            })
            .finally(() => setIsLoading(false));
    };

    const getDownloadTime = () => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_SYNDICATE_TRADING.GET_DOWNLOAD_TIME}`)
            .then(res => {
                const data: any = res.data;
                if (res.status === 200) {
                    filterForm.setFieldValue("updateTimeStr", [moment(data.startTime), moment(data.endTime)]);
                    setDefaultDates({
                        startDate: moment(data.startTime).format("YYYY-MM-DD HH:mm"),
                        endDate: moment(data.endTime).format("YYYY-MM-DD HH:mm"),
                    });
                } else {
                    filterForm.setFieldValue("updateTimeStr", [moment(), moment()]);
                    setDefaultDates(currentDateTime);
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Failed to get download time`, SUCCESS_FAILED.OTHERS_FAILED, err))
            )
            .finally(() => setIsExporting(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getData();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        getDownloadTime();
        return () => {};
    }, []);

    useEffect(() => {
        getData();

        const timer = setInterval(() => {
            getData();
        }, 30000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const playAudio: boolean = useMemo(
        () => data.some((item: any) => item.isNewData === 1) || data.some((item: any) => item.exposure >= 50 * 100000),
        [data]
    );

    return (
        <div className="syndicate-trading-collapse-item-div">
            <BasicFilter
                titleBarChildren={
                    <div key={"alarm-btn"}>
                        <SoundButton soundSrc={syndicateTradingAudio} isActive={playAudio} />
                    </div>
                }
            >
                <Form form={filterForm} layout="inline" onFinish={onFinished} initialValues={{ defaultDates }}>
                    <Row gutter={24} style={{ width: "100%" }}>
                        <Col {...colsProps}>
                            <FormComponent
                                label=""
                                name="updateTimeStr"
                                extra={{
                                    type: ComponentType.daterange,
                                    value: [],
                                    rules: [{ required: true, message: `${REQUIRED_FIELD} in order to download record` }],
                                    inputProps: {
                                        showTime: { format: "HH:mm" },
                                        format: "YYYY-MM-DD HH:mm",
                                        ranges: {
                                            "Current Default": [
                                                moment(defaultDates.startDate, "YYYY-MM-DD HH:mm"),
                                                moment(defaultDates.endDate, "YYYY-MM-DD HH:mm"),
                                            ],
                                        },
                                    },
                                }}
                            />
                        </Col>
                        <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} loading={isExporting}>
                            Download
                        </Button>
                    </Row>
                </Form>
            </BasicFilter>
            <FlexiDataTable
                bordered
                rowKeyProperty="batchNum"
                title={false}
                columns={columns}
                options={{
                    enableFilter: false,
                    showHideColumns: false,
                    recordRowClassName: (record: any) => {
                        return record.isInMinute === 1 ? "row-yellow" : "";
                    },
                }}
                dataSource={data ?? []}
                callback={componentCallback}
                loading={isLoading}
                exporting={isExporting}
                pagination={pagination}
                serverSide={true}
            />
        </div>
    );
};

export default SyndicateTradingTable;
