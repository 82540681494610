import { Space, Tabs } from "antd";
import { useState } from "react";
import BalanceAdjustmentServers from "./Settings/BalanceAdjustmentServers";
import BalanceAdjustmentSMS from "./Settings/BalanceAdjustmentSMS";

interface BalanceAdjustmentSettingsProps {
    enableUpdate: boolean;
}

const BalanceAdjustmentSettings = (props: BalanceAdjustmentSettingsProps) => {
    const [currTab, setCurrTab] = useState<string>("servers");
    return (
        <>
            <Tabs
                activeKey={currTab}
                onChange={setCurrTab}
                type="card"
                style={{ padding: "0.5rem 0.5rem 0 0.5rem" }}
                tabBarStyle={{ marginBottom: 0 }}
                items={[
                    { label: "Servers", key: "servers" },
                    { label: "SMS", key: "sms" },
                ]}
            />
            <Space style={{ width: "100%", padding: "1rem" }} direction="vertical">
                {currTab === "servers" && <BalanceAdjustmentServers enableUpdate={props.enableUpdate} />}
                {currTab === "sms" && <BalanceAdjustmentSMS enableUpdate={props.enableUpdate} />}
            </Space>
        </>
    );
};

export default BalanceAdjustmentSettings;
