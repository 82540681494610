import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { RollOverBatchRecordList, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { FolderOpenOutlined, MailOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, message, Tag } from "antd";
import moment from "moment";
import { useState, useMemo, useEffect, useCallback } from "react";
import { stepInfo } from "./stepInfo";
import EditBatchComponent from "./components/editBatchComponent";
import { ToObjectWithKey } from "@/utils/array";

interface StepObjectKeysProps {
    stepObj: { [key: number]: { label: string; color: string } };
    stepOptions: KeyValuePair[];
}

const RollOverTool = () => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [batchData, setBatchData] = useState<RollOverBatchRecordList[]>([]);
    const [steps] = useState<number[]>([0, 1, 2, 3, 4]);
    const [emailTo, setEmailTo] = useState<string>("");
    const [currentEditedBatch, setCurrentEditedBatch] = useState<string | undefined>(undefined);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_ROLLOVER_EDIT);

    const stepObjKeys: StepObjectKeysProps = useMemo(
        () => ({
            stepObj: ToObjectWithKey(stepInfo, "value"),
            stepOptions: stepInfo.map(x => ({ text: x.label, value: x.value })),
        }),
        []
    );

    const columns = useMemo(
        () => [
            {
                title: "Batch ID",
                dataIndex: "id",
                key: "id",
            },
            DTColProps.Small({
                title: "Symbol",
                dataIndex: "cleanSymbol",
                key: "cleanSymbol",
            }),
            {
                title: "Current Step",
                dataIndex: "currentStep",
                key: "currentStep",
                render: (step: number) => <Tag color={stepObjKeys.stepObj[step].color}>{stepObjKeys.stepObj[step].label}</Tag>,
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: stepObjKeys.stepOptions,
                        inputProps: {
                            mode: "multiple",
                            allowClear: true,
                        },
                        callback: (filterValue: any, rowData: any) =>
                            isEmptyOrNull(filterValue as number[]) ? true : (filterValue as number[]).includes(rowData["currentStep"] || 0),
                    },
                },
            },
            DTColProps.Middle({
                title: "Create Time",
                dataIndex: "createDate",
                key: "createDate",
                sorter: (a: RollOverBatchRecordList, b: RollOverBatchRecordList) => (moment(a.createDate) > moment(b.createDate) ? -1 : 1),
                render: (createDate: string) => (createDate ? moment(createDate).format("YYYY-MM-DD HH:mm:ss") : ""),
            }),
            DTColProps.Middle({
                title: "Last Modify Time",
                dataIndex: "updateDate",
                key: "updateDate",
                sorter: (a: RollOverBatchRecordList, b: RollOverBatchRecordList) => (moment(a.updateDate) > moment(b.updateDate) ? -1 : 1),
                render: (updateDate: string) => (updateDate ? moment(updateDate).format("YYYY-MM-DD HH:mm:ss") : ""),
            }),
        ],
        [stepObjKeys]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            separateActionButton: true,
            add: enableUpdate,
            rowExtra: enableUpdate
                ? [
                      { text: "Edit Batch", icon: <FolderOpenOutlined />, value: "editBatch" },
                      { text: "Send Email To", icon: <MailOutlined />, value: "sendEmailTo" },
                      { text: "Delete ", icon: <DeleteOutlined style={{ color: "#f00f00" }} />, value: "deleteBatch" },
                  ]
                : [{ text: "Send Email To", icon: <MailOutlined />, value: "sendEmailTo" }],
        }),
        [enableUpdate]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                createNewBatch();
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "sendEmailTo") {
                    sentEmailTo(FormData.data.id);
                    break;
                } else if (FormData.key === "editBatch") {
                    setIsEditModalVisible(true);
                    setCurrentEditedBatch(FormData.data.id);
                    break;
                } else if (FormData.key === "deleteBatch") {
                    deleteBatch(FormData.data.id);
                }
                break;
            default:
                break;
        }
    };

    const deleteBatch = (id: any) => {
        setIsLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to delete batch : ${id}?`,
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_ROLLOVER_DELETE_BATCH_RECORD}?batchId=${id}`)
                    .then((res: any) => {
                        if (res.data === "success") {
                            ErrorMessageHandler(`Batch ID: [ ${id} ]`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            setRunRefetchDataList(true);
                        } else {
                            ErrorMessageHandler("batch record", SUCCESS_FAILED.FAILED_DELETE_DATA);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("batch record", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => setIsLoading(false));
            },
            onCancel: () => {
                setIsLoading(false);
            },
        });
    };

    const sentEmailTo = useCallback(
        (id: any) => {
            setIsLoading(true);
            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure send roll over mail to 【" + emailTo + "】?",
                width: "30%",
                onOk() {
                    plainAxiosInstance
                        .get(`${APIs.RISK_TOOL.GET_ROLLOVER_SEND_EMAIL}?batchId=${id}`)
                        .then(res => {
                            if (res.data === "success") {
                                ErrorMessageHandler("Email sent successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                            } else {
                                ErrorMessageHandler(`Send email failed. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        })
                        .catch((error: any) =>
                            ErrorCatchValidator(error, (err: any) =>
                                ErrorMessageHandler(`Send email failed: ${error.response.data.message}.`, SUCCESS_FAILED.OTHERS_FAILED)
                            )
                        )
                        .finally(() => setIsLoading(false));
                },
                onCancel: () => {
                    setIsLoading(false);
                },
            });
        },
        [emailTo]
    );

    const createNewBatch = () => {
        setIsLoading(true);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure you want to add new batch?",
            width: "30%",
            onOk() {
                plainAxiosInstance
                    .get(APIs.RISK_TOOL.GET_ROLLOVER_ADD_BATCH_RECORD)
                    .then(res => {
                        if (res.data === "success") {
                            ErrorMessageHandler("New batch created successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                            setRunRefetchDataList(true);
                        } else {
                            ErrorMessageHandler(`Created new batch failed. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("rollover new batch", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                    )
                    .finally(() => setIsLoading(false));
            },
            onCancel: () => {
                setIsLoading(false);
            },
        });
    };

    const getBatchData = useCallback(() => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("steps", steps.join(","));
        plainAxiosInstance
            .post(APIs.RISK_TOOL.POST_ROLLOVER_BATCH_RECORD_LIST, formData)
            .then((res: any) => {
                setBatchData(res.data && res.data.length > 0 ? res.data : []);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("rollover batch list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [steps]);

    const getConfig = () => {
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_ROLLOVER_EMAILTO)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setEmailTo(res.data);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("rollover email to", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getBatchData();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList, getBatchData]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <>
            <CardBox title={"Roll Over Tool"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title={false}
                    columns={columns}
                    options={options}
                    dataSource={batchData}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={{
                        defaultPageSize: 20,
                    }}
                    filterInitialValue={{ currentStep: steps }}
                />
            </CardBox>
            <Modal
                style={{ top: 10 }}
                width={"90vw"}
                maskClosable={false}
                title={currentEditedBatch ? `Batch: ${currentEditedBatch}` : ""}
                open={isEditModalVisible}
                onCancel={() => {
                    setCurrentEditedBatch(undefined);
                    setRunRefetchDataList(true);
                    setIsEditModalVisible(false);
                }}
                footer={null}
                bodyStyle={{ padding: 0 }}
            >
                <EditBatchComponent currentBatchId={currentEditedBatch} />
            </Modal>
        </>
    );
};

export default RollOverTool;
