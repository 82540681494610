import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { FlexiDataTableCallbackProps } from "@/constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { Col, Form, message, Modal, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { CreditToBalanceRecord, CreditToBalanceUploadData, ServerDetails } from "./type";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import LoadingComponent from "@/components/Loading";
import { cloneDeep } from "lodash";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";

const couponTypeOptions = [
    { value: 2, text: "2" },
    { value: 3, text: "3" },
    { value: 4, text: "4" },
];

const initialServerList: ServerDetails[] = [
    {
        serverId: "0",
        serverName: "All",
    },
];

const CreditToBalanceUpload = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CREDIT_TO_BALANCE_UPLOAD_EDIT);

    const [data, setData] = useState<CreditToBalanceUploadData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [servers, setServers] = useState<ServerDetails[]>(initialServerList);
    const [currCreateEdit, setCurrCreateEdit] = useState<string | "__new__" | null>(null);
    const [recordForm] = Form.useForm();
    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({ serverId: "0" });

    const columns: any[] = [
        DTColProps.Middle({
            title: "Server",
            dataIndex: "serverId",
            key: "serverId",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: servers.map((x: ServerDetails) => ({ value: x.serverId, text: x.serverName })),
                    rules: [{ required: true, message: REQUIRED_FIELD }],
                },
            },
        }),
        DTColProps.Small({
            title: "Coupon Type",
            dataIndex: "couponType",
            key: "couponType",
            sorter: (a: CreditToBalanceUploadData, b: CreditToBalanceUploadData) => a.couponType - b.couponType,
        }),
        {
            title: "User Group",
            dataIndex: "userGroup",
            key: "userGroup",
        },
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "serverName") {
                            fParams["serverId"] = FormData[x];
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CREATE_NEW:
                setCurrCreateEdit("__new__");
                break;
            case CALLBACK_KEY.DO_EDIT:
                recordForm.setFieldsValue(FormData);
                setCurrCreateEdit(FormData.serverId);
                break;
            default:
                break;
        }
    };

    /**
     *  19/08/2024 - Xin
     *  Notes from RC:
     *  1) 儲存對應的Map => 因為tb_credit_to_balance沒有server_name欄位，而畫面的serverName
     *  2) 是show name，可能會變。因此後面新增、修改時，需要轉換成server_id與DB進行交互
     */
    const getServersList = () => {
        plainAxiosInstance
            .get(`${APIs.RC_CREDIT_TO_BALANCE.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setServers(initialServerList.concat(...res.data));
                    setRunRefetchDataList(true);
                } else setServers([]);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Failed to get servers list`, SUCCESS_FAILED.OTHERS_FAILED, err))
            )
            .finally(() => setIsFilterLoaded(true));
    };

    const getDataList = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_CREDIT_TO_BALANCE.GET_CREDIT_TO_BALANCE_DATA}?serverIndexStr=${filterParams.serverId}`)
            .then((res: any) => {
                if (res.data) {
                    let newData = res.data.map((x: CreditToBalanceUploadData) => {
                        let thisServer = servers.find((y: ServerDetails) => y.serverId === x.serverId);
                        return {
                            ...x,
                            key: x.serverId + "_" + x.couponType,
                            serverName: isEmptyOrNull(thisServer) ? "-" : thisServer?.serverName,
                        };
                    });
                    setData(newData);
                } else setData([]);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Failed to load data list`, SUCCESS_FAILED.OTHERS_FAILED, err));
                setData([]);
            })
            .finally(() => setIsLoading(false));
    };

    const onSubmit = useCallback(
        (values: any) => {
            let newSubmittedData: CreditToBalanceRecord = cloneDeep(values);
            setIsLoading(true);
            Modal.confirm({
                title: "Are you sure you want to submit this record?",
                content: (
                    <>
                        <p>Server: {newSubmittedData.serverId}</p>
                        <p>Coupon Type: {newSubmittedData.couponType}</p>
                    </>
                ),
                okText: "Yes",
                onOk: () => {
                    if (currCreateEdit === "__new__") {
                        plainAxiosInstance
                            .post(`${APIs.RC_CREDIT_TO_BALANCE.POST_CREATE_NEW_CTB_RECORD}`, newSubmittedData)
                            .then((res: any) => {
                                if (res.data) {
                                    setCurrCreateEdit(null);
                                    ErrorMessageHandler(`Successfully inserted ${newSubmittedData.serverId}!`, SUCCESS_FAILED.OTHERS_SUCCESS);
                                    setRunRefetchDataList(true);
                                }
                            })
                            .catch((error: any) =>
                                ErrorCatchValidator(error, (err: any) =>
                                    ErrorMessageHandler(
                                        `Failed to insert new record: ${err.response ? err.response.data.message : err.message}`,
                                        SUCCESS_FAILED.OTHERS_FAILED
                                    )
                                )
                            )
                            .finally(() => setIsLoading(false));
                    } else {
                        plainAxiosInstance
                            .post(`${APIs.RC_CREDIT_TO_BALANCE.POST_UPDATE_CTB_RECORD}`, newSubmittedData)
                            .then((res: any) => {
                                if (res.data) {
                                    setCurrCreateEdit(null);
                                    ErrorMessageHandler(`Successfully updated ${newSubmittedData.serverId}!`, SUCCESS_FAILED.OTHERS_SUCCESS);
                                    setRunRefetchDataList(true);
                                }
                            })
                            .catch((error: any) =>
                                ErrorCatchValidator(error, (err: any) =>
                                    ErrorMessageHandler(
                                        `Failed to update ${newSubmittedData.serverId}: ${err.response ? err.response.data.message : err.message}`,
                                        SUCCESS_FAILED.OTHERS_FAILED
                                    )
                                )
                            )
                            .finally(() => setIsLoading(false));
                    }
                },
                onCancel: () => {
                    setIsLoading(false);
                },
            });
        },
        [currCreateEdit]
    );

    useEffect(() => {
        getServersList();
        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            getDataList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <div>
            <CardBox title="Credit To Balance Upload">
                <LoadingComponent tip="Loading filters..." spinning={!isFilterLoaded}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="key"
                        title={false}
                        columns={columns}
                        options={{
                            edit: enableUpdate,
                            add: enableUpdate,
                            serverFiltering: true,
                        }}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading}
                        filterInitialValue={{ serverId: "0" }}
                    />
                    <Modal
                        width={700}
                        destroyOnClose
                        forceRender
                        title={`${currCreateEdit === "__new__" ? "Create New" : "Edit"} Credit-To-Balance Record`}
                        open={currCreateEdit !== null}
                        confirmLoading={isLoading}
                        okText="Submit"
                        onOk={() => {
                            recordForm
                                .validateFields()
                                .then(values => onSubmit(values))
                                .catch(err => {
                                    console.log("Validate Failed: ", err);
                                });
                        }}
                        onCancel={() => {
                            recordForm.resetFields();
                            setCurrCreateEdit(null);
                        }}
                    >
                        <Form form={recordForm} layout="horizontal" labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} initialValues={{}}>
                            <Row>
                                <Col span={24}>
                                    <FormComponent
                                        label="Server"
                                        name="serverId"
                                        extra={{
                                            type: ComponentType.dropdown,
                                            value: servers.map((x: ServerDetails) => ({ value: x.serverId, text: x.serverName })),
                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                            inputProps: {
                                                disabled: currCreateEdit !== "__new__",
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <FormComponent
                                        label="Type"
                                        name="couponType"
                                        extra={{
                                            type: ComponentType.dropdown,
                                            value: couponTypeOptions,
                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <FormComponent
                                        label="User Group"
                                        name="userGroup"
                                        extra={{
                                            type: ComponentType.textarea,
                                            value: "",
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </LoadingComponent>
            </CardBox>
        </div>
    );
};

export default CreditToBalanceUpload;
