import { useQuery, useMutation } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
export interface IRCPriceBackendOpt {
    serverUno: number;
    type: number;
    serverDn: string;
}

// type TPriceBackendOptType = "Price Calculate" | "Price Connector" | "Price Analysis" | "Tick Receiver";

const useRCPriceBackendOpt = ({ type }: { type: number }) => {
    // const returnType = (type: TPriceBackendOptType) => {
    //     switch (type) {
    //         case "Price Calculate":
    //             return 1;
    //         case "Price Connector":
    //             return 1;
    //         case "Price Analysis":
    //             return 2;
    //         case "Tick Receiver":
    //             return 3;
    //         default:
    //             return 1;
    //     }
    // };
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcPriceBackendOpt", type],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_BACKEND_OPT}?type=${type}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcPriceBackendOpt: IRCPriceBackendOpt[] = data?.data?.status ? [] : data?.data || [];
    // @ts-ignore
    const rcPriceBackendOptError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceBackendOpt,
        rcPriceBackendOptError,
        refetchRcPriceBackendOptError: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCPriceBackendOptDetails = ({ param, isTickReciverTools = false }: { param: string; isTickReciverTools?: boolean }) => {
    const formData = new FormData();
    formData.append("keyStr", param);
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcPriceBackendOptDetails", param],
        queryFn: () =>
            plainAxiosInstance.post(
                isTickReciverTools
                    ? APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_DETAILS_TICK_RECEIVER
                    : APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_DETAILS,
                formData,
                {
                    headers: {
                        Accept: "application/json, text/plain, */*",
                    },
                }
            ),
        staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    // @ts-ignore
    const rcPriceBackendOptDetails: string | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcPriceBackendOptDetailsError: string | null = data?.data?.message || error?.message || error || null;

    return {
        rcPriceBackendOptDetails,
        rcPriceBackendOptDetailsError,
        refetchRcPriceBackendOptDetails: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCPriceBackendOptMutations = () => {
    // restart servers
    const { mutateAsync, isLoading, error } = useMutation(
        ({ ids, isSingleRun }: { ids: any; isSingleRun: boolean }) =>
            isSingleRun
                ? plainAxiosInstance.post(APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_SINGLE_RESTART, ids, {
                      headers: {
                          Accept: "application/json, text/plain, */*",
                      },
                  })
                : plainAxiosInstance.post(APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_RESTART, ids, {
                      headers: {
                          Accept: "application/json, text/plain, */*",
                      },
                  }),
        {
            onSuccess: res => {
                return res;
            },
            onError: err => {
                return err;
            },
        }
    );

    return {
        restartServers: mutateAsync,
        isLoadingRestartServers: isLoading,
        restartServersError: error,
    };
};

export default useRCPriceBackendOpt;
