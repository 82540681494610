import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType } from "@/constants";
import { FlexiDataColumnProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { DTColProps } from "@/utils/Common";
import { useMemo, useState } from "react";

type ResultTableProps = {
    isLoading: boolean;
    data: any[];
};

const CHANGETYPE: { [key: string]: string } = {
    "1": "Scheduled change",
    "2": "Resign common account change",
    "3": "Resign personal account change",
    "4": "Manually change",
};
const changeStatusParser = (value: number) => {
    if (value === undefined || value === null || value > 4 || value < 1) {
        return "Unknown";
    }
    return CHANGETYPE[value.toString()];
};

const ResultTable = ({ data, isLoading }: ResultTableProps) => {
    const datalist = useMemo(() => {
        return data.map((item: any, index: number) => {
            return {
                ...item,
                rowKey: `${index}_${item.login}_${item.serverCode}_${item.updateDate}_${item.changeType}`,
                errorType: item.errorType === 0 ? "Success" : "Fail",
                changeType: changeStatusParser(item.changeType),
            };
        });
    }, [data]);

    const componentCallback: FlexiDataTableCallbackProps = (type, cbData) => {};

    const columns: FlexiDataColumnProps[] = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "serverDn",
                key: "serverDn",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            DTColProps.Small({
                title: "Update Date",
                dataIndex: "updateDate",
                key: "updateDate",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            DTColProps.Small({
                title: "Change Type",
                dataIndex: "changeType",
                key: "changeType",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: Object.keys(CHANGETYPE).map(x => {
                            return { text: CHANGETYPE[x], value: CHANGETYPE[x] };
                        }),
                        inputProps: {
                            mode: "multiple",
                        },
                        callback(filterValue: string[], rowData: any) {
                            return filterValue.includes(rowData.changeType);
                        },
                    },
                },
            }),
            DTColProps.Small({
                title: "Status",
                dataIndex: "errorType",
                key: "errorType",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            { text: "Success", value: "Success" },
                            { text: "Fail", value: "Fail" },
                        ],
                    },
                },
            }),
            DTColProps.Large({
                title: "Reason",
                dataIndex: "reason",
                key: "reason",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
        ],
        []
    );

    return (
        <FlexiDataTable
            rowKeyProperty="rowKey"
            title=""
            columns={columns}
            dataSource={datalist}
            callback={componentCallback}
            loading={isLoading}
            bordered
        />
    );
};

export default ResultTable;
