import LoadingComponent from "@/components/Loading";
import { SUCCESS_FAILED } from "@/constants";
import { KeyValuePair } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { CheckSquareOutlined, BorderOutlined, SearchOutlined, EditOutlined } from "@ant-design/icons";
import { Form, Input, Button, Modal } from "antd";
import { useState, useMemo, useEffect } from "react";
import EditHubFailOverForm from "./editForm";

export interface MTServerHubConfigurationProps {
    //searchText: string;
}

export interface MTConfigServerProps {
    serverId: Number;
    isEnable: boolean;
    serverCode: string;
    server: string;
    oneZeroConfig?: OneZeroConfigProps;
    priceEngineConfig?: PriceEngineConfigProps;
    primeXMConfig?: PrimeXMConfigProps;
}

export interface HubManagerProps {
    gatewayName?: string | null;
    managerLogin?: number | null;
}

export interface OneZeroConfigProps extends HubManagerProps {}
export interface PrimeXMConfigProps extends HubManagerProps {}
export interface PriceEngineConfigProps extends HubManagerProps {
    routingNamePrefix?: string | null;
    dataFeederName?: string | null;
    clusterId: number;
}

export const isMT4Server = (serverCode: string): boolean =>
    serverCode !== undefined && serverCode.length > 0 && serverCode.toLowerCase().indexOf("mt4") === 0;

const ifConfigNotNull = (serverCode: string, config: any, defaultRequiredField: string = "") => {
    let requiredField: string = defaultRequiredField.length > 0 ? defaultRequiredField : isMT4Server(serverCode) ? "managerLogin" : "gatewayName";
    return (
        config !== undefined &&
        config !== null &&
        config?.hasOwnProperty(requiredField) &&
        config[requiredField] !== null &&
        `${config[requiredField]}`.length > 0
    );
};

const MTServerHubConfiguration = (props: MTServerHubConfigurationProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    //const [searchText, setSearchText] = useState<string>("");
    const [data, setData] = useState<MTConfigServerProps[]>([]);
    const [clusterOpt, setClusterOpt] = useState<KeyValuePair[]>([]);
    const [editingObj, setEditingObj] = useState<MTConfigServerProps | undefined>(undefined);
    const [searchText, setSearchText] = useState<string>("");
    const [editForm] = Form.useForm();

    const filteredData: MTConfigServerProps[] = useMemo(() => {
        if (data.length > 0) {
            if (searchText.length > 0) {
                let lowerSearchText: string = searchText.toLowerCase();
                return data.filter((x: MTConfigServerProps) => {
                    let isMT4: boolean = isMT4Server(x.serverCode);
                    return (
                        x.server.toLowerCase().indexOf(lowerSearchText) > -1 ||
                        (ifConfigNotNull(x.serverCode, x.oneZeroConfig) &&
                            `${x.oneZeroConfig !== undefined && x.oneZeroConfig[isMT4 ? "managerLogin" : "gatewayName"]}`
                                .toLowerCase()
                                .indexOf(lowerSearchText) > -1) ||
                        (ifConfigNotNull(x.serverCode, x.priceEngineConfig, isMT4Server(x.serverCode) ? "" : "routingNamePrefix") &&
                            `${x.priceEngineConfig !== undefined && x.priceEngineConfig[isMT4 ? "managerLogin" : "routingNamePrefix"]}`
                                .toLowerCase()
                                .indexOf(lowerSearchText) > -1) ||
                        (ifConfigNotNull(x.serverCode, x.primeXMConfig) &&
                            `${x.primeXMConfig !== undefined && x.primeXMConfig[isMT4 ? "managerLogin" : "gatewayName"]}`
                                .toLowerCase()
                                .indexOf(lowerSearchText) > -1)
                    );
                });
            }
            return data;
        }
        return [];
    }, [data, searchText]);

    const setFormFromObject = (item: MTConfigServerProps) => {
        let initObj: any = {
            serverId: item.serverId,
            isEnable: item.isEnable,
            ...(ifConfigNotNull(item.serverCode, item.oneZeroConfig)
                ? {
                      ozc_enable: true,
                      ozc_gatewayName: item.oneZeroConfig?.gatewayName,
                      ozc_managerLogin: item.oneZeroConfig?.managerLogin,
                  }
                : {
                      ozc_enable: false,
                      ozc_gatewayName: "",
                      ozc_managerLogin: "",
                  }),
            ...(ifConfigNotNull(item.serverCode, item.priceEngineConfig, isMT4Server(item.serverCode) ? "" : "routingNamePrefix")
                ? {
                      pec_enable: true,
                      pec_gatewayName: item.priceEngineConfig?.gatewayName,
                      pec_managerLogin: item.priceEngineConfig?.managerLogin,
                      pec_routingNamePrefix: item.priceEngineConfig?.routingNamePrefix,
                      pec_dataFeederName: item.priceEngineConfig?.dataFeederName,
                      pec_clusterId: item.priceEngineConfig?.clusterId,
                  }
                : {
                      pec_enable: false,
                      pec_gatewayName: "",
                      pec_managerLogin: "",
                      pec_routingNamePrefix: "",
                      pec_dataFeederName: "",
                      pec_clusterId: "",
                  }),
            ...(ifConfigNotNull(item.serverCode, item.primeXMConfig)
                ? {
                      pxmc_enable: true,
                      pxmc_gatewayName: item.primeXMConfig?.gatewayName,
                      pxmc_managerLogin: item.primeXMConfig?.managerLogin,
                  }
                : {
                      pxmc_enable: false,
                      pxmc_gatewayName: "",
                      pxmc_managerLogin: "",
                  }),
        };
        editForm.setFieldsValue(initObj);
    };

    const updateHubFailoverSetting = (values: any) => {
        setIsLoading(true);
        let findPrefix: any[] = [
                { prefix: "ozc_", key: "oneZeroConfig" },
                { prefix: "pec_", key: "priceEngineConfig" },
                //{ prefix: "pxmc_", key: "primeXMConfig" },
            ],
            finalValues: any = {
                serverId: values["serverId"],
                isEnable: values["isEnable"],
                ...Object.keys(values)
                    .filter((x: string) => findPrefix.some((y: any) => x.indexOf(y.prefix) === 0))
                    .reduce((finalObj: any, x: string) => {
                        let fIdx: number = findPrefix.findIndex(y => x.indexOf(y.prefix) === 0);
                        if (fIdx > -1) {
                            let selectItem: any = findPrefix[fIdx];
                            if (!finalObj?.hasOwnProperty(selectItem.key)) {
                                finalObj[selectItem.key] = {};
                            }
                            finalObj[selectItem.key][x.replace(selectItem.prefix, "")] = values[x];
                        }
                        return finalObj;
                    }, {}),
            };
        // Remove object which enableStatus = false
        findPrefix.forEach(x => {
            if (finalValues[x.key]?.hasOwnProperty("enable") && !finalValues[x.key]["enable"]) {
                delete finalValues[x.key];
            }
        });
        apiRequest(APIs.UPDATE_HUB_FAILOVER_SETTING, finalValues)
            .then((res: any) => {
                ErrorMessageHandler("hub failover setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                getServerConfigList();
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hub failover setting list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                setIsLoading(false);
            });
    };

    const getServerConfigList = () => {
        apiRequest(APIs.HUB_FAILOVER_SETTING_LIST, {})
            .then((res: any) => {
                if (res && res.length > 0) {
                    let tmp: any = res;
                    tmp.sort((a: MTConfigServerProps, b: MTConfigServerProps) =>
                        a.isEnable === b.isEnable ? a.server.toLowerCase().localeCompare(b.server.toLowerCase()) : a.isEnable ? -1 : 1
                    );
                    setData(tmp);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hub failover setting list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const getCheckTemplate = (serverCode: string, config: any, defaultGatewayName: string) => {
        return (
            <div className="check-item">
                {ifConfigNotNull(
                    serverCode,
                    config,
                    defaultGatewayName === "Price Engine Config" ? (isMT4Server(serverCode) ? "" : "routingNamePrefix") : ""
                ) ? (
                    <CheckSquareOutlined className="active" />
                ) : (
                    <BorderOutlined />
                )}
                <span>{defaultGatewayName}</span>
            </div>
        );
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["hubfailoverpecluster"] })
            .then((res: any) => {
                if (res.hubFailoverPeCluster) {
                    setClusterOpt(res.hubFailoverPeCluster.map((x: any) => ({ text: x.description, value: x.id })));
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to load hub failover pe cluster", err)))
            .finally(() => getServerConfigList());
    };

    useEffect(() => {
        getConfig();
        return () => {};
    }, []);

    return (
        <>
            <div className="mtserverhub-config-container">
                <div className="page-title">
                    <Input
                        type="text"
                        name="search"
                        placeholder="Search"
                        className="search-input"
                        suffix={<SearchOutlined />}
                        allowClear
                        onChange={(e: any) => setSearchText(e.currentTarget.value)}
                    />
                </div>
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <div className="items-container">
                        {filteredData.map((x: MTConfigServerProps, idx: number) => (
                            <div key={`mtshc-${idx}`} className={`item shadow-1 ${x.isEnable ? "" : "inactive"}`}>
                                <div>
                                    <div className="title">
                                        <div className="active"></div>
                                        {x.server}
                                    </div>
                                    <div className="content">
                                        {getCheckTemplate(x.serverCode, x.oneZeroConfig, "One Zero Config")}
                                        {getCheckTemplate(x.serverCode, x.priceEngineConfig, "Price Engine Config")}
                                        {/* {getCheckTemplate(x.serverCode, x.primeXMConfig, "PrimeXM Config")} */}
                                    </div>
                                    <div className="footer">
                                        <Button
                                            type="text"
                                            icon={<EditOutlined />}
                                            style={{ fontSize: "1.125rem", width: "100%" }}
                                            onClick={() => {
                                                setEditingObj(x);
                                                setFormFromObject(x);
                                                setIsModalVisible(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <Modal
                width={700}
                style={{ top: 20 }}
                open={isModalVisible}
                title="Edit MT Server & Hub Configuration"
                onCancel={() => {
                    setIsModalVisible(false);
                    editForm.resetFields();
                }}
                onOk={() => {
                    editForm
                        .validateFields()
                        .then(values => {
                            updateHubFailoverSetting(values);
                            setIsModalVisible(false);
                            editForm.resetFields();
                        })
                        .catch(err => {});
                }}
            >
                <EditHubFailOverForm form={editForm} editingFormObj={editingObj} clusterOptions={clusterOpt} />
            </Modal>
        </>
    );
};

export default MTServerHubConfiguration;
