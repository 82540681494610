import { DeleteOutlined, EditOutlined, HomeOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Form, message, Modal, Row } from "antd";
import { cloneDeep } from "lodash";
import { useState, useEffect, useRef, MutableRefObject } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { FormComponent } from "../../../../components/FormComponent";
import LoadingComponent from "../../../../components/Loading";
import SitePageHeader from "../../../../components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { Metric2, MetricGroup2 } from "../../../../constants/type";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import EmptyData from "../../../../components/Common/Empty";

export type CreateEditMetricMappingProps = {
    action: string;
    data: any;
};

const MetricConfigCreateEditPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: CreateEditMetricMappingProps = location.state as CreateEditMetricMappingProps;
    const [currentState] = useState<CreateEditMetricMappingProps>(com_state || { action: "add", data: null });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showEditGroupModal, setShowEditGroupModal] = useState<boolean>(false);
    const [, setEditingGroupId] = useState<number>(0);
    const [groups, setGroups] = useState<MetricGroup2[]>([]);
    const [metrics, setMetrics] = useState<Metric2[]>([]);
    const [groupForm] = Form.useForm();

    const [scrollX, setscrollX] = useState<number>(0); // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = useState<boolean>(false); // For detecting end of scrolling
    const scrl = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;

    const getGroupInformation = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_METRIC_CONFIGURATION_LIST, { metricTypeId: currentState.data.metricTypeId })
            .then((res: any) => {
                if (res) {
                    let filteredMetrics = cloneDeep(res.metrics).filter((y: any) => `${y.metricTypeId}` === `${currentState.data.metricTypeId}`);
                    filteredMetrics.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName));
                    let groupList: MetricGroup2[] = res.categories.map((x: any) => ({
                        ...x,
                        items: filteredMetrics
                            .filter((y: any) => y.categories.indexOf(x.id) > -1)
                            .map((y: any) => ({ id: y.metricId, name: y.displayName, description: y.description } as Metric2)),
                    }));
                    setGroups(groupList);
                    setMetrics(filteredMetrics.map((x: any) => ({ id: x.metricId, name: x.displayName, description: x.description } as Metric2)));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("metric group", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const removeMetricFromCategory = (categoryId: number, metricId: number) => {
        let newCategory = cloneDeep(groups);
        let cIdx = newCategory.findIndex(x => x.id === categoryId);
        if (cIdx > -1) {
            let mIdx = newCategory[cIdx].items.findIndex(x => x.id === metricId);
            if (mIdx > -1) {
                //let backUpMetricObj = cloneDeep(newCategory[cIdx].items[mIdx]);
                newCategory[cIdx].items.splice(mIdx, 1);
                setGroups(checkUnMappingMetric(newCategory));
            }
        }
    };

    const checkUnMappingMetric = (groups: MetricGroup2[]) => {
        let newMetricIdx = groups.findIndex(x => x.id === 0);
        if (newMetricIdx > -1) {
            let otherGroups = groups.filter(x => x.id !== 0);
            groups[newMetricIdx].items = metrics.filter(x => otherGroups.filter(y => y.items.some(z => z.id === x.id)).length === 0);
        }
        return groups;
    };

    const editCategoryMetrics = (categoryId: number) => {
        let gIdx = groups.findIndex(x => x.id === categoryId);
        if (gIdx > -1) {
            let foundGroup = groups[gIdx];
            groupForm.setFieldsValue({ groupId: foundGroup.id, groupName: foundGroup.name, selectedMetric: foundGroup.items.map(x => x.id) });
            setEditingGroupId(categoryId);
            setShowEditGroupModal(true);
        }
    };

    const submitMetricMapping = () => {
        let newMetricGroupIdx = groups.findIndex(x => x.id === 0);
        if (newMetricGroupIdx > -1) {
            if (groups[newMetricGroupIdx].items.length > 0) {
                ErrorMessageHandler(`Please make sure all metric(s) assigned to any category before update.`, SUCCESS_FAILED.OTHERS_FAILED);
                return false;
            }
        }

        let params: any = {
            metricTypeId: currentState.data.metricTypeId,
            categories: groups.map(x => ({ id: x.id, metricIds: x.items.map(y => y.id) })),
        };

        apiRequest(APIs.UPDATE_METRIC_MAPPING_LIST, params)
            .then(res => {
                ErrorMessageHandler("metric mapping configuration", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                navigate("/siteadmin/appconfig/metricmapping");
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("metric mapping configuration", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            );
    };

    useEffect(() => {
        getGroupInformation();
        return () => {};
    }, []);

    const slide = (shift: number) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift); // Updates the latest scrolled postion

        //For checking if the scroll has ended
        if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    //This will check scroll event and checks for scroll end
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    useEffect(() => {
        //Check width of the scollings
        if (scrl.current && scrl?.current?.scrollWidth === scrl?.current?.offsetWidth) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
        return () => {};
    }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

    return (
        <SitePageHeader
            title={"Edit Metric Mapping"}
            routes={[
                {
                    path: "/siteadmin/appconfig/metricmapping",
                    breadcrumbName: "Metric Mapping",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: "Edit Metric Mapping",
                },
            ]}
            onBack={() => navigate("/siteadmin/appconfig/metricmapping")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <>
                    <Row>
                        <Col span={24}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "2.828vh",
                                }}
                            >
                                <div>
                                    <Button className="prev" onClick={() => slide(-750)} style={{ marginRight: "1.302vw" }} disabled={scrollX === 0}>
                                        <LeftOutlined />
                                    </Button>
                                    <Button className="next" onClick={() => slide(+750)} disabled={scrolEnd}>
                                        <RightOutlined />
                                    </Button>
                                </div>
                                <Button type="primary" htmlType="submit" onClick={() => submitMetricMapping()}>
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="metric-mapping-container" ref={scrl} onScroll={scrollCheck}>
                        {groups.length > 0 &&
                            groups.map(x => (
                                <div key={`mcc-${x.id}`} className="group-container">
                                    <div className="title">
                                        <span>{x.name}</span>
                                        {x.id !== 0 && <Button type="dashed" icon={<EditOutlined />} onClick={() => editCategoryMetrics(x.id)} />}
                                    </div>
                                    <div className="content nice-scrollbar">
                                        {x.items.length > 0 ? (
                                            x.items.map(y => (
                                                <div key={`mcc-${x.id}-${y.id}`} className="item">
                                                    <span>{y.name}</span>
                                                    {x.id !== 0 && (
                                                        <Button
                                                            type="link"
                                                            danger
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => removeMetricFromCategory(x.id, y.id)}
                                                        />
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <EmptyData />
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                    <Modal
                        width={700}
                        open={showEditGroupModal}
                        title="Add/Remove metric from category"
                        onCancel={() => setShowEditGroupModal(false)}
                        onOk={() => {
                            groupForm
                                .validateFields()
                                .then(values => {
                                    let latestGroup = cloneDeep(groups);
                                    let gIdx = latestGroup.findIndex(x => x.id === values["groupId"]);
                                    if (gIdx > -1) {
                                        let selectedMetric: number[] = values["selectedMetric"];
                                        latestGroup[gIdx] = { ...latestGroup[gIdx], items: metrics.filter(x => selectedMetric.indexOf(x.id) > -1) };
                                        latestGroup = checkUnMappingMetric(latestGroup);
                                        setGroups(latestGroup);
                                    }
                                    setShowEditGroupModal(false);
                                })
                                .catch(err => {});
                        }}
                    >
                        <Form form={groupForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
                            <FormComponent label={""} name={"groupId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent label={"Category Name"} name={"groupName"} extra={{ type: ComponentType.labelOnly, value: "" }} />
                            <FormComponent
                                label={"Selected Metric"}
                                name={"selectedMetric"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: metrics.map(x => ({ text: x.name, value: x.id })),
                                    inputProps: {
                                        style: { width: "100%" },
                                        mode: "multiple",
                                        allowClear: true,
                                    },
                                }}
                            />
                        </Form>
                    </Modal>
                </>
            )}
        </SitePageHeader>
    );
};

export default MetricConfigCreateEditPage;
