import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";
// type RCIssueLogType = {
//     REQUEST_TIMEOUT
// }
// i want to create enum to map back to logType, like REQUEST_TIMEOUT = 3, etc
// but i'm not sure how to do it in typescript

export type RCIssueLogType =
    | "REQUEST_TIMEOUT"
    | "CLOSED_ORDER"
    | "MODIFY_OPEN_ORDER"
    | "MANAGER_ACCOUNT_INTERCEPTED"
    | "MT5_ORDER_TYPE"
    | "ACCOUNT_MONITOR"
    | "RESTORE_ORDER"
    | "UPDATE_ORDER_URGENT"
    | "NO_MONEY"
    | "NO_MONEY_WHITELIST";

export const mapIssueLogBackToEnum = (logType: RCIssueLogType) => {
    switch (logType) {
        case "REQUEST_TIMEOUT":
            return 2;
        case "NO_MONEY_WHITELIST":
            return 3;
        case "CLOSED_ORDER":
            return 5;
        case "MODIFY_OPEN_ORDER":
            return 6;
        case "MANAGER_ACCOUNT_INTERCEPTED":
            return 8;
        case "ACCOUNT_MONITOR":
            return 11;
        case "RESTORE_ORDER":
            return 15;
        case "UPDATE_ORDER_URGENT":
            return 16;
        case "NO_MONEY":
            return 9999;
        case "MT5_ORDER_TYPE":
            return 9998;
        default:
            return null;
    }
};

export const revertEnumToIssueLog = (logType: number) => {
    switch (logType) {
        case 2:
            return "REQUEST_TIMEOUT";
        case 5:
            return "CLOSED_ORDER";
        case 6:
            return "MODIFY_OPEN_ORDER";
        case 8:
            return "MANAGER_ACCOUNT_INTERCEPTED";
        case 11:
            return "ACCOUNT_MONITOR";
        case 15:
            return "RESTORE_ORDER";
        case 16:
            return "UPDATE_ORDER_URGENT";
        case 9999:
            return "NO_MONEY";
        case 9998:
            return "MT5_ORDER_TYPE";
        default:
            return null;
    }
};

const useRCIssuesLog = (logType: RCIssueLogType) => {
    const logTypeNumber = mapIssueLogBackToEnum(logType);
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcIssuesLog", logType],
        queryFn: () =>
            logType === "NO_MONEY"
                ? plainAxiosInstance.post(`${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_NOMONEY}`, {
                      headers: {
                          Accept: "application/json",
                      },
                  })
                : logType === "MT5_ORDER_TYPE"
                ? // just return empty array if logType is MT5_ORDER_TYPE
                  Promise.resolve(null)
                : plainAxiosInstance.get(`${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG}/${logTypeNumber}`, {
                      headers: {
                          Accept: "application/json",
                      },
                  }),
        staleTime: 20 * 1000, //(60 x 1000ms = 1minute)
        refetchInterval: 20 * 1000, //(60 x 1000ms = 1minute)
        enabled: !!logType || !logTypeNumber,
    });

    const rcIssuesLog = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcIssuesLogError = data?.data?.message || error?.message || error || null;

    return {
        rcIssuesLog,
        rcIssuesLogError,
        refetchRcIssuesLog: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

interface RCIssuesLogSettingsResponse {
    login: number;
    loginName: string;
    mainServerId: string;
    type: number;
}

export const useRCIssuesLogSettings = ({ logType }: { logType: RCIssueLogType }) => {
    const logTypeNumber = mapIssueLogBackToEnum(logType);
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcIssuesLogSettings", logType],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_SETTINGS}/${logTypeNumber}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
    });

    const rcIssuesLogSettings: RCIssuesLogSettingsResponse[] | null = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcIssuesLogSettingsError = data?.data?.message || error?.message || error || null;

    return {
        rcIssuesLogSettings,
        rcIssuesLogSettingsError,
        refetchRcIssuesLogSettings: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCIssuesLogHistory = ({ logType, startDate, endDate }: { logType: RCIssueLogType; startDate: string; endDate: string }) => {
    const logTypeNumber = mapIssueLogBackToEnum(logType);
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcIssuesLogHistory", logType, startDate, endDate],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_HISTORY}/${logTypeNumber}?startDate=${startDate}&endDate=${endDate}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
        enabled: logType !== "MT5_ORDER_TYPE" && !!startDate && !!endDate,
    });

    const rcIssuesLogHistory: {
        checkTime: string;
        checkUser: string;
        comment: string;
        id: number;
        ip: string;
        msg: string;
        server: string;
        time: string;
    }[] = data?.data?.status ? [] : data?.data || [];
    // @ts-ignore
    const rcIssuesLogHistoryError = data?.data?.message || error?.message || error || null;

    return {
        rcIssuesLogHistory,
        rcIssuesLogHistoryError,
        refetchRcIssuesLogHistory: refetch,
        isFetching,
        isLoading,
    };
};

/*
sample resp
[{
    "serverId": "AT",
    "serverName": "AlphaTick",
    "serverType": 0,
    "comment": null,
    "serverAttributes": 1,
    "oldServerId": 8,
    "login": null,
    "password": null
}]
*/

interface IIssuesLogServer {
    serverId: string;
    serverName: string;
    serverType: number;
    comment: string | null;
    serverAttributes: number;
    oldServerId: number;
    login: string | null;
    password: string | null;
}

export const useRCIssuesLogMainServers = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcIssuesLogMainServers"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ISSUES_LOG.GET_SERVER_LIST}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
        refetchInterval: 60 * 1000, //(60 x 1000ms = 1minute)
    });

    const rcIssuesLogMainServers: IIssuesLogServer[] = data?.data?.status ? [] : data?.data || [];
    // @ts-ignore
    const rcIssuesLogMainServersError = data?.data?.message || error?.message || error || null;

    return {
        rcIssuesLogMainServers,
        rcIssuesLogMainServersError,
        refetchRcIssuesLogMainServers: refetch,
        isFetching,
        isLoading,
    };
};

/*
sample resp
[{
  "serverUno": "16",
  "orderId": 28388010,
  "symbol": "BTCUSD",
  "type": "1",
  "openTime": "2022-09-08T17:38:01",
  "volume": 0.00030099,
  "state": "1",
  "createTime": "2024-08-28T11:45:00",
  "firstTime": "2024-08-28T11:45:00",
  "lastTime": null
}]
 */

interface IIssuesLogMT5Monitor {
    serverUno: string;
    orderId: number;
    symbol: string;
    type: string;
    openTime: string;
    volume: number;
    state: string;
    createTime: string;
    firstTime: string;
    lastTime: string | null;
}

export const useRCIssuesLogMT5Monitor = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcIssuesLogServers"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_MT5_MONITOR}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
        refetchInterval: 60 * 1000, //(60 x 1000ms = 1minute)
    });

    const rcIssuesLogMT5Monitor: IIssuesLogMT5Monitor[] = data?.data?.status ? [] : data?.data || [];
    // @ts-ignore
    const rcIssuesLogMT5MonitorError = data?.data?.message || error?.message || error || null;

    return {
        rcIssuesLogMT5Monitor,
        rcIssuesLogMT5MonitorError,
        refetchRcIssuesLogMT5Monitor: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCIssuesLogNoMoneyMail = ({ startTime, endTime, page, rows }: { startTime: string; endTime: string; page: number; rows: number }) => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcIssuesLogNoMoneyMail", startTime, endTime, page, rows],
        queryFn: () =>
            plainAxiosInstance.post(
                `${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_NOMONEY_MAIL}`,
                {
                    startTime,
                    endTime,
                    page,
                    rows,
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            ),
        staleTime: 20 * 1000, //(60 x 1000ms = 1minute)
        refetchInterval: 20 * 1000, //(60 x 1000ms = 1minute)
        // if no startTime or endTime, disable
        enabled: !!startTime && !!endTime,
    });

    const rcIssuesLogNoMoneyMail = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcIssuesLogNoMoneyMailError = data?.data?.message || error?.message || error || null;

    return {
        rcIssuesLogNoMoneyMail,
        rcIssuesLogNoMoneyMailError,
        refetchRcIssuesLogNoMoneyMail: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCIssuesLogNoMoneySettings = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcIssuesLogNoMoneySettings"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_NOMONEY_SETTINGS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
    });

    const rcIssuesLogNoMoneySettings: { server: string; highLoad: boolean }[] = data?.data?.status ? [] : data?.data || [];
    // @ts-ignore
    const rcIssuesLogNoMoneySettingsError = data?.data?.message || error?.message || error || null;

    return {
        rcIssuesLogNoMoneySettings,
        rcIssuesLogNoMoneySettingsError,
        refetchRcIssuesLogNoMoneySettings: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCIssuesLogMT5History = ({ start, end }: { start: string | null; end: string | null }) => {
    const formData = new FormData();
    formData.append("startTime", start || "");
    formData.append("endTime", end || "");
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcIssuesLogMT5", start, end],
        queryFn: () =>
            plainAxiosInstance.post(APIs.RC_ISSUES_LOG.POST_ISSUES_LOG_MT5, formData, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
        enabled: !!start && !!end,
    });

    const rcIssuesLogMT5 = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcIssuesLogMT5Error = data?.data?.message || error?.message || error || null;

    return {
        rcIssuesLogMT5,
        rcIssuesLogMT5Error,
        refetchRcIssuesLogMT5: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCIssuesLogRequestTimeoutSettings = () => {
    const { data, refetch, isLoading, error, isFetching } = useQuery({
        queryKey: ["rcRequestTimeoutSettings"],
        queryFn: () =>
            plainAxiosInstance.get(`${APIs.RC_ISSUES_LOG.GET_REQUEST_TIMEOUT_SETTINGS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        staleTime: 60 * 1000, //(60 x 1000ms = 1minute)
    });

    const rcRequestTimeoutSettings = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcRequestTimeoutSettingsError = data?.data?.message || error?.message || error || null;

    return {
        rcRequestTimeoutSettings,
        rcRequestTimeoutSettingsError,
        refetchRcRequestTimeoutSettings: refetch,
        isFetching,
        isLoading,
    };
};

export const useRCIssuesLogMutate = ({ type }: { type?: RCIssueLogType }) => {
    const queryClient = useQueryClient();

    // Delete No Money Log
    const {
        mutateAsync: deleteIssueLogNoMoney,
        isLoading: isLoadingDeleteIssueLogNoMoney,
        error: deleteIssueLogNoMoneyError,
    } = useMutation(
        async (uniqueKeys: string[]): Promise<any> => {
            const formData = new FormData();
            formData.append("uniqueKeys", uniqueKeys.join(","));
            if (uniqueKeys.length === 0) {
                return Promise.reject("select at least 1 server");
            }
            return plainAxiosInstance.post(APIs.RC_ISSUES_LOG.POST_ISSUES_LOG_DELETE_NOMONEY, formData, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcIssuesLog", "NO_MONEY"]);
                // console.log(`Solving reject orders response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcIssuesLog", "NO_MONEY"]);
                console.error(`Error on deleting No Money Log:`, error);
                return error;
            },
        }
    );

    // Mutate No Money Settings

    const {
        mutateAsync: updateIssueLogNoMoneySettings,
        isLoading: isLoadingUpdateIssueLogNoMoneySettings,
        error: updateIssueLogNoMoneySettingsError,
    } = useMutation(
        async ({ server, checked }: { server: string; checked: boolean }): Promise<any> => {
            const formData = new FormData();
            formData.append("server", server);
            formData.append("highLoad", checked.toString());
            return plainAxiosInstance.post(`${APIs.RC_ISSUES_LOG.POST_ISSUES_LOG_NOMONEY_SETTINGS}`, formData, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcIssuesLogNoMoneySettings"]);
                // console.log(`Solving reject orders response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcIssuesLogNoMoneySettings"]);
                console.error(`Error on adjusting settings:`, error);
                return error;
            },
        }
    );

    // check common issues log

    const {
        mutateAsync: checkIssueLog,
        isLoading: isLoadingCheckIssueLog,
        error: checkIssueLogError,
    } = useMutation(
        async ({ ids = [] }: { ids: string[] }): Promise<any> => {
            return plainAxiosInstance.put(
                `${APIs.RC_ISSUES_LOG.PUT_ISSUES_LOG_CHECK}`,
                ids,
                // { idList: ids },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcIssuesLog", type]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcIssuesLog", type]);
                console.error(`Error on checking issues log:`, error);
                return error;
            },
        }
    );

    const {
        mutateAsync: doneIssueLog,
        isLoading: isLoadingDoneIssueLog,
        error: doneIssueLogError,
    } = useMutation(
        async ({ ids = [] }: { ids: string[] }): Promise<any> => {
            return plainAxiosInstance.put(
                `${APIs.RC_ISSUES_LOG.PUT_ISSUES_LOG_DONE}`,
                ids,
                // { idList: ids },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcIssuesLog", type]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcIssuesLog", type]);
                console.error(`Error on marking issues log as done:`, error);
                return error;
            },
        }
    );

    // update issues log

    const {
        mutateAsync: updateCommentIssueLog,
        isLoading: isLoadingUpdateCommentIssueLog,
        error: updateCommentIssueLogError,
    } = useMutation(
        async ({ ids, comment }: { ids: string[]; comment: string }): Promise<any> => {
            const formData = new FormData();
            formData.append("comment", comment);
            formData.append("ids", ids.join(","));
            return plainAxiosInstance.put(`${APIs.RC_ISSUES_LOG.PUT_ISSUES_LOG}`, formData, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcIssuesLog", type]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcIssuesLog", type]);
                console.error(`Error on marking issues log as done:`, error);
                return error;
            },
        }
    );

    // add issues log settings

    const {
        mutateAsync: addIssueLogSettings,
        isLoading: isLoadingAddIssueLogSettings,
        error: addIssueLogSettingsError,
    } = useMutation(
        async ({ login, loginName, mainServerId, type }: { login: number; loginName: string; mainServerId: string; type: number }): Promise<any> => {
            return plainAxiosInstance.post(
                `${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_SETTINGS}`,
                {
                    login,
                    loginName,
                    mainServerId,
                    type,
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                console.error(`Error on adding issues log settings:`, error);
                return error;
            },
        }
    );

    // delete issues log settings

    const {
        mutateAsync: deleteIssueLogSettings,
        isLoading: isLoadingDeleteIssueLogSettings,
        error: deleteIssueLogSettingsError,
    } = useMutation(
        async ({ login, loginName, mainServerId, type }: { login: number; loginName: string; mainServerId: string; type: number }): Promise<any> => {
            return plainAxiosInstance.delete(
                // .delete(`${restfulUrl}/setting?server=${data.mainServerId}&login=${data.login}&type=${props.logType}`)
                `${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_SETTINGS}?server=${mainServerId}&login=${login}&type=${type}`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                console.error(`Error on adding issues log settings:`, error);
                return error;
            },
        }
    );

    // upload issues log settings

    const {
        mutateAsync: uploadIssueLogSettings,
        isLoading: isLoadingUploadIssueLogSettings,
        error: uploadIssueLogSettingsError,
    } = useMutation(
        async ({ formData, type }: { formData: FormData; type: number }): Promise<any> => {
            return plainAxiosInstance.post(
                // .post(`${restfulUrl}/setting/file/` + props.logType, formData)
                `${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_SETTINGS_DOWNLOAD}/${type}`,
                formData
                // {
                //     headers: {
                //         Accept: "application/json",
                //     },
                // }
            );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                console.error(`Error on adding issues log settings:`, error);
                return error;
            },
        }
    );
    const {
        mutateAsync: uploadIssuesLogTypeList,
        isLoading: isLoadingUploadIssuesLogTypeList,
        error: uploadIssuesLogTypeListError,
    } = useMutation(
        async ({ formData, type }: { formData: FormData; type: "ACCOUNT_MONITOR" | "MANAGER_ACCOUNT_INTERCEPTED" }): Promise<any> => {
            const apiEndpoint = (type: string) => {
                switch (type) {
                    case "ACCOUNT_MONITOR":
                        return APIs.RC_ISSUES_LOG.POST_UPLOAD_ACCOUNT_MONITOR;
                    case "MANAGER_ACCOUNT_INTERCEPTED":
                        return APIs.RC_ISSUES_LOG.POST_UPLOAD_MANAGER_ACCOUNT_INTERCEPTED;
                    default:
                        return "";
                }
            };

            return plainAxiosInstance.post(apiEndpoint(type), formData);
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcIssuesLogSettings", type]);
                console.error(`Error on adding issues log settings:`, error);
                return error;
            },
        }
    );

    //POST_REQUEST_TIMEOUT_SETTINGS
    const {
        mutateAsync: updateRequestTimeoutSettings,
        isLoading: isLoadingUpdateRequestTimeoutSettings,
        error: updateRequestTimeoutSettingsError,
    } = useMutation(
        // use form data
        async ({ requestTimeout }: { requestTimeout: string }): Promise<any> => {
            const formData = new FormData();
            formData.append("frequency", requestTimeout);
            return plainAxiosInstance.post(`${APIs.RC_ISSUES_LOG.POST_REQUEST_TIMEOUT_SETTINGS}`, formData, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcRequestTimeoutSettings"]);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcRequestTimeoutSettings"]);
                console.error(`Error on adding issues log settings:`, error);
                return error;
            },
        }
    );

    return {
        deleteIssueLogNoMoney,
        isLoadingDeleteIssueLogNoMoney,
        deleteIssueLogNoMoneyError,

        updateIssueLogNoMoneySettings,
        isLoadingUpdateIssueLogNoMoneySettings,
        updateIssueLogNoMoneySettingsError,

        checkIssueLog,
        isLoadingCheckIssueLog,
        checkIssueLogError,

        doneIssueLog,
        isLoadingDoneIssueLog,
        doneIssueLogError,

        updateCommentIssueLog,
        isLoadingUpdateCommentIssueLog,
        updateCommentIssueLogError,

        addIssueLogSettings,
        isLoadingAddIssueLogSettings,
        addIssueLogSettingsError,

        deleteIssueLogSettings,
        isLoadingDeleteIssueLogSettings,
        deleteIssueLogSettingsError,

        uploadIssueLogSettings,
        isLoadingUploadIssueLogSettings,
        uploadIssueLogSettingsError,

        uploadIssuesLogTypeList,
        isLoadingUploadIssuesLogTypeList,
        uploadIssuesLogTypeListError,

        updateRequestTimeoutSettings,
        isLoadingUpdateRequestTimeoutSettings,
        updateRequestTimeoutSettingsError,
    };
};

export default useRCIssuesLog;
