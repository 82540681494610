import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { isEmptyOrNull } from "@/utils/string";
import { Col, Form, message, Modal, Row } from "antd";
import { useEffect } from "react";
import { BalanceUpdateUploadModalCallbackKey } from "./uploadFileModal";
import { ErrorMessageHandler } from "@/utils/Common";

export interface BalanceUpdatePageSingleModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    serverList: KeyValuePair[];
}

export enum BalanceUpdatePageSingleModalCallbackKey {
    Close = 0,
    SubmitSuccess = 1,
    SubmitFailed = 2,
    Submitting = 3,
}

const BalanceUpdatePageSingleModal = (props: BalanceUpdatePageSingleModalProps) => {
    const [executeForm] = Form.useForm();

    const confirmExecute = (data: any) => {
        let formData = { ...data };
        if (formData.hasOwnProperty("expiration") && formData["expiration"] !== undefined) {
            formData["expiration"] = formData["expiration"].toISOString();
        }

        Modal.confirm({
            title: "Are you sure you want to create the balance update?",
            onOk: () => {
                props.callback(BalanceUpdatePageSingleModalCallbackKey.Submitting, null);

                plainAxiosInstance
                    .post(`${APIs.RISK_TOOL.GET_BALANCE_UPDATE_UPLOAD_File}`, formData)
                    .then(res => {
                        if (res.status === 200 && res.data.indexOf("BalanceUpload") === -1) {
                            ErrorMessageHandler("Balance update created successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                            props.callback &&
                                props.callback(BalanceUpdatePageSingleModalCallbackKey.SubmitSuccess, `${formData["serverId"]},${res.data}`);
                        } else {
                            ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                            props.callback && props.callback(BalanceUpdatePageSingleModalCallbackKey.SubmitFailed, null);
                        }
                    })
                    .catch((error: any) => {
                        ErrorMessageHandler(`Create balance update failed: (${error.response.data.message})`, SUCCESS_FAILED.OTHERS_FAILED);
                        props.callback && props.callback(BalanceUpdatePageSingleModalCallbackKey.SubmitFailed, null);
                    });
            },
        });
    };

    useEffect(() => {
        if (props.isModalVisible) {
            executeForm.setFieldsValue({ selectedServer: "" });
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title="Create Balance Update Record"
            open={props.isModalVisible}
            okText="Execute"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        confirmExecute(values);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(BalanceUpdatePageSingleModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 6 }}>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Server"
                            name="serverId"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.serverList,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Login"
                            name="login"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Update Type"
                            name="updateType"
                            extra={{
                                type: ComponentType.dropdown,
                                value: [
                                    { text: "Balance", value: 0 },
                                    { text: "Credit", value: 1 },
                                ],
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Upload Currency"
                            name="uploadCurrency"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Upload Amount"
                            name="uploadAmount"
                            extra={{
                                type: ComponentType.number,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Comment"
                            name="comment"
                            extra={{
                                type: ComponentType.textarea,
                                value: "",
                                inputProps: {
                                    row: 6,
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Expiration"
                            name="expiration"
                            extra={{
                                type: ComponentType.date,
                                value: "",
                                inputProps: {
                                    showTime: { format: "HH:mm:ss" },
                                },
                                rules: [
                                    {
                                        validator: (_: any, value: any) => {
                                            let upType = executeForm.getFieldValue("updateType");

                                            if (upType !== undefined && `${upType}`.length > 0 && `${upType}` === "1" && isEmptyOrNull(value)) {
                                                return Promise.reject(`Expiration time is required.`);
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ],
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default BalanceUpdatePageSingleModal;
