import CardBox from "@/components/Common/CardBox";
import { Button, Collapse, Form, message, Modal } from "antd";
import ReadOnlyTable from "./components/ReadOnlyTable";
import AllTable from "./components/AllTable";
import SyndicateTradingTable from "./components/SyndicateTradingTable";
import { FormComponent } from "@/components/FormComponent";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { useState } from "react";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";

export interface SyndicateTradingData {
    id: any;
    batchNum: number;
    server: string;
    serverUno: number;
    ticket: number;
    login: number;
    group: string;
    openTime: string;
    cmd: number;
    symbol: string;
    symbolBasic: any;
    volume: number;
    openPrice: number;
    sl: number;
    tp: number;
    reason: number;
    profit: number;
    comment: string;
    userComment: string | null;
    createTime: string;
    openTimeStr: string;
    createTimeStr: string;
    isInMinute: number;
    isNewDaa: number;
    sumVolume: number;
    changeReason: string | null;
    isReplenish: any;
    userCity: string;
    exposure: number;
    dataSources: string;
}

const SyndicateTrading = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.SYNDICATE_TRADING_EDIT);

    const [filterForm] = Form.useForm();
    const [runRefetchDataList, setRunRefetchData] = useState<boolean>(false);

    const onFinished = (values: any) => {
        Modal.confirm({
            title: "Are you sure you want to set readonly?",
            icon: <ExclamationCircleOutlined />,
            content: `Batch Number: ${values.batchId}`,
            onOk: () => {
                plainAxiosInstance
                    .get(`${APIs.RC_SYNDICATE_TRADING.GET_SET_READ_ONLY}?batchId=${values.batchId}`)
                    .then(res => {
                        const data: any = res.data;
                        if (data.code === 0) {
                            Modal.success({
                                title: "Successfully set readonly",
                                content: data.value ? data.value : "",
                            });
                            setRunRefetchData(true);
                        } else {
                            Modal.error({
                                icon: <ExclamationCircleOutlined />,
                                title: "Failed to set readonly",
                                content: data.desc ? data.desc : "",
                            });
                            setRunRefetchData(false);
                        }
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Failed to set readonly`, SUCCESS_FAILED.OTHERS_FAILED, err))
                    );
            },
            onCancel: () => {},
        });
    };

    return (
        <CardBox title="Syndicate Trading">
            <div className="syndicate-trading-container">
                {enableUpdate && (
                    <div className="set-read-only-form">
                        <Form form={filterForm} layout="inline" onFinish={onFinished}>
                            <FormComponent
                                label=""
                                name="batchId"
                                extra={{
                                    type: ComponentType.number,
                                    value: 0,
                                    rules: [{ required: true, message: "Please input batch number!" }],
                                    inputProps: {
                                        placeholder: "Batch Number",
                                        style: { width: "250px" },
                                    },
                                }}
                            />
                            <Form.Item>
                                <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
                                    Set ReadOnly
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                )}
                <Collapse defaultActiveKey={["2", "3"]}>
                    <Collapse.Panel header="ReadOnly" key="1">
                        <ReadOnlyTable refreshState={runRefetchDataList} ableToEdit={enableUpdate} />
                    </Collapse.Panel>
                    <Collapse.Panel header="Volume >=3" key="2">
                        <SyndicateTradingTable />
                    </Collapse.Panel>
                    <Collapse.Panel header="All" key="3">
                        <AllTable />
                    </Collapse.Panel>
                </Collapse>
            </div>
        </CardBox>
    );
};

export default SyndicateTrading;
