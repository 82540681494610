import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { CreateEditInlineFormBased, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest, APIs } from "@/services/apiConfig";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { useEffect, useState } from "react";
import BridgeCreateEditPageV2 from "./BridgeCreateEditPage";

export type BridgeSettingList = {
    bridgeId: number;
    bridgeName: string;
    bridgeDisplayName: string;
    bridgeType: string;
    bridgeTypeId: number;
    enable: boolean;
    version: string;
    host: string;
    login: string;
    password: string;
    port: number;
    brokerVhost: string;
    brokerLogin: string;
    brokerPassword: string;
};

const BridgeSettingPage = () => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [refetch, setRefetch] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<BridgeSettingList[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_BRIDGE_SETTINGS_EDIT);

    const columns: any[] = [
        {
            title: "Bridge Name",
            dataIndex: "bridgeName",
            key: "bridgeName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
            sorter: (a: any, b: any) => a.bridgeName.localeCompare(b.bridgeName),
        },
        {
            title: "Bridge Display Name",
            dataIndex: "bridgeDisplayName",
            key: "bridgeDisplayName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
            sorter: (a: any, b: any) => a.bridgeDisplayName.localeCompare(b.bridgeDisplayName),
            defaultSortOrder: "ascend",
        },
        DTColProps.XSmall({
            title: "Bridge Type",
            dataIndex: "bridgeType",
            key: "bridgeType",
        }),
        DTColProps.Middle({
            title: "Host",
            dataIndex: "host",
            key: "host",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.XSmall({
            title: "Port",
            dataIndex: "port",
            key: "port",
        }),
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.Small({
            title: "VHost",
            dataIndex: "brokerVhost",
            key: "brokerVhost",
        }),
        DTColProps.Small({
            title: "BrokerLogin",
            dataIndex: "brokerLogin",
            key: "brokerLogin",
        }),
        DTColProps.XSmall({
            title: "Version",
            dataIndex: "version",
            key: "version",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Status({
            title: "Status",
            dataIndex: "enable",
            key: "enable",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Active", value: true },
                        { text: "Inactive", value: false },
                    ],
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: FormData.bridgeId });
                setRefetch(prev => prev + 1);
                break;
            default:
                break;
        }
    };

    const getBridgeList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRIDGE_LIST, {})
            .then((res: any) => {
                setData(res && res.length > 0 ? res : []);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getBridgeList();

        return () => {};
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Bridge Management</span>
                </div>
            </div>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <div className="v2-bridge-setting">
                        <FlexiDataTable
                            bordered
                            rowKeyProperty="bridgeId"
                            title=""
                            columns={columns}
                            options={options}
                            dataSource={data}
                            callback={componentCallback}
                            loading={isLoading}
                        />
                    </div>
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <BridgeCreateEditPageV2
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        getBridgeList();
                                    }
                                    break;
                            }
                        }}
                        resetState={refetch}
                    />
                </div>
            </div>
        </>
    );
};

export default BridgeSettingPage;
