import { FormComponent } from "@/components/FormComponent";
import {
    ComponentType,
    TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE,
    TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM,
    TOXIC_CLIENT_LABEL_UI_OPTION_TYPE,
    TIMEZONE_FORMATS,
    TOXIC_CLIENT_LABEL_UI_OPTION_TYPE_ENUM,
} from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { ToOptionTypeList } from "@/utils/array";
import {
    FieldNumberOutlined,
    CalculatorOutlined,
    DownCircleOutlined,
    CheckSquareOutlined,
    CalendarOutlined,
    ClockCircleOutlined,
    ScheduleOutlined,
} from "@ant-design/icons";
import { FormInstance, Form, Row, Col } from "antd";
import AttrOptionsCreateEdit from "./AttrOptionsCreateEdit";
import text_field_icon from "@/assets/icons/short_text_icon.png";
import paragraph_icon from "@/assets/icons/paragraph_icon.png";
import radio_icon from "@/assets/icons/radio_icon.png";
import switch_icon from "@/assets/icons/switch_icon.png";

interface AttributeCreateEditProps {
    form: FormInstance;
    data: any;
    isAddAction: boolean;
    isAddLabelAttr: boolean;
}

const initialValues = {
    attrId: 0,
    isAttrActive: true,
    isMultiselectable: false,
    isMandatory: false,
    attrName: "",
    attrDescription: "",
};

const AttributeCreateEdit = (props: AttributeCreateEditProps) => {
    const currUiElementType = Form.useWatch("uiElementType", props.form);
    const currOptionType = Form.useWatch("optionType", props.form);

    return (
        <div className="create-edit-modal">
            <Form form={props.form} layout="horizontal" labelCol={{ span: 7 }} wrapperCol={{ span: 14 }} initialValues={initialValues}>
                <Row>
                    <Col span={24}>
                        <FormComponent label={""} name={"attrId"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent
                            label="Name"
                            name="attrName"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Description"
                            name="attrDescription"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Status"
                            name="isAttrActive"
                            extra={{
                                type: ComponentType.switch,
                                value: ["Inactive", "Active"],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Is Mandatory"
                            name="isMandatory"
                            extra={{
                                type: ComponentType.switch,
                                value: ["No", "Yes"],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="UI Element Type"
                            name="uiElementType"
                            extra={{
                                type: ComponentType.dropdown,
                                value: [
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <img src={text_field_icon} alt="text-field-icon" style={{ height: 18 }} />
                                                </Col>
                                                <Col>{TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.TextField]}</Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.TextField,
                                    },
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <img src={paragraph_icon} alt="text-area-icon" style={{ height: 18 }} />
                                                </Col>
                                                <Col>{TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.TextArea]}</Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.TextArea,
                                    },
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <FieldNumberOutlined />
                                                </Col>
                                                <Col>
                                                    {TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.IntegerNumericField]}
                                                </Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.IntegerNumericField,
                                    },
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <CalculatorOutlined />
                                                </Col>
                                                <Col>
                                                    {TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DecimalNumericField]}
                                                </Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DecimalNumericField,
                                    },
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <DownCircleOutlined />
                                                </Col>
                                                <Col>{TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DropdownMenu]}</Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DropdownMenu,
                                    },
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <CheckSquareOutlined />
                                                </Col>
                                                <Col>{TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.Checkbox]}</Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.Checkbox,
                                    },
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <img src={radio_icon} alt="radio-btn-icon" style={{ height: 16 }} />
                                                </Col>
                                                <Col>{TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.RadioButton]}</Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.RadioButton,
                                    },
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <img src={switch_icon} alt="switch-icon" style={{ height: 16 }} />
                                                </Col>
                                                <Col>
                                                    {TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.ToggleSwitcher]}
                                                </Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.ToggleSwitcher,
                                    },
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <CalendarOutlined />
                                                </Col>
                                                <Col>{TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker]}</Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker,
                                    },
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <ClockCircleOutlined />
                                                </Col>
                                                <Col>{TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.TimePicker]}</Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.TimePicker,
                                    },
                                    {
                                        text: (
                                            <Row>
                                                <Col span={2}>
                                                    <ScheduleOutlined />
                                                </Col>
                                                <Col>
                                                    {TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE[TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker]}
                                                </Col>
                                            </Row>
                                        ),
                                        value: TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker,
                                    },
                                ],
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                disabled:
                                    props.isAddAction === true && props.isAddLabelAttr === true
                                        ? false
                                        : props.isAddAction === true && props.isAddLabelAttr === false
                                        ? false
                                        : props.isAddAction === false && props.isAddLabelAttr === true
                                        ? false
                                        : true,
                                itemProps: {
                                    tooltip: "Once created, can't be changed",
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    {currUiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DropdownMenu && (
                        <Col flex={2} offset={3}>
                            <FormComponent
                                label="Option Type"
                                name="optionType"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: ToOptionTypeList(TOXIC_CLIENT_LABEL_UI_OPTION_TYPE),
                                }}
                            />
                        </Col>
                    )}
                    {(currUiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.RadioButton ||
                        currUiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.Checkbox) && (
                        <Col span={24}>
                            <FormComponent
                                label="Option Type"
                                name="optionType"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: ToOptionTypeList(TOXIC_CLIENT_LABEL_UI_OPTION_TYPE),
                                }}
                            />
                        </Col>
                    )}
                    {currUiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DropdownMenu && (
                        <Col flex={1}>
                            <FormComponent
                                label=""
                                name="isMultiselectable"
                                extra={{
                                    type: ComponentType.checkbox,
                                    value: "Allow multi select",
                                }}
                            />
                        </Col>
                    )}
                    {(currUiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker ||
                        currUiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker) && (
                        <Col span={24}>
                            <FormComponent
                                label="Time Zone Format"
                                name="timeZoneFormat"
                                extra={{
                                    type: ComponentType.radio,
                                    value: ToOptionTypeList(TIMEZONE_FORMATS),
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    )}
                </Row>
                {currOptionType === TOXIC_CLIENT_LABEL_UI_OPTION_TYPE_ENUM.CUSTOMIZED && <AttrOptionsCreateEdit />}
            </Form>
        </div>
    );
};

export default AttributeCreateEdit;
