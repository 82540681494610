import { useEffect, useMemo, useState } from "react";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { isEmptyOrNull } from "../../../utils/string";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { NegativeEquityClosedTradeData } from "./type";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "../../../constants/type";
import moment from "moment";
import { Button, message, Modal } from "antd";
import { ContainerOutlined, DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { matchSorter } from "match-sorter";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "../ReportBatch";
import { REQUIRED_FIELD } from "@/constants/errorMessage";

interface ClosedTradeTabProps {
    servers: any[];
}

const ClosedTradeTab = (props: ClosedTradeTabProps) => {
    const [data, setData] = useState<NegativeEquityClosedTradeData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [servers, setServers] = useState<any[]>([]);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({
        serverId: 0,
        closeTime: moment().format("YYYY-MM-DD"),
    });
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);

    const columns = useMemo(
        () => [
            {
                title: "Date",
                dataIndex: "closeTime",
                key: "closeTime",
                options: {
                    filter: {
                        type: ComponentType.date,
                        value: "",
                        dateFormat: "YYYY-MM-DD",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    },
                    visible: false,
                },
            },
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "server",
                key: "server",
                fixed: "left",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    },
                },
            }),
            {
                title: "Server (All), Login, Brand, Group, Ticket, Symbol or CMD",
                dataIndex: "searchAll",
                key: "searchAll",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                    visible: false,
                },
            },
            DTColProps.XSmall({
                title: "Login",
                dataIndex: "login",
                key: "login",
                fixed: "left",
            }),
            DTColProps.Small({
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                fixed: "left",
            }),
            DTColProps.Middle({
                title: "Group",
                dataIndex: "group",
                key: "group",
            }),
            DTColProps.XSmall({
                title: "Ticket",
                dataIndex: "ticket",
                key: "ticket",
            }),
            DTColProps.XSmall({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
            }),
            DTColProps.XXSmall(
                {
                    title: "CMD",
                    dataIndex: "cmd",
                    key: "cmd",
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Volume",
                    dataIndex: "volume",
                    key: "volume",
                },
                ["text-right"]
            ),
            DTColProps.Middle({
                title: "Open Time",
                dataIndex: "openTime",
                key: "openTime",
                width: "10vw",
                render: (datetime: string) => (!isEmptyOrNull(datetime) ? datetime.replace("T", " ") : datetime),
            }),
            DTColProps.Small(
                {
                    title: "Open Price",
                    dataIndex: "openPrice",
                    key: "openPrice",
                    render: (openPrice: number) => currencyRender(openPrice.toFixed(5)),
                },
                ["text-right"]
            ),
            DTColProps.Middle({
                title: "Close Time",
                dataIndex: "closeTime",
                key: "closeTime",
                width: "10vw",
                render: (datetime: string) => (!isEmptyOrNull(datetime) ? datetime.replace("T", " ") : datetime),
            }),
            DTColProps.Small(
                {
                    title: "Close Price",
                    dataIndex: "closePrice",
                    key: "closePrice",
                    render: (closePrice: number) => currencyRender(closePrice.toFixed(5)),
                },
                ["text-right"]
            ),
            DTColProps.Small(
                {
                    title: "Profit",
                    dataIndex: "profit",
                    key: "profit",
                    render: (profit: number) => currencyRender(profit.toFixed(2)),
                },
                ["text-right"]
            ),
        ],
        [servers]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            export: {
                text: "",
                Element: (
                    <Button
                        key={"ner-export"}
                        icon={<DownloadOutlined />}
                        style={{ marginLeft: 10 }}
                        loading={isExporting}
                        onClick={() => downloadNEClosedTradeReport(filterParams)}
                    >
                        Download Report
                    </Button>
                ),
            },
            serverFiltering: true,
            extraButtons: [{ text: "Report Listing", value: "reportlist", icon: <ContainerOutlined /> }],
        }),
        [isExporting, filterParams]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                let feSearchFilters: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "server") {
                            fParams["serverId"] = FormData[x];
                        } else if (x === "closeTime") {
                            fParams["closeTime"] = moment(FormData[x]).format("YYYY-MM-DD");
                        } else if (x === "searchAll") {
                            feSearchFilters["searchAll"] = FormData[x];
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                if (fParams.serverId !== filterParams.serverId) {
                    if (feSearchFilters?.searchAll) setSearchValue(feSearchFilters?.searchAll);
                    setFilterParams(fParams);
                    setRunRefetchDataList(true);
                } else setSearchValue(feSearchFilters?.searchAll ?? "");
                break;
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "reportlist":
                        setIsReportModalVisible(true);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    };

    const downloadNEClosedTradeReport = (fParams: any) => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_NEGATIVE_EQUITY_RECORD.GET_NE_CLOSED_TRADE_REPORT_DOWNLOAD}?serverId=${fParams.serverId}&closeTime=${fParams.closeTime}`)
            .then(response => {
                if (response.data === 0) {
                    Modal.success({
                        width: "30%",
                        title: "Successfully added batch",
                        content: "Please download the report from 【Report Listing】 once it is completed",
                    });
                } else {
                    Modal.error({
                        icon: <ExclamationCircleOutlined />,
                        title: "Failed to add batch",
                        content: "Please try again later",
                    });
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Failed to add download batch`, SUCCESS_FAILED.OTHERS_FAILED, err))
            )
            .finally(() => setIsExporting(false));
    };

    const getNEClosedTrades = () => {
        plainAxiosInstance
            .get(`${APIs.RC_NEGATIVE_EQUITY_RECORD.GET_NE_CLOSED_TRADE_DATA}?serverId=${filterParams.serverId}&closeTime=${filterParams.closeTime}`)
            .then((res: any) => {
                if (res.status === 200) {
                    let newData = res.data.map((x: any) => ({
                        ...x,
                        key: `${x.serverId}|${x.ticket}`,
                    }));
                    setData(newData);
                } else {
                    setData([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("negative equity closed trades", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                })
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getNEClosedTrades();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        if (props.servers.length > 0) {
            setServers(props.servers.map((x: any) => ({ value: x.id, text: x.name })));
            setRunRefetchDataList(true);
        } else {
            setServers([]);
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [props.servers]);

    const filteredData: NegativeEquityClosedTradeData[] = useMemo(() => {
        return matchSorter(data ?? [], searchValue ?? "", {
            keys: ["server", "login", "brand", "group", "ticket", "symbol", "cmd"],
        });
    }, [data, searchValue]);

    return (
        <div className="ner-closed-trade-tab">
            <FlexiDataTable
                bordered
                rowKeyProperty="key"
                title=""
                columns={columns}
                options={options}
                dataSource={filteredData || []}
                callback={componentCallback}
                loading={isLoading}
                filterInitialValue={{ server: 0, closeTime: moment() }}
            />
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "NegativeEquityClosedTrade"}
            />
        </div>
    );
};

export default ClosedTradeTab;
