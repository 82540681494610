import { useState } from "react";
import { useRCBalanceAdjustmentMutate, useRCBalanceAdjustmentSettings } from "@/hooks/useRCBalanceAdjustment";
import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import moment from "moment";

interface BalanceAdjustmentServersProps {
    enableUpdate: boolean;
}

const BalanceAdjustmentServers = (CProps: BalanceAdjustmentServersProps) => {
    const { rcBalanceAdjustmentSettings, isLoading } = useRCBalanceAdjustmentSettings();
    const { mutateUploadBalanceAdjustmentSettings, isLoadingUploadBalanceAdjustmentSettings } = useRCBalanceAdjustmentMutate();
    const [isExporting, setIsExporting] = useState<boolean>(false);

    const columns = [
        {
            title: "Server Name",
            dataIndex: "serverName",
            key: "serverName",
            sorter: (a: any, b: any) => a?.serverName.localeCompare(b?.serverName),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        {
            title: "Login",
            dataIndex: "login",
            key: "login",
            sorter: (a: any, b: any) => a?.login - b?.login,
            options: {
                filter: {
                    type: ComponentType.number,
                    value: "",
                },
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a: any, b: any) => a?.name.localeCompare(b?.name),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        {
            title: "IP",
            dataIndex: "ip",
            key: "ip",
            render: (ip: string) => ip.replace(/,|，/g, ", "),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a?.ip?.toLowerCase() < b?.ip?.toLowerCase()) {
                    return -1;
                }
                if (a?.ip?.toLowerCase() > b?.ip?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
    ];

    const handleSubmit = (file: any) => {
        let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
            isLt5M = file.size / 1024 / 1024 < 5;

        if (fileExtension[0] !== ".xlsx") {
            ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        } else if (!isLt5M) {
            ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        }

        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure you want to import?",
            content: <p>{file?.name}</p>,
            width: "30%",
            okButtonProps: { loading: isLoadingUploadBalanceAdjustmentSettings },
            onOk: () => {
                var formData = new FormData();
                formData.append("uploadFile", file);

                handleUpload({ formData });
            },
            onCancel: () => {},
        });
    };

    const handleDownload = async () => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_ISSUES_LOG.GET_BALANCE_ADJUSTMENT_SETTINGS_DOWNLOAD}`, {
                headers: {
                    Accept: "application/octet-stream,text/csv, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                // console.log(response);
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "text/csv;charset=UTF-8" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const getCurrentDate = moment().format("YYYYMMDDHHmmss");
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `Balance_Adjustment_Settings_${getCurrentDate}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    ErrorMessageHandler(`Balance adjustment settings`, SUCCESS_FAILED.SUCCESS_DOWNLOAD_DATA);
                } else {
                    ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler(`Failed to download balance adjustment settings. Error`, SUCCESS_FAILED.OTHERS_FAILED, err)
                )
            )
            .finally(() => setIsExporting(false));
    };

    const options: FlexiDataTableOptionsProps = {
        export: { text: "Download" },
        ...(CProps.enableUpdate && {
            upload: {
                name: "file",
                multiple: false,
                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                showUploadList: false,
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any) => handleSubmit(file),
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                handleDownload();
                break;
            default:
                break;
        }
    };

    const handleUpload = async ({ formData }: { formData: FormData }) => {
        try {
            const resp = await mutateUploadBalanceAdjustmentSettings(formData);
            if (resp.data === 0) {
                ErrorMessageHandler(`File uploaded successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
            } else {
                ErrorMessageHandler(`File upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
            }
        } catch (error) {
            ErrorMessageHandler(`File upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    return (
        <div className="single-page">
            <div className={`active`}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="serverUno"
                    title={false}
                    columns={columns}
                    dataSource={rcBalanceAdjustmentSettings}
                    loading={isLoading || isLoadingUploadBalanceAdjustmentSettings}
                    options={options}
                    callback={componentCallback}
                    pagination={{ defaultPageSize: 50, pageSizeOptions: [10, 20, 50, 100, 500, 1000] }}
                    exporting={isExporting}
                />
            </div>
        </div>
    );
};

export default BalanceAdjustmentServers;
