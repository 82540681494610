import { useEffect, useState } from "react";
import PriceMonitorBreadCrumbs from "../components/PriceMonitorBreadCrumbs";
import { Space, Tabs } from "antd";
import TabContent from "../../SystemMonitor/components/TabContent";
import useRCOutOfPriceAlarm from "../../../hooks/useRCOutOfPriceAlarm";
import OutOfPriceAlarmTable from "./OutOfPriceAlarmTable";

const OutOfPriceAlarm = () => {
    const [currTab, setCurrTab] = useState<string | null>(null);
    // Ensure user can change tab by URL params
    useEffect(() => {
        const currTabParam = new URLSearchParams(window.location.search).get("tab");
        if (currTabParam) {
            setCurrTab(currTabParam);
        } else {
            setCurrTab("alarm");
        }
    }, []);

    // Handle popstate event
    useEffect(() => {
        const handlePopState = () => {
            const currTabParam = new URLSearchParams(window.location.search).get("tab");
            if (currTabParam) {
                setCurrTab(currTabParam);
            } else {
                setCurrTab("alarm");
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };
    if (!currTab) return null;

    return (
        <div style={{ marginBottom: "3rem" }}>
            <PriceMonitorBreadCrumbs currMenu="Out of Price Alarm" />
            <Tabs
                activeKey={currTab}
                onChange={handleChangeTab}
                type="card"
                style={{ marginBottom: 0 }}
                items={[
                    {
                        label: "Unsolved Alarm in 7 days",
                        key: "alarm",
                    },
                    {
                        label: "Solved in 7 days",
                        key: "solved",
                    },
                ]}
                tabBarStyle={{ marginBottom: 0 }}
            />
            <TabContent>
                <Space direction="vertical" size={4} style={{ width: "100%", padding: "1rem" }}>
                    {currTab === "alarm" && <TabAlarm />}
                    {currTab === "solved" && <TabSolved />}
                </Space>
            </TabContent>
        </div>
    );
};

const TabAlarm = () => {
    const { rcOutOfPriceAlarm } = useRCOutOfPriceAlarm({ type: "alarm" });
    return (
        <div>
            <OutOfPriceAlarmTable type="alarm" data={rcOutOfPriceAlarm} />
        </div>
    );
};
const TabSolved = () => {
    const { rcOutOfPriceAlarm } = useRCOutOfPriceAlarm({ type: "solved" });
    return (
        <div>
            <OutOfPriceAlarmTable type="solved" data={rcOutOfPriceAlarm} />
        </div>
    );
};

export default OutOfPriceAlarm;
