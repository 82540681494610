import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { Button, Col, Form, message, Modal, Row, Table, Upload } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { FormComponent } from "@/components/FormComponent";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import moment from "moment";
import { WhitelistSettingsList } from "@/pages/AdminTools/RiskTools/CID/components/type";

export interface WhitelistSettingProps {}

const WhitelistSetting = (props: WhitelistSettingProps) => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CID_EDIT);

    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [data, setData] = useState<WhitelistSettingsList[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [whitelistSettingForm] = Form.useForm();
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isAddAction, setIsAddAction] = useState<boolean>(true);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<WhitelistSettingsList[]>([]);

    const columns = [
        DTColProps.Middle({
            title: "Server",
            dataIndex: "mainServerId",
            key: "mainServerId",
            sorter: (a: any, b: any) => a.mainServerId.localeCompare(b.mainServerId),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Login",
            dataIndex: "login",
            key: "login",
            sorter: (a: any, b: any) => a.login - b.login,
            options: {
                filter: {
                    type: ComponentType.number,
                    value: "",
                },
            },
        },
    ];

    const handleModalCancel = () => {
        setIsModalOpen(false);
        whitelistSettingForm.resetFields();
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsModalOpen(true);
                setIsAddAction(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                downloadWhitelistSetting();
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedRowKeys(FormData.selectedRowKeys);
                break;
            case CALLBACK_KEY.ON_SELECT_TABLE_ROW_CALLBACK:
                setSelectedData(FormData.selectedRows);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "batch-delete") {
                    batchDeleteWhitelistSettings();
                }
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                plainAxiosInstance
                    .delete(`${APIs.RC_CID.DELETE_BATCH_WHITELIST_SETTING}?id=${FormData.newKey}`)
                    .then((res: any) => {
                        if (res.data.length === 0) {
                            ErrorMessageHandler("Whitelist setting", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            setRunRefetchDataList(true);
                            setSelectedRowKeys([]);
                            setSelectedData([]);
                        } else {
                            ErrorMessageHandler("whitelist setting", SUCCESS_FAILED.FAILED_DELETE_DATA);
                            setIsLoading(false);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => {
                            ErrorMessageHandler("whitelist setting", SUCCESS_FAILED.FAILED_DELETE_DATA, err);
                            setIsLoading(false);
                        })
                    );
                break;
            default:
                break;
        }
    };

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            export: { text: "Download" },
            add: enableUpdate,
            // edit: enableUpdate,
            delete: enableUpdate,
            separateActionButton: true,
            enableRowSelection: true,
            hideRowSelectionsSummary: true,
            rowSelectionData: {
                rowSelectionType: "checkbox",
                selectedRowKeys: selectedRowKeys,
                options: {
                    fixed: "left",
                    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
                    preserveSelectedRowKeys: true,
                },
            },
            extraButtons: () => {
                if (enableUpdate)
                    return (
                        <div className="extra-header-buttons" key={"cid-ws-extra-buttons"}>
                            <Button
                                key={"cid-ws-batch-delete"}
                                icon={<DeleteOutlined />}
                                onClick={() => componentCallback(CALLBACK_KEY.OTHERS, "batch-delete")}
                                disabled={selectedRowKeys.length === 0}
                            >
                                Batch Delete
                            </Button>
                            <Upload
                                key={"cid-ls-batch-upload"}
                                showUploadList={false}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                beforeUpload={file => {
                                    return new Promise((resolve, reject) => {
                                        uploadWhitelistSettings(file);
                                        reject();
                                    });
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload Excel</Button>
                            </Upload>
                        </div>
                    );
                else return null;
            },
        };
    }, [enableUpdate, selectedRowKeys]);

    const onFormSubmit = (obj: any) => {
        setIsBtnLoading(true);
        if (isAddAction) {
            plainAxiosInstance
                .post(`${APIs.RC_CID.POST_CREATE_WHITELIST_SETTING}`, obj)
                .then((res: any) => {
                    if (res.data.length === 0) {
                        ErrorMessageHandler("Whitelist setting", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        setIsModalOpen(false);
                        setRunRefetchDataList(true);
                        whitelistSettingForm.resetFields();
                    } else {
                        ErrorMessageHandler(`Failed to create whitelist setting due to duplication of existing data.`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("whitelist setting", SUCCESS_FAILED.FAILED_CREATE_DATA, err);
                    })
                )
                .finally(() => setIsBtnLoading(false));
        } else {
            /**
             *  12/09/2024 - Xin
             *  - Edit feature is available but it's commented out in RC for now
             */
            // plainAxiosInstance
            //     .post(`${APIs.RC_CID.POST_UPDATE_WHITELIST_SETTING}`, obj)
            //     .then((res: any) => {
            //         if (res.data.length === 0) {
            //             ErrorMessageHandler("Whitelist setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
            //             setIsModalOpen(false);
            //             setRunRefetchDataList(true);
            //             whitelistSettingForm.resetFields();
            //         } else {
            //                ErrorMessageHandler(`Failed to update whitelist setting${res.data.length > 0 && `: ${res.data}`}.`, SUCCESS_FAILED.OTHERS_FAILED);
            //         };
            //     })
            //     .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
            //         ErrorMessageHandler("whitelist setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
            //     }))
            //     .finally(() => setIsBtnLoading(false));
        }
    };

    const getWhtelistSettingList = () => {
        plainAxiosInstance
            .get(`${APIs.RC_CID.GET_WHITELIST_SETTING}`)
            .then((res: any) => {
                if (res.status === 200) {
                    let newData: any = res.data.map((x: any) => ({
                        ...x,
                        newKey: x.mainServerId + "_" + x.login,
                    }));
                    setData(newData);
                } else setData([]);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("whitelist settings", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                })
            )
            .finally(() => setIsLoading(false));
    };

    const downloadWhitelistSetting = () => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_CID.GET_WHITELIST_SETTING_DOWNLOAD}`, {
                headers: { Accept: "application/octet-stream, */*" },
                responseType: "blob",
            })
            .then(res => {
                const fileName = `Cid_whitelist_setting-${moment().format("YYYYMMDD_HHmmss")}.xlsx`;
                const contentType = res.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${fileName}`); // or any other extension
                    link.setAttribute("type", "hidden");
                    document.body.appendChild(link);
                    link.click();
                    if (link.parentNode) {
                        link.parentNode.removeChild(link); // Clean up and remove the link
                    } else document.body.removeChild(link);
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    ErrorMessageHandler(`Received non-file response. Error: ${res}`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch(err => {
                ErrorMessageHandler(`Download error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
            })
            .finally(() => setIsExporting(false));
    };

    const batchDeleteWhitelistSettings = useCallback(() => {
        Modal.confirm({
            title: "Are you sure you want to delete these whitelist settings?",
            content: (
                <ul>
                    {selectedData.map((x: WhitelistSettingsList) => (
                        <li key={x.newKey}>
                            {x.mainServerId} - {x.login}
                        </li>
                    ))}
                </ul>
            ),
            width: "30%",
            okText: "Confirm",
            onOk: () => {
                setIsLoading(true);
                plainAxiosInstance
                    .delete(`${APIs.RC_CID.DELETE_BATCH_WHITELIST_SETTING}?id=${selectedRowKeys.join(",")}`)
                    .then((res: any) => {
                        if (res.data.length === 0) {
                            ErrorMessageHandler("Whitelist settings", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            setRunRefetchDataList(true);
                            setSelectedRowKeys([]);
                            setSelectedData([]);
                        } else {
                            ErrorMessageHandler("whitelist settings", SUCCESS_FAILED.FAILED_DELETE_DATA);
                            setIsLoading(false);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => {
                            ErrorMessageHandler("whitelist settings", SUCCESS_FAILED.FAILED_DELETE_DATA, err);
                            setIsLoading(false);
                        })
                    );
            },
        });
    }, [selectedData, selectedRowKeys]);

    const uploadWhitelistSettings = (file: any) => {
        Modal.confirm({
            title: "Are you sure you want to upload?",
            width: "30%",
            okText: "Confirm",
            onOk: () => {
                setIsLoading(true);
                const formData = new FormData();
                formData.append("uploadFile", file);
                plainAxiosInstance
                    .post(`${APIs.RC_CID.POST_UPLOAD_LABEL_SETTING}`, formData)
                    .then((res: any) => {
                        if (res.status === 200) {
                            if (res.data === 0) {
                                ErrorMessageHandler("Whitelist setting", SUCCESS_FAILED.SUCCESS_UPLOAD_DATA);
                                setRunRefetchDataList(true);
                            } else {
                                ErrorMessageHandler(`Failed to upload whitelist setting: ${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                                setIsLoading(false);
                            }
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => {
                            ErrorMessageHandler("whitelist settings", SUCCESS_FAILED.FAILED_UPLOAD_DATA, err);
                            setIsLoading(false);
                        })
                    );
            },
        });
    };

    const getServersList = () => {
        plainAxiosInstance
            .get(`${APIs.RC_CID.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setServers(res.data.map((x: any) => ({ value: x.serverId, text: x.serverName })) as KeyValuePair[]);
                } else setServers([]);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("error during pull servers.", err)));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getWhtelistSettingList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        getServersList();
        return () => {};
    }, []);

    return (
        <div className="whitelist-setting-tab">
            <FlexiDataTable
                bordered
                rowKeyProperty={"newKey"} // record.mainServerId_record.login
                title={false}
                columns={columns}
                options={options}
                callback={componentCallback}
                dataSource={data}
                loading={isLoading}
                exporting={isExporting}
            />
            <Modal
                width={700}
                title={`${isAddAction ? "Create New" : "Edit"} Whitelist Setting`}
                open={isModalOpen}
                maskClosable={false}
                onCancel={handleModalCancel}
                footer={[
                    <Button key={"cancel"} onClick={handleModalCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key={"regular-submit"}
                        type="primary"
                        htmlType="submit"
                        loading={isBtnLoading}
                        onClick={() => {
                            whitelistSettingForm
                                .validateFields()
                                .then(res => {
                                    onFormSubmit(res);
                                })
                                .catch(err =>
                                    ErrorMessageHandler(`Failed to submit form. Please check the form for errors.`, SUCCESS_FAILED.OTHERS_FAILED)
                                );
                        }}
                    >
                        Submit
                    </Button>,
                ]}
            >
                <Form form={whitelistSettingForm} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} layout="horizontal" initialValues={{}}>
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Server"
                                name={"mainServerId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: servers,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <FormComponent
                                label="Login"
                                name={"login"}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default WhitelistSetting;
