import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";
import { InnerPageActionMode, SUCCESS_FAILED, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { Form, Row, Col } from "antd";
import { useMemo, useState, useCallback, useEffect } from "react";

export interface DashboardConfigurationCreateEditPageProps {
    mode: InnerPageActionMode;
    groupId?: number;
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

interface HubfailoverGroupProps {
    id?: number;
    groupType: number;
    groupName: string;
    sequence: number;
    dashboardSourceGroupIds: HubfailoverGroupItemProps[];
    dashboardSecurityGroupIds: HubfailoverGroupItemProps[];
    isDeleted?: boolean;
}

interface HubfailoverGroupItemProps {
    id: number;
    groupName: string;
}

const defaultValue = {
    id: "",
    groupName: "",
    sequence: 1,
    dashboardSourceGroupIds: [],
    dashboardSecurityGroupIds: [],
};

const DashboardConfigurationCreateEditPage = (props: DashboardConfigurationCreateEditPageProps) => {
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [securities, setSecurities] = useState<KeyValuePair[]>([]);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [oldValue, setOldValue] = useState<HubfailoverGroupProps | undefined>(undefined);
    const [groupForm] = Form.useForm();

    const onSubmit = useCallback(
        (values: any) => {
            if (isAddAction) {
                apiRequest(APIs.CREATE_HUB_FAILOVER_SERVER_SECURITY_GROUP_CONFIG, {
                    groupType: 2,
                    groupName: values.groupName,
                    sequence: values.sequence,
                    dashboardSourceGroupIds: values.dashboardSourceGroupIds,
                    dashboardSecurityGroupIds: values.dashboardSecurityGroupIds,
                })
                    .then(res => {
                        ErrorMessageHandler("hub failover group", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 800);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hub failover group", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    );
            } else {
                let submitParams = {
                    ...oldValue,
                    groupName: values.groupName,
                    sequence: values.sequence,
                    dashboardSourceGroupIds: values.dashboardSourceGroupIds,
                    dashboardSecurityGroupIds: values.dashboardSecurityGroupIds,
                    isDeleted: false,
                } as HubfailoverGroupProps;
                apiRequest(APIs.UPDATE_HUB_FAILOVER_SERVER_SECURITY_GROUP_CONFIG, submitParams)
                    .then(res => {
                        ErrorMessageHandler("hub failover group", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 800);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hub failover group", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    );
            }
        },
        [oldValue, isAddAction]
    );

    const getHubfailoverGroupDetails = (groupId?: number) => {
        if (props.groupId) {
            setIsLoading(true);
            apiRequest(APIs.GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_DETAIL, { groupType: 2, groupId })
                .then(res => {
                    if (res?.hasOwnProperty("groupId")) {
                        groupForm.setFieldsValue({
                            ...defaultValue,
                            id: res.groupId,
                            groupName: res.groupName,
                            sequence: res.sequence,
                            dashboardSourceGroupIds: res.dashboardSourceGroups.map((x: any) => x.id),
                            dashboardSecurityGroupIds: res.dashboardSecurityGroups.map((x: any) => x.id),
                        });
                        let cObj = {
                            id: res.groupId,
                            groupType: 2,
                            groupName: res.groupName,
                            sequence: res.sequence,
                            dashboardSourceGroupIds: res.dashboardSourceGroups.map((x: any) => x.id),
                            dashboardSecurityGroupIds: res.dashboardSecurityGroups.map((x: any) => x.id),
                        };
                        setOldValue(cObj);
                    } else {
                        setOldValue(undefined);
                    }
                })
                .catch(error =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hub failover group", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
                )
                .finally(() => setIsLoading(false));
        } else {
            groupForm.setFieldsValue(defaultValue);
            setIsLoading(false);
        }
    };

    const getConfig = () => {
        apiRequest(APIs.GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_LIST, { groupType: 0 })
            .then((res: any) => {
                setServers(res.length > 0 ? res.map((x: any) => ({ text: x.groupName, value: x.id })) : []);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(err)));
        apiRequest(APIs.GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_LIST, { groupType: 1 })
            .then((res: any) => {
                setSecurities(res.length > 0 ? res.map((x: any) => ({ text: x.groupName, value: x.id })) : []);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(err)));
    };

    useEffect(() => getHubfailoverGroupDetails(props.groupId), [props.resetState]);
    useEffect(() => getConfig(), []);

    return (
        <SitePageHeaderInline
            enableSubmit
            title={isAddAction ? "Create New Hub Failover Group" : "Edit Hub Failover Group"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        groupForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={groupForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                    <Row>
                        <Col span={15}>
                            <FormComponent label="" name="id" extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Group Name"
                                name="groupName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Sequence"
                                name="sequence"
                                extra={{
                                    type: ComponentType.number,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Source Group"
                                name="dashboardSourceGroupIds"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: servers,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: {
                                        mode: "multiple",
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Security Group"
                                name="dashboardSecurityGroupIds"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: securities,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: {
                                        mode: "multiple",
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default DashboardConfigurationCreateEditPage;
