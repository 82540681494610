import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import {
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    RejectRecordBridge,
    KeyValuePair,
    RejectRecordChildren,
} from "../../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../../helpers/authHelper";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { APIs } from "../../../../../services/apis";
import { Button, message, Modal, notification } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import SoundButton from "../../../../SystemMonitor/components/SoundButton";
import rejectOrderVoice from "../../../../../../src/assets/audios/rc/rejectOrder.mp3";
import RejectRecordExpandableTable from "./rejectRecordExpandedTable";
import { rejectRecordDummy } from "../dummydata/rejectRecordDummy";
import { bridgeListDummy } from "../dummydata/bridgeListDummy";

interface RejectRecordProps {
    mode: number;
}

const RejectRecord = (props: RejectRecordProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [bridgeData, setBridgeData] = useState<KeyValuePair[]>([]);
    const [data, setData] = useState<RejectRecordBridge[]>([]);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const authHp = new AuthHelper();
    const alarmSecends = 10 * 60 * 1000;
    const [selectedBridge, setSelectedBridge] = useState<string>("");
    const [shouldPlaySound, setShouldPlaySound] = useState<boolean>(false);

    const solvedAll = useCallback(
        async (data: RejectRecordBridge) => {
            let item = data;

            let loginList: any;
            if (item.bridge) {
                item.subitems = item.subitems?.filter(record => record.downloadUser);
                loginList = item.subitems?.map(record => record.login);
            }

            const newSubItems = item.subitems?.map(record => ({
                dateTime: record.dateTime,
                duration: record.duration,
                server: record.server,
                login: record.login,
                status: record.status,
                downloadUser: record.downloadUser,
                comment: record.comment,
            }));

            Modal.confirm({
                title: "Solved all " + bridgeData.find(bridge => bridge.value === item.bridge)?.text + "'s login?\n" + loginList,
                content: "",
                onOk: () => {
                    plainAxiosInstance
                        .put(APIs.RISK_TOOL.GET_ORDER_ROUTING_RECORD, newSubItems)
                        .then((res: any) => {
                            if (res.status === 200) {
                                ErrorMessageHandler("All solved successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                                setTimeout(getRejectRecords, 300);
                            } else {
                                ErrorMessageHandler(
                                    res.data.msg ? res.data.msg : "Failed to solve order reject record. Please try again.",
                                    SUCCESS_FAILED.OTHERS_FAILED
                                );
                            }
                        })
                        .catch((error: any) =>
                            ErrorCatchValidator(error, (err: any) =>
                                ErrorMessageHandler("Failed to solve order reject record. Error", SUCCESS_FAILED.OTHERS_FAILED, err)
                            )
                        )
                        .finally(() => {
                            setIsLoading(false);
                        });
                },
            });
        },
        [bridgeData]
    );

    const componentCallback: FlexiDataTableCallbackProps = useCallback(
        (type, formData: any) => {
            switch (type) {
                case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                    try {
                        Modal.confirm({
                            title: "Download " + selectedBridge + " server's data'",
                            content: "Would you want to download this server's login data?",
                            onOk: () => {
                                plainAxiosInstance
                                    .get(`${APIs.RISK_TOOL.GET_ORDER_ROUTING_RECORD_DOWNLOAD}?bridge=${selectedBridge}&mode=${props.mode}`, {
                                        headers: {
                                            Accept: "application/octet-stream, */*",
                                        },
                                        responseType: "blob",
                                    })
                                    .then(response => {
                                        const contentType = response.headers["content-type"];
                                        if (
                                            contentType === "application/octet-stream" ||
                                            contentType === "text/csv;charset=UTF-8" ||
                                            contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        ) {
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement("a");
                                            link.href = url;
                                            link.setAttribute(
                                                "download",
                                                props.mode === 1 ? "order_routing_record_ANY.csv" : "order_routing_record_OPEN.csv"
                                            );
                                            document.body.appendChild(link);
                                            link.click();
                                            window.URL.revokeObjectURL(url);
                                            notification.success({
                                                message: "Downloaded",
                                                description: `Order Rpouting Record downloaded successfully`,
                                            });
                                            setRunRefetchDataList(true);
                                        } else {
                                            notification.error({
                                                message: "Error",
                                                description: `Received non-file response. Error: ${response}`,
                                            });
                                            console.log("Received non-file response:", response);
                                        }
                                    })
                                    .catch(err => {
                                        notification.error({
                                            message: "Error",
                                            description: `Download error: ${err}`,
                                        });
                                        console.log("download error", err);
                                    });
                            },
                        });
                    } catch (e: any) {
                        ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                    break;
                case CALLBACK_KEY.DO_DELETE:
                    setIsLoading(true);
                    const newDt = {
                        dateTime: formData.dateTime,
                        duration: formData.duration,
                        server: formData.server,
                        login: formData.login,
                        status: formData.status,
                        downloadUser: formData.downloadUser,
                        comment: formData.comment,
                    };
                    plainAxiosInstance
                        .delete(`${APIs.RISK_TOOL.GET_ORDER_ROUTING_RECORD}`, { data: newDt })
                        .then((res: any) => {
                            ErrorMessageHandler(`Record [ ${formData.login} ]`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            setRunRefetchDataList(true);
                        })
                        .catch((error: any) =>
                            ErrorCatchValidator(error, (err: any) =>
                                ErrorMessageHandler("order routing record list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                            )
                        )
                        .finally(() => setIsLoading(false));
                    break;
                case CALLBACK_KEY.REFRESH:
                    setRunRefetchDataList(true);
                    break;
                default:
                    break;
            }
        },
        [selectedBridge]
    );

    const columns = useMemo(
        () => [
            {
                title: "Bridge",
                dataIndex: "bridge",
                key: "bridge",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: bridgeData,
                    },
                },
            },
            DTColProps.Large({
                title: "",
                dataIndex: "action",
                key: "Action",
                align: "right",
                render: (solvedUser: string, rowData: RejectRecordBridge) => {
                    return (
                        <Button
                            className="m-1"
                            type="primary"
                            icon={<CheckCircleOutlined />}
                            onClick={() => solvedAll(rowData)}
                            disabled={!checkDownloadUserExisted(rowData)}
                        >
                            Solved all logins of the bridge
                        </Button>
                    );
                },
            }),
        ],
        [bridgeData, data]
    );

    const checkDownloadUserExisted = (rowData: RejectRecordBridge) => {
        if (!rowData.subitems) {
            return true;
        }
        return !rowData.subitems.every((record: { downloadUser: any }) => record.downloadUser === null);
    };

    const getExpandedRowRender = useCallback(
        (record: RejectRecordBridge) => {
            if (!record.subitems || record.subitems.length === 0) return null;
            return <RejectRecordExpandableTable record={record.subitems} callback={componentCallback} />;
        },
        [componentCallback, data]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            expandable: {
                expandedRowRender: getExpandedRowRender,
            },
            enableFilter: true,
            ...(data.length > 0 && {
                export: {
                    text: "Download",
                },
            }),
            extraButtons: () => <SoundButton soundSrc={rejectOrderVoice} isActive={shouldPlaySound} />,
            recordRowClassName: (record: RejectRecordBridge) => {
                if (record.subitems) {
                    return record.subitems.some((child: RejectRecordChildren) => child.alarmStatus === 1) ? "alert-bg-error" : "";
                } else {
                    return record.alarmStatus === 1 ? "alert-bg-error" : "";
                }
            },
        }),
        [data, getExpandedRowRender, shouldPlaySound]
    );

    const getRejectRecords = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ORDER_ROUTING_RECORD}/${props.mode}`)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    const resD: RejectRecordBridge[] = res.data;

                    let playSound = false;

                    // const resD: RejectRecordBridge[] = rejectRecordDummy;

                    resD.forEach(records => {
                        records.children?.forEach(record => {
                            if (record.duration > alarmSecends) {
                                record.alarmStatus = 1;
                                playSound = true;
                            }
                        });
                    });

                    setData(
                        resD.map((item, index) => ({
                            bridge: item.bridge,
                            uniqueBridgeKey: `${item.bridge}_${index}`,
                            subitems: item.children?.map((child, childIndex) => ({
                                ...child,
                                uniqueKey: `${item.bridge}_${child.server}_${child.login}_${childIndex}`,
                            })),
                        }))
                    );

                    setShouldPlaySound(playSound);
                    setIsLoading(false);
                } else {
                    setData([]);
                    setIsLoading(false);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("order reject records", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => {
                setIsLoading(false);
            });
    }, [props.mode, alarmSecends, data]);

    const getBridgeList = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_ORDER_ROUTING_RECORD_BRIDGE_LIST}/${props.mode}`)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    const resB = res.data;
                    // const resB = bridgeListDummy;
                    setBridgeData(resB.map((b: any) => ({ text: b.label, value: b.value })));
                    setSelectedBridge(resB[0].value);
                    getRejectRecords();
                    setIsLoading(false);
                } else {
                    setSelectedBridge("");
                    setBridgeData([]);
                    setIsLoading(false);
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => {
                setIsLoading(false);
            });
    }, [props.mode]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            const timer = setInterval(getRejectRecords, 1000 * 60);
            return () => {
                clearInterval(timer);
                setRunRefetchDataList(false);
            };
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getBridgeList();
        return () => {};
    }, []);

    return (
        <div className="order-routing-record-reject-container">
            <FlexiDataTable
                rowKeyProperty="uniqueBridgeKey"
                title={false}
                columns={columns}
                options={options}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
                filterInitialValue={{ bridge: selectedBridge }}
            />
        </div>
    );
};

export default RejectRecord;
