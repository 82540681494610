import { useEffect, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { useRCIssuesLogMutate } from "@/hooks/useRCIssuesLog";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, message } from "antd";
import { read, utils } from "xlsx";

const columns = [
    {
        title: "Server Code",
        dataIndex: "serverCode",
        key: "serverCode",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a?.serverCode?.toLowerCase() < b?.serverCode?.toLowerCase()) {
                return -1;
            }
            if (a?.serverCode?.toLowerCase() > b?.serverCode?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Login ID",
        dataIndex: "loginId",
        key: "loginId",
        sorter: (a: any, b: any) => {
            if (typeof a.loginId === "number" && typeof b.loginId === "number") {
                return a.loginId - b.loginId;
            } else {
                // sort alphabetically and length
                if (a?.loginId?.toLowerCase() < b?.loginId?.toLowerCase()) {
                    return -1;
                }
                if (a?.loginId?.toLowerCase() > b?.loginId?.toLowerCase()) {
                    return 1;
                }
                return 0;
            }
        },
    },
];

const CommonIssuesLogTypeDownload = ({
    type,
    fileExtension = ".xlsx",
    canUpload = true,
}: {
    type: "ACCOUNT_MONITOR" | "MANAGER_ACCOUNT_INTERCEPTED";
    fileExtension?: string;
    canUpload?: boolean;
}) => {
    const fileName = type;
    const downloadURL = () => {
        switch (type) {
            case "ACCOUNT_MONITOR":
                return APIs.RC_ISSUES_LOG.POST_DOWNLOAD_ACCOUNT_MONITOR;
            case "MANAGER_ACCOUNT_INTERCEPTED":
                return APIs.RC_ISSUES_LOG.POST_DOWNLOAD_MANAGER_ACCOUNT_INTERCEPTED;
        }
    };
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [previewFileData, setPreviewFileData] = useState<any>([]);

    // get todayDate YYYYMMDD
    const todayDate = new Date();
    const todayDateStr = todayDate.toISOString().split("T")[0].replace(/-/g, "");

    const { uploadIssuesLogTypeList, isLoadingUploadIssuesLogTypeList } = useRCIssuesLogMutate({ type });

    const handleUpload = async ({ formData }: { formData: FormData }) => {
        try {
            const resp = await uploadIssuesLogTypeList({ formData, type });

            if (resp.status === 200 && resp?.data !== 0) {
                ErrorMessageHandler(`Failed to upload settings. Error: ${resp?.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
            } else {
                ErrorMessageHandler("Settings", SUCCESS_FAILED.SUCCESS_UPLOAD_DATA);
            }
            // console.log(resp);
        } catch (err) {
            ErrorMessageHandler(`Failed to upload settings. Error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const handlePreview = async () => {
        const dummyFormData = new FormData();
        setIsLoading(true);
        plainAxiosInstance
            .post(downloadURL(), dummyFormData, {
                headers: {
                    Accept: "application/octet-stream,text/csv, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                // console.log(response);
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "text/csv;charset=UTF-8" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    const reader = new FileReader();
                    reader.onload = e => {
                        const data = e.target?.result;
                        const workbook = read(data, { type: "array" });
                        const firstSheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[firstSheetName];
                        const dataParse = utils.sheet_to_json(worksheet);
                        let newParsedData = dataParse.map((x: any) => ({
                            ...x,
                            key: `${x.serverCode}-${x.loginId}`,
                        }));
                        setPreviewFileData(newParsedData);
                    };
                    reader.readAsArrayBuffer(response.data);
                } else {
                    ErrorMessageHandler(`Failed to load settings preview. Error: ${response}.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler(`Failed to load settings preview. Error`, SUCCESS_FAILED.OTHERS_FAILED, err)
                )
            )
            .finally(() => setIsLoading(false));
    };

    const handleDownload = async () => {
        const dummyFormData = new FormData();
        setIsExporting(true);
        plainAxiosInstance
            .post(downloadURL(), dummyFormData, {
                headers: {
                    Accept: "application/octet-stream,text/csv, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                // console.log(response);
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "text/csv;charset=UTF-8" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                        })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${fileName}_${todayDateStr}${fileExtension}`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    ErrorMessageHandler(`${fileName}`, SUCCESS_FAILED.SUCCESS_DOWNLOAD_DATA);
                } else {
                    ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Failed to download settings. Error`, SUCCESS_FAILED.OTHERS_FAILED, err))
            )
            .finally(() => setIsExporting(false));
    };

    const handleSubmit = (file: any) => {
        let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
            isLt5M = file.size / 1024 / 1024 < 5;

        if (fileExtension[0] !== ".xlsx") {
            ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        } else if (!isLt5M) {
            ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
            return;
        }

        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure you want to import?",
            width: "30%",
            okButtonProps: { loading: isLoadingUploadIssuesLogTypeList },
            onOk() {
                var formData = new FormData();
                formData.append("uploadFile", file);

                handleUpload({ formData });
            },
            onCancel() {},
        });
    };

    const options: FlexiDataTableOptionsProps = {
        enableFilter: false,
        export: previewFileData.length > 0 ? { text: "Download" } : false,
        ...(canUpload && {
            upload: {
                name: "file",
                multiple: false,
                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                showUploadList: false,
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any) => handleSubmit(file),
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                handleDownload();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        handlePreview();
    }, []);

    return (
        <div className="single-page">
            <div className={`active`}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="key"
                    title={"Account Monitor List"}
                    columns={columns}
                    dataSource={previewFileData ?? []}
                    loading={isLoading || isLoadingUploadIssuesLogTypeList}
                    options={options}
                    callback={componentCallback}
                    exporting={isExporting}
                    pagination={{ defaultPageSize: 50, pageSizeOptions: [10, 20, 50, 100, 500, 1000] }}
                />
            </div>
        </div>
    );
};

export default CommonIssuesLogTypeDownload;
