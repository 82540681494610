import { useRef, useState } from "react";
import { Modal, Form, Typography, notification, Row, Col, Select, TimePicker, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import useRCRejectOrderServers from "@/hooks/useRCRejectOrderServers";
import useRCRejectOrderSettings, { useRCRejectOrderSettingsUpdate, useRCRejectOrderSettingsDelete } from "@/hooks/useRCRejectOrdersSettings";
import Loader from "@/pages/SystemMonitor/components/Loader";
import TabContent from "@/pages/SystemMonitor/components/TabContent";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { DTColProps } from "@/utils/Common";

const TabRejectOrderSettings = () => {
    return (
        <TabContent>
            <RejectOrderSettingsTable />
        </TabContent>
    );
};

const RejectOrderSettingsTable = () => {
    const { rcRejectOrderSettings, rcRejectOrderSettingsError, refetchRcRejectOrdersSettings, isLoading } = useRCRejectOrderSettings();
    const transformedDataWithKey = rcRejectOrderSettings?.map((each: any, idx: number) => ({ ...each, key: idx })) ?? [];

    const [currEdit, setCurrEdit] = useState<string | "__new__" | null>(null);
    const [currDelete, setCurrDelete] = useState<string | null>(null);
    const currEditInfo = rcRejectOrderSettings?.find((each: any) => each.mainServerId === currEdit);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.SYSTEM_MONITOR_REJECT_ORDER_EDIT);

    const handleEdit = (server: string | null) => {
        if (server === null) {
            setCurrEdit(null);
            return;
        }
        setCurrEdit(server);
    };

    const handleCloseEditModal = () => setCurrEdit(null);

    const handleDelete = (server: string) => setCurrDelete(server);

    const columns = [
        {
            title: "Server",
            dataIndex: "mainServerId",
            key: "mainServerId",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.mainServerId?.toLowerCase() < b.mainServerId?.toLowerCase()) return -1;
                if (a.mainServerId?.toLowerCase() > b.mainServerId?.toLowerCase()) return 1;
                return 0;
            },
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        DTColProps.Status({
            title: "Status",
            dataIndex: "enable",
            key: "enable",
            sorter: (a: any, b: any) => a.enable - b.enable,
        }),
        DTColProps.Middle({
            title: "Start Time",
            dataIndex: "startTime",
            align: "center",
        }),
        DTColProps.Middle({
            title: "End Time",
            dataIndex: "endTime",
            key: "endTime",
            align: "center",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate ? (record: any, btn: any) => (record.mainServerId !== "default" ? btn : <></>) : false,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                handleEdit("__new__");
                break;
            case CALLBACK_KEY.DO_EDIT:
                handleEdit(FormData.mainServerId);
                break;
            case CALLBACK_KEY.DO_DELETE:
                handleDelete(FormData.mainServerId);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Reject Order Settings</span>
                </div>
            </div>
            <FlexiDataTable
                bordered
                rowKeyProperty="key"
                title={false}
                columns={columns}
                options={options}
                dataSource={transformedDataWithKey}
                callback={componentCallback}
                loading={isLoading}
            />
            {currEdit && (
                <SettingsModal
                    open={currEdit !== null}
                    handleCloseModal={() => {
                        handleCloseEditModal();
                    }}
                    currEdit={currEdit}
                    data={currEditInfo}
                />
            )}
            {currDelete && (
                <DeleteModal
                    open={currDelete !== null}
                    handleCloseModal={() => {
                        setCurrDelete(null);
                    }}
                    server={currDelete}
                />
            )}
        </>
    );
};

const SettingsModal = ({
    open,
    handleCloseModal,
    data,
    currEdit,
}: {
    open: boolean;
    currEdit: string | null;
    handleCloseModal: () => void;
    data?: {
        mainServerId?: string;
        startTime?: string;
        endTime?: string;
        enable?: 0 | 1;
    };
}) => {
    // const { RangePicker } = TimePicker;

    const { rcRejectOrderServers, isLoading } = useRCRejectOrderServers();
    const { rcRejectOrderSettings } = useRCRejectOrderSettings();

    const [form] = Form.useForm();
    const { Item } = Form;
    const { Text } = Typography;

    const serverOptions =
        rcRejectOrderServers
            ?.filter((each: any) => {
                if (currEdit === "__new__") {
                    return !rcRejectOrderSettings?.find((item: any) => item.mainServerId === each);
                } else {
                    return each === data?.mainServerId;
                }
            })
            ?.map((each: any) => ({ label: each, value: each })) ?? [];

    const initFormValue = {
        mainServerId: data?.mainServerId || null,
        // init startime as moment object
        startTime: data?.startTime ? moment(data.startTime, "HH:mm:ss") : moment("00:00:00", "HH:mm:ss"),
        // init endtime as moment object
        endTime: data?.endTime ? moment(data.endTime, "HH:mm:ss") : moment("00:00:00", "HH:mm:ss"),
        enable: data?.enable || 0,
    };

    const formRef = useRef<any>(null);

    const { mutateAsync } = useRCRejectOrderSettingsUpdate();

    const handleSubmit = async (values: any) => {
        const startTime = moment(values.startTime).format("HH:mm:ss");
        const endTime = moment(values.endTime).format("HH:mm:ss");
        const mainServerId = values.mainServerId;
        const enable = values.enable;
        // console.log(values);

        try {
            const resp = await mutateAsync({ mainServerId, startTime, endTime, enable });
            console.log(resp);
            notification.success({
                message: `Success`,
                description: `Successfully updated reject order settings`,
            });
            handleCloseModal();
        } catch (e) {
            console.error(e);
            notification.error({
                message: `Error`,
                description: `Failed to update reject order settings`,
            });
        }
    };

    return (
        <Form ref={formRef} form={form} layout="vertical" initialValues={initFormValue} requiredMark={true} onFinish={handleSubmit} key={currEdit}>
            <Modal
                title={(currEdit === "__new__" ? "Add " : `Edit `) + `Reject Order Settings` + (currEdit === "__new__" ? "" : ` - ${currEdit}`)}
                okText={"Save"}
                okButtonProps={
                    {
                        // icon: <DownloadOutlined />,
                    }
                }
                open={open}
                onOk={() => form.submit()}
                onCancel={() => handleCloseModal()}
                // disable click outside of modal to close
                maskClosable={false}
            >
                {isLoading && !rcRejectOrderServers ? (
                    <Loader />
                ) : (
                    <Row gutter={[8, 8]}>
                        <Col span={12} xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
                            <Text>Server *</Text>
                            <Item style={{ margin: 0, padding: 0 }} name="mainServerId" rules={[{ required: true, message: "Please select server" }]}>
                                <Select
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder="Select Servers"
                                    options={serverOptions}
                                    showSearch
                                    onChange={data => {
                                        form.setFieldsValue({ mainServerId: data });
                                    }}
                                    disabled={currEdit !== "__new__"}
                                />
                            </Item>
                        </Col>
                        <Col span={12} xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
                            <Text>Enable</Text>
                            <Item style={{ margin: 0, padding: 0 }} name="enable" rules={[{ required: true, message: "Please select server" }]}>
                                {/* toggle enable */}
                                <Switch
                                    checkedChildren={
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <CheckOutlined />
                                        </div>
                                    }
                                    unCheckedChildren={
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <CloseOutlined />
                                        </div>
                                    }
                                    defaultChecked={data?.enable === 1}
                                    onChange={data => {
                                        form.setFieldsValue({ enable: data ? 1 : 0 });
                                    }}
                                />
                            </Item>
                        </Col>
                        <Col span={12} xs={{ span: 24 }} md={{ span: 12 }}>
                            <Text>Start Time *</Text>
                            <Item
                                style={{ margin: 0, padding: 0 }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                required
                                name="startTime"
                                rules={[{ required: true, message: "Please select start time" }]}
                            >
                                <TimePicker
                                    style={{ width: "100%" }}
                                    onChange={data => {
                                        form.setFieldsValue({ startTime: data });
                                    }}
                                />
                            </Item>
                        </Col>
                        <Col span={12} xs={{ span: 24 }} md={{ span: 12 }}>
                            <Text>End Time *</Text>
                            <Item
                                style={{ margin: 0, padding: 0 }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                required
                                name="endTime"
                                rules={[
                                    { required: true, message: "Please select end time" },
                                    // end time must be greater than start time
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("startTime") < value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("End time must be greater than start time"));
                                        },
                                    }),
                                ]}
                            >
                                <TimePicker
                                    style={{ width: "100%" }}
                                    onChange={data => {
                                        form.setFieldsValue({ endTime: data });
                                    }}
                                />
                            </Item>
                        </Col>
                    </Row>
                )}
            </Modal>
        </Form>
    );
};

const DeleteModal = ({ open, handleCloseModal, server }: { open: boolean; handleCloseModal: () => void; server: string }) => {
    const { mutateAsync } = useRCRejectOrderSettingsDelete();
    const handleDelete = async () => {
        try {
            const resp = await mutateAsync({ mainServerId: server });
            console.log(resp);
            notification.success({
                message: `Success`,
                description: `Successfully deleted reject order settings`,
            });
            handleCloseModal();
        } catch (e) {
            console.error(e);
            notification.error({
                message: `Error`,
                description: `Failed to delete reject order settings`,
            });
        }
    };
    return (
        <Modal
            title="Delete Reject Order Settings"
            okText={"Delete"}
            okButtonProps={{
                danger: true,
            }}
            open={open}
            onOk={handleDelete}
            onCancel={handleCloseModal}
        >
            <p>
                Are you sure you want to delete reject order settings of server <strong>{server}</strong>?
            </p>
        </Modal>
    );
};

export default TabRejectOrderSettings;
