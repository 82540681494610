import TinyTag from "@/components/Common/Tag";
import FlexiDataTable from "@/components/FlexiDataTable";
import { InnerPageActionMode, ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { KeyValuePair, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { GetUniqueKeysList } from "@/utils/array";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { objectRemoveProps } from "@/utils/object";
import { useState, useMemo, useEffect } from "react";
import { CreateEditFormMode } from "..";
import SecurityGroupCreateEditPage from "./createEditPage";

export interface SecurityGroupListingProps {}

export const SecurityGroupListing = () => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [refetch, setRefetch] = useState<number>(0);
    const [formObject, setFormObject] = useState<CreateEditFormMode>({ mode: InnerPageActionMode.CREATE_NEW });
    const [securityList, setSecurityList] = useState<KeyValuePair[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_TOOLS_HUB_CONFIG_EDIT);

    const columns = useMemo(
        () => [
            DTColProps.XLarge({
                title: "Security Group Name",
                dataIndex: "groupName",
                key: "groupName",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            {
                title: "Securities",
                dataIndex: "securities",
                key: "securities",
                render: (value: string[]) => {
                    let sortedArray = [...value];
                    sortedArray.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
                    return sortedArray.map((x: string, index: number) => <TinyTag key={`${x}-${index}`} text={x} />);
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: securityList,
                        callback: (filterValue: number, rowData: any) => rowData["securities"].includes(filterValue),
                    },
                },
            },
        ],
        [securityList]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, rowData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, groupId: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, groupId: rowData["id"] });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                apiRequest(APIs.GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_DETAIL, { groupType: 1, groupId: rowData["id"] })
                    .then(res => {
                        apiRequest(APIs.UPDATE_HUB_FAILOVER_SERVER_SECURITY_GROUP_CONFIG, {
                            ...objectRemoveProps(res, ["groupId", "sourceGroupDetails", "securityGroupDetails"]),
                            id: res.groupId,
                            groupType: 1,
                            securityGroups: res.securityGroupDetails.map((x: any) => ({ ...x, isDeleted: true })),
                            isDeleted: true,
                        })
                            .then(() => {
                                ErrorMessageHandler("security group", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                                getSecurityGroupList();
                            })
                            .catch(error =>
                                ErrorCatchValidator(error, (err: any) =>
                                    ErrorMessageHandler("security group", SUCCESS_FAILED.FAILED_DELETE_DATA, err)
                                )
                            );
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("security group", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    );
                break;
            default:
                break;
        }
    };

    const getSecurityGroupList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_LIST, { groupType: 1 })
            .then(res => {
                setData(res.length > 0 ? res : []);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("security group", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const loadFilterConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["hubFailover"] })
            .then(
                (data: any) =>
                    data.hubFailover &&
                    data.hubFailover.securities &&
                    setSecurityList(GetUniqueKeysList(data.hubFailover.securities, "name", true).map((x: any) => ({ text: x, value: x })))
            )
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(err)));
    };

    useEffect(() => {
        loadFilterConfig();
        getSecurityGroupList();
    }, []);

    return (
        <div className="single-page">
            <div className={`${isFirstOpt ? "active" : ""}`}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title={false}
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <div className={`${isFirstOpt ? "" : "active"}`}>
                <SecurityGroupCreateEditPage
                    {...formObject}
                    callback={(action: InnerPageActionMode, value: any) => {
                        switch (action) {
                            case InnerPageActionMode.BACK:
                                setIsFirstOpt(prev => !prev);
                                if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                    getSecurityGroupList();
                                }
                                break;
                        }
                    }}
                    resetState={refetch}
                />
            </div>
        </div>
    );
};

export default SecurityGroupListing;
