import { Segmented } from "antd";
import { useEffect, useState } from "react";
import CardBox from "../../../components/Common/CardBox";
import { SUCCESS_FAILED } from "../../../constants";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import DataTab from "./DataTab";
import EquityMonitorTab from "./EquityMonitor";

export type ServerUnoMapName = {
    [serverUno: number | string]: string;
};
const LinkedAccountRecord = () => {
    const [serversList, setServersList] = useState<ServerUnoMapName>({});
    const segmentOptions = ["Data", "Equity Monitor"];
    const [currentTab, setCurrentTab] = useState<string>(segmentOptions[0]);

    useEffect(() => {
        plainAxiosInstance
            .get(`${APIs.RC_LINKED_ACCOUNT_RECORD.GET_SERVERS_LIST}`)
            .then(res => {
                const list: ServerUnoMapName = {};
                if (!res || !res.data) return;

                res.data.forEach((item: any) => {
                    const oldServerId = item["oldServerId"] ?? item["serverId"];
                    list[oldServerId] = item["serverName"];
                });
                setServersList(list);
            })
            .catch((error: any) => {
                return ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("servers list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });
    }, []);

    return (
        <div className="linked-account-record-container">
            <CardBox title={"Linked Account Record"}>
                <div className="main-container">
                    <Segmented
                        className="tab-container"
                        value={currentTab}
                        options={segmentOptions}
                        onChange={(activeKey: any) => setCurrentTab(activeKey)}
                    />
                    <div className={currentTab === "Data" ? "" : "hide"}>
                        <DataTab />
                    </div>
                    {currentTab === "Equity Monitor" && <EquityMonitorTab servers={serversList} />}
                </div>
            </CardBox>
        </div>
    );
};

export default LinkedAccountRecord;
