import FlexiDataTable from "@/components/FlexiDataTable";
import { SUCCESS_FAILED, CALLBACK_KEY } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, Module } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, DTColProps, ErrorCatchValidator } from "@/utils/Common";
import { objectRemoveProps } from "@/utils/object";
import { Form, Modal } from "antd";
import DirectoryTree from "antd/lib/tree/DirectoryTree";
import { useState, useEffect, useMemo } from "react";
import CreateEditPage from "./CreateEditPage";
import { getTreeList } from "../RolePermission/ViewRolePage";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";

interface MenuMangementProps { }

const MenuMangement = (props: MenuMangementProps) => {
    const [menus, setMenus] = useState<Module[]>([]);
    const [oriMenus, setOriMenus] = useState<Module[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [menuForm] = Form.useForm();

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_MENUS_EDIT);

    const getTreeTemplate = (data: Module[], keys: string[]) => {
        return data.filter((x) => keys.some((y) => y === `${x.parentId}`));
    };

    const getMenuList = () => {
        apiRequest(APIs.GET_FULL_MENU_LISTING, {})
            .then((data: any) => {
                let sortedData: Module[] = data as Module[];
                sortedData.sort((a: Module, b: Module) => a.order - b.order);

                setMenus(getTreeList(sortedData.filter((x) => x.type !== 3)));
                setOriMenus(sortedData);
            })
            .catch((err) => {
                ErrorMessageHandler("menu listing", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
            });
    };

    const onNodeSelect = (selectedKeys: any, e: any) => setSelectedKeys(selectedKeys);

    const columns: any[] = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (title: string | null) => (title === null ? "-" : title),
        },
        {
            title: "Key",
            dataIndex: "key",
            key: "key",
        },
        DTColProps.XSmall({
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (type: number) => {
                switch (`${type}`) {
                    case "1":
                        return "Module";
                    case "2":
                        return "Action";
                    case "3":
                        return "API";
                    default:
                        return "";
                }
            },
        }),
        DTColProps.XSmall({
            title: "Order",
            dataIndex: "order",
            key: "order",
        }),
        DTColProps.Status({
            title: "Status",
            dataIndex: "status",
            key: "status",
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            separateActionButton: true,
            add: enableUpdate,
            edit: enableUpdate,
            delete: enableUpdate,
            enableFilter: false,
        }
    }, [enableUpdate]);

    const componentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                if (selectedKeys.length > 0) {
                    menuForm.setFieldsValue({ id: -1, parentId: selectedKeys[0], key: "", title: "", order: "", status: "", type: "" });
                    setIsModalVisible(true);
                }
                break;
            case CALLBACK_KEY.DO_EDIT:
                menuForm.setFieldsValue(data);
                setIsModalVisible(true);
                break;
            case CALLBACK_KEY.DO_DELETE:
                apiRequest(APIs.DELETE_MENU, {
                    id: data.id,
                })
                    .then((data) => ErrorMessageHandler("menu row", SUCCESS_FAILED.SUCCESS_DELETE_DATA))
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("menu list", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => {
                        getMenuList();
                    });
                break;
        }
    };

    const createEditSubmit = (values: any) => {
        if (`${values["id"]}` === "-1") {
            //do create
            apiRequest(APIs.CREATE_MENU, objectRemoveProps(values, ["id"]))
                .then((res: any) => {
                    ErrorMessageHandler("menu list", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    getMenuList();
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("menu list", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                });
        } else {
            apiRequest(APIs.UPDATE_MENU, values)
                .then((res: any) => {
                    ErrorMessageHandler("menu list", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    getMenuList();
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("menu list", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                });
        }
    };

    useEffect(() => {
        getMenuList();
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Menu Management</span>
                </div>
            </div>
            <div className="single-page">
                <div className="menu-management-container">
                    <div className="left">
                        <div className="tree-panel">
                            <div className="title">
                                <span>Menus</span>
                            </div>
                            <div className="content">
                                <DirectoryTree
                                    multiple
                                    showLine
                                    showIcon={false}
                                    defaultExpandedKeys={["0"]}
                                    treeData={[
                                        {
                                            title: "Insight",
                                            key: "0",
                                            children: menus,
                                        },
                                    ]}
                                    onSelect={onNodeSelect}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <FlexiDataTable
                            title=""
                            rowKeyProperty="id"
                            columns={columns}
                            options={options}
                            dataSource={getTreeTemplate(oriMenus, selectedKeys)}
                            loading={false}
                            callback={componentCallback}
                            pagination={{ defaultPageSize: 100 }}
                        />
                    </div>
                </div>

                <Modal
                    open={isModalVisible}
                    width={"40%"}
                    title={"Create New Menu"}
                    onCancel={() => {
                        menuForm.resetFields();
                        setIsModalVisible(false);
                    }}
                    onOk={() => {
                        menuForm
                            .validateFields()
                            .then((values: any) => {
                                createEditSubmit(values);
                                setIsModalVisible(false);
                            })
                            .catch((err) => { });
                    }}
                >
                    <CreateEditPage form={menuForm} />
                </Modal>
            </div>
        </>
    );
};

export default MenuMangement;
