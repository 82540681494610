import useRCIssuesLogStore from "@/store/useRCIssuesLogStore";
import { Tabs } from "antd";
import { useState } from "react";
import FloatingTitleBox from "../../components/FloatingTitleBox";
import CommonIssuesLogHistoryTable from "../CommonIssuesLogHistoryTable";
import CommonIssuesLogTable from "../CommonIssuesLogTable";

const IssueModifyOpenOrder = () => {
    const updateInView = useRCIssuesLogStore(state => state.updateInView);
    const [currTab, setCurrTab] = useState<string>("monitor");
    const handleChangeTab = (activeKey: string) => setCurrTab(activeKey);

    return (
        <FloatingTitleBox
            minHeight="unset"
            title="Modify Open Order"
            inViewCallback={(isInView: boolean) => updateInView({ MODIFY_OPEN_ORDER: isInView })}
            titleBarTabs={
                <Tabs
                    activeKey={currTab}
                    onChange={handleChangeTab}
                    type="card"
                    style={{
                        padding: "0.5rem 0.5rem 0 0.5rem",
                    }}
                    tabBarStyle={{
                        marginBottom: 0,
                    }}
                    items={[
                        {
                            label: "Monitoring",
                            key: "monitor",
                        },
                        {
                            label: "History",
                            key: "history",
                        },
                    ]}
                />
            }
        >
            {currTab === "monitor" && <CommonIssuesLogTable type="MODIFY_OPEN_ORDER" />}
            {currTab === "history" && <CommonIssuesLogHistoryTable type="MODIFY_OPEN_ORDER" />}
        </FloatingTitleBox>
    );
};

export default IssueModifyOpenOrder;
