import { Button, Col, Form, Modal, Row, Tabs, message } from "antd";
import { useCallback, useState } from "react";
import PriceMonitorSpreadAlarm30Mins from "./components/alarm30mins";
import PriceMonitorSpreadAlarmHistorical from "./components/historicalSpreadAlarm";
import { DownloadOutlined } from "@ant-design/icons";
import { FormComponent } from "../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import moment from "moment";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import PriceMonitorBreadCrumbs from "../components/PriceMonitorBreadCrumbs";
import { ErrorMessageHandler } from "@/utils/Common";

export interface PriceMonitorSpreadAlarmProps {}

const PriceMonitorSpreadAlarm = (props: PriceMonitorSpreadAlarmProps) => {
    const [currentTab, setCurrentTab] = useState<string>("1");
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [reportRowCount, setReportRowCount] = useState<number>(0);
    const [downloadForm] = Form.useForm();

    const downloadReport = useCallback(
        (selectedDate: string) => {
            if (reportRowCount > 0) {
                try {
                    plainAxiosInstance
                        .get(`${plainAxiosInstance.defaults.baseURL}${APIs.RC_PRICE_MONITOR.GET_REPORT_DOWNLOAD}?date=${selectedDate}`, {
                            headers: {
                                Accept: "application/octet-stream, */*",
                            },
                            responseType: "blob",
                        })
                        .then(response => {
                            const contentType = response.headers["content-type"];
                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "text/csv" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", `spread_${selectedDate}.csv`);
                                document.body.appendChild(link);
                                link.click();
                                // Clean up
                                window.URL.revokeObjectURL(url);
                                ErrorMessageHandler(`Spread Report downloaded successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                            } else {
                                ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        });
                } catch (e: any) {
                    ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            } else {
                message.info(`No record found on selected date "${selectedDate}"`, 3);
            }
        },
        [reportRowCount]
    );

    const onValueChanged = (changedValue: any) => {
        if (changedValue?.hasOwnProperty("reportDate")) {
            let selectedDate = moment(changedValue["reportDate"]).format("YYYY-MM-DD");
            plainAxiosInstance
                .get(`${APIs.RC_PRICE_MONITOR.GET_REPORT_ROW_COUNT}/${selectedDate}`)
                .then(response => {
                    setReportRowCount(response.data);
                })
                .catch(() => setReportRowCount(0));
        }
    };

    return (
        <>
            <PriceMonitorBreadCrumbs currMenu="Spread Alarm" />
            <div className="pricemonitor-spread-alarm-container">
                <Tabs
                    activeKey={currentTab}
                    onChange={(activeKey: string) => setCurrentTab(activeKey)}
                    type="card"
                    tabBarStyle={{ marginBottom: "0px" }}
                    items={[
                        {
                            label: "Alarm in 30 min",
                            key: "1",
                            children: <PriceMonitorSpreadAlarm30Mins />,
                        },
                        {
                            label: "Historical Spread Alarm",
                            key: "2",
                            children: <PriceMonitorSpreadAlarmHistorical />,
                        },
                    ]}
                    tabBarExtraContent={{
                        right: (
                            <Button
                                icon={<DownloadOutlined />}
                                onClick={() => {
                                    downloadForm.setFieldsValue({ reportDate: moment() });
                                    setIsModalVisible(true);
                                }}
                            >
                                Download Report
                            </Button>
                        ),
                    }}
                />
            </div>

            <Modal
                width={500}
                title="Download Report"
                open={isModalVisible}
                cancelButtonProps={{ style: { display: "none" } }}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => {
                    downloadForm
                        .validateFields()
                        .then((values: any) => {
                            downloadReport(moment(values["reportDate"]).format("YYYY-MM-DD"));
                            setIsModalVisible(false);
                        })
                        .catch(errorInfo => console.log(errorInfo));
                }}
            >
                <Form
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    form={downloadForm}
                    layout="horizontal"
                    initialValues={{}}
                    onValuesChange={onValueChanged}
                >
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Download Date"
                                name="reportDate"
                                extra={{
                                    type: ComponentType.date,
                                    value: "",
                                    dateFormat: "YYYY-MM-DD",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default PriceMonitorSpreadAlarm;
