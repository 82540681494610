import { Col, Form, FormInstance, Row, Typography, Image } from "antd";
import larkImage from "@/assets/images/lark_template2.png";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType, STATUS_TYPE } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { OptionType } from "@/constants/type";
import { ToOptionTypeList } from "@/utils/array";
import { useState, useEffect } from "react";

const { Text } = Typography;

export interface CreateEditChannelProps {
    form: FormInstance;
    lineQR: string;
    lineUserList: LINEUserProps[];
    whatsappGroupList: WhatsappGroupProps[];
    whatsappNumber: string;
    channelType: OptionType[];
    callback?: (type: string, data: any) => void;
}

export interface LINEUserProps {
    sourceId: string;
    sourceName: string;
    sourceType: string;
}

export interface WhatsappGroupProps {
    id: string;
    name: string;
}

const CreateEditChannel = (props: CreateEditChannelProps) => {
    const [lineUsers, setLineUsers] = useState<LINEUserProps[]>(props.lineUserList);
    const [whatsappGroup, setWhatsappGroup] = useState<WhatsappGroupProps[]>(props.whatsappGroupList);
    const [whatsappNumber, setWhatsappNumber] = useState<string>(props.whatsappNumber);
    const cType = Form.useWatch("type", props.form);

    useEffect(() => {
        setLineUsers(props.lineUserList);
    }, [props.lineUserList]);

    useEffect(() => {
        setWhatsappGroup(props.whatsappGroupList);
        setWhatsappNumber(props.whatsappNumber);
    }, [props.whatsappGroupList]);

    return (
        <div>
            <Form labelCol={{ span: 6 }} labelAlign={"right"} wrapperCol={{ span: 18 }} form={props.form} layout="horizontal">
                <Row>
                    <Col span={17}>
                        <Row>
                            <Col span={24}>
                                <FormComponent label={""} name={"action"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <FormComponent label={""} name={"mediumUid"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <FormComponent
                                    label="Type"
                                    name="type"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: props.channelType,
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                        inputProps: {
                                            onChange: (value: string) => {
                                                let previousType: string = cType;
                                                if (previousType !== undefined) {
                                                    if (previousType === "line") {
                                                        if (value === "whatsapp") {
                                                            props.form.setFieldsValue({ recipients: [] });
                                                        } else {
                                                            props.form.setFieldsValue({ recipients: "" });
                                                        }
                                                    } else {
                                                        if (value === "line") {
                                                            props.form.setFieldsValue({ recipients: [] });
                                                        } else if (value === "whatsapp") {
                                                            props.form.setFieldsValue({ recipients: [] });
                                                        }
                                                    }
                                                }
                                            },
                                        },
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Name"
                                    name="name"
                                    extra={{
                                        type: ComponentType.text,
                                        value: "",
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {cType === "email" ? (
                                    <FormComponent
                                        label="Subject"
                                        name="subject"
                                        extra={{
                                            type: ComponentType.text,
                                            value: "",
                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                        }}
                                    />
                                ) : (
                                    <FormComponent
                                        label=""
                                        name="subject"
                                        extra={{
                                            type: ComponentType.hidden,
                                            value: "",
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Content"
                                    name="content"
                                    extra={{
                                        type: ComponentType.textarea,
                                        value: "",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {cType === "line" ? (
                                    <FormComponent
                                        label="Recipients"
                                        name="recipients"
                                        extra={{
                                            type: ComponentType.dropdown,
                                            value: lineUsers.map(x => ({
                                                text: x.sourceName,
                                                value: x.sourceId,
                                            })),
                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                            inputProps: {
                                                mode: "tags",
                                            },
                                            withSyncBtn: {
                                                enable: true,
                                                callback: () => props.callback && props.callback("user-list", {}),
                                            },
                                        }}
                                    />
                                ) : cType === "whatsapp" ? (
                                    <>
                                        <FormComponent
                                            label="Recipients"
                                            name="recipients"
                                            extra={{
                                                type: ComponentType.dropdown,
                                                value: whatsappGroup.map(x => ({
                                                    text: x.name,
                                                    value: x.id,
                                                })),
                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                inputProps: {
                                                    mode: "tags",
                                                    placeholder: `Select recipients / Enter valid phone number`,
                                                },
                                                withSyncBtn: {
                                                    enable: true,
                                                    callback: () => props.callback && props.callback("whatsapp-list", {}),
                                                },
                                            }}
                                        />
                                        <Row style={{ marginTop: -20, marginBottom: 10 }}>
                                            <Col span={6}></Col>
                                            <Col span={18}>
                                                <Text type="secondary" italic>
                                                    <small>Example: +601xxxxxxxx</small>
                                                </Text>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <FormComponent
                                        label="Recipients"
                                        name="recipients"
                                        extra={{
                                            type: ComponentType.text,
                                            value: "",
                                            inputProps: {
                                                placeholder: `Please use comma "," as separator`,
                                            },
                                            ...((cType === "teams" || cType === "line" || cType === "whatsapp") && {
                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                            }),
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Status"
                                    name="status"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: ToOptionTypeList(STATUS_TYPE),
                                    }}
                                />
                            </Col>
                        </Row>
                        {cType === "in-app" && (
                            <Row>
                                <Col span={24}>
                                    <FormComponent
                                        label=" "
                                        name="enableBroadcast"
                                        extra={{
                                            type: ComponentType.checkbox,
                                            value: "Broadcast",
                                            itemProps: {
                                                colon: false,
                                                labelCol: {
                                                    span: 6,
                                                },
                                                style: {
                                                    marginBottom: "0px",
                                                },
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Col>
                    <Col span={7} style={{ paddingLeft: "1vw" }}>
                        {cType === "line" && (
                            <div className="line-description-panel">
                                <div>LINE QR:</div>
                                <div>
                                    {props.lineQR.length > 0 && (
                                        <img
                                            src={`data:image/png;base64,${props.lineQR}`}
                                            alt="LINE QRCODE"
                                            style={{ width: "100%", height: "auto" }}
                                        />
                                    )}
                                </div>
                                <div>
                                    <span>Instruction:</span>
                                    <ol>
                                        <li>Please scan the QRCode.</li>
                                        <li>Please click "Refresh" button from recipient field after add LINE account.</li>
                                    </ol>
                                </div>
                            </div>
                        )}
                        {cType === "whatsapp" && (
                            <div className="whatsapp-description-panel">
                                <div>WhatsApp Number:</div>
                                <div>Insight Risk</div>
                                <div>{whatsappNumber}</div>
                                <div>
                                    <span>Instruction:</span>
                                    <ol>
                                        <li>Add the above phone number (Insight Risk) into your contact list.</li>
                                        <li>Add Insight Risk into your desired whatsapp group.</li>
                                        <li>
                                            Please click "Refresh" button from recipient field in order to display the group association in the
                                            recipient list.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        )}
                        {cType === "lark" && (
                            <div className="lark-description-panel">
                                <div>Lark Notification:</div>
                                <div>
                                    <Image src={larkImage} alt="lark template image" />
                                </div>
                                <div>
                                    <span>Instruction:</span>
                                    <ul style={{ padding: "0px 15px" }}>
                                        <li>The "Content" input field supports both standard plain text and customizable message card templates.</li>
                                        <li>
                                            For customizing message card templates, please refer to below:
                                            <br />
                                            <br />
                                            <a
                                                rel="noreferrer"
                                                href="https://open.larksuite.com/document/ukTMukTMukTM/uYzM3QjL2MzN04iNzcDN/message-card-builder"
                                                target="_blank"
                                            >
                                                Message Card Tutorial
                                            </a>
                                            <br />
                                            <a rel="noreferrer" href="https://open.larksuite.com/tool/cardbuilder" target="_blank">
                                                Message Card Builder
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default CreateEditChannel;
