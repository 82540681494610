import { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { apiRequest, APIs } from "../../../services/apiConfig";
import CardBox from "../../../components/Common/CardBox";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import {
    BrandsList,
    CustomPaginationProps,
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    ServersList,
    ToxicAccountHistoricalItem,
    ToxicAccountHistoricalItemClient,
    ToxicAccountResult,
} from "../../../constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { ExclamationCircleOutlined, EyeOutlined, FileTextOutlined, LoadingOutlined, MenuOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileParams, getNavigationUrl } from "../../Analysis/AccountProfileHelper";
import { DateTimeUtil } from "../../../utils/datetime";
import { REQUIRED_FIELD, SOMETHING_WENT_WRONG } from "../../../constants/errorMessage";
import { Button, Dropdown, Form, Modal, Popover, Timeline, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ToObjectWithKey } from "../../../utils/array";
import LoadingComponent from "../../../components/Loading";
import { formatDistanceToNow } from "date-fns";
import EmptyData from "../../../components/Common/Empty";
import { FormComponent } from "../../../components/FormComponent";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";

enum OffQuoteModeType {
    Normal = 0,
    Off_Quote = 1,
    Close_Only = 2,
}

const OffQuoteMode: any = {
    0: "Normal",
    1: "Off Quote",
    2: "Close Only",
};

const ExtClientList: any = {
    1: "ProTrader",
    2: "RC",
    3: "APP",
};

const PMIList = () => {
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [data, setData] = useState<ToxicAccountResult[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({ finalScoreFrom: 75, finalScoreTo: 100 });
    const [sorting, setSorting] = useState<string>("");
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isStatusModalVisible, setIsStatusModalVisible] = useState<boolean>(false);
    const [isPullingHistorical, setIsPullingHistorical] = useState<boolean>(false);
    const [currentHistoricalObject, setCurrentHistoricalObject] = useState<ToxicAccountHistoricalItem[] | undefined>(undefined);
    const [servers, setServers] = useState<ServersList[]>([]);
    const [brands, setBrands] = useState<BrandsList[]>([]);
    const [refreshRate, setRefreshRate] = useState<number>(30);
    const [statusForm] = Form.useForm();
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.TOXIC_ACCOUNTS_EDIT);

    const columnConfig = useMemo(() => {
        return [
            DTColProps.DateTime_ServerTime({
                title: "Captured Time (Server)",
                dataIndex: "capturedTime",
                key: "capturedTime",
                sorter: true,
                width: "8.5vw",
            }),

            DTColProps.DateTime_ServerTime({
                title: "Event Date",
                realTitle: "Event Date",
                dataIndex: "eventDate",
                key: "eventDate",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        text: "Event Date",
                    },
                },
            }),

            {
                title: "Account ID",
                dataIndex: "accountId",
                key: "accountId",
                render: (accountId: number, rowData: any) => {
                    return (
                        <Link
                            to={getNavigationUrl(
                                ACCOUNT_PROFILE_FROM_MODULE.TOXIC_ACCOUNT,
                                getAccountProfileParams(rowData["serverId"], accountId, ACCOUNT_PROFILE_FROM_MODULE.TOXIC_ACCOUNT, "", "", "", "", "")
                            )}
                            target="_blank"
                            style={{ color: "#0e65cc" }}
                        >
                            {accountId}
                        </Link>
                    );
                },
                options: {
                    filter: {
                        type: ComponentType.number,
                    },
                },
            },

            {
                title: "Brand",
                dataIndex: "brandName",
                key: "brandName",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: brands.map(x => ({ text: x.brand, value: x.id })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            },

            {
                title: "Server",
                dataIndex: "serverName",
                key: "serverName",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers.map(x => ({ text: x.server, value: x.id })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            },

            DTColProps.Small(
                {
                    title: "Final Score (100%)",
                    dataIndex: "finalScore",
                    key: "finalScore",
                    sorter: (a: any, b: any) => a["finalScore"] - b["finalScore"],
                    options: {
                        filter: {
                            type: ComponentType.slider,
                            value: "",
                            inputProps: {
                                //defaultValue: [75, 100],
                                initialValue: [75, 100],
                            },
                        },
                    },
                },
                ["text-center"]
            ),

            DTColProps.Small({
                title: "Status",
                dataIndex: "offQuoteModeId",
                key: "offQuoteModeId",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: Object.keys(OffQuoteMode).reduce((finalArr: any, x: string) => {
                            finalArr.push({ text: OffQuoteMode[parseInt(x)], value: parseInt(x) });
                            return finalArr;
                        }, []),
                        inputProps: {
                            style: { width: "100%" },
                            mode: "multiple",
                            allowClear: true,
                        },
                    },
                },
                render: (offQuoteModeId: number, rowData: ToxicAccountResult) => {
                    if (rowData.latestActivityLog === null) return "-";

                    let opts: any = [];
                    if (offQuoteModeId !== 0) {
                        opts.push({
                            key: `1`,
                            label: (
                                <Button
                                    type="text"
                                    style={{ textAlign: "left" }}
                                    onClick={e => {
                                        e.preventDefault();
                                        changeStatusConfirmation(rowData.accountId, rowData.serverId, rowData.brandId, OffQuoteModeType.Normal);
                                    }}
                                >
                                    Reset to Normal Mode
                                </Button>
                            ),
                        });
                    }
                    if (
                        rowData.processingStatus !== 1 &&
                        rowData.latestActivityLog !== null &&
                        rowData.latestActivityLog.offQuoteModeStatusByExtClientId.filter(x => x.offQuoteModeStatus === 0).length > 0
                    ) {
                        opts.push({
                            key: `2`,
                            label: (
                                <Button
                                    type="text"
                                    style={{ textAlign: "left" }}
                                    onClick={e => {
                                        e.preventDefault();
                                        changeStatusConfirmation(
                                            rowData.accountId,
                                            rowData.serverId,
                                            rowData.brandId,
                                            rowData.offQuoteModeId,
                                            true,
                                            rowData.latestActivityLog?.offQuoteModeStatusByExtClientId
                                                .filter(x => x.offQuoteModeStatus === 0)
                                                .map(x => x.extClientId)
                                        );
                                    }}
                                >
                                    Retry Failed Trigger
                                </Button>
                            ),
                        });
                    }
                    if (offQuoteModeId !== 2) {
                        opts.push({
                            key: `3`,
                            label: (
                                <Button
                                    type="text"
                                    style={{ textAlign: "left" }}
                                    onClick={e => {
                                        e.preventDefault();
                                        changeStatusConfirmation(rowData.accountId, rowData.serverId, rowData.brandId, OffQuoteModeType.Close_Only);
                                    }}
                                >
                                    Set Close Only Mode
                                </Button>
                            ),
                        });
                    }
                    return (
                        <div className="status-col">
                            <span className={`val val-${offQuoteModeId}`}>{OffQuoteMode[offQuoteModeId] ?? offQuoteModeId}</span>
                            {enableUpdate && (
                                <Dropdown
                                    trigger={["click"]}
                                    overlayClassName="row-dropdow-panel"
                                    menu={{
                                        items: opts,
                                    }}
                                >
                                    <MenuOutlined />
                                </Dropdown>
                            )}
                        </div>
                    );
                },
            }),

            DTColProps.XLarge({
                title: "Latest Activity Log",
                dataIndex: "latestActivityLog",
                key: "latestActivityLog",
                render: (latestActivityLog: any, rowData: ToxicAccountResult) => {
                    if (latestActivityLog !== null && latestActivityLog.offQuoteModeStatusByExtClientId.length > 0) {
                        return (
                            <div className="latest-activity-log-container">
                                <div className="latest-activity-log-client-desc">
                                    <div className="main-desc">
                                        {rowData.offQuoteModeId === OffQuoteModeType.Off_Quote ? (
                                            <>
                                                Account captured by toxic account event and updated status to "
                                                <b>{OffQuoteMode[OffQuoteModeType.Off_Quote]}</b>"
                                            </>
                                        ) : (
                                            <>
                                                <b>{latestActivityLog.modifiedBy}</b> updated status to "<b>{OffQuoteMode[rowData.offQuoteModeId]}</b>
                                                "
                                            </>
                                        )}
                                    </div>
                                    <div className="remarks">
                                        {latestActivityLog.remarks && latestActivityLog.remarks !== null && latestActivityLog.remarks.length > 0 && (
                                            <div className="inner-div">{latestActivityLog.remarks}</div>
                                        )}
                                    </div>
                                    <div className="clients-desc">
                                        {latestActivityLog.offQuoteModeStatusByExtClientId.map((x: any) =>
                                            rowData.processingStatus === 0 ? (
                                                x.offQuoteModeStatus === 0 ? (
                                                    <Popover
                                                        key={`lacl-${Math.random()}`}
                                                        content={
                                                            <>
                                                                Error Message: <span style={{ color: "#f00f00" }}>{x.exceptionMessage}</span>
                                                            </>
                                                        }
                                                    >
                                                        <span className={`can-hover status-${x.offQuoteModeStatus}`}>
                                                            {ExtClientList?.hasOwnProperty(x.extClientId)
                                                                ? ExtClientList[x.extClientId]
                                                                : x.extClientId}
                                                        </span>
                                                    </Popover>
                                                ) : (
                                                    <span key={`lacl-${Math.random()}`} className={`status-${x.offQuoteModeStatus}`}>
                                                        {ExtClientList?.hasOwnProperty(x.extClientId) ? ExtClientList[x.extClientId] : x.extClientId}
                                                    </span>
                                                )
                                            ) : (
                                                <span
                                                    key={`lacl-${Math.random()}`}
                                                    className={`status-${x.offQuoteModeStatus === 0 ? "2" : x.offQuoteModeStatus}`}
                                                >
                                                    {x.offQuoteModeStatus === 0 && <LoadingOutlined />}
                                                    {ExtClientList?.hasOwnProperty(x.extClientId) ? ExtClientList[x.extClientId] : x.extClientId}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                                <FileTextOutlined
                                    onClick={() => {
                                        setCurrentHistoricalObject(undefined);
                                        getHistoricalDetails(rowData.accountId, rowData.serverId, rowData.brandId);
                                        setIsModalVisible(true);
                                    }}
                                />
                            </div>
                        );
                    }
                    return <></>;
                },
            }),
        ];
    }, [servers, brands, enableUpdate]);

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        export: { text: "Export as CSV" },
        view: (record: any) => {
            return (
                <Link to={`/realtime/toxicaccount/snapshot/${record.id}`} target="_blank">
                    <EyeOutlined style={{ fontSize: "1.125rem", color: "#000000" }} />
                </Link>
            );
        },
        refresh: {
            timer: true,
            refreshSecond: refreshRate,
            enablePeriodSelection: true,
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination(prev => ({
                    ...prev,
                    current: FormData.pagination.current,
                    pageSize: FormData.pagination.pageSize,
                }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "serverName") {
                            fParams["serverIds"] = FormData[x];
                        } else if (x === "brandName") {
                            fParams["brandIds"] = FormData[x];
                        } else if (x === "accountId") {
                            fParams["accountIds"] = [parseInt(FormData[x])];
                        } else if (x === "offQuoteModeId") {
                            fParams["offQuoteModeIds"] = FormData[x];
                        } else if (x === "finalScore") {
                            fParams.finalScoreFrom = FormData[x][0];
                            fParams.finalScoreTo = FormData[x][1];
                        } else if (x === "eventDate") {
                            fParams["eventDateFrom"] = DateTimeUtil.GetOrigin(FormData[x][0]).slice(0, 10);
                            fParams["eventDateTo"] = DateTimeUtil.GetOrigin(FormData[x][1]).slice(0, 10);
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                setIsBtnLoading(true);
                apiRequest(
                    APIs.DOWNLOAD_TOXIC_ACCOUNT_EVENT,
                    {
                        fileType: 0,
                        ...(Object.keys(filterParams)?.length > 0 && { ...filterParams }),
                        ...(sorting.length > 0 && { order: sorting }),
                    },
                    "POST",
                    "arraybuffer"
                )
                    .then((res: any) => {
                        const fileName = res.headers["x-filename"];
                        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/zip" })); // Create blob link to download
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName); // or any other extension
                        document.body.appendChild(link); // Append to html link element page
                        link.click(); // start download
                        document.body.removeChild(link); // Clean up and remove the link
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(SOMETHING_WENT_WRONG, SUCCESS_FAILED.OTHERS_FAILED))
                    )
                    .finally(() => setIsBtnLoading(false));
                break;
            case CALLBACK_KEY.REFRESH_RATE_CHANGED:
                setRefreshRate(FormData.currentRefreshPeriod);
                break;
            case CALLBACK_KEY.REFRESH:
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getToxicAccountList = () => {
        apiRequest(APIs.GET_TOXIC_ACCOUNT_EVENT, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    let serverKeyVal = ToObjectWithKey(servers, "id"),
                        brandKeyVal = ToObjectWithKey(brands, "id"),
                        newData = data.result.map((currData: ToxicAccountResult) => ({
                            ...currData,
                            serverName: serverKeyVal?.hasOwnProperty(currData.serverId) ? serverKeyVal[currData.serverId].server : currData.serverId,
                            brandName: brandKeyVal?.hasOwnProperty(currData.brandId) ? brandKeyVal[currData.brandId].brand : currData.brandId,
                        }));
                    setData(newData);
                    setPagination(prev => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => console.log(err)))
            .finally(() => setIsLoading(false));
    };

    const getHistoricalDetails = (accountId: number, serverId: number, brandId: number) => {
        setIsPullingHistorical(true);
        apiRequest(APIs.GET_TOXIC_ACCOUNT_HISTORICAL_LOG, { accountId, serverId, brandId })
            .then((data: any) => {
                setCurrentHistoricalObject(data);
            })
            .finally(() => setIsPullingHistorical(false));
    };

    const getTimelineTemplate = (item: ToxicAccountHistoricalItem) => {
        let errorArr = item.ClientDetails.filter(x => x.ExceptionMessage.length > 0);
        return (
            <div className="historical-item-container">
                <div className="main-desc">
                    {item.OffQuoteMode === OffQuoteModeType.Off_Quote ? (
                        <>
                            Account captured by toxic account event and updated status to "<b>{OffQuoteMode[OffQuoteModeType.Off_Quote]}</b>"
                        </>
                    ) : (
                        <>
                            <b>{item.ModifiedBy}</b> updated status to "<b>{OffQuoteMode[item.OffQuoteMode]}</b>"
                        </>
                    )}
                </div>
                <div className="remarks"> {item.Remarks && item.Remarks.length > 0 && <div className="inner-div">{item.Remarks}</div>}</div>
                <div className="clients-desc">
                    <div className="latest-activity-log-client-desc">
                        {item.ClientDetails.map((x: ToxicAccountHistoricalItemClient) =>
                            x.ExceptionMessage.length > 0 ? (
                                <Popover
                                    key={`lacl-${Math.random()}`}
                                    content={
                                        <>
                                            {x.OffQuoteUpdateSuccessStatus === 0 ? "Error" : ""} Message:{" "}
                                            <span style={{ color: "#f00f00" }}>{x.ExceptionMessage}</span>
                                        </>
                                    }
                                >
                                    <span key={`lacl-${Math.random()}`} className={`can-hover status-${x.OffQuoteUpdateSuccessStatus}`}>
                                        {x.ClientDesc}
                                        {x.OffQuoteUpdateSuccessStatus === 0 ? ` : ${x.OffQuoteUpdateSuccessStatusDesc}` : ""}
                                    </span>
                                </Popover>
                            ) : (
                                <span key={`lacl-${Math.random()}`} className={`status-${x.OffQuoteUpdateSuccessStatus}`}>
                                    {x.ClientDesc}
                                    {x.OffQuoteUpdateSuccessStatus === 0 ? ` : ${x.OffQuoteUpdateSuccessStatusDesc}` : ""}
                                </span>
                            )
                        )}
                    </div>
                </div>
                {errorArr.length > 0 && (
                    <div className="error-text-container">
                        {errorArr.map(x => (
                            <div key={`err-t-${Math.random()}`}>
                                {x.ClientDesc} : {x.ExceptionMessage}
                            </div>
                        ))}
                    </div>
                )}

                <div className="time-desc">{formatDistanceToNow(new Date(item.ModifiedDateUtc))}</div>
            </div>
        );
    };

    const updateStatusCallback = (params: any) => {
        setTimeout(() => setRunRefetchDataList(true), 1000);
        apiRequest(APIs.UPDATE_TOXIC_ACCOUNT_STATUS, params)
            .then((data: any) => setRunRefetchDataList(true))
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler(
                        err.message && err.message.length > 0 ? err.message : "Failed to process retry calling fail client(s)",
                        SUCCESS_FAILED.OTHERS_FAILED
                    )
                )
            );
    };

    const changeStatusConfirmation = (
        accountId: number,
        serverId: number,
        brandId: number,
        offQuoteMode: number,
        isRetry: boolean = false,
        extClientList: number[] = []
    ) => {
        if (isRetry) {
            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Confirmation",
                width: "50%",
                content: (
                    <span>
                        Confirm to retry calling <b>({extClientList.map(x => ExtClientList[x]).join(", ")})</b> client
                        {extClientList.length > 0 ? "s" : ""}?
                    </span>
                ),
                onOk() {
                    updateStatusCallback({
                        accountId,
                        serverId,
                        brandId,
                        extClientList,
                        offQuoteMode,
                        isRetryForFailedOffQuoteStatus: true,
                    });
                },
                onCancel() {},
            });
        } else {
            statusForm.setFieldsValue({
                accountId,
                serverId,
                brandId,
                toStatus: OffQuoteMode[offQuoteMode],
                offQuoteMode: offQuoteMode,
                remarks: "",
            });
            setIsStatusModalVisible(true);
        }
    };

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server", "brand"] })
            .then((data: any) => {
                setServers(data.servers);
                setBrands(data.brands);
                setRunRefetchDataList(true);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getToxicAccountList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <>
            <div className="toxic-account-div">
                <CardBox title={"Toxic Account"}>
                    <div className="top-info-div">
                        <Popover
                            content={
                                <>
                                    <div>
                                        <b>NOTE:</b>
                                        <ul>
                                            <li>Only applicable to Gold symbols</li>
                                            <li style={{ width: "20vw" }}>
                                                Only include those trades closed within 20 mins or opened for more than 20 mins
                                            </li>
                                        </ul>
                                        <h4>Score: 60</h4>
                                        <ol>
                                            <li>300 &gt; Max DPM &gt; 1,000</li>
                                            <li>Max profit &gt; 3,000</li>
                                            <li>DPM slope &gt; 0.4, DPM R-squared &gt; 0.80</li>
                                        </ol>
                                        <h4>Score: 80</h4>
                                        <ol>
                                            <li>300 &gt; Max DPM &gt; 1,000</li>
                                            <li>Max profit &gt; 10,000</li>
                                            <li>DPM slope &gt; 0.4, DPM R-squared &gt; 0.80</li>
                                        </ol>
                                        <h4>Score: 100</h4>
                                        <ol>
                                            <li>400 &gt; Max DPM &gt; 1,000</li>
                                            <li>Max profit &gt; 15,000</li>
                                            <li>DPM slope &gt; 0.5, DPM R-squared &gt; 0.80</li>
                                        </ol>
                                        <div style={{ width: "23vw" }}>
                                            <h4 style={{ color: "red", overflowWrap: "break-word" }}>
                                                *If the decay score is 60 or higher, we will employ the Gold Volume Moving Average (VMA) model to
                                                determine the VMA score, which will help us assess whether this account has a high likelihood of being
                                                a toxic account or not.
                                            </h4>
                                        </div>
                                    </div>
                                </>
                            }
                            title={<h3>Decay Scoring Model (50%)</h3>}
                            trigger="click"
                            placement="leftTop"
                        >
                            <Button icon={<QuestionCircleOutlined />}>Decay Scoring Model (50%)</Button>
                        </Popover>
                    </div>
                    <FlexiDataTable
                        rowKeyProperty="id"
                        title=""
                        columns={columnConfig}
                        options={options}
                        dataSource={data ?? []}
                        callback={componentCallback}
                        loading={isLoading}
                        pagination={pagination}
                        serverSide={true}
                        tableProps={{
                            bordered: true,
                        }}
                        exporting={isBtnLoading}
                        filterInitialValue={{ finalScore: [75, 100], offQuoteModeId: Object.keys(OffQuoteMode).map(x => parseInt(x)) }}
                    />
                </CardBox>
            </div>

            <Modal
                open={isModalVisible}
                width="35%"
                title="Historical Log"
                wrapClassName="toxic-account-modal-container"
                footer={null}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
            >
                {isPullingHistorical ? (
                    <div style={{ textAlign: "center", paddingTop: "8vh", paddingBottom: "8vh" }}>
                        <LoadingComponent />
                    </div>
                ) : currentHistoricalObject === undefined ? (
                    <>
                        <EmptyData />
                    </>
                ) : (
                    <Timeline>
                        {currentHistoricalObject !== undefined &&
                            (currentHistoricalObject as ToxicAccountHistoricalItem[]).map((x: ToxicAccountHistoricalItem, index: number) => (
                                <Timeline.Item key={`chob-${index}`}>{getTimelineTemplate(x)}</Timeline.Item>
                            ))}
                    </Timeline>
                )}
            </Modal>

            <Modal
                open={isStatusModalVisible}
                width="35%"
                title="Change Status"
                onOk={() => {
                    statusForm
                        .validateFields()
                        .then((values: any) => {
                            updateStatusCallback(values);
                            setIsStatusModalVisible(false);
                        })
                        .catch((info: any) => console.log("Validate Failed:", info));
                }}
                onCancel={() => {
                    setIsStatusModalVisible(false);
                }}
            >
                <Form form={statusForm} layout="horizontal" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
                    <FormComponent label="" name="accountId" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="serverId" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="brandId" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="offQuoteMode" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent
                        label="To Status"
                        name="toStatus"
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            disabled: true,
                        }}
                    />
                    <FormComponent
                        label="Remarks"
                        name="remarks"
                        extra={{
                            type: ComponentType.textarea,
                            value: "",
                            rules: [{ required: true, message: REQUIRED_FIELD }],
                            inputProps: {
                                rows: 10,
                            },
                        }}
                    />
                </Form>
            </Modal>
        </>
    );
};

export default PMIList;
