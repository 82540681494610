import { RCIssueLogType } from "@/hooks/useRCIssuesLog";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import moment from "moment";

export type IssueLogGState = { [Key in RCIssueLogType]: IssueLogNoMoneyStateProps };

export interface IssueLogNoMoneyStateProps {
    dateRange: string[];
    totalCount: number;
}

interface PayloadProps {
    key: string;
    value: any;
}

export const issueLogSlice = createSlice({
    name: "IssueLogSlice",
    initialState: {
        REQUEST_TIMEOUT: {},
        CLOSED_ORDER: {},
        MODIFY_OPEN_ORDER: {
            dateRange: [moment(moment().subtract(1, "days").startOf("day")).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
            totalCount: 0,
        },
        MANAGER_ACCOUNT_INTERCEPTED: {},
        MT5_ORDER_TYPE: {
            dateRange: [moment(moment().subtract(1, "days").startOf("day")).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
            totalCount: 0,
        },
        ACCOUNT_MONITOR: {
            dateRange: [moment(moment().subtract(1, "days").startOf("day")).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
            totalCount: 0,
        },
        RESTORE_ORDER: {},
        UPDATE_ORDER_URGENT: {},
        NO_MONEY: {
            dateRange: [moment(moment().subtract(1, "days").startOf("day")).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
            totalCount: 0,
        },
    } as IssueLogGState,
    reducers: {
        setDateRange: (state, action: PayloadAction<PayloadProps>) => {
            set(state, `${action.payload.key}.dateRange`, action.payload.value);
            console.log(`state update: ${action.payload.key}`, action.payload.value);
        },
        setTotalCount: (state, action: PayloadAction<PayloadProps>) => {
            set(state, `${action.payload.key}.totalCount`, action.payload.value);
        },
    },
});

export const { setDateRange, setTotalCount } = issueLogSlice.actions;

export default issueLogSlice.reducer;
