import TinyTag from "@/components/Common/Tag";
import FlexiDataTable from "@/components/FlexiDataTable";
import { InnerPageActionMode, ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { KeyValuePair, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { objectRemoveProps } from "@/utils/object";
import { useState, useMemo, useEffect } from "react";
import { CreateEditFormMode } from "..";
import ServerGroupCreateEditPage from "./serverGroupCreateEditPage";

export interface ServerGroupListingProps {}

const ServerGroupListing = (props: ServerGroupListingProps) => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [refetch, setRefetch] = useState<number>(0);
    const [formObject, setFormObject] = useState<CreateEditFormMode>({ mode: InnerPageActionMode.CREATE_NEW });
    const [serverList, setServerList] = useState<KeyValuePair[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_TOOLS_HUB_CONFIG_EDIT);

    const columns = useMemo(
        () => [
            DTColProps.XLarge({
                title: "Server Group Name",
                dataIndex: "groupName",
                key: "groupName",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            {
                title: "Server Name",
                dataIndex: "servers",
                key: "servers",
                render: (value: string[]) => {
                    let sortedArray = [...value];
                    sortedArray.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
                    return sortedArray.map((x: string, index: number) => <TinyTag key={`${x}-${index}`} text={x} />);
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: serverList,
                        callback: (filterValue: number, rowData: any) => rowData["servers"].includes(filterValue),
                    },
                },
            },
        ],
        [serverList]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, rowData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, groupId: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, groupId: rowData["id"] });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                apiRequest(APIs.GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_DETAIL, { groupType: 0, groupId: rowData["id"] })
                    .then(res => {
                        apiRequest(APIs.UPDATE_HUB_FAILOVER_SERVER_SECURITY_GROUP_CONFIG, {
                            ...objectRemoveProps(res, ["groupId", "sourceGroupDetails", "securityGroupDetails"]),
                            id: res.groupId,
                            groupType: 0,
                            sourceGroups: res.sourceGroupDetails.map((x: any) => ({ ...x, isDeleted: true })),
                            isDeleted: true,
                        })
                            .then(() => {
                                ErrorMessageHandler("server group", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                                getList();
                            })
                            .catch(error =>
                                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server group", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                            );
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server group", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    );
                break;
            default:
                break;
        }
    };

    const getList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_HUB_FAILOVER_SERVER_SECURITY_GROUP_LIST, { groupType: 0 })
            .then((res: any) => {
                setData(res.length > 0 ? res : []);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server group", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    const loadFilterConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["hubFailover"] })
            .then(
                (data: any) =>
                    data.hubFailover &&
                    data.hubFailover.servers &&
                    setServerList(data.hubFailover.servers.map((x: any) => ({ text: x.server, value: x.server })))
            )
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(err)));
    };

    useEffect(() => {
        loadFilterConfig();
        getList();
        return () => {};
    }, []);

    return (
        <div className="single-page">
            <div className={`${isFirstOpt ? "active" : ""}`}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <div className={`${isFirstOpt ? "" : "active"}`}>
                <ServerGroupCreateEditPage
                    {...formObject}
                    callback={(action: InnerPageActionMode, value: any) => {
                        switch (action) {
                            case InnerPageActionMode.BACK:
                                setIsFirstOpt(prev => !prev);
                                if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                    getList();
                                }
                                break;
                        }
                    }}
                    resetState={refetch}
                />
            </div>
        </div>
    );
};

export default ServerGroupListing;
