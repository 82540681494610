import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType, STATUS_TYPE, CALLBACK_KEY, SUCCESS_FAILED, InnerPageActionMode } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { User, RoleType, ProfileProps, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, CreateEditInlineFormBased } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { getProfile } from "@/services/localStorage";
import { ToOptionTypeList } from "@/utils/array";
import { DTColProps, DataTableColumnRender, ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Form, Tooltip, Modal } from "antd";
import moment from "moment";
import { useState, useMemo, useCallback, useEffect } from "react";
import UserCreatePage from "./UserCreatePage";
import UserEditPage from "./UserEditPage";


const UsersManagementTab = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<User[]>([]);

    const [roleList, setRoleList] = useState<RoleType[]>([]);
    const [teamList, setTeamList] = useState<string[]>([]);
    const [manualLoading, setManualLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [, setResetPasswordModal] = useState<any>({});
    const [resetPwdForm] = Form.useForm();
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [isAddAction, setIsAddAction] = useState<boolean>(false);
    const [refetch, setRefetch] = useState<number>(0);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_USER_EDIT);
    const currentUser: ProfileProps = getProfile() as ProfileProps;

    const columns = useMemo(
        () => [
            {
                title: (
                    <span key={"urnm-tlps"}>
                        Username
                        <Tooltip title={"User's login name"} key={"urnm-tlps-cl"}>
                            <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                        </Tooltip>
                    </span>
                ),
                realTitle: "Username",
                dataIndex: "username",
                key: "username",
                fixed: "left",
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            },

            {
                title: "User Role",
                dataIndex: "roles",
                key: "roles",
                render: (text: string, record: User) => {
                    return text.split(",").join(", ");
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: roleList.map(x => ({ text: x.role, value: x.id })),
                        callback: (filter_values: any, record: User) => {
                            return record["roleIds"].split(",").indexOf(filter_values.toString()) > -1;
                        },
                    },
                },
            },

            {
                title: "User Team",
                dataIndex: "team",
                key: "team",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: teamList.map(x => ({ text: x, value: x })),
                        callback: (filterValue: any, rowData: any) => {
                            if (rowData.team === null) {
                                return false;
                            }

                            return filterValue.includes(rowData.team);
                        },
                    },
                },
            },

            DTColProps.Status({
                title: "Status",
                dataIndex: "isActive",
                key: "isActive",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: ToOptionTypeList(STATUS_TYPE),
                    },
                },
            }),

            DTColProps.Middle({
                title: "Last Login (Local)",
                dataIndex: "lastLoginTimeUtc",
                key: "lastLoginTimeUtc",
                sorter: (a: any, b: any) => {
                    const dateA = a.lastLoginTimeUtc ? moment(a.lastLoginTimeUtc) : null;
                    const dateB = b.lastLoginTimeUtc ? moment(b.lastLoginTimeUtc) : null;

                    if (dateA && dateB) {
                        return dateA.isAfter(dateB) ? 1 : -1;
                    } else if (dateA) {
                        return 1; // Non-null date should come first
                    } else if (dateB) {
                        return -1; // Non-null date should come first
                    } else {
                        return 0; // Both are null, consider them equal
                    }
                },

                render: (value: string) => (value ? DataTableColumnRender.DateTime(value) : ""),
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: true,
                        },
                    },
                },
            }),
            DTColProps.Middle({
                title: "Last Access (Local)",
                dataIndex: "lastAccessTimeUtc",
                key: "lastAccessTimeUtc",
                sorter: (a: any, b: any) => {
                    const dateA = a.lastAccessTimeUtc ? moment(a.lastAccessTimeUtc) : null;
                    const dateB = b.lastAccessTimeUtc ? moment(b.lastAccessTimeUtc) : null;

                    if (dateA && dateB) {
                        return dateA.isAfter(dateB) ? 1 : -1;
                    } else if (dateA) {
                        return 1; // Non-null date should come first
                    } else if (dateB) {
                        return -1; // Non-null date should come first
                    } else {
                        return 0; // Both are null, consider them equal
                    }
                },

                render: (value: string) => (value ? DataTableColumnRender.DateTime(value) : ""),
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: true,
                        },
                    },
                },
            }),
        ],
        [roleList, teamList]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate ? { text: "Bind User" } : false,
        edit: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(false);
                setIsAddAction(true);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(false);
                setIsAddAction(false);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: FormData });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setManualLoading(true);
                apiRequest(`${APIs.DELETE_USER_ACCOUNT}`, { uuid: FormData.uuid }, "POST")
                    .then(res => {
                        ErrorMessageHandler("The user record", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setManualLoading(false);
                        getBindedUsers();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("user. User needs to be deactivated first before deleting.", SUCCESS_FAILED.FAILED_DELETE_DATA, err)
                        );
                        setManualLoading(false);
                    });
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "reset_password") {
                    setShowModal(true);
                    setResetPasswordModal(FormData.data);
                    resetPwdForm.setFieldsValue({ uuid: FormData.data["uuid"], password: "" });
                }
                break;
        }
    };

    const resetUserPassword = (uuid: string, password: string) => {
        apiRequest(`${APIs.RESET_PASSWORD_USER_ACCOUNT}`, { uuid, password })
            .then(res => {
                ErrorMessageHandler("Account password reset", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                setShowModal(false);
                resetPwdForm.resetFields();
                getBindedUsers();
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account password reset", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                setShowModal(false);
            });
    };

    const getUserTeams = () => {
        apiRequest(APIs.GET_SETTING_LIST, { keys: ["UserTeams"] })
            .then((res: any) => {
                const userTeamsValue = JSON.parse(res.find((item: { key: string }) => item.key === "UserTeams")?.value || "null");
                setTeamList(userTeamsValue);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to get latest user teams: ", err));
            });
    };

    const getUserRoles = useCallback(() => {
        apiRequest(APIs.GET_USER_ROLE_LIST, { userId: currentUser.id })
            .then((response: any) => {
                let roles = response as RoleType[];
                setRoleList(roles);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(`Error: ${err}`));
            });
    }, [currentUser]);

    const getBindedUsers = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_USER_PROFILES, { isBinded: true })
            .then((res: User[]) => {
                setData(res && res.length > 0 ? res.filter(x => `${x.uuid}` !== `${currentUser.id}`) : []);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to get user list: ", err));
            })
            .finally(() => setIsLoading(false));
    }, [currentUser]);

    useEffect(() => {
        getBindedUsers();
        getUserTeams();
        getUserRoles();

        return () => { };
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Users Management</span>
                </div>
            </div>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <FlexiDataTable
                        rowKeyProperty="uuid"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading || manualLoading}
                    />
                    <Modal
                        open={showModal}
                        title="Change Password"
                        onCancel={() => {
                            setShowModal(false);
                            resetPwdForm.resetFields();
                        }}
                        onOk={() => {
                            resetPwdForm
                                .validateFields()
                                .then(values => resetUserPassword(values.uuid, values.password))
                                .catch(err => { });
                        }}
                    >
                        <Form form={resetPwdForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
                            <div className="reset-password-pattern">
                                <WarningOutlined className="error" style={{ height: "20px" }} />
                                <span className="error">
                                    Password must contain at least 8 characters including at least 1 uppercase letter, 1 lowercase letter and 1 number.
                                </span>
                            </div>
                            <FormComponent label={""} name={"uuid"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Temporary Password"
                                name={"password"}
                                extra={{
                                    type: ComponentType.password,
                                    value: "",
                                    rules: [
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || value.length < 1) {
                                                    return Promise.reject(new Error(REQUIRED_FIELD));
                                                } else if (!(/[a-z]+/.test(value) && /[A-Z]+/.test(value) && /[0-9]+/.test(value)) || value.length < 8) {
                                                    return Promise.reject(new Error("Invalid password."));
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ],
                                }}
                            />
                        </Form>
                    </Modal>
                </div>
                {!isFirstOpt && (
                    <div className={`${isFirstOpt ? "" : "active"}`}>
                        {isAddAction ? (
                            <UserCreatePage
                                {...formObject}
                                callback={(action: InnerPageActionMode, value: any) => {
                                    switch (action) {
                                        case InnerPageActionMode.BACK:
                                            setIsFirstOpt(prev => !prev);
                                            if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                                getBindedUsers();
                                            }
                                            break;
                                    }
                                }}
                                resetState={refetch}
                            />
                        ) : (
                            <UserEditPage
                                {...formObject}
                                callback={(action: InnerPageActionMode, value: any) => {
                                    switch (action) {
                                        case InnerPageActionMode.BACK:
                                            setIsFirstOpt(prev => !prev);
                                            if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                                getBindedUsers();
                                            }
                                            break;
                                    }
                                }}
                                resetState={refetch}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default UsersManagementTab;
