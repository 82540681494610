import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState } from "react";
import FeatureSetting from "./FeatureSetting";
import ServerFeatureAccountPage from "./ServerFeatureAccount";

interface FeatureSettingsPageProps {}

const FeatureSettingsPage = (props: FeatureSettingsPageProps) => {
    const [segmentVal, setSegmentVal] = useState<string>("Feature Setting");

    return (
        <div className="settings-panel-main-segment-panel-container">
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Feature Settings</span>
                </div>
            </div>
            <div className="top-panel">
                <Segmented
                    options={["Feature Setting", "Server Feature Account"]}
                    value={segmentVal}
                    onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                />
            </div>
            <div className="container">
                {segmentVal === "Feature Setting" && <FeatureSetting />}
                {segmentVal === "Server Feature Account" && <ServerFeatureAccountPage />}
            </div>
        </div>
    );
};

export default FeatureSettingsPage;
