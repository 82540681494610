import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState } from "react";
import LabelSetting from "./LabelSetting";
import WhitelistSetting from "./WhitelistSetting";

export interface CIDSettingsPageProps {}

const CIDSettingsPage = (props: CIDSettingsPageProps) => {
    const [segmentVal, setSegmentVal] = useState<string>("Label Configuration");

    return (
        <div className="CID-setting-container">
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>CID Settings</span>
                </div>
            </div>
            <div className="top-panel">
                <Segmented
                    options={["Label Configuration", "Whitelist Setting"]}
                    value={segmentVal}
                    onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                />
            </div>
            <div className="container">
                {segmentVal === "Label Configuration" && <LabelSetting />}
                {segmentVal === "Whitelist Setting" && <WhitelistSetting />}
            </div>
        </div>
    );
};

export default CIDSettingsPage;
