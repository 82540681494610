import { useState } from "react";
import FloatingTitleBox from "../../components/FloatingTitleBox";
import { Tabs } from "antd";
import useRCIssuesLogStore from "../../../../store/useRCIssuesLogStore";
import NoMoneyMailTable from "./NoMoneyMailTable";

const IssueNoMoney = () => {
    const updateInView = useRCIssuesLogStore(state => state.updateInView);
    const [currTab, setCurrTab] = useState<string>("mail");
    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
    };

    return (
        <FloatingTitleBox
            minHeight="unset"
            title="No Money"
            inViewCallback={(isInView: boolean) => updateInView({ NO_MONEY: isInView })}
            titleBarTabs={
                <Tabs
                    activeKey={currTab}
                    onChange={handleChangeTab}
                    type="card"
                    style={{
                        padding: "0.5rem 0.5rem 0 0.5rem",
                    }}
                    tabBarStyle={{
                        marginBottom: 0,
                    }}
                    items={[
                        {
                            label: "Mail Log",
                            key: "mail",
                        },
                    ]}
                />
            }
        >
            {currTab === "mail" && <NoMoneyMailTable />}
        </FloatingTitleBox>
    );
};

export default IssueNoMoney;
