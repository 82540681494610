import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Button, Col, Modal, Row, Tooltip, Form, message, Tabs, Segmented } from "antd";
import { useEffect, useState } from "react";
import AccountOverviewPage from "./accountOverview";
import TaggingListPage from "./tagging";
import { FilePdfOutlined, HomeOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { apiRequest, APIs } from "../../../services/apiConfig";
import HistoricalTradesPage from "./historicalTrades";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { getSource, removeSource, setSource } from "../../../services/localStorage";
import { FormComponent } from "../../../components/FormComponent";
import { ACCOUNT_PROFILE_FROM_MODULE, getAccountInfo, getAccountProfileIdByObj, getNavigationUrl } from "../AccountProfileHelper";
import LoadingComponent from "../../../components/Loading";
import { AccountWatchDetails, DateRangeFilter, MetricGroup, NavigationItem, UsersList, WatchListGroup } from "../../../constants/type";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import SymbolAnalysis from "./symbolAnalysis";
import AdvanceAnalysisPage from "./advanceAnalysis";
import moment from "moment";
import { DateTimeUtil } from "../../../utils/datetime";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";

export interface ProfileInfoProps {
    accountId: string | number;
    accountType?: string;
    brandId?: string | number;
    brand?: string;
    serverId: string | number;
    server?: string;
    clientId?: string | number;
    clientName?: string;
    regulatorId?: string | number;
    regulator?: string;
    regulatorName?: string;
    currency?: string;
    isWatch?: boolean;
    fromModule?: string;
    groupName?: string;
    accountWatchDetails?: AccountWatchDetails | null;
    viewReportId?: string;
    client360Id?: string;
    watchGroupName?: string;
    labels?: string[];
    dateRangeFilter?: DateRangeFilter;
    crmServerId?: string | number;
    crmServer?: string;
}

/**
 *  29/02/2024 - Xin
 *  commenting out Symbol Analysis tab
 *  because inaccurate symbol data
 */
const NavigationList: NavigationItem[] = [
    { key: "1", title: "Account Overview" },
    { key: "2", title: "Tagging" },
    { key: "3", title: "Historical Trade" },
    // { key: "4", title: "Symbol Analysis" },
    { key: "5", title: "Advance Analysis" },
];

export const initialValues: ProfileInfoProps = {
    accountId: 0,
    accountType: "",
    brandId: 0,
    brand: "",
    serverId: 0,
    server: "",
    clientId: 0,
    clientName: "",
    regulatorId: 0,
    regulatorName: "",
    currency: "",
    isWatch: false,
    fromModule: "",
    groupName: "",
    accountWatchDetails: {
        addedDatetimeUtc: "",
        comment: "",
        pnlCaptured: 0,
        pnlHistorical: 0,
        pnlDifference: 0,
        pnlPass7Days: 0,
        pnlBreach: "",
        addedBy: "",
        pnlThreshold: 0,
        operator: 0,
        watchGroupId: 0,
        watchGroupName: "",
        actionDate: "",
        assigneeId: 0,
        depositCaptured: 0,
        depositCurrent: 0,
        withdrawalCaptured: 0,
        withdrawalCurrent: 0,
    },
    watchGroupName: "",
    dateRangeFilter: {},
};

const Account360Profile = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let { id } = useParams();
    const profileParams = location.search.includes("?") ? location.search : id || "";

    const paramsInfo = getAccountInfo(profileParams);
    const [currentActiveTab, setCurrentActiveTab] = useState<string>("1");
    const [currIsWatch, setCurrIsWatch] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [watchListForm] = Form.useForm();
    const [accountInfo, setAccountInfo] = useState<ProfileInfoProps>(initialValues);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isWLLoading, setIsWLLoading] = useState<boolean>(false);
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
    const [symbols, setSymbols] = useState<string[]>([]);
    const [metricCategory, setMetricCategory] = useState<MetricGroup[]>([]);
    const [groupsList, setGroupsList] = useState<WatchListGroup[]>([]);
    const [usersList, setUsersList] = useState<UsersList[]>([]);

    const ecAction = Form.useWatch("action", watchListForm);
    const ecOperator = Form.useWatch("operator", watchListForm);

    const getBreadcum = (paramsInfo: any) => {
        if (accountInfo && accountInfo.accountId !== 0) {
            return (
                <div className="breadcrumb-panel">
                    <Breadcrumb>
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.CLIENTS_LIST && (
                            <>
                                <Breadcrumb.Item href="#" onClick={() => navigate("/analysis/clientlist")}>
                                    <HomeOutlined />
                                    <span>Client List</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href="#"
                                    onClick={() =>
                                        navigate(
                                            `/analysis/clientlist/launch?clientId=${paramsInfo.clientId}&regulatorId=${paramsInfo.regulatorId}&crmServerId=${paramsInfo.crmServerId}`
                                        )
                                    }
                                >
                                    Client Profile
                                </Breadcrumb.Item>
                            </>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.ACCOUNT_PROFILE_LIST && (
                            <Breadcrumb.Item href="#" onClick={() => navigate("/analysis/accountprofile")}>
                                <HomeOutlined />
                                <span>Account List</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.TAGGING_LIST && (
                            <Breadcrumb.Item href="#" onClick={() => navigate("/tagging/list")}>
                                <HomeOutlined />
                                <span>Tagging List</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.WATCH_LIST && (
                            <Breadcrumb.Item href="#" onClick={() => navigate("/analysis/watchlist")}>
                                <HomeOutlined />
                                <span>Account Watch List</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.MONITOR_PROFILE_LIST && (
                            <Breadcrumb.Item href="#" onClick={() => navigate("/realtime/trademonitor")}>
                                <HomeOutlined />
                                <span>Monitor Profile List</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.HISTORICAL_EVENT_LIST && (
                            <Breadcrumb.Item href="#" onClick={() => navigate("/realtime/historicaleventbatches")}>
                                <HomeOutlined />
                                <span>Historical Event List</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.HISTORICAL_EVENT_SUMMARY_LIST && (
                            <Breadcrumb.Item href="#" onClick={() => navigate("/realtime/historicaleventsummary")}>
                                <HomeOutlined />
                                <span>Historical Event Summary List</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.VIEW_REPORT_INSTANCES && (
                            <Breadcrumb.Item href="#" onClick={() => navigate(`/report/result/view/${paramsInfo.viewReportId}`)}>
                                <HomeOutlined />
                                <span>View Report Instances</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.DECAY_ANALYSIS && (
                            <Breadcrumb.Item href="#" onClick={() => navigate(`/realtime/decaychart`)}>
                                <HomeOutlined />
                                <span>Decay Analysis</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.CLOSED_DECAY_ANALYSIS && (
                            <Breadcrumb.Item href="#" onClick={() => navigate(`/realtime/closeddecaychart`)}>
                                <HomeOutlined />
                                <span>Closed Decay Analysis</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.TOXIC_ACCOUNT && (
                            <Breadcrumb.Item href="#" onClick={() => navigate(`/realtime/toxicaccount`)}>
                                <HomeOutlined />
                                <span>Toxic Account</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.PROBLEMATIC_CLIENT_ACCOUNT && (
                            <Breadcrumb.Item href="#" onClick={() => navigate(`/analysis/problematicClients`)}>
                                <HomeOutlined />
                                <span>Problematic Clients</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_SUMMARY && (
                            <Breadcrumb.Item href="#" onClick={() => navigate(`/intradaymonitor/summary`)}>
                                <HomeOutlined />
                                <span>Intraday Summary</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_SEARCH && (
                            <Breadcrumb.Item href="#" onClick={() => navigate(`/intradaymonitor/search`)}>
                                <HomeOutlined />
                                <span>Intraday Search</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.INTRADAY_MONITOR_ALARM && (
                            <Breadcrumb.Item href="#" onClick={() => navigate(`/intradaymonitor/alarm`)}>
                                <HomeOutlined />
                                <span>Intraday Alarm</span>
                            </Breadcrumb.Item>
                        )}
                        {paramsInfo.fromModule === ACCOUNT_PROFILE_FROM_MODULE.PREMARKET_ACCOUNT && (
                            <Breadcrumb.Item href="#" onClick={() => navigate(`/analysis/premarketAccountModel`)}>
                                <HomeOutlined />
                                <span>Pre-Market Account Model</span>
                            </Breadcrumb.Item>
                        )}
                        <Breadcrumb.Item>Account 360 Analysis</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            );
        }
        return "";
    };

    const getEditRemoveModalProps = () => {
        switch (ecAction) {
            case "add":
                return {
                    title: "Add account into watch list",
                    footer: [
                        <Button
                            key="back"
                            onClick={() => {
                                watchListForm.resetFields();
                                setVisible(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            loading={isWLLoading}
                            type="primary"
                            onClick={() => {
                                watchListForm
                                    .validateFields()
                                    .then((values: any) => {
                                        let isAdded: boolean = values.action === "add";
                                        if (isAdded) {
                                            if (values.operator.toString() === "0" || values.operator === undefined || values.operator === "") {
                                                delete values["operator"];
                                                delete values["pnlThreshold"];
                                            } else if (values.pnlThreshold === "") {
                                                delete values["pnlThreshold"];
                                            }
                                        }
                                        if (values.comment === "") {
                                            delete values["comment"];
                                        }
                                        setIsWLLoading(true);
                                        apiRequest(APIs.ADD_WATCHLIST, {
                                            ...values,
                                            actionDate: values["actionDate"] ? DateTimeUtil.GetOrigin(values["actionDate"]) : null,
                                        })
                                            .then((data: any) => {
                                                ErrorMessageHandler(
                                                    `Successfully added current account into watch list.`,
                                                    SUCCESS_FAILED.OTHERS_SUCCESS
                                                );
                                                setVisible(false);
                                                setIsWLLoading(false);
                                                setCurrIsWatch(isAdded);
                                                window.location.href = getNavigationUrl(
                                                    paramsInfo.fromModule as string,
                                                    getAccountProfileIdByObj({ ...paramsInfo })
                                                );
                                            })
                                            .catch((error: any) => {
                                                ErrorCatchValidator(error, (err: any) =>
                                                    ErrorMessageHandler(
                                                        `Failed to add current account into watch list`,
                                                        SUCCESS_FAILED.OTHERS_FAILED,
                                                        err
                                                    )
                                                );
                                                setIsWLLoading(false);
                                            });
                                    })
                                    .catch(info => {
                                        console.log("Validate Failed:", info);
                                    });
                            }}
                        >
                            OK
                        </Button>,
                    ],
                };
            case "edit":
                return {
                    title: "Edit watch list information",
                    footer: [
                        <Button
                            key="back"
                            onClick={() => {
                                watchListForm.resetFields();
                                setVisible(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key="remove"
                            type="primary"
                            onClick={() => {
                                setIsModalLoading(true);
                                setTimeout(() => {
                                    setIsModalLoading(false);
                                    watchListForm.setFieldsValue({
                                        action: "delete",
                                        accountId: accountInfo.accountId,
                                        brandId: accountInfo.brandId,
                                        serverId: accountInfo.serverId,
                                        comment: "",
                                        operator: 0,
                                        pnlThreshold: "",
                                        watchGroupId: null,
                                        actionDate: null,
                                    });
                                }, 200);
                            }}
                        >
                            Remove account from watch list
                        </Button>,
                        <Button
                            key="submit"
                            loading={isWLLoading}
                            type="primary"
                            onClick={() => {
                                watchListForm
                                    .validateFields()
                                    .then((values: any) => {
                                        if (values.operator.toString() === "0" || values.operator === undefined || values.operator === "") {
                                            delete values["operator"];
                                            delete values["pnlThreshold"];
                                        } else {
                                            if (values.pnlThreshold === "") {
                                                delete values["pnlThreshold"];
                                            }
                                        }

                                        if (values.comment === "") {
                                            delete values["comment"];
                                        }
                                        setIsWLLoading(true);
                                        apiRequest(APIs.UPDATE_WATCHLIST_COMMENT, {
                                            ...values,
                                            actionDate: values["actionDate"] ? DateTimeUtil.GetOrigin(values["actionDate"]) : null,
                                        })
                                            .then((data: any) => {
                                                ErrorMessageHandler("Current watch list comment", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                                                setVisible(false);
                                                setIsWLLoading(false);
                                                window.location.href = getNavigationUrl(
                                                    paramsInfo.fromModule as string,
                                                    getAccountProfileIdByObj({ ...paramsInfo })
                                                );
                                            })
                                            .catch((error: any) => {
                                                ErrorCatchValidator(error, (err: any) =>
                                                    ErrorMessageHandler("current watch list comment", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                                                );
                                                setIsWLLoading(false);
                                            });
                                    })
                                    .catch(info => {
                                        console.log("Validate Failed:", info);
                                    });
                            }}
                        >
                            OK
                        </Button>,
                    ],
                };
            case "delete":
                return {
                    title: "Remove account from watch list",
                    footer: [
                        <Button
                            key="back"
                            onClick={() => {
                                watchListForm.resetFields();
                                setVisible(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            loading={isWLLoading}
                            type="primary"
                            onClick={() => {
                                watchListForm
                                    .validateFields()
                                    .then((values: any) => {
                                        delete values["operator"];
                                        delete values["pnlThreshold"];
                                        delete values["depositCaptured"];
                                        delete values["withdrawalCaptured"];
                                        delete values["assigneeId"];
                                        if (values.comment === "") {
                                            delete values["comment"];
                                        }
                                        setIsWLLoading(true);
                                        apiRequest(APIs.REMOVE_WATCHLIST, { ...values })
                                            .then((data: any) => {
                                                ErrorMessageHandler(
                                                    `Successfully removed current account from watch list.`,
                                                    SUCCESS_FAILED.OTHERS_SUCCESS
                                                );
                                                setVisible(false);
                                                setIsWLLoading(false);
                                                window.location.href = getNavigationUrl(
                                                    paramsInfo.fromModule as string,
                                                    getAccountProfileIdByObj({ ...paramsInfo })
                                                );
                                            })
                                            .catch((error: any) => {
                                                ErrorCatchValidator(error, (err: any) =>
                                                    ErrorMessageHandler(`Failed to remove account from watch list`, SUCCESS_FAILED.OTHERS_FAILED, err)
                                                );
                                                setIsWLLoading(false);
                                            });
                                    })
                                    .catch(info => {
                                        console.log("Validate Failed:", info);
                                    });
                            }}
                        >
                            OK
                        </Button>,
                    ],
                };
            default:
                break;
        }
    };

    const setSymbolLink = (symbolList: string[]) => {
        symbolList.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
        setSymbols(symbolList);
    };

    const initLoad = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["metriccategory", "accountwatchlistgroup", "user"] })
            .then((data: any) => {
                setUsersList(data.users);
                setMetricCategory(data.metricCategories);
                setGroupsList(data.accountWatchListGroups);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {}));
    };

    const getAccountProfile = () => {
        apiRequest(APIs.GET_ACCOUNT_PROFILE_LIST, {
            serverId: paramsInfo.serverId,
            accountId: paramsInfo.accountId,
            isOneOnly: true,
        })
            .then((data: any) => {
                if (data === null) {
                    ErrorMessageHandler(`Account does not exist.`, SUCCESS_FAILED.OTHERS_FAILED);
                } else {
                    watchListForm.setFieldsValue({
                        accountId: data.result[0].accountId,
                        brandId: data.result[0].brandId,
                        serverId: data.result[0].serverId,
                    });
                    setAccountInfo(data.result[0]);
                    setCurrIsWatch(data.result[0].isWatch);
                    setIsLoading(false);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account profile details", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });
    };

    useEffect(() => {
        setSource(location);

        return () => {
            removeSource();
        };
    }, []);

    useEffect(() => {
        setIsLoading(true);
        initLoad();
        getAccountProfile();
        return () => {};
    }, [profileParams]);

    return (
        <>
            {getBreadcum(paramsInfo)}
            <div className="account-360-profile shadow-light">
                <div className="account-360-profile-acc-info">
                    <div className="left">
                        <div className="info-container">
                            <div className="item">
                                <span>{accountInfo.brand}</span>
                                <span>Brand</span>
                            </div>
                            <div className="item">
                                <span>{accountInfo.server}</span>
                                <span>Server</span>
                            </div>
                            <div className="item">
                                <span>{accountInfo.accountId}</span>
                                <span>Account ID</span>
                            </div>
                            {/* <div className="item">
                                <span>{accountInfo.accountType}</span>
                                <span>Account Type</span>
                            </div> */}
                            <div className="item">
                                <span>{accountInfo.currency}</span>
                                <span>Currency</span>
                            </div>
                            <div className="item">
                                <span>{accountInfo.groupName}</span>
                                <span>Group Name</span>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="title-extra-panel">
                            {accountInfo && accountInfo.isWatch && (
                                <Tooltip title="Watch List Group">
                                    <div className={`watch-group-name ${enabledCheckingByLocation(getSource(location)) ? "" : "view-only"}`}>
                                        <div className="name">
                                            {accountInfo.watchGroupName && accountInfo.watchGroupName?.length > 0
                                                ? accountInfo.watchGroupName
                                                : "Uncategorized"}
                                        </div>
                                    </div>
                                </Tooltip>
                            )}
                            {enabledCheckingByLocation(getSource(location)) &&
                                (currIsWatch ? (
                                    <Tooltip title="Click to edit watch list">
                                        <Button
                                            type="primary"
                                            icon={<StarFilled style={{ fontSize: "1.25rem" }} />}
                                            className="selected"
                                            onClick={() => {
                                                watchListForm.setFieldsValue({
                                                    action: "edit",
                                                    accountId: accountInfo.accountId,
                                                    brandId: accountInfo.brandId,
                                                    serverId: accountInfo.serverId,
                                                    comment: accountInfo?.accountWatchDetails?.comment || "",
                                                    operator: accountInfo?.accountWatchDetails?.operator || 0,
                                                    pnlThreshold: accountInfo?.accountWatchDetails?.pnlThreshold || "",
                                                    depositCaptured: accountInfo?.accountWatchDetails?.depositCaptured || null,
                                                    withdrawalCaptured: accountInfo?.accountWatchDetails?.withdrawalCaptured || null,
                                                    watchGroupId: accountInfo?.accountWatchDetails?.watchGroupId || null,
                                                    pnlCaptured: accountInfo?.accountWatchDetails?.pnlCaptured || null,
                                                    actionDate: accountInfo?.accountWatchDetails?.actionDate
                                                        ? moment(accountInfo?.accountWatchDetails?.actionDate)
                                                        : null,
                                                    assigneeId: accountInfo?.accountWatchDetails?.assigneeId || null,
                                                });
                                                setVisible(true);
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Click to add to watch list">
                                        <Button
                                            icon={<StarOutlined style={{ fontSize: "1.25rem" }} />}
                                            className="watchlist"
                                            onClick={() => {
                                                watchListForm.setFieldsValue({
                                                    action: "add",
                                                    accountId: accountInfo.accountId,
                                                    brandId: accountInfo.brandId,
                                                    serverId: accountInfo.serverId,
                                                    comment: "",
                                                    operator: 0,
                                                    pnlThreshold: "",
                                                    depositCaptured: null,
                                                    withdrawalCaptured: null,
                                                    watchGroupId: null,
                                                    pnlCaptured: null,
                                                    actionDate: null,
                                                    assigneeId: null,
                                                });
                                                setVisible(true);
                                            }}
                                        />
                                    </Tooltip>
                                ))}
                            <Tooltip title="Export to PDF">
                                <a href={`/export/accountprofile/${getAccountProfileIdByObj(accountInfo)}`} target="_blank">
                                    <Button type="primary" icon={<FilePdfOutlined style={{ fontSize: "1.25rem" }} />} />
                                </a>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="account-360-profile-navs">
                    <div className="left">
                        <Segmented
                            value={currentActiveTab}
                            options={NavigationList.map((x: NavigationItem) => ({
                                label: x.title,
                                value: x.key,
                            }))}
                            onChange={(activeKey: any) => setCurrentActiveTab(activeKey)}
                        />
                    </div>
                    <div className="right"></div>
                </div>
                <div className="account-360-profile-content">
                    <div className="content">
                        <div className="major-content">
                            {isLoading ? (
                                <div className="loading-container" style={{ width: "100%" }}>
                                    <LoadingComponent tip="Loading..." />
                                </div>
                            ) : (
                                <>
                                    {currentActiveTab === "1" && (
                                        <AccountOverviewPage
                                            {...accountInfo}
                                            metricCategories={metricCategory}
                                            setSymbolCallback={setSymbolLink}
                                            groupsList={groupsList}
                                            setAccountInfoCallback={(accountInfo: ProfileInfoProps) => setAccountInfo(accountInfo)}
                                        />
                                    )}
                                    {currentActiveTab === "2" && <TaggingListPage {...accountInfo} />}
                                    {currentActiveTab === "3" && <HistoricalTradesPage {...accountInfo} />}
                                    {currentActiveTab === "4" && (
                                        <SymbolAnalysis {...accountInfo} metricCategories={metricCategory} symbols={symbols} />
                                    )}
                                    {currentActiveTab === "5" && <AdvanceAnalysisPage {...paramsInfo} symbols={symbols} />}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                {...getEditRemoveModalProps()}
                open={visible}
                width={"40%"}
                onCancel={() => {
                    watchListForm.resetFields();
                    setVisible(false);
                }}
            >
                {isModalLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <Form form={watchListForm} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal" initialValues={{}}>
                        <FormComponent label={""} name={"action"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent label={""} name={"accountId"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent label={""} name={"brandId"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent label={""} name={"serverId"} extra={{ type: ComponentType.hidden, value: "" }} />
                        {ecAction === "add" || ecAction === "edit" ? (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Inspector"
                                            name={"assigneeId"}
                                            extra={{
                                                type: ComponentType.dropdown,
                                                value: usersList.map(x => ({ text: x.name + "  |  " + x.email, value: x.id })),
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="PNL Captured"
                                            name={"pnlCaptured"}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Deposit Captured"
                                            name={"depositCaptured"}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Withdrawal Captured"
                                            name={"withdrawalCaptured"}
                                            extra={{
                                                type: ComponentType.number,
                                                value: "",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Action Date"
                                            name="actionDate"
                                            extra={{
                                                type: ComponentType.date,
                                                value: "",
                                                inputProps: {
                                                    showTime: { format: "HH:mm:ss" },
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={13}>
                                        <FormComponent
                                            label="PNL Breach (USD)"
                                            name={"operator"}
                                            extra={{
                                                type: ComponentType.dropdown,
                                                value: [
                                                    { text: "None", value: 0 },
                                                    { text: "Greater Than", value: 1 },
                                                    { text: "Lower Than", value: 2 },
                                                ],
                                                itemProps: {
                                                    labelCol: {
                                                        span: 11,
                                                    },
                                                    wrapperCol: {
                                                        span: 13,
                                                    },
                                                },
                                                inputProps: {
                                                    allowClear: false,
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col span={9}>
                                        {ecOperator === 0 || ecOperator === undefined || ecOperator === "" ? (
                                            <FormComponent label={""} name={"pnlThreshold"} extra={{ type: ComponentType.hidden, value: "" }} />
                                        ) : (
                                            <FormComponent
                                                label=""
                                                name={"pnlThreshold"}
                                                extra={{
                                                    type: ComponentType.text,
                                                    value: "",
                                                    itemProps: {
                                                        wrapperCol: {
                                                            span: 24,
                                                        },
                                                    },
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: REQUIRED_FIELD,
                                                        },
                                                    ],
                                                }}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Watch List Group"
                                            name={"watchGroupId"}
                                            extra={{
                                                type: ComponentType.dropdown,
                                                value: groupsList.map((x: any) => ({ text: x.name, value: x.id })),
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <FormComponent label={""} name={"operator"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <FormComponent label={""} name={"pnlThreshold"} extra={{ type: ComponentType.hidden, value: "" }} />
                            </>
                        )}
                        <Row>
                            <Col span={24}>
                                <FormComponent
                                    label="Comment"
                                    name={"comment"}
                                    extra={{
                                        type: ComponentType.textarea,
                                        value: "",
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal>
        </>
    );
};

export default Account360Profile;

export const enabledCheckingByLocation = (source: string): boolean => {
    const authHp = new AuthHelper();

    /**
     * 20/04/2023 - Xin
     * if other modules want to access this page, they need to have EDIT perm action
     */

    if (AuthKeys.ANALYSIS_ACCOUNT_PROFILE_EDIT.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.ANALYSIS_ACCOUNT_PROFILE_EDIT);
    } else if (AuthKeys.ANALYSIS_ACCOUNT_WATCHLIST_EDIT.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.ANALYSIS_ACCOUNT_WATCHLIST_EDIT);
    } else if (AuthKeys.TAGGING_LIST_EDIT.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.TAGGING_LIST_EDIT);
    } else if (AuthKeys.REPORT_HISTORY_EDIT.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.REPORT_HISTORY_EDIT);
    } else if (AuthKeys.MONITOR_TRADE_EDIT.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.MONITOR_TRADE_EDIT);
    } else if (AuthKeys.HISTORICAL_EVENT_BATCHES_EDIT.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.HISTORICAL_EVENT_BATCHES_EDIT);
    } else if (AuthKeys.HISTORICAL_EVENT_SUMMARY_EDIT.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.HISTORICAL_EVENT_SUMMARY_EDIT);
    } else if (AuthKeys.ANALYSIS_CLIENT_PROFILE_EDIT.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.ANALYSIS_CLIENT_PROFILE_EDIT);
    } else if (AuthKeys.DECAY_ANALYSIS_VIEW.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.DECAY_ANALYSIS_VIEW);
    } else if (AuthKeys.HFT_CHART_VIEW.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.HFT_CHART_VIEW);
    } else if (AuthKeys.CLOSED_DECAY_ANALYSIS_VIEW.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.CLOSED_DECAY_ANALYSIS_VIEW);
    } else if (AuthKeys.TOXIC_ACCOUNT_VIEW.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.TOXIC_ACCOUNT_VIEW);
    } else if (AuthKeys.PROBLEMATIC_CLIENT_VIEW.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.PROBLEMATIC_CLIENT_VIEW);
    } else if (AuthKeys.PREMARKET_ACCOUNT_VIEW.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.PREMARKET_ACCOUNT_VIEW);
    } else if (AuthKeys.LP360_VIEW.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.LP360_VIEW);
    } else if (AuthKeys.INTRADAY_SEARCH_VIEW.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.INTRADAY_SEARCH_VIEW);
    } else if (AuthKeys.INTRADAY_ALARM_VIEW.indexOf(source) > -1) {
        return authHp.isAuthorized(AuthKeys.INTRADAY_ALARM_VIEW);
    }

    return false;
};
