import { useState } from "react";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { Checkbox, Empty } from "antd";

export interface PermissionProps {
    keyPrefix: string;
    moduleList: any[];
    selectedList: string[];
    viewOnly?: boolean;
}

export interface RolePermissionModule {
    key: string;
    title: string;
    children: RolePermissionModule[];
}

const Permission = (props: PermissionProps) => {
    const [modules] = useState<RolePermissionModule[]>(props.moduleList as RolePermissionModule[]);

    return (
        <div className="permission-container">
            <div className="header-panel">
                <div className="module-panel">
                    <div className="item"></div>
                    <div className="actions">View</div>
                    <div className="actions">Edit</div>
                </div>
            </div>
            <div className="content">
                {modules.length > 0 ? (
                    modules.map((x: RolePermissionModule, idx: number) => (
                        <div key={`mmd-x-${idx}`} className="item">
                            <div className="group-panel">{x.title}</div>
                            {x.children && x.children.length > 0 && (
                                <div className="child-panel">
                                    {x.children.map((y: RolePermissionModule, idx: number) => (
                                        <div key={`mmd-y-${idx}`} className="module-panel">
                                            <div className="item">{y.title} </div>
                                            <div className="actions">
                                                {y.children.filter(z => z.title.toLowerCase() === `view`).length > 0 &&
                                                    (props.viewOnly ? (
                                                        <Checkbox
                                                            checked={props.selectedList.some(
                                                                k => k === y.children.filter(z => z.title.toLowerCase() === `view`)[0].key
                                                            )}
                                                        />
                                                    ) : (
                                                        <FormComponent
                                                            label={""}
                                                            name={`${props.keyPrefix}${y.children.filter(z => z.title.toLowerCase() === `view`)[0].key
                                                                }`}
                                                            extra={{ type: ComponentType.checkbox, value: "" }}
                                                        />
                                                    ))}
                                            </div>
                                            <div className="actions">
                                                {y.children.filter(z => z.title.toLowerCase() === `edit`).length > 0 &&
                                                    (props.viewOnly ? (
                                                        <Checkbox
                                                            checked={props.selectedList.some(
                                                                k => k === y.children.filter(z => z.title.toLowerCase() === `edit`)[0].key
                                                            )}
                                                        />
                                                    ) : (
                                                        <FormComponent
                                                            label={""}
                                                            name={`${props.keyPrefix}${y.children.filter(z => z.title.toLowerCase() === `edit`)[0].key
                                                                }`}
                                                            extra={{ type: ComponentType.checkbox, value: "" }}
                                                        />
                                                    ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <>
                        <Empty />
                    </>
                )}
            </div>
        </div>
    );
};

export default Permission;
