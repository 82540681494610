import { message, Modal } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { SpreadGoogleSheetServerProps } from "..";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import LoadingComponent from "@/components/Loading";
import { SUCCESS_FAILED } from "@/constants";
import { ErrorMessageHandler } from "@/utils/Common";

export interface SpreadGoogleSheetModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
    data: SpreadGoogleSheetServerProps | undefined;
}

export enum SpreadGoogleSheetModalCallbackKey {
    Close = 0,
}

const SpreadGoogleSheetModal = (props: SpreadGoogleSheetModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [logs, setLogs] = useState<string>("");
    const scrollableLogsDivRef = useRef<any>(null);

    const showLog = useCallback(() => {
        setLogs("");
        setIsLoading(true);
        const formData = new FormData();
        formData.append("keyStr", `${(props.data as SpreadGoogleSheetServerProps).serverDn},CandlesTickReceiverAPI`);
        plainAxiosInstance
            .post(APIs.RC_SPREAD_GOOGLE_SHEET.SHOW_LOG, formData)
            .then((res: any) => {
                if (res.data) {
                    setLogs(res.data);
                } else {
                    ErrorMessageHandler(
                        `Get ${(props.data as SpreadGoogleSheetServerProps).serverDn} settings failed.`,
                        SUCCESS_FAILED.OTHERS_FAILED
                    );
                }
                scrollableLogsDivRef.current.scrollTo({ top: 0 });
            })
            .catch((error: any) => {
                setLogs(`Error: ${error.response.data.error}\nMessage: ` + error.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
                scrollableLogsDivRef.current.scrollTo({ top: 0 });
            });
    }, [props.data, scrollableLogsDivRef]);

    useEffect(() => {
        if (props.isModalVisible && props.data !== undefined) {
            showLog();
        }
    }, [props.isModalVisible, props.data]);

    return (
        <Modal
            // style={{ top: 10 }}
            centered
            width={"60vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okButtonProps={{
                style: {
                    display: "none",
                },
            }}
            cancelText="Close"
            onCancel={() => {
                props.callback(SpreadGoogleSheetModalCallbackKey.Close, null);
            }}
            maskClosable={false}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="nice-scrollbar" style={{ maxHeight: "75vh", overflow: "auto" }} ref={scrollableLogsDivRef}>
                    <div style={{ fontFamily: "monospace" }} dangerouslySetInnerHTML={{ __html: logs.replace(/\r?\n|\r/g, "<br>") }} />
                </div>
            )}
        </Modal>
    );
};

export default SpreadGoogleSheetModal;
