import { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { FlexiDataTableCallbackProps, KeyValuePair } from "@/constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { DateTimeUtil } from "@/utils/datetime";
import moment from "moment";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { message } from "antd";

interface LoginSearchProps {
    servers: KeyValuePair[];
}

const LoginSearch = (props: LoginSearchProps) => {
    const [data, setData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({
        startDate: moment().startOf("day").format("YYYY-MM-DD"),
        endDate: moment().endOf("day").format("YYYY-MM-DD"),
    });
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);

    const columns = useMemo(
        () => [
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
                sorter: (a: any, b: any) => a.login - b.login,
                options: {
                    filter: {
                        type: ComponentType.number,
                        value: "",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    },
                },
            }),
            DTColProps.Small({
                title: "Server",
                dataIndex: "mainServerId",
                key: "mainServerId",
                sorter: (a: any, b: any) => a.mainServerId - b.mainServerId,
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: props.servers,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    },
                },
            }),
            DTColProps.Middle({
                title: "CID",
                dataIndex: "cid",
                key: "cid",
                sorter: (a: any, b: any) => a.cid.localeCompare(b.cid),
            }),
            DTColProps.DateTime_ServerDateOnly({
                title: "Time",
                dataIndex: "showLogTime",
                key: "showLogTime",
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: [],
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        inputProps: {
                            dateOnly: true,
                        },
                    },
                },
            }),
        ],
        [props.servers]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "showLogTime") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["startDate"] = DateTimeUtil.GetOrigin(FormData[x][0], "YYYY-MM-DD");
                                fParams["endDate"] = DateTimeUtil.GetOrigin(FormData[x][1], "YYYY-MM-DD");
                            }
                        } else if (x === "mainServerId") {
                            fParams["serverId"] = FormData[x];
                        } else if (x === "login") {
                            fParams["login"] = FormData[x].toString();
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                if (Object.keys(filterParams).length === 3) {
                    setIsExporting(true);
                    plainAxiosInstance
                        .post(
                            `${APIs.RC_CID.POST_LOGIN_SEARCH_DOWNLOAD}`,
                            { ...filterParams },
                            {
                                /**
                                 *  11/09/2024 - Xin
                                 *  not sure to include this or not, just leaving it here just in case
                                 */
                                // headers: {
                                //     Accept: "application/octet-stream, */*",
                                // },
                                responseType: "blob",
                            }
                        )
                        .then(res => {
                            const fileName = `login_search_data-${moment().format("YYYY-MM-DD_HH_mm")}.xlsx`;
                            const contentType = res.headers["content-type"];
                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "text/csv" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                // Handle the file download response
                                const url = window.URL.createObjectURL(new Blob([res.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", `${fileName}`); // or any other extension
                                link.setAttribute("type", "hidden");
                                document.body.appendChild(link);
                                link.click();
                                if (link.parentNode) {
                                    link.parentNode.removeChild(link); // Clean up and remove the link
                                } else document.body.removeChild(link);
                                // Clean up
                                window.URL.revokeObjectURL(url);
                            } else {
                                ErrorMessageHandler(`Received non-file response. Error: ${res}`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        })
                        .catch(err => {
                            ErrorMessageHandler(`Download error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
                        })
                        .finally(() => setIsExporting(false));
                } else {
                    ErrorMessageHandler(`Please apply all filters to the data before exporting.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
                break;
            default:
                break;
        }
    };

    const getLoginSearchData = () => {
        plainAxiosInstance
            .post(`${APIs.RC_CID.POST_LOGIN_SEARCH_DATA}`, {
                ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            })
            .then((res: any) => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        let newData = res.data.map((x: any) => ({
                            ...x,
                            newKey: `${x.cid}|${x.mainServerId}|${x.login}`,
                            showLogTime: DateTimeUtil.GetOrigin(x.logTime, "YYYY-MM-DD HH:mm:ss"),
                        }));
                        setData(newData);
                    } else setData([]);
                } else {
                    setData([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("login search data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                })
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getLoginSearchData();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    return (
        <div>
            <FlexiDataTable
                rowKeyProperty={"newKey"} // record.cid|record.mainServerId|record.login
                title={""}
                columns={columns}
                options={{
                    serverFiltering: true,
                    export: { text: "Download" },
                }}
                callback={componentCallback}
                dataSource={data || []}
                loading={isLoading}
                exporting={isExporting}
                filterInitialValue={{ showLogTime: [moment(), moment()] }}
            />
        </div>
    );
};

export default LoginSearch;
