import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "../../../constants/type";
import { Button, message, Modal, Switch, Tooltip } from "antd";
import {
    ContainerOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    MinusCircleOutlined,
} from "@ant-design/icons";
import { NegativeEquityProcessData } from "./type";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { matchSorter } from "match-sorter";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "../ReportBatch";
import { REQUIRED_FIELD } from "@/constants/errorMessage";

interface ProcessTabProps {
    servers: any[];
}

const ProcessTab = (props: ProcessTabProps) => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.NEGATIVE_EQUITY_RECORD_EDIT);

    const [data, setData] = useState<NegativeEquityProcessData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowHiddenCols, setIsShowHiddenCols] = useState<boolean>(false);
    const [sensitiveDataCalledCount, setSensitiveDataCalledCount] = useState<number>(0);
    const [auditKey, setAuditKey] = useState<string>("");
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [filterParams, setFilterParams] = useState<any>({ serverId: 0 });
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);

    const columns = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "server",
                key: "server",
                fixed: "left",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    },
                },
            }),
            {
                title: "Server (All), Login, Brand or Group",
                dataIndex: "searchAll",
                key: "searchAll",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                    visible: false,
                },
            },
            DTColProps.XSmall({
                title: "Login",
                dataIndex: "login",
                key: "login",
                fixed: "left",
            }),
            DTColProps.XSmall({
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                fixed: "left",
            }),
            DTColProps.Middle({
                title: "Group",
                dataIndex: "group",
                key: "group",
            }),
            DTColProps.XSmall(
                {
                    title: "Equity",
                    dataIndex: "equity",
                    key: "equity",
                    sorter: (a: any, b: any) => a.equity - b.equity,
                    render: (equity: number) => currencyRender(equity.toFixed(2)),
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Open Trades Count",
                    dataIndex: "totalOpenPosition",
                    key: "totalOpenPosition",
                    sorter: (a: any, b: any) => a.totalOpenPosition - b.totalOpenPosition,
                },
                ["text-right"]
            ),
            ...(isShowHiddenCols
                ? [
                      DTColProps.Middle({
                          title: "Email",
                          dataIndex: "email",
                          key: "email",
                          width: "10vw",
                      }),
                  ]
                : []),
            DTColProps.XSmall(
                {
                    title: "Check Count",
                    dataIndex: "checkTotalCount",
                    key: "checkTotalCount",
                    defaultSortOrder: "descend",
                    sorter: (a: any, b: any) => a.checkTotalCount - b.checkTotalCount,
                },
                ["text-right"]
            ),
            ...(enableUpdate
                ? [
                      DTColProps.XSmall(
                          {
                              title: "Set Check Count Zero",
                              dataIndex: "action",
                              key: "action",
                              render: (_: any, rowData: NegativeEquityProcessData) => (
                                  <Button icon={<MinusCircleOutlined />} type="primary" onClick={() => setCheckCountToZero(rowData)} />
                              ),
                          },
                          ["text-center"]
                      ),
                  ]
                : []),
            DTColProps.Middle({
                title: "Created Date",
                dataIndex: "createDate",
                key: "createDate",
                width: "10vw",
            }),
            DTColProps.Middle({
                title: "Updated Date",
                dataIndex: "updateDate",
                key: "updateDate",
                fixed: "right",
                width: "10vw",
            }),
        ],
        [isShowHiddenCols, servers, enableUpdate]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            export: {
                text: "",
                Element: (
                    <Button
                        key={"ner-export"}
                        icon={<DownloadOutlined />}
                        style={{ marginLeft: 10 }}
                        loading={isExporting}
                        onClick={() => downloadNEProcessReport(filterParams)}
                    >
                        Download Report
                    </Button>
                ),
            },
            extraButtons: () => {
                return (
                    <div className="ner-extra-buttons" key={"ner-p-eb"}>
                        <div key={"ner-p-switch"} className="ner-s">
                            <Tooltip placement="topRight" title={`${isShowHiddenCols ? "Hide" : "Show"} Sensitive Data`}>
                                <Switch
                                    checkedChildren={<EyeOutlined />}
                                    unCheckedChildren={<EyeInvisibleOutlined />}
                                    onChange={(checked: boolean) => getSensitiveData(checked)}
                                    disabled={auditKey === "" || isLoading}
                                    checked={isShowHiddenCols}
                                />
                            </Tooltip>
                        </div>
                        <div key={"ner-p-rl"} className="extra-table-header-buttons">
                            <Button
                                icon={<ContainerOutlined />}
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    componentCallback(CALLBACK_KEY.OTHERS, "reportlist");
                                }}
                            >
                                Report Listing
                            </Button>
                        </div>
                    </div>
                );
            },
            recordRowClassName: (record: NegativeEquityProcessData) => {
                return record.equity < -500 ? "row-yellow" : "";
            },
            serverFiltering: true,
        }),
        [auditKey, isShowHiddenCols, isLoading, isExporting, filterParams]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                let feSearchFilters: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "server") {
                            fParams["serverId"] = FormData[x];
                        } else if (x === "searchAll") {
                            feSearchFilters["searchAll"] = FormData[x];
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                if (fParams.serverId !== filterParams.serverId) {
                    if (feSearchFilters?.searchAll) setSearchValue(feSearchFilters?.searchAll);
                    setFilterParams(fParams);
                    setRunRefetchDataList(true);
                } else setSearchValue(feSearchFilters?.searchAll ?? "");
                break;
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "reportlist":
                        setIsReportModalVisible(true);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    };

    const setCheckCountToZero = (rowData: NegativeEquityProcessData) => {
        Modal.confirm({
            title: `Are you sure you want to set Login ${rowData.login} Check Count to 0 ?`,
            icon: <ExclamationCircleOutlined />,
            content: "",
            onOk: () => {
                setIsLoading(true);
                plainAxiosInstance
                    .get(`${APIs.RC_NEGATIVE_EQUITY_RECORD.POST_SET_CHECK_COUNT_TO_ZERO}?serverId=${rowData.serverId}&login=${rowData.login}`)
                    .then((res: any) => {
                        if (res.data === 1) {
                            ErrorMessageHandler("Check count", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        } else ErrorMessageHandler("check count to 0", SUCCESS_FAILED.FAILED_UPDATE_DATA);
                        setRunRefetchDataList(true);
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("check count to 0", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    )
                    .finally(() => setIsLoading(false));
            },
            onCancel: () => {},
        });
    };

    const getNegativeEquityProcesses = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_NEGATIVE_EQUITY_RECORD.GET_NE_PROCESS}?serverId=${filterParams.serverId}`)
            .then((res: any) => {
                if (res.data) {
                    Object.keys(res.data).forEach((key: string) => {
                        setAuditKey(key);
                        let newData = res.data[key].map((x: any) => ({
                            ...x,
                            key: `${x.serverId}|${x.login}`,
                        }));
                        setData(newData);
                    });
                } else setData([]);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("negative equity processes", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                })
            )
            .finally(() => setIsLoading(false));
    }, [filterParams]);

    const getSensitiveData = useCallback(
        (checked: boolean) => {
            if (checked) {
                setIsShowHiddenCols(true);
                let newIsSensitiveDataCalled = sensitiveDataCalledCount + 1;
                setSensitiveDataCalledCount(newIsSensitiveDataCalled);

                if (newIsSensitiveDataCalled > 1) return;
                else {
                    setIsLoading(true);
                    plainAxiosInstance
                        .get(`${APIs.RC_NEGATIVE_EQUITY_RECORD.GET_SENSITIVE_DATA}?auditKey=${auditKey}`)
                        .catch((error: any) => {
                            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("sensitive data", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                            setSensitiveDataCalledCount(0);
                            setIsShowHiddenCols(false);
                        })
                        .finally(() => setIsLoading(false));
                }
            } else {
                setIsShowHiddenCols(false);
            }
        },
        [auditKey, sensitiveDataCalledCount]
    );

    const downloadNEProcessReport = (fParams: any) => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_NEGATIVE_EQUITY_RECORD.GET_NE_PROCESS_REPORT_DOWNLOAD}?serverId=${fParams.serverId}`)
            .then(response => {
                if (response.data === 0) {
                    Modal.success({
                        width: "30%",
                        title: "Successfully added batch",
                        content: "Please download the report from 【Report Listing】 once it is completed",
                    });
                } else {
                    Modal.error({
                        icon: <ExclamationCircleOutlined />,
                        title: "Failed to add batch",
                        content: "Please try again later",
                    });
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Failed to add download batch", SUCCESS_FAILED.OTHERS_FAILED, err))
            )
            .finally(() => setIsExporting(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getNegativeEquityProcesses();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        if (props.servers.length > 0) {
            setServers(props.servers.map((x: any) => ({ value: x.id, text: x.name })));
            setRunRefetchDataList(true);
        } else {
            setServers([]);
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [props.servers]);

    const filteredData: NegativeEquityProcessData[] = useMemo(() => {
        return matchSorter(data ?? [], searchValue ?? "", {
            keys: ["server", "login", "brand", "group"],
        });
    }, [data, searchValue]);

    return (
        <div className="ner-process-tab">
            <FlexiDataTable
                bordered
                rowKeyProperty="key"
                title=""
                columns={columns}
                options={options}
                dataSource={filteredData}
                callback={componentCallback}
                loading={isLoading}
                filterInitialValue={{ server: 0 }}
            />
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "NegativeEquityProcess"}
            />
        </div>
    );
};

export default ProcessTab;
