import ServerPerformanceSettingsTable from "./ServerPerformanceSettingsTable";

const TabServerPerformanceSettings = () => {
    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Server Performance Settings</span>
                </div>
            </div>
            <ServerPerformanceSettingsTable />
        </>
    );
};

export default TabServerPerformanceSettings;
