import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, PageHeader } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface PageHeaderProps {
    title: string;
    subtitle?: string;
    routes?: CustomRoute[];
    onBack?: Function;
    itemRender?: (route: CustomRoute) => React.ReactNode;
    extraProps?: any;
    children?: React.ReactNode;
}

export interface CustomRoute extends Route {
    icon?: React.ReactNode;
    state?: any;
}

const SitePageHeader = (props: PageHeaderProps) => {
    let navigate = useNavigate();
    return (
        <div className="site-main-content">
            <PageHeader
                className="site-page-header"
                breadcrumb={{
                    routes: props.routes || [],
                    itemRender: props.itemRender
                        ? props.itemRender
                        : (route: CustomRoute) => {
                              return route.path && route.path.length > 0 ? (
                                  <Button type="link" onClick={() => navigate(route.path, { state: route.state || {} })}>
                                      {route.icon ? route.icon : ""} {route.breadcrumbName}
                                  </Button>
                              ) : (
                                  <div className="name-container">
                                      {route.icon ? route.icon : ""} {route.breadcrumbName}
                                  </div>
                              );
                          },
                }}
                backIcon={props.onBack !== undefined ? <ArrowLeftOutlined /> : <></>}
                onBack={() => {
                    props.onBack && props.onBack();
                }}
                title={props.title}
                subTitle={props.subtitle || ""}
                {...props.extraProps}
            />
            <div className="page-content">
                <Card bordered={false}>{props.children && props.children}</Card>
            </div>
        </div>
    );
};

export default SitePageHeader;
