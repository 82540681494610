export const stepInfo = [
    {
        label: "Step 1: Adjustment",
        value: 0,
        color: "volcano",
        description: "Upload And Calculate Adjustment",
    },
    {
        label: "Step 2: Open Position",
        value: 1,
        color: "yellow",
        description: "Calculate Open Position Adjustment",
    },
    {
        label: "Step 3: Supplementary",
        value: 2,
        color: "blue",
        description: "Execute Supplementary",
    },
    {
        label: "Step 4: Deduction",
        value: 3,
        color: "green",
        description: "Execute Deduction",
    },
    {
        label: "Step 5: Completion",
        value: 4,
        color: "default",
        description: "Result And Stop Out Order",
    },
];
