import { Row, Col, Input, Badge, Button, Tooltip, Modal, message } from "antd";
import { useCallback, useMemo, useState } from "react";
import { CheckOutlined, FileSearchOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import useRCPriceBackendOpt, { IRCPriceBackendOpt } from "@/hooks/useRCPriceBackendOpt";
import { isEmptyOrNull } from "@/utils/string";
import EmptyData from "@/components/Common/Empty";
import { SortList } from "@/utils/array";
import LoadingComponent from "@/components/Loading";
import ShowDetailsModal, { ShowDetailsModalCallbackKey } from "../ShowDetailModal";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { PriceBackendOptBasedProps } from "../TabRestartServer";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";

interface PriceConnectorPageProps extends PriceBackendOptBasedProps {}

interface ViewDetailConfigProps {
    isModalVisible: boolean;
    data: IRCPriceBackendOpt | undefined;
}

interface PageInformationProps {
    pageTitle: string;
    settingType: string;
    warningDetails: (restartItems: IRCPriceBackendOpt[]) => any;
    restartingResponseMessage: (restartItems: IRCPriceBackendOpt[], isSuccess: boolean) => string;
}

const PriceConnectorPage = (props: PriceConnectorPageProps) => {
    const { rcPriceBackendOpt, isLoading } = useRCPriceBackendOpt({ type: props.dataType });
    const [searchText, setSearchText] = useState<string>("");
    const [selectedItems, setSelectedItems] = useState<IRCPriceBackendOpt[]>([]);
    const [selectedViewDetailsItem, setSelectedViewDetailsItem] = useState<IRCPriceBackendOpt | undefined>(undefined);
    const [restartingServers, setRestartingServers] = useState<IRCPriceBackendOpt[]>([]);
    const [isBatchRestarting, setIsBatchRestarting] = useState<boolean>(false);

    const pageInformation: PageInformationProps = useMemo(() => {
        switch (props.pageType) {
            case 1:
                return {
                    pageTitle: "",
                    settingType: "1",
                    warningDetails: (restartItems: IRCPriceBackendOpt[]) => ({
                        title: `Are you want to reload selected server${restartItems.length > 1 ? "s" : ""}?`,
                        content: `"${restartItems.map(x => x.serverDn).join('", "')}"`,
                    }),
                    restartingResponseMessage: (restartItems: IRCPriceBackendOpt[], isSuccess: boolean) =>
                        isSuccess
                            ? `Reload ${restartItems.length} server${restartItems.length > 1 ? "s" : ""} successfully.`
                            : `Reload ${restartItems.length} server${restartItems.length > 1 ? "s" : ""} failed.`,
                };
            case 2:
                return {
                    pageTitle: "",
                    settingType: "2",
                    warningDetails: (restartItems: IRCPriceBackendOpt[]) => ({
                        title: `Are you want to reload "${restartItems[0].serverDn}" connecter?`,
                    }),
                    restartingResponseMessage: (restartItems: IRCPriceBackendOpt[], isSuccess: boolean) =>
                        isSuccess
                            ? `Reload ${restartItems[0].serverDn} broadcaster successfully.`
                            : `Reload ${restartItems[0].serverDn} broadcaster failed.`,
                };
            case 3:
                return {
                    pageTitle: "",
                    settingType: "1",
                    warningDetails: (restartItems: IRCPriceBackendOpt[]) => ({
                        title: `Are you want to reload selected analysis tool${restartItems.length > 1 ? "s" : ""}?`,
                        content: `"${restartItems.map(x => x.serverDn).join('", "')}"`,
                    }),
                    restartingResponseMessage: (restartItems: IRCPriceBackendOpt[], isSuccess: boolean) =>
                        isSuccess
                            ? `Reload ${restartItems.length} analysis tool${restartItems.length > 1 ? "s" : ""} successfully.`
                            : `Reload ${restartItems.length} analysis tool${restartItems.length > 1 ? "s" : ""} failed.`,
                };
            case 4:
                return {
                    pageTitle: "",
                    settingType: "CandlesTickReceiverAPI",
                    warningDetails: (restartItems: IRCPriceBackendOpt[]) => ({
                        title: `Are you want to reload selected server${restartItems.length > 1 ? "s" : ""}?`,
                        content: `"${restartItems.map(x => x.serverDn).join('", "')}"`,
                    }),
                    restartingResponseMessage: (restartItems: IRCPriceBackendOpt[], isSuccess: boolean) =>
                        isSuccess
                            ? `Reload ${restartItems.length} server${restartItems.length > 1 ? "s" : ""} successfully.`
                            : `Reload ${restartItems.length} server${restartItems.length > 1 ? "s" : ""} failed.`,
                };
            default:
                return {
                    pageTitle: "",
                    settingType: "0",
                    warningDetails: (restartItems: IRCPriceBackendOpt[]) => ({}),
                    restartingResponseMessage: (restartItems: IRCPriceBackendOpt[], isSuccess: boolean) => "",
                };
        }
    }, [props.pageType]);

    const markupDatas: IRCPriceBackendOpt[] = useMemo(() => {
        let tmp: string = (searchText || "").toLowerCase();
        if (!isEmptyOrNull(searchText)) {
            return SortList(
                rcPriceBackendOpt.filter((x: IRCPriceBackendOpt) => x.serverDn.toLowerCase().includes(tmp)),
                "serverDn"
            );
        }
        return SortList(rcPriceBackendOpt, "serverDn");
    }, [rcPriceBackendOpt, searchText]);

    const viewDetailConfig: ViewDetailConfigProps = useMemo(
        () => ({ isModalVisible: !isEmptyOrNull(selectedViewDetailsItem), data: selectedViewDetailsItem }),
        [selectedViewDetailsItem]
    );

    const isSelectedAll = useMemo(
        () => rcPriceBackendOpt.length > 0 && selectedItems.length === rcPriceBackendOpt.length,
        [rcPriceBackendOpt, selectedItems]
    );

    const addOrRemoveFromArray = (item: IRCPriceBackendOpt[], arr: IRCPriceBackendOpt[]) => {
        let sIS: IRCPriceBackendOpt[] = [...arr];
        item.forEach((x: IRCPriceBackendOpt) => {
            let idx: number = sIS.findIndex((y: IRCPriceBackendOpt) => y.serverDn === x.serverDn);
            if (idx > -1) {
                sIS.splice(idx, 1);
            } else {
                sIS.push(x);
            }
        });
        return sIS;
    };

    const singleRestartServerHandler = useCallback(
        (item: IRCPriceBackendOpt) => {
            if (props.pageType === 2) {
                // Price Connector single refresh
                Modal.confirm({
                    ...pageInformation.warningDetails([item]),
                    onOk: () => {
                        setRestartingServers((prev: IRCPriceBackendOpt[]) => addOrRemoveFromArray([item], prev));
                        new Promise((resolve, reject) => {
                            // Restarting server - Close server connection 1st
                            plainAxiosInstance
                                .post(APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_SINGLE_RESTART, { ...item, priceEnum: 2 })
                                .then((res: any) => {
                                    if (res.data === "ok") {
                                        // Restarting server - re-open server connection
                                        plainAxiosInstance
                                            .post(APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_SINGLE_RESTART, { ...item, priceEnum: 1 })
                                            .then((res: any) => {
                                                if (res.data === "ok") {
                                                    resolve("ok");
                                                } else {
                                                    reject(res.data.message);
                                                }
                                            })
                                            .catch((error: any) => reject(error));
                                    } else {
                                        reject(res.data.message);
                                    }
                                })
                                .catch((error: any) => reject(error));
                        })
                            .then(() => ErrorMessageHandler(pageInformation.restartingResponseMessage([item], true), SUCCESS_FAILED.OTHERS_SUCCESS))
                            .catch(() => ErrorMessageHandler(pageInformation.restartingResponseMessage([item], false), SUCCESS_FAILED.OTHERS_FAILED))
                            .finally(() => setRestartingServers((prev: IRCPriceBackendOpt[]) => addOrRemoveFromArray([item], prev)));
                    },
                });
            } else {
                batchRestartServerHandler([item], false);
            }
        },
        [props.pageType]
    );

    const batchRestartServerHandler = useCallback(
        (restartItems: IRCPriceBackendOpt[], viaBatchRestartingBtn: boolean = true) => {
            Modal.confirm({
                ...pageInformation.warningDetails(restartItems),
                onOk: () => {
                    batchRestartServer(restartItems, viaBatchRestartingBtn);
                },
            });
        },
        [pageInformation]
    );

    const batchRestartServer = (restartItems: IRCPriceBackendOpt[], viaBatchRestartingBtn: boolean) => {
        setRestartingServers((prev: IRCPriceBackendOpt[]) => addOrRemoveFromArray(restartItems, prev));
        viaBatchRestartingBtn && setIsBatchRestarting(true);
        plainAxiosInstance
            .post(
                APIs.RC_PRICE_SETTINGS.POST_PRICE_BACKEND_OPT_RESTART,
                restartItems.map(x => x.serverUno)
            )
            .then((res: any) => {
                if (res.data === "ok") {
                    ErrorMessageHandler(pageInformation.restartingResponseMessage(restartItems, true), SUCCESS_FAILED.OTHERS_SUCCESS);
                } else {
                    ErrorMessageHandler(pageInformation.restartingResponseMessage(restartItems, false), SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler(pageInformation.restartingResponseMessage(restartItems, false), SUCCESS_FAILED.OTHERS_FAILED)
                )
            )
            .finally(() => {
                setRestartingServers((prev: IRCPriceBackendOpt[]) => addOrRemoveFromArray(restartItems, prev));
                viaBatchRestartingBtn && setSelectedItems((prev: IRCPriceBackendOpt[]) => addOrRemoveFromArray(restartItems, prev));
                viaBatchRestartingBtn && setIsBatchRestarting(false);
            });
    };

    const getItemPanel = useCallback(
        (keys: string, item: IRCPriceBackendOpt) => {
            let btnIsLoading = restartingServers.some((x: IRCPriceBackendOpt) => x.serverDn === item.serverDn && x.serverUno === item.serverUno),
                selected = selectedItems.some((x: IRCPriceBackendOpt) => x.serverDn === item.serverDn && x.serverUno === item.serverUno),
                tmp: React.ReactNode = (
                    <>
                        <div className="outer">
                            <div className="name-panel">{item.serverDn}</div>
                            <div className="footer">
                                <Tooltip title="Restart Server">
                                    <Button
                                        icon={<SyncOutlined />}
                                        shape="circle"
                                        {...(btnIsLoading
                                            ? {
                                                  loading: true,
                                              }
                                            : {
                                                  onClick: (e: any) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      singleRestartServerHandler(item);
                                                  },
                                              })}
                                    ></Button>
                                </Tooltip>
                                <Tooltip title="View Details">
                                    <Button
                                        icon={<FileSearchOutlined />}
                                        shape="circle"
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelectedViewDetailsItem(item);
                                        }}
                                    ></Button>
                                </Tooltip>
                            </div>
                        </div>
                    </>
                );
            return (
                <div
                    key={keys}
                    className={`item-panel ${selected ? "selected" : ""} ${props.multipleSelection ? "multiple-selection" : ""}`}
                    {...(props.multipleSelection && {
                        onClick: e => setSelectedItems((prev: IRCPriceBackendOpt[]) => addOrRemoveFromArray([item], prev)),
                    })}
                >
                    {selected || btnIsLoading ? (
                        <Badge.Ribbon
                            {...(btnIsLoading
                                ? {
                                      text: " Restarting...",
                                      placement: "start",
                                      color: "#0e65cc",
                                  }
                                : { text: <CheckOutlined /> })}
                        >
                            {tmp}
                        </Badge.Ribbon>
                    ) : (
                        tmp
                    )}
                </div>
            );
        },
        [selectedItems, restartingServers, isBatchRestarting, props.multipleSelection]
    );

    return (
        <>
            <div className="price-connector-container">
                <div className="title-panel">
                    <Row>
                        <Col span={16}>
                            {props.multipleSelection && (
                                <div className="multiple-selection-header-panel">
                                    <Button
                                        onClick={() => {
                                            if (isSelectedAll) {
                                                setSelectedItems([]);
                                            } else {
                                                setSelectedItems([...markupDatas]);
                                            }
                                        }}
                                    >
                                        {isSelectedAll ? "Deselect All" : "Select All"}
                                    </Button>
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => batchRestartServerHandler(selectedItems)}
                                        disabled={isBatchRestarting || selectedItems.length < 1}
                                    >
                                        {`Batch Restart ${
                                            !isBatchRestarting && selectedItems.length > 0
                                                ? `( ${selectedItems.length} server${selectedItems.length > 1 ? "s" : ""} selected )`
                                                : ""
                                        }`}
                                    </Button>
                                </div>
                            )}
                        </Col>
                        <Col span={8}>
                            <Input
                                prefix={<SearchOutlined />}
                                value={searchText}
                                onChange={(e: any) => setSearchText(e.target.value)}
                                placeholder={`Search`}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="main-content-panel">
                    {isLoading ? (
                        <LoadingComponent />
                    ) : isEmptyOrNull(markupDatas) ? (
                        <EmptyData />
                    ) : (
                        markupDatas.map((x: IRCPriceBackendOpt, idx: number) => getItemPanel(`${x.type}-${x.serverUno}`, x))
                    )}
                </div>
            </div>
            <ShowDetailsModal
                isModalVisible={viewDetailConfig.isModalVisible}
                modalTitle={viewDetailConfig.data !== undefined ? `${viewDetailConfig.data.serverDn} Settings` : ""}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case ShowDetailsModalCallbackKey.Close:
                            setSelectedViewDetailsItem(undefined);
                            break;
                        default:
                            break;
                    }
                }}
                data={viewDetailConfig.data}
                settingType={pageInformation.settingType}
                pageType={props.pageType}
            />
        </>
    );
};

export default PriceConnectorPage;
