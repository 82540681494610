import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorMessageHandler } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { Badge, message, Tag } from "antd";
import { FormInstance } from "antd/es/form";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";

export interface BalanceUpdateHistoricalPageProps {}

export interface BalanceUpdateHistoricalItemProps {
    batchId: string;
    serverId: string;
    serverUno: number;
    login: number;
    updateType: number;
    uploadCurrency: null | string;
    uploadAmount: number;
    currency: string;
    amount: number;
    comment: string;
    expiration: null | string;
    updateStatus: number;
    updateTime: string;
    updateTimeStr: string;
    createUser: string;
    msg: string;
}

const BalanceUpdateHistoricalPage = (props: BalanceUpdateHistoricalPageProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<BalanceUpdateHistoricalItemProps[]>([]);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [historyRecord, setHistoryRecord] = useState<KeyValuePair[]>([]);
    const [filterParams, setFilterParams] = useState<any>({});
    const inputRef = useRef<string>("");

    const columns: any[] = useMemo(
        () => [
            {
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        inputProps: {
                            allowClear: false,
                        },
                    },
                },
            },
            {
                title: "History Event",
                dataIndex: "batchId",
                key: "batchId",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        value: historyRecord,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        dependencyElement: {
                            targetColumnKey: "serverId",
                            expression: (currentTargetValue: any, currentComponentValue: any, form: FormInstance) => {
                                getHistoricalSelectOptions(currentTargetValue, form);
                                return currentComponentValue;
                            },
                        },
                    },
                },
            },
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
            }),
            DTColProps.XSmall(
                {
                    title: "Type",
                    dataIndex: "updateType",
                    key: "updateType",
                    render: (text: number) => (text === 0 ? "Balance" : "Credit"),
                },
                ["text-center"]
            ),
            DTColProps.XSmall(
                {
                    title: "Upload Currency",
                    dataIndex: "uploadCurrency",
                    key: "uploadCurrency",
                },
                ["text-center"]
            ),
            DTColProps.SCurrency({
                title: "Upload Amount",
                dataIndex: "uploadAmount",
                key: "uploadAmount",
                sorter: (a: any, b: any) => a.uploadAmount - b.uploadAmount,
            }),
            DTColProps.XSmall(
                {
                    title: "Currency",
                    dataIndex: "currency",
                    key: "currency",
                },
                ["text-center"]
            ),
            DTColProps.SCurrency({
                title: "Amount",
                dataIndex: "amount",
                key: "amount",
                sorter: (a: any, b: any) => a.amount - b.amount,
            }),
            DTColProps.Middle({
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
            }),
            DTColProps.Small({
                title: "Expiration",
                dataIndex: "expiration",
                key: "expiration",
            }),
            DTColProps.Small(
                {
                    title: "Status",
                    dataIndex: "updateStatus",
                    key: "updateStatus",
                    render: (text: number) => {
                        let displayText = "",
                            doneNumbers = [1, 4, 5, 6];

                        switch (text) {
                            case 0:
                                displayText = "Initial";
                                break;
                            case 1:
                                displayText = "Check Fail";
                                break;
                            case 2:
                                displayText = "Check Success";
                                break;
                            case 3:
                                displayText = "Waiting";
                                break;
                            case 4:
                                displayText = "Update Fail";
                                break;
                            case 5:
                                displayText = "Update Success";
                                break;
                            case 6:
                                displayText = "Cancel";
                                break;
                            case 7:
                                displayText = "Processing";
                                break;
                            default:
                                break;
                        }

                        return doneNumbers.includes(text) ? (
                            <Tag color={text === 5 ? "#87d068" : text === 4 || text === 6 || text === 1 ? "#f50" : ""}>{displayText}</Tag>
                        ) : (
                            <Badge status="processing" text={displayText} />
                        );
                    },
                },
                ["text-center"]
            ),
            DTColProps.Small({
                title: "CreateUser",
                dataIndex: "createUser",
                key: "createUser",
            }),
            DTColProps.Small({
                title: "Update Time",
                dataIndex: "updateTimeStr",
                key: "updateTimeStr",
            }),
            DTColProps.Middle({
                title: "Message",
                dataIndex: "msg",
                key: "msg",
            }),
        ],
        [servers, historyRecord]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            serverFiltering: true,
            disableFilterTagClosable: true,
            ...(data.some(x => [2, 3, 7].includes(x.updateStatus)) && {
                refresh: {
                    timer: true,
                    refreshSecond: 3,
                },
            }),
            ...(Object.keys(filterParams).length === 2 && {
                export: {
                    text: "Download",
                },
            }),
        }),
        [filterParams, data]
    );

    const downloadReport = useCallback(() => {
        try {
            plainAxiosInstance
                .get(
                    `${plainAxiosInstance.defaults.baseURL}${APIs.RISK_TOOL.GET_BALANCE_UPDATE_DOWNLOAD}?keyStr=${filterParams.serverId},${filterParams.batchId}`,
                    {
                        headers: {
                            Accept: "application/octet-stream,text/csv, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `balanceUpdate(${filterParams.serverId},${filterParams.batchId}).csv`);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED));
        } catch (e: any) {
            ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    }, [filterParams]);

    const componentCallback: FlexiDataTableCallbackProps = (type, formData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                if (Object.keys(formData).filter((x: string) => !isEmptyOrNull(formData[x])).length === 2) {
                    setFilterParams(formData);
                    setRunRefetchDataList(true);
                }
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                downloadReport();
                break;
            case CALLBACK_KEY.REFRESH:
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getHistoricalList = useCallback(() => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("keyStr", `${filterParams.serverId},${filterParams.batchId}`);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_BALANCE_UPDATE_HISTORY_LIST, formData)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setData(res.data);
                } else {
                    setData([]);
                }
            })
            .finally(() => setIsLoading(false));
    }, [filterParams]);

    const getServerList = () => {
        plainAxiosInstance.get(APIs.RISK_TOOL.GET_BALANCE_UPDATE_HISTORY_SERVERS).then((res: any) => {
            if (res.data && res.data.length > 0) {
                setServers(res.data.map((x: any) => ({ text: x.serverName, value: x.serverId })));
            } else {
                setServers([]);
            }
        });
    };

    const getHistoricalSelectOptions = (serverId: string, form: FormInstance) => {
        if (!isEmptyOrNull(serverId) && inputRef.current !== serverId) {
            inputRef.current = serverId;

            setTimeout(() => {
                form.setFieldsValue({ batchId: "" });
                plainAxiosInstance
                    .get(`${APIs.RISK_TOOL.GET_BALANCE_UPDATE_HISTORY_EVENT_LIST}/${serverId}`)
                    .then((res: any) => {
                        if (res.data && res.data.length > 0) {
                            setHistoryRecord(res.data.map((x: any) => ({ text: x, value: x })));
                        } else {
                            setHistoryRecord([]);
                        }
                    })
                    .catch(err => setHistoryRecord([]));
            }, 100);
        }
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getHistoricalList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getServerList();

        return () => {};
    }, []);

    return (
        <div className="historical-container">
            <FlexiDataTable
                bordered
                rowKeyProperty="login"
                title=""
                columns={columns}
                options={options}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
            />
        </div>
    );
};

export default BalanceUpdateHistoricalPage;
