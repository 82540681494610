import { Alert, Button, Col, Form, InputNumber, Row, Space, Typography, notification } from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { useRCIssuesLogMutate, useRCIssuesLogRequestTimeoutSettings } from "@/hooks/useRCIssuesLog";
import MessageCard from "@/pages/SystemMonitor/components/MessageCard";
import { colorMap, colorMapRGB } from "@/pages/SystemMonitor/StatusMonitor/statusUtils";

interface RequestTimeoutSettingsProps {
    enableUpdate: boolean;
}

const RequestTimeoutSettings = (props: RequestTimeoutSettingsProps) => {
    const { rcRequestTimeoutSettings, isLoading } = useRCIssuesLogRequestTimeoutSettings();
    const { updateRequestTimeoutSettings } = useRCIssuesLogMutate({ type: "REQUEST_TIMEOUT" });
    const { Title, Text } = Typography;
    const [form] = Form.useForm();
    const { Item } = Form;
    const initFormValue = {
        count: rcRequestTimeoutSettings ?? null,
    };
    const handleSubmit = async () => {
        const count = form.getFieldValue("count");
        try {
            const resp = await updateRequestTimeoutSettings({ requestTimeout: count });
            // @ts-ignore
            if (resp?.data > 0) {
                notification.success({
                    message: "Success",
                    description: "Request Timeout Settings Updated Successfully",
                });
            } else {
                notification.error({
                    message: "Failed to update Request Timeout Settings",
                    description: "Error: " + resp?.data,
                });
            }
        } catch (e) {
            notification.error({
                message: "Failed to update Request Timeout Settings",
                description: "Error: " + e,
            });
            console.log(e);
        }
    };
    return (
        <div style={{ padding: "1rem" }}>
            <Title level={5}>Request Timeout Settings</Title>
            {isLoading ? (
                <MessageCard type="info">Loading...</MessageCard>
            ) : (
                <>
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={initFormValue}
                        /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                        onFinish={() => {
                            handleSubmit();
                        }}
                    >
                        <Row gutter={[8, 8]}>
                            <Col span={12} xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
                                <Space direction="vertical" style={{ width: "100%" }} size={4}>
                                    <Text>Request Timeout Log Frequency Alarm Setting Per 10 mins</Text>
                                    <Item
                                        name="count"
                                        rules={[{ required: true, message: "Please input at least 1" }]}
                                        style={{ margin: 0, padding: 0 }}
                                    >
                                        <InputNumber placeholder="Request Timeout Alarm Trigger Count" min={1} max={1000} style={{ width: "100%" }} />
                                    </Item>
                                    <Alert
                                        message="Will trigger an alarm if the error more than the set value. Default is 1."
                                        type="info"
                                        showIcon
                                        icon={<FaInfoCircle style={{ color: colorMap.info }} />}
                                        style={{ background: `rgba(${colorMapRGB.info},0.25)`, border: "none", fontSize: "0.9em" }}
                                    />
                                </Space>
                            </Col>
                        </Row>
                        {props.enableUpdate && (
                            <Button type="primary" htmlType="submit" style={{ marginTop: "1rem" }}>
                                Update
                            </Button>
                        )}
                    </Form>
                </>
            )}
        </div>
    );
};

export default RequestTimeoutSettings;
