import { InnerPageActionMode } from "@/constants";
import MTServerHubConfiguration from "@/pages/SiteAdminModule/Configuration/MTServerHubConfiguration";
import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState } from "react";
import DashboardConfigurationPage from "./DashboardConfiguration";
import SecurityGroupListing from "./SecurityGroup";
import ServerGroupListing from "./ServerGroup/serverGroupListing";

export interface HubConfigurationPageProps {}

export interface CreateEditFormMode {
    mode: InnerPageActionMode;
    groupId?: number;
}

const HubConfigurationPage = (props: HubConfigurationPageProps) => {
    const [segmentVal, setSegmentVal] = useState<string>("Server Group Listing");

    return (
        <div className="settings-panel-main-segment-panel-container">
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Hub Failover Configuration</span>
                </div>
            </div>
            <div className="top-panel">
                <Segmented
                    options={["Server Group Listing", "Security Group Listing", "Hub Status Dashboard Configuration", "MT Server Configuration"]}
                    value={segmentVal}
                    onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                />
            </div>
            <div className="container">
                {segmentVal === "Server Group Listing" && <ServerGroupListing />}
                {segmentVal === "Security Group Listing" && <SecurityGroupListing />}
                {segmentVal === "Hub Status Dashboard Configuration" && <DashboardConfigurationPage />}
                {segmentVal === "MT Server Configuration" && (
                    <div className="hub-config-main-content shadow-light">
                        <MTServerHubConfiguration />
                    </div>
                )}
            </div>
        </div>
    );
};

export default HubConfigurationPage;
