import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";
import { SUCCESS_FAILED, ComponentType, InnerPageActionMode } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CreateEditInlineFormBased, KeyValuePair } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { Form, Row, Col } from "antd";
import { useState, useEffect, useMemo, useCallback } from "react";

export interface GroupPurposeCreateEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
    brands: KeyValuePair[];
    servers: KeyValuePair[];
}

export type GroupPurposeState = {
    action: string;
    data: any;
};

const initialValues = {
    serverId: "",
    brandId: "",
    groupPurpose: "",
    groupPurposePattern: "",
    includeInReports: false,
    isLive: false,
};

const GroupPurposeCreateEditPage = (props: GroupPurposeCreateEditPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [groupPurposeForm] = Form.useForm();

    const stateInfo = useMemo(() => {
        return {
            isAddAction: props.mode === InnerPageActionMode.CREATE_NEW,
            data: defaultIfEmptyOrNull(props.id, ""),
        };
    }, [props.mode, props.id]);

    const processBackAndRefetch = () => setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);

    const onSubmit = useCallback(
        (values: any) => {
            const newValues = {
                groupPurposeId: values.id,
                groupPurposeName: values.groupPurpose,
                groupPurposePattern: values.groupPurposePattern,
                serverId: values.serverId,
                brandId: values.brandId,
                includeReports: values.includeInReports,
                isLive: values.isLive,
            };

            setIsLoading(true);
            if (stateInfo.isAddAction) {
                apiRequest(APIs.CREATE_GROUP_PURPOSE, newValues)
                    .then(data => {
                        ErrorMessageHandler("New group purpose", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new group purpose", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                        setIsLoading(false);
                    });
            } else {
                apiRequest(APIs.UPDATE_GROUP_PURPOSE, newValues)
                    .then(data => {
                        ErrorMessageHandler("Existing group purpose", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        processBackAndRefetch();
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("existing group purpose", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        );
                        setIsLoading(false);
                    });
            }
        },
        [stateInfo]
    );

    const getGroupPurposeList = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_GROUP_PURPOSES_LIST, { groupPurposeId: stateInfo.data })
            .then((data: any) => {
                if (data) {
                    groupPurposeForm.setFieldsValue(data);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("group purpose list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                processBackAndRefetch();
            })
            .finally(() => setIsLoading(false));
    }, [stateInfo]);

    useEffect(() => {
        if (!stateInfo.isAddAction) {
            getGroupPurposeList();
        } else {
            groupPurposeForm.setFieldsValue(initialValues);
        }

        return () => {};
    }, [stateInfo, getGroupPurposeList, props.resetState]);

    return (
        <SitePageHeaderInline
            enableSubmit={!isLoading}
            title={stateInfo.isAddAction ? "Create New Group Purpose" : "Edit Group Purpose"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        groupPurposeForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={groupPurposeForm}
                    layout="horizontal"
                    initialValues={{}}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Server"
                                name={"serverId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    inputProps: {
                                        style: { width: "100%" },
                                    },
                                    value: props.servers,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Brand"
                                name={"brandId"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    inputProps: {
                                        style: { width: "100%" },
                                    },
                                    value: props.brands,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Group Purpose Name"
                                name="groupPurpose"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Group Purpose Pattern"
                                name="groupPurposePattern"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={5} />
                        <Col span={5}>
                            <FormComponent
                                label={""}
                                name={"includeInReports"}
                                extra={{ type: ComponentType.checkbox, value: "Include in Reports" }}
                            />
                        </Col>
                        <Col span={5}>
                            <FormComponent label={""} name={"isLive"} extra={{ type: ComponentType.checkbox, value: "Is Live" }} />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default GroupPurposeCreateEditPage;
