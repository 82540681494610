import React, { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { ExportOutlined, WarningOutlined } from "@ant-design/icons";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { FlexiDataTableCallbackProps, PreviewMt4RestoreTradeStopOut, ServersList, SymbolsList, uploadedMt4Mt5Tickets } from "../../../constants/type";
import { CALLBACK_KEY, SUCCESS_FAILED } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { message } from "antd";
import { isEmptyOrNull } from "../../../utils/string";

interface BatchUploadTabProps {
    uploadedServer: ServersList | undefined;
    fileItemData: any;
    servers: ServersList[];
    symbols: SymbolsList[];
    errorMessage: string;
    isDuplicate: boolean;
    uploadedFileItem: any;
}

const BatchUploadTab = (props: BatchUploadTabProps) => {
    let navigate = useNavigate();
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_TOOLS_TICKET_RESTORATION_EDIT);

    const [data, setData] = useState<any>([]);
    const [uploadedServer, setUploadedServer] = useState<ServersList>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isMt5, setIsMt5] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>(props.errorMessage || "");

    const previewButton = useMemo(() => {
        return enableUpdate && data.length > 0 && !props.isDuplicate && props.uploadedServer !== undefined
            ? {
                  extraButtons: [{ text: "Preview", value: data, icon: <ExportOutlined /> }],
              }
            : {};
    }, [data]);

    const tableCols = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "serverName",
                key: "serverName",
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
                sorter: (a: any, b: any) => a.login - b.login,
            }),
            ...(!isMt5
                ? [
                      DTColProps.XSmall({
                          title: "Ticket",
                          dataIndex: "ticket",
                          key: "ticket",
                          sorter: (a: any, b: any) => a.ticket - b.ticket,
                      }),
                  ]
                : [
                      DTColProps.XSmall({
                          title: "Order",
                          dataIndex: "order",
                          key: "order",
                          sorter: (a: any, b: any) => a.order - b.order,
                      }),
                      DTColProps.XSmall({
                          title: "Deal",
                          dataIndex: "deal",
                          key: "deal",
                          sorter: (a: any, b: any) => a.deal - b.deal,
                      }),
                  ]),
            DTColProps.Small({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                sorter: (a: any, b: any) => (a.symbol > b.symbol ? 1 : -1),
            }),
            DTColProps.Small({
                title: "Profit",
                dataIndex: "profit",
                key: "profit",
                sorter: (a: any, b: any) => a.profit - b.profit,
            }),
            ...(!isMt5
                ? [
                      DTColProps.DateTime_ServerTime({
                          title: "Open Time (Server)",
                          dataIndex: "openTime",
                          key: "openTime",
                          // sorter: (a: any, b: any) => (moment(b.openTime) > moment(a.openTime) ? -1 : 1),
                          // render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss.SSS") : ""),
                      }),
                  ]
                : []),
            DTColProps.DateTime_ServerTime({
                title: "Close Time (Server)",
                dataIndex: "closeTime",
                key: "closeTime",
            }),
            DTColProps.Small({
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
            }),
        ],
        [isMt5]
    );

    useEffect(() => {
        if (props.errorMessage.length > 0) setErrMsg(props.errorMessage);
        else setErrMsg("");

        if (isEmptyOrNull(props.uploadedServer)) setUploadedServer(undefined);
        else setUploadedServer(props.uploadedServer);

        if (props.fileItemData && props.fileItemData.length > 0) {
            setIsLoading(true);
            let newFileItemData = props.fileItemData.map((currTicketData: uploadedMt4Mt5Tickets) => {
                let currSymbol = props.symbols.find((x: SymbolsList) => x.name.toLowerCase() === currTicketData.Symbol.toLowerCase());
                let currServer = props.servers.find((x: ServersList) => x.server.toLowerCase() === currTicketData.ServerName.toLowerCase());
                return {
                    symbol: isEmptyOrNull(currSymbol) ? "" : currSymbol?.name,
                    serverName: isEmptyOrNull(currServer) ? currTicketData.ServerName : currServer?.server,
                    closeTime: currTicketData.ServerCloseTime,
                    profit: currTicketData.Profit,
                    comment: currTicketData.Comment,
                    ticket: currTicketData.Ticket,
                    order: currTicketData.Order,
                    deal: currTicketData.Deal,
                    openTime: currTicketData.ServerOpenTime,
                    login: currTicketData.Login,
                };
            });
            setData(newFileItemData);

            if (newFileItemData[0].serverName.includes("MT5")) setIsMt5(true);
            else setIsMt5(false);
        }
        setIsLoading(false);
        return () => {};
    }, [props.fileItemData, props.errorMessage, props.uploadedServer]);

    useEffect(() => {
        setData([]);
        setErrMsg("");
    }, []);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, fData: any) => {
        switch (type) {
            case CALLBACK_KEY.OTHERS:
                setIsLoading(true);
                let formData = new FormData();
                formData.append("File", props.uploadedFileItem);
                if (isMt5) formData.append("UploadServer", "1");
                else formData.append("UploadServer", "0");

                apiRequest(APIs.BATCH_UPLOAD_TICKET_RESTORATION_FOR_PREVIEW, formData, "POST", "json", {
                    Accept: "text/plain",
                    "Content-type": "multipart/form-data",
                })
                    .then((res: PreviewMt4RestoreTradeStopOut[]) => {
                        navigate("/admintools/ticket/calculate", {
                            state: {
                                uploadedData: data,
                                previewData: res,
                                serverId: uploadedServer && uploadedServer.id,
                                method: "byUpload",
                            },
                        });
                    })
                    .catch((err: any) =>
                        ErrorCatchValidator(err, (err: any) =>
                            ErrorMessageHandler(`Failed to batch upload for ticket restoration`, SUCCESS_FAILED.OTHERS_FAILED, err)
                        )
                    )
                    .finally(() => setIsLoading(false));
                break;
            default:
                break;
        }
    };

    return (
        <div className="content">
            <FlexiDataTable
                rowKeyProperty={isMt5 ? "deal" : "ticket"}
                title={
                    errMsg.length > 0 ? (
                        <span style={{ color: "red" }}>
                            <WarningOutlined /> {errMsg}
                        </span>
                    ) : (
                        ""
                    )
                }
                columns={tableCols}
                options={{
                    ...previewButton,
                    enableFilter: false,
                    autoFilter: false,
                    showHideColumns: false,
                }}
                callback={componentCallback}
                dataSource={data || []}
                loading={isLoading}
            />
        </div>
    );
};

export default BatchUploadTab;
