import CardBox from "../../../../components/Common/CardBox";
import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, CustomPaginationProps } from "../../../../constants/type";
import { APIs } from "../../../../services/apis";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { hasAnyKey } from "../../../../utils/object";
import { defaultIfEmptyOrNull } from "../../../../utils/string";
import { ContainerOutlined, DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import HedgeToolDownloadModal, { HedgeToolDownloadModalCallbackKey } from "./components/HedgeToolDownloadModal";
import { message } from "antd";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "../../../ReportingModule/ReportBatch";

export interface HedgeToolsPageProps {}

interface HedgeToolDataProps {
    id: number;
    reportDate: string;
    mainServer: string;
    mainLogin: number;
    server: string;
    login: number;
    credit: number;
    cpa: number | null;
    ib: number | null;
    country: string;
    email: string;
    mainSumVolume: number;
    sumVolume: number;
    countTicket: number;
    mainSumPnl: number;
    sumPnl: number;
    avgHoldTime: number;
    encodeCountry: string;
    encodeEmail: string;
}

const HedgeToolsPage = (props: HedgeToolsPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<HedgeToolDataProps[]>([]);
    const [isDownloadReportModalVisible, setIsDownloadReportModalVisible] = useState<boolean>(false);
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorting, setSorting] = useState<string>("createdDateUtc,desc");
    const [auditLogKey, setAuditLogKey] = useState<string>("");
    const [isShowSensitiveData, setIsShowSensitiveData] = useState<boolean>(false);
    const [currentReportDate, setCurrentReportDate] = useState<string>("");
    const [filterParams, setFilterParams] = useState<any>({});

    const columns: any[] = useMemo(() => {
        return [
            {
                title: "Main Login Or Login Search",
                dataIndex: "id",
                key: "id",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            },
            DTColProps.XSmall({
                title: "Main Server",
                dataIndex: "mainServer",
                key: "mainServer",
            }),
            {
                title: "Main Login",
                dataIndex: "mainLogin",
                key: "mainLogin",
            },
            DTColProps.XSmall({
                title: "Hedge Server",
                dataIndex: "server",
                key: "server",
            }),
            {
                title: "Hedge Login",
                dataIndex: "login",
                key: "login",
            },
            DTColProps.XSCurrency({
                title: "Credit",
                dataIndex: "credit",
                key: "credit",
            }),
            {
                title: "CPA",
                dataIndex: "cpa",
                key: "cpa",
            },
            {
                title: "IB",
                dataIndex: "ib",
                key: "ib",
            },
            ...(isShowSensitiveData
                ? [
                      {
                          title: "Country",
                          dataIndex: "country",
                          key: "country",
                      },
                      {
                          title: "Email",
                          dataIndex: "email",
                          key: "email",
                      },
                  ]
                : []),
            DTColProps.XSmall(
                {
                    title: "Main Login Trading Lots",
                    dataIndex: "mainSumVolume",
                    key: "mainSumVolume",
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Hedge Login Trading Lots",
                    dataIndex: "sumVolume",
                    key: "sumVolume",
                },
                ["text-right"]
            ),
            DTColProps.XSmall(
                {
                    title: "Count Ticket",
                    dataIndex: "countTicket",
                    key: "countTicket",
                },
                ["text-center"]
            ),
            DTColProps.XSCurrency({
                title: "Main Login PNL",
                dataIndex: "mainSumPnl",
                key: "mainSumPnl",
            }),
            DTColProps.XSCurrency({
                title: "Hedge Login PNL",
                dataIndex: "sumPnl",
                key: "sumPnl",
            }),
            DTColProps.XSmall(
                {
                    title: "Avg Hold Time (Minute)",
                    dataIndex: "avgHoldTime",
                    key: "avgHoldTime",
                },
                ["text-center"]
            ),
        ];
    }, [isShowSensitiveData]);

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
        extraButtons: [
            {
                text: "Show Sensitive Data",
                icon: <EyeOutlined />,
                value: "sensitiveData",
            },
            { text: "Report Listing", value: "reportlist", icon: <ContainerOutlined /> },
            {
                text: "Download Report",
                icon: <DownloadOutlined />,
                value: "downloadReport",
            },
        ],
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "id") {
                            filterParams["search"] = FormData[x];
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setSorting("");
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "sensitiveData") {
                    logShowSensitiveData();
                    setIsShowSensitiveData(true);
                } else if (FormData === "downloadReport") {
                    setIsDownloadReportModalVisible(true);
                } else if (FormData === "reportlist") {
                    setIsReportModalVisible(true);
                }
                break;
            default:
                break;
        }
    };

    const downloadReport = (date: string) => {
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_HEDGE_TOOL_DOWNLOAD}?date=${date}`)
            .then((res: any) => {
                if (res.data === 0) {
                    ErrorMessageHandler(`Add to batch successed. Please download from【Report Listing】.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                } else {
                    ErrorMessageHandler(`Add to batch failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) => ErrorMessageHandler(`Add to batch failed: "${error.message}".`, SUCCESS_FAILED.OTHERS_FAILED));
    };

    const logShowSensitiveData = useCallback(() => {
        plainAxiosInstance.get(`${APIs.RISK_TOOL.GET_HEDGE_TOOL_SHOW_SENSITIVE_DATA}?auditKey=${auditLogKey}`);
    }, [auditLogKey]);

    const getMappingData = useCallback(() => {
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_HEDGE_TOOL_DATA, { page: pagination.current, rows: pagination.pageSize, search: "", ...filterParams })
            .then((res: any) => {
                if (hasAnyKey(res.data)) {
                    let resData = Object.keys(res.data).reduce((obj: any, key: any) => {
                        if (res.data[key]?.hasOwnProperty("resultList")) {
                            obj = {
                                auditLogKey: key,
                                data: res.data[key].resultList,
                                total: res.data[key].total,
                            };
                        }
                        return obj;
                    }, {});
                    setAuditLogKey(resData.auditLogKey);
                    setData(resData.data);
                    setPagination(prev => ({ ...prev, total: resData.total }));
                } else {
                    setAuditLogKey("");
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hedge tool", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    }, [filterParams, pagination]);

    const initConfig = () => {
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_HEDGE_TOOL_GET_REPORTDATE)
            .then((res: any) => {
                if (res.data) {
                    setCurrentReportDate(res.data);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hedge tool report date", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            );
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getMappingData();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        initConfig();
    }, []);

    return (
        <>
            <div className="hedge-tool-page-container">
                <CardBox title={"Hedge Tool"}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="id"
                        title={
                            <span style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}>
                                Report Date: {defaultIfEmptyOrNull(currentReportDate, "")}
                            </span>
                        }
                        columns={columns}
                        options={options}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading}
                        pagination={pagination}
                        serverSide={true}
                    />
                </CardBox>
            </div>
            <HedgeToolDownloadModal
                isModalVisible={isDownloadReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case HedgeToolDownloadModalCallbackKey.Close:
                            setIsDownloadReportModalVisible(false);
                            break;
                        case HedgeToolDownloadModalCallbackKey.Submit:
                            downloadReport(data.reportDate.format("YYYY-MM-DD"));
                            setIsDownloadReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
            />
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "Hedge"}
            />
        </>
    );
};

export default HedgeToolsPage;
